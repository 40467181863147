import {GET_OPT_CATEGORY} from "../../actionConst/getActions";

const initialState = {
    optCategory: {}
}

export const optCategoriesReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_OPT_CATEGORY:
            return {
                ...state,
                optCategory: action.payload
            }
        default:
            return state
    }
}