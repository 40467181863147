import React from 'react'

import bg1 from '../../../../img/quiz/bg1.png'

const QuizModalStart = props => {

    return (
        <div className="modal-quiz-start">
            <div className="modal-close">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M25.3332 8.54602L23.4532 6.66602L15.9998 14.1193L8.5465 6.66602L6.6665 8.54602L14.1198 15.9993L6.6665 23.4527L8.5465 25.3327L15.9998 17.8793L23.4532 25.3327L25.3332 23.4527L17.8798 15.9993L25.3332 8.54602Z"
                        fill="#868686"/>
                </svg>
            </div>
            <div className="modal-quiz-start__bg">
                <img src={bg1} alt="" />
            </div>
            <div className="modal-quiz-start__body">
                <div className="modal-quiz-start__title">
                    5 простих кроків для підбору необхідного кондиціонера
                </div>
                <button className="modal-quiz-start__start-btn">
                    Почати
                </button>
            </div>
        </div>
    )
}

export default QuizModalStart