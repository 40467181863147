import React, {Fragment} from 'react'
import Slider from 'react-slick'
import CatalogBannerSlide from "./CatalogBannerSlide";
import CatalogBannerPrev from "./CatalogBannerPrev";
import CatalogBannerNext from "./CatalogBannerNext";

const CatalogBanner = (props) => {

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        prevArrow: <CatalogBannerPrev />,
        nextArrow: <CatalogBannerNext />,
        className: 'catalog-slider',
    }

    return (
        <div className="catalog-slider-wrap">
            <Slider {...settings}>
                {
                    props.banners.map((data, i) => {
                        return (
                            <Fragment key={i}>
                                <CatalogBannerSlide data={data}/>
                            </Fragment>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default CatalogBanner