import React, {useState} from 'react'
import arrowDown from '../../img/arrow-down-18.svg'

const CasesHeader = (props) => {

    const [drop, setDrop] = useState(false)

    return (
        <div className="cases__header">
            <h1 className="page-title">Фото наших робіт</h1>
            <div className={`content-filter ${!drop? '' : 'active'}`}>
                <div className="content-filter__head" onClick={() => setDrop(!drop)}>
                    <div className="content-filter__title">Категорії: </div>
                    <div className="content-filter__picked">
                        <div className="content-filter__item">{props.tag}</div>
                        <div className="arrow-down">
                            <img src={arrowDown} alt="" />
                        </div>
                    </div>
                </div>
                <ul className="content-filter__dropdown">
                    <li className="content-filter__item" onClick={() => {
                        setDrop(!drop)
                        props.setTag('Всі')
                    }}>{'Всі'}</li>
                    {
                        props.categories.map((data, i) => {
                            return (
                                <li className="content-filter__item" key={i} onClick={() => {
                                    setDrop(!drop)
                                    props.setTag(data)
                                }}>{data}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default CasesHeader