import React, {useEffect} from 'react'
import bg from '../../img/page404/bg.png'
import nf from '../../img/page404/404.png'
import './Page404.scss'
import {Link} from "react-router-dom";
import ScrollToTopOnMount from "../../ScrolToTop";

const Page404 = () => {

    useEffect(() => {
        document.title = 'Сторінку не знайдено';
    });

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <div className="content">
                <main className="page-404">
                    <div className="container">
                        <div className="page-404__background">
                            <img src={bg} alt="Помилка 404" />
                        </div>
                        <div className="page-404__body">
                            <div className="page-404__text-wrap">
                                <h2 className="page-404__title">
                                    Сторінку не знайдено
                                </h2>
                                <p className="page-404__subtitle">
                                    Поверніться до головної сторінки або скористайтеся пошуком по сайту
                                </p>
                                <div className="page-404__bottom-wrap">
                                    <Link className="page-404__back-to-main" to='/'>
                                        На головну
                                    </Link>
                                    <div className="page-404__img">
                                        <img src={nf} alt="Помилка 404" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Page404