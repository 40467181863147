import React, {useState} from "react";
import {connect} from 'react-redux'
import check from "../../../../img/check.svg";

const CatalogFilterBlockItem = (props) => {
    const checked = props.checked ? props.checked.includes(props.data.id) : false;

    return (
        <>
            <label className="checkbox-label">
                <input type="checkbox" name="cond-system" checked={checked} onClick={() => {
                    props.setUrlFilters(checked ? props.data.url0 : props.data.url1)
                }}/>
                <div className="checkbox">
                    <img src={check} alt="checkmark"/>
                    <span className="input-text">
                        {props.data.name}
                    </span>
                </div>
                <span className='filter-counter'>
                    {props.data.products_count}
                </span>
            </label>
        </>
    )
}

const mapStateToProps = store => {
    return {
        preFilter: store.catalogFilterReducer.preFilter,
    }
}

export default connect(mapStateToProps)(CatalogFilterBlockItem)