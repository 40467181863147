import React from 'react'
import {Link} from "react-router-dom";

const FooterListItems = (props) => {

    return (
        <>
            {
                props.items.map((data, i) => {
                    return (
                        <Link key={i} className="footer__link" to={data.link}>
                            {data.name}
                        </Link>
                    )
                })
            }
        </>
    )
}

export default FooterListItems