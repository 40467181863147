import React, {useEffect, useState} from 'react'
import BreadCrumbs from '../globalComponents/BreadCrumbs/BreadCrumbs';
import ProductsSlider from '../globalComponents/ProductsSlider/ProductsSlider';
import {getOptCategory} from '../../redux/actionCreators/getCreators/getOptCategory';
import {connect} from 'react-redux'
import bg1 from '../../img/cases/case-article-bg1.svg'
import bg2 from '../../img/cases/case-article-bg2.svg'
import callback from '../../img/cases/callback.svg'
import './OptCatalog.scss'
import '../CasesPage/CasesPage.scss'
import ScrollToTopOnMount from '../../ScrolToTop';
import {_api, _images} from '../../API';
import {
    toggleModal,
    toggleCallMeBack,
} from '../../redux/actionCreators/modalsCreator';
import Preloader from '../globalComponents/Preloader/Preloader';
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";
import {Redirect} from 'react-router';

const OptCatalog = props => {

    const [loading, setLoading] = useState(true)

    const [seoH1Page, setSeoH1Page] = useState()
    const [seoTitlePage, setSeoTitlePage] = useState()
    const [seoDescription, setSeoDescription] = useState()

    const [title, setTitle] = useState()
    const [slug, setSlug] = useState()
    const [image, setImage] = useState()
    const [text, setText] = useState()
    const [products, setProducts] = useState()

    const [isOptCategoryNotFound, setIsOptCategoryNotFound] = useState(false);

    useEffect(() => {
        setLoading(true)
        window.axios.get(`${_api}/opt_category/${props.match.params.slug}`)
            .then(res => {
                setTitle(res.data.title)
                setSlug(res.data.slug)
                setImage(res.data.image)
                setText(res.data.text)

                setSeoH1Page(res.data.metadata['seo-h1'])
                setSeoTitlePage(res.data.metadata['seo-title'])
                setSeoDescription(res.data.metadata['seo-description'])

                setProducts(res.data.products)

                props.getOptCategory(res.data)

                props.toggleModal(true)
                props.toggleCallMeBack(true)
            })
            .catch(() => {
                setIsOptCategoryNotFound(true);
            })
            .finally(() => setLoading(false))
    }, [props.match.params.slug]);

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: title, link: `/${slug}`},
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount/>
            {
                isOptCategoryNotFound
                    ? (<Redirect to='/404' />)
                    : (
                        <>
                            <MetaTags>
                                {
                                    seoTitlePage !== null && seoTitlePage !== undefined ? (
                                        <>
                                            <title>{seoTitlePage}</title>
                                            <meta property="og:title" content={seoTitlePage}/>
                                        </>
                                    ) : (
                                        <>
                                            <title>{title}</title>
                                            <meta property="og:title" content={title}/>
                                        </>
                                    )
                                }
                                {
                                    seoDescription !== null && seoDescription !== undefined ? (
                                        <>
                                            <meta name="description" content={seoDescription}/>
                                            <meta property="og:description" content={seoDescription}/>
                                        </>
                                    ) : (
                                        <>
                                            <meta name="description" content={title}/>
                                            <meta property="og:description" content={title}/>
                                        </>
                                    )
                                }
                                <meta property="og:locale" content="ua_UA" />
                                <meta property="og:type" content="website" />
                                <meta property="og:url" content={_domain+window.location.pathname} />
                                <meta property="og:image" content={_domain+logo} />
                                <meta property="og:site_name" content="Ліка Комфорт" />
                            </MetaTags>
                            <div className="content">
                                <main className="case-article">
                                    <div className="container">
                                        <BreadCrumbs items={crumbs}/>
                                    </div>
                                    {
                                        loading ? (
                                            <Preloader/>
                                        ) : (
                                            <>
                                                <div className="case-header">
                                                    <div className="case-header__title-section opt">
                                                        <div className="case-header__title-section-bg">
                                                            <img className="case-header__bg-img-left" src={bg1} alt=""/>
                                                            <img className="case-header__bg-img-right" src={bg2} alt=""/>
                                                        </div>
                                                        <h1 className="case-header__title">{((seoH1Page !== null) && (seoH1Page !== undefined)) ? seoH1Page : title}</h1>
                                                    </div>
                                                    <div className="case-header__img-section">
                                                        <img src={`${_images}${image}`} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="container">
                                                    <article className="case-article__body" dangerouslySetInnerHTML={{__html: [text]}}/>
                                                    {
                                                        products.length > 0 ? (
                                                            <div className="slider-container slider-container--product">
                                                                <ProductsSlider
                                                                    name={''}
                                                                    products={products}
                                                                />
                                                            </div>
                                                        ) : ''
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className="callback-container">
                                        <div className="container">
                                            <h4 className="callback-container__title">Цікавить співпраця з нами?<br/>Залиши свою заявку</h4>
                                            <div className="callback-container__call-wrap">
                                                <div className="callback-container__img">
                                                    <img src={callback} alt="Заявка на консультацію"/>
                                                </div>
                                                <button className="callback-container__sumbit btn-dark" onClick={() => {
                                                    props.toggleModal(true)
                                                    props.toggleCallMeBack(true)
                                                }}>Залишити заявку
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </>
                    )
            }
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        optCategory: store.optCategoriesReducer.optCategory,
    }
}

const mapDispatchToProps = {
    getOptCategory,
    toggleModal,
    toggleCallMeBack,
}

export default connect(mapStateToProps, mapDispatchToProps)(OptCatalog)
