import React from "react";

const OrderBodyDataPayScore = (props) => {
  return (
    <>
      <label className="radio-wrap">
        <input
          id="score"
          type="radio"
          name="payment"
          checked={props.activeRadio === "score"}
          onChange={props.openSubSelect}
          onClick={() => {
            props.addDeliveryPay(2);
            props.addDeliveryPayForBitrix(3);
          }}
        />
        <span className="radio">
          <svg
            style={{ marginRight: "8px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.239 0.118522C17.0766 0.00103372 16.8666 -0.032713 16.6766 0.0335305L13.1444 1.21092L10.2322 0.0460293C10.0835 -0.0139648 9.91726 -0.0139648 9.76853 0.0460293L6.85631 1.21092L3.32291 0.0335305C3.13168 -0.032713 2.92295 0.00103372 2.76046 0.118522C2.59798 0.236011 2.50049 0.424742 2.50049 0.625973V19.3741C2.50049 19.5754 2.59673 19.7641 2.76046 19.8803C2.92295 19.9991 3.13293 20.0266 3.32416 19.9666L6.85631 18.7892L9.76978 19.9541C9.91851 20.0153 10.0847 20.0153 10.2335 19.9541L13.1457 18.7892L16.6779 19.9666C16.7416 19.9878 16.8078 19.9991 16.8741 19.9991C17.0041 19.9991 17.1316 19.9591 17.239 19.8803C17.4028 19.7641 17.499 19.5754 17.499 19.3741V0.625973C17.499 0.424742 17.4028 0.236011 17.239 0.118522Z"
              fill="#ECEFF1"
            />
            <path
              d="M10.6249 5.00037H5.62543C5.28046 5.00037 5.00049 4.72039 5.00049 4.37543C5.00049 4.03046 5.28046 3.75049 5.62543 3.75049H10.6249C10.9699 3.75049 11.2499 4.03046 11.2499 4.37543C11.2499 4.72039 10.9699 5.00037 10.6249 5.00037Z"
              fill="#B0BEC5"
            />
            <path
              d="M9.37506 9.99988H5.62543C5.28046 9.99988 5.00049 9.7199 5.00049 9.37494C5.00049 9.02997 5.28046 8.75 5.62543 8.75H9.37506C9.72003 8.75 10 9.02997 10 9.37494C10 9.7199 9.72003 9.99988 9.37506 9.99988Z"
              fill="#B0BEC5"
            />
            <path
              d="M9.37506 13.7496H5.62543C5.28046 13.7496 5.00049 13.4697 5.00049 13.1247C5.00049 12.7797 5.28046 12.4998 5.62543 12.4998H9.37506C9.72003 12.4998 10 12.7797 10 13.1247C10 13.4697 9.72003 13.7496 9.37506 13.7496Z"
              fill="#B0BEC5"
            />
            <path
              d="M14.3746 7.50012H5.62543C5.28046 7.50012 5.00049 7.22015 5.00049 6.87518C5.00049 6.53022 5.28046 6.25024 5.62543 6.25024H14.3746C14.7195 6.25024 14.9995 6.53022 14.9995 6.87518C14.9995 7.22015 14.7195 7.50012 14.3746 7.50012Z"
              fill="#B0BEC5"
            />
            <path
              d="M14.9996 13.437C14.9996 12.5759 14.1585 11.8747 13.1248 11.8747C12.7424 11.8747 12.4999 11.6697 12.4999 11.5622C12.4999 11.4547 12.7424 11.2498 13.1248 11.2498C13.316 11.2498 13.486 11.2947 13.6148 11.381C13.9035 11.5735 14.2897 11.496 14.4822 11.2085C14.6734 10.9223 14.5972 10.5336 14.3097 10.3423C14.141 10.2299 13.951 10.1436 13.7498 10.0861V9.37494C13.7498 9.02997 13.4698 8.75 13.1248 8.75C12.7798 8.75 12.4999 9.02997 12.4999 9.37494V10.0961C11.7737 10.3111 11.25 10.8835 11.25 11.5622C11.25 12.4234 12.0912 13.1246 13.1248 13.1246C13.5073 13.1246 13.7498 13.3296 13.7498 13.437C13.7498 13.5445 13.5073 13.7495 13.1248 13.7495C12.9348 13.7495 12.7648 13.7033 12.6349 13.617C12.3474 13.427 11.9599 13.5033 11.7674 13.7895C11.5762 14.077 11.6525 14.4657 11.9399 14.6569C12.1099 14.7707 12.2986 14.8557 12.4999 14.9131V15.6243C12.4999 15.9693 12.7798 16.2493 13.1248 16.2493C13.4698 16.2493 13.7498 15.9693 13.7498 15.6243V14.9031C14.4759 14.6882 14.9996 14.1157 14.9996 13.437Z"
              fill="#4CAF50"
            />

            <defs>
              <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Рахунок-фактура (безготівково для юридичних осіб)
        </span>
      </label>
      <div
        className={`radio-wrap__drop-section ${
          props.activeRadio === "score" ? "active" : ""
        }`}
      >
        <p className="radio-wrap__drop-section-descr">
          Увага! Для оплати обраним способом необхідно ввести код ЄДРПОУ
          юридичної особи, з розрахункового рахунку якого буде здійснена оплата
        </p>
        <label className="form__label">
          <input
            className="form__input"
            type="email"
            placeholder=" "
            required
            onChange={(e) => {
              props.addDeliveryEmail(e.target.value);
            }}
          />
          <span className="standart">Електронна адреса</span>
        </label>
        <label className="form__label">
          <input
            className="form__input"
            type="text"
            placeholder=" "
            required
            onChange={(e) => {
              props.addDeliveryName(e.target.value);
            }}
          />
          <span className="standart">Повна названа юридичної особи</span>
        </label>
        <label className="form__label">
          <input
            className="form__input"
            type="text"
            placeholder=" "
            required
            onChange={(e) => {
              props.addDeliveryCode(e.target.value);
            }}
          />
          <span className="standart">Код ЄДРПОУ</span>
        </label>
      </div>
    </>
  );
};

export default OrderBodyDataPayScore;
