import React from 'react'
import './BuyOneClickThx.scss'

const BuyOneClickThx = props => {

    return (
        <div className={`buy-on-click ${props.buyOneClick? 'active' : ''}`}>
            <button className="close-btn" onClick={() => {
                props.toggleBuyOneClick(false)
                props.toggleModal(false)
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="buy-on-click__title">Дякуємо!</div>
            <div className="buy-on-click__subtitle">Найближчим часом наш спеціаліст зателефонує вам</div>
            <div className="buy-on-click__done-btn btn-light" onClick={() => {
                props.toggleBuyOneClick(false)
                props.toggleModal(false)
            }}>Готово</div>
        </div>
    )
}

export default BuyOneClickThx