import { initialState } from './catalogProductsReducer'
import {FILTER} from "../../actionConst/action";

export const filterReducer = (state = initialState, action) => {
    if(action.type === FILTER) {
        return [
            ...state,
            {
                filters: [...state.filters.push(action.payload)]
            }
        ]
    }
    return state
}