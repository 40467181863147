import React from 'react'

const MenuBtn = (props) => {

    return (
        <div className={`menu-btn-wrap ${props.mobileMenuCategory? 'active' : ''}`} onClick={props.openMobileMenu}>
            <div className='menu-btn'>
                <span/>
                <span/>
                <span/>
            </div>
        </div>
    )
}

export default MenuBtn