import React from "react";
import CatalogFilterTagsItem from "./CatalogFilterTagsItem";

const CatalogFilterTags = (props) => {
    return (
        <div className={`filter__picked-section`}>
            <div className="filter__picked-items-wrap">
                {
                    props.appliedFilters?.brands?.map((data, i) => {
                        return (
                            <CatalogFilterTagsItem
                                key={i}
                                name={`Бренд: `+props.brands.find(function(el) { return el.id == data }).name}
                                url={props.brands.find(function(el) { return el.id == data }).url0}
                                setUrlFilters={props.setUrlFilters}
                            />
                        )
                    })
                }
                {
                    props.appliedFilters?.prices ? (
                        <CatalogFilterTagsItem
                            key={`prices`}
                            name={`Ціна: `+props.appliedFilters?.prices.min+` - `+props.appliedFilters?.prices.max}
                            url={props.prices.url0}
                            setUrlFilters={props.setUrlFilters}
                        />
                    ) : ''
                }
                {
                    props.appliedFilters?.specifications?.map((data, i) => {

                        var specification = null; var value = null; var temp = null;
                        Object.keys(props.specifications).map((data2, i2) => {
                            temp = props.specifications[data2].values.find(function(el) { return el.id == data });
                            if(temp) {
                                specification = props.specifications[data2].property;
                                value = temp;
                            }
                        })

                        return (
                            <CatalogFilterTagsItem
                                key={i}
                                name={specification.title+`: `+value.name}
                                url={value.url0}
                                setUrlFilters={props.setUrlFilters}
                            />
                        )
                    })
                }
            </div>
            {
                Object.keys(props.appliedFilters).length ? (
                    <div className="filter__reset-section">
                        <button className="filter__reset-btn" onClick={() => {
                            props.setUrlFilters(props.base.url0)
                        }}>
                            Очистити фільтр
                        </button>
                    </div>
                ) : ''
            }
        </div>
    )
}

export default CatalogFilterTags