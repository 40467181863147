import {
    GET_DISCOUNT_PRODUCTS,
    GET_MAIN_PAGE_BANNERS,
    GET_NEW_PRODUCTS,
    GET_NEW_INSTALYATOR_PRODUCTS,
    GET_PARTNERS_SLIDES
} from "../../actionConst/getActions";

export const getNewProducts = payload => {
    return {
        type: GET_NEW_PRODUCTS,
        payload
    }
}
export const getNewInstalyatorProducts = payload => {
    return {
        type: GET_NEW_INSTALYATOR_PRODUCTS,
        payload
    }
}
export const getDiscountProducts = payload => {
    return {
        type: GET_DISCOUNT_PRODUCTS,
        payload
    }
}
export const getMainPageBanners = payload => {
    return {
        type: GET_MAIN_PAGE_BANNERS,
        payload
    }
}
export const getMainPagePartners = payload => {
    return {
        type: GET_PARTNERS_SLIDES,
        payload
    }
}