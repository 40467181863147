import React, {Fragment} from 'react'
import Slider from 'react-slick'

import ProductPageRecommendedSliderPrev from "./ProductPageRecommendedSliderPrev";
import ProductRecommendedSliderNext from "./ProductRecommendedSliderNext";
import ProductPageRecommendedSliderSlide from "./ProductPageRecommendedSliderSlide";

const ProductPageRecommendedSlider = (props) => {

    const settings = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        className: 'products-slider products-slider--related',
        // // cssEase: 'ease-in-out',
        prevArrow: <ProductPageRecommendedSliderPrev/>,
        nextArrow: <ProductRecommendedSliderNext />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }

    return (
        <div className="slider-container slider-container--related">
            <div className="container">
                <h3 className="main-page__title">Рекомендовані товари</h3>
            </div>
            <div className="products-slider-wrap products-slider-wrap--related">
                <Slider {...settings}>
                    {
                        props.products.map((data, i) => {
                            return (
                                <Fragment key={i}>
                                    <ProductPageRecommendedSliderSlide data={data}/>
                                </Fragment>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default ProductPageRecommendedSlider