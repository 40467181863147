import slide1 from '../../../img/about-us/slide1.jpg'
import slide2 from '../../../img/about-us/slide2.jpg'
import slide3 from '../../../img/about-us/slide3.jpg'
import slide4 from '../../../img/about-us/slide4.jpg'
import {GET_EVENTS_ABOUT_US} from "../../actionConst/getActions";

const initialState = {
    slider : [
        {link: '/', name: 'Семінар Samsung', date: '21.02.2020', image: slide1,},
        {link: '/', name: 'Конференція по VRV системах «Cooper&Hunter», Відень', date: '14.02.2020', image: slide2,},
        {link: '/', name: 'Cемінар-практикум «Cooper&Hunter»', date: '12.02.2020', image: slide3,},
        {link: '/', name: 'Семінар TCL для фахівців галузі', date: '09.05.2019', image: slide4,},
        {link: '/', name: 'Cемінар-практикум «Cooper&Hunter»', date: '12.02.2020', image: slide3,},
    ]
}

export const aboutUsReducer = (state = initialState, action) => {
    if(action.type === GET_EVENTS_ABOUT_US) {
        return {
            ...state,
            slider: action.payload
        }
    } else {
        return state
    }
}