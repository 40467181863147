import React from "react";

const CatalogBannerNext = (props) => {

    return (
        <button className="slider-arrow-right" onClick={props.onClick}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="32" width="32" height="32" transform="rotate(90 32 0)" fill="white"
                      fillOpacity="0.5"/>
                <path
                      d="M14.1642 25.6702C13.9868 25.581 13.8519 25.4252 13.7892 25.2368C13.7265 25.0484 13.7411 24.8428 13.8297 24.6652L18.1602 15.9997L13.8282 7.33565C13.7832 7.24751 13.756 7.15132 13.7484 7.05264C13.7407 6.95396 13.7526 6.85473 13.7835 6.76068C13.8143 6.66663 13.8635 6.57962 13.9281 6.50466C13.9928 6.4297 14.0716 6.36828 14.1601 6.32394C14.2486 6.27959 14.345 6.2532 14.4437 6.24629C14.5424 6.23938 14.6416 6.25208 14.7354 6.28366C14.8292 6.31524 14.9158 6.36507 14.9903 6.43029C15.0647 6.49551 15.1255 6.57482 15.1692 6.66365L19.6692 15.6637C19.7215 15.7679 19.7487 15.883 19.7487 15.9997C19.7487 16.1163 19.7215 16.2314 19.6692 16.3357L15.1692 25.3357C15.0801 25.5131 14.9242 25.6479 14.7358 25.7106C14.5474 25.7733 14.3419 25.7588 14.1642 25.6702Z"
                      fill="white"/>
            </svg>
        </button>
    )
}

export default CatalogBannerNext