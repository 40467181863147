import React from 'react'

const ProductPageModalCost = props => {

    return (
        <div className="product-info__pricing">
            <div className="product-info__price-wrap">
                {
                    props.discount > 0 ? (
                        <p className="product-info__discounted-price">{props.price.toLocaleString()} грн</p>
                    ) : ''
                }
                <h4 className="product-info__price">{
                    props.final_price === 0 ? (
                        'За домовленістю'
                    ) : props.final_price.toLocaleString() + ' грн'
                }</h4>
            </div>
            {
                props.discount > 0 ? (
                    <div className="product-info__discount">
                        <div className="product-info__discount-text">Знижка</div>
                        <div className="product-info__discount-val">{props.discount.toLocaleString()} грн</div>
                    </div>
                ) : ''
            }
        </div>
    )
}

export default ProductPageModalCost