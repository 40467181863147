import React from 'react'
import {_images} from "../../../../API";

const ProductPageSliderSlide = props => {

    return (
        <div className="product-slider-modal__slide-item">
            <img src={`${_images}${props.data}`} alt="image" />
        </div>
    )
}

export default ProductPageSliderSlide