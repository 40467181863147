import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import check from "../../../../img/check.svg";
import OrderBodyBasketProducts from "./OrderBodyBasketProducts";
import OrderBodyBasketPrice from "./OrderBodyBasketPrice";
import { Link } from "react-router-dom";
import {
  clearBasket,
  addQuantityBasket,
  removeQuantityBasket,
  removeBasket,
  removeServices,
  addServices,
} from "../../../../redux/actionCreators/basketProducts";
import { getOrderData } from "../../../../redux/actionCreators/getCreators/getOrder";

import {
  toggleOrderConfirm,
  toggleModal,
} from "../../../../redux/actionCreators/modalsCreator";
import { useHistory } from "react-router";
import { cleanPhone } from "../../../CleanPhone";
import { createLeadOrder } from "../../../../bitrixApi";
import {analyticEvents} from '../../../../utils/googleAnalytics/events';

const OrderBodyBasket = (props) => {
  const [orderProducts, setOrderProducts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setOrderProducts([]);
    props.products.map((data) => {
      let product = {
        id: data.id,
        quantity: data.quantity,
        services: data.new_services_id,
      };
      setOrderProducts((prev) => [...prev, product]);
    });
  }, [props.products]);

  const [checked, setChecked] = useState(true);

  const postOrderBranch = () => {
    if (
      props.delivery.deliveryPay.name === 1 ||
      props.delivery.deliveryPay.name === 3
    ) {
      window.axios
        .post(`/orders`, {
          delivery_method: props.delivery.deliveryWay,
          payment_method: props.delivery.deliveryPay.name,
          back_call: props.callback,
          delivery_price: props.delivery.cost,
          cashback: props.delivery.useCashback,
          bill_to: {
            name: props.info.name,
            last_name: props.info.lastName,
            phone: props.info.phone === "" ? "" : cleanPhone(props.info.phone),
            email: props.info.email,
          },
          ship_to: {
            city: props.delivery.city,
            office: props.delivery.branch,
            street: props.delivery.address.street,
            house: props.delivery.address.house,
            flat: props.delivery.address.at,
            floor: props.delivery.address.floor,
          },
          products: orderProducts,
        })
        .then((res) => {
          props.getOrderData(res.data.data);
          analyticEvents.doOrder(res.data.data);
          localStorage.setItem("order", JSON.stringify(res.data.data));
          localStorage.setItem("basket", JSON.stringify([]));
          history.push("/order_result");
          createLeadOrder(
            cleanPhone(props.info.phone),
            props.info.name,
            props.info.lastName,
            props.products,
            props.delivery.deliveryWay,
            props.delivery.deliveryPay.name
          );
          setTimeout(() => {
            localStorage.setItem("basket", JSON.stringify([]));
            props.toggleOrderConfirm(true);
            props.toggleModal(true);
          }, 100);
        })
        .catch((res) => {
          props.setEmpty(true);
          Object.keys(res.response.data).forEach((data) => {
            if (data === "bill_to.name") {
              props.setErrorName(true);
            } else if (data === "bill_to.email") {
              props.setErrorEmail(true);
            } else if (data === "bill_to.last_name") {
              props.setErrorLastName(true);
            } else if (data === "bill_to.phone") {
              props.setErrorPhone(true);
            }
          });
        });
    } else if (props.delivery.deliveryPay.name === 2) {
      window.axios
        .post(`/orders`, {
          delivery_method: props.delivery.deliveryWay,
          payment_method: props.delivery.deliveryPay.name,
          back_call: props.callback,
          delivery_price: props.delivery.cost,
          cashback: props.delivery.useCashback,
          bill_to: {
            company_name: props.delivery.deliveryPay.userName,
            company_email: props.delivery.deliveryPay.email,
            edrpou: props.delivery.deliveryPay.code,
            name: props.info.name,
            last_name: props.info.lastName,
            phone: props.info.phone === "" ? "" : cleanPhone(props.info.phone),
            email: props.info.email,
          },
          ship_to: {
            city: props.delivery.city,
            office: props.delivery.branch,
            street: props.delivery.address.street,
            house: props.delivery.address.house,
            flat: props.delivery.address.at,
            floor: props.delivery.address.floor,
          },
          products: orderProducts,
        })
        .then((res) => {
          props.getOrderData(res.data.data);
          analyticEvents.doOrder(res.data.data);
          localStorage.setItem("order", JSON.stringify(res.data.data));
          localStorage.setItem("basket", JSON.stringify([]));
          history.push("/order_result");
          createLeadOrder(
            cleanPhone(props.info.phone),
            props.info.name,
            props.info.lastName,
            props.products,
            props.delivery.deliveryWay,
            props.delivery.deliveryPay.name
          );
        })
        .catch((res) => {
          props.setEmpty(true);
          Object.keys(res.response.data).forEach((data) => {
            if (data === "bill_to.name") {
              props.setErrorName(true);
            } else if (data === "bill_to.email") {
              props.setErrorEmail(true);
            } else if (data === "bill_to.last_name") {
              props.setErrorLastName(true);
            } else if (data === "bill_to.phone") {
              props.setErrorPhone(true);
            }
          });
        });
    } else if (
      props.delivery.deliveryPay.name === 5 ||
      props.delivery.deliveryPay.name === 6
    ) {
      window.axios
        .post(`/orders`, {
          delivery_method: props.delivery.deliveryWay,
          payment_method: props.delivery.deliveryPay.name,
          back_call: props.callback,
          delivery_price: props.delivery.cost,
          cashback: props.delivery.useCashback,
          bill_to: {
            name: props.info.name,
            last_name: props.info.lastName,
            phone: props.info.phone === "" ? "" : cleanPhone(props.info.phone),
            email: props.info.email,
          },
          ship_to: {
            city: props.delivery.city,
            office: props.delivery.branch,
            street: props.delivery.address.street,
            house: props.delivery.address.house,
            flat: props.delivery.address.at,
            floor: props.delivery.address.floor,
          },
          products: orderProducts,
          period: (props.delivery.deliveryPay.name === 5) ? props.delivery.deliveryPay.periodPrivat : props.delivery.deliveryPay.periodMono,
        })
        .then((res) => {
          props.getOrderData(res.data.data);
          analyticEvents.doOrder(res.data.data);
          localStorage.setItem("order", JSON.stringify(res.data.data));
          localStorage.setItem("basket", JSON.stringify([]));
          history.push("/order_result");
          createLeadOrder(
            cleanPhone(props.info.phone),
            props.info.name,
            props.info.lastName,
            props.products,
            props.delivery.deliveryWay,
            props.delivery.deliveryPay.name
          );
          setTimeout(() => {
            localStorage.setItem("basket", JSON.stringify([]));
            props.toggleOrderConfirm(true);
            props.toggleModal(true);
          }, 100);
        })
        .catch((res) => {
          props.setEmpty(true);
          Object.keys(res.response.data).forEach((data) => {
            if (data === "bill_to.name") {
              props.setErrorName(true);
            } else if (data === "bill_to.email") {
              props.setErrorEmail(true);
            } else if (data === "bill_to.last_name") {
              props.setErrorLastName(true);
            } else if (data === "bill_to.phone") {
              props.setErrorPhone(true);
            }
          });
        });
    } else {
      window.axios
        .post(`/orders`, {
          delivery_method: props.delivery.deliveryWay,
          payment_method: props.delivery.deliveryPay.name,
          back_call: props.callback,
          delivery_price: props.delivery.cost,
          cashback: props.delivery.useCashback,
          bill_to: {
            name: props.info.name,
            last_name: props.info.lastName,
            phone: props.info.phone === "" ? "" : cleanPhone(props.info.phone),
            email: props.info.email,
          },
          ship_to: {
            city: props.delivery.city,
            office: props.delivery.branch,
            street: props.delivery.address.street,
            house: props.delivery.address.house,
            flat: props.delivery.address.at,
            floor: props.delivery.address.floor,
          },
          products: orderProducts,
        })
        .then((res) => {
          props.getOrderData(res.data.data);
          analyticEvents.doOrder(res.data.data);
          localStorage.setItem("order", JSON.stringify(res.data.data));
          localStorage.setItem("basket", JSON.stringify([]));
          history.push("/order_result");
          createLeadOrder(
            cleanPhone(props.info.phone),
            props.info.name,
            props.info.lastName,
            props.products,
            props.delivery.deliveryWay,
            props.delivery.deliveryPay.name
          );
        })
        .catch((res) => {
          props.setEmpty(true);

          Object.keys(res.response.data).forEach((data) => {
            if (data === "bill_to.name") {
              props.setErrorName(true);
            } else if (data === "bill_to.email") {
              props.setErrorEmail(true);
            } else if (data === "bill_to.last_name") {
              props.setErrorLastName(true);
            } else if (data === "bill_to.phone") {
              props.setErrorPhone(true);
            }
          });
        });
    }
  };

  const changeInfo = () => {
    window.axios.put(`/users/${props.user.id}`, {
      data: {
        name: props.info.name,
        last_name: props.info.lastName,
        phone: cleanPhone(props.info.phone),
        email: props.info.email,
      },
    });
  };

  return (
    <div className="order-basket">
      <div className="order-basket__title">Ваше замовлення</div>
      <div className="order-basket__products-section">
        {props.products.map((data) => {
          return (
            <OrderBodyBasketProducts
              key={data.id}
              products={props.products}
              removeBasket={props.removeBasket}
              addQuantityBasket={props.addQuantityBasket}
              removeQuantityBasket={props.removeQuantityBasket}
              data={data}
              removeServices={props.removeServices}
              addServices={props.addServices}
            />
          );
        })}
      </div>
      <div className="order-basket__price-data">
        <OrderBodyBasketPrice
          delivery={props.delivery}
          products={props.products}
        />
      </div>
      {props.empty && (
        <span className="empty-feedback-text">
          <svg
            width="16"
            height="16"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z"
              fill="#DB4915"
            />
            <path
              d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z"
              fill="#DB4915"
            />
            <path
              d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z"
              fill="#DB4915"
            />
          </svg>
          Заповніть всі поля
        </span>
      )}
      <div className="filter__form">
        <div
          className="order-basket__confirm-btn btn-light"
          onClick={(e) => {
            if (props.info.isLogged) {
              changeInfo();
            }
            props.setEmpty(false);
            postOrderBranch();
          }}
        >
          Підтвердити замовлення
        </div>
        <label className="checkbox-label" onClick={() => setChecked(!checked)}>
          <input type="checkbox" name="trademark" checked={checked} />
          <div className="checkbox">
            <img src={check} alt="checkmark" />
            <span className="input-text">
              Підтверджуючи замовлення, я приймаю умови
              <Link className="input-text-link" to="/offer">
                Публічної оферти
              </Link>
            </span>
          </div>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    products: store.basketReducer.basket,
    delivery: store.basketReducer.delivery,
    info: store.userReducer.info,
    user: store.userReducer.userData,
  };
};

const mapDispatchToProps = {
  clearBasket,
  addQuantityBasket,
  removeQuantityBasket,
  removeBasket,
  removeServices,
  addServices,
  getOrderData,
  toggleOrderConfirm,
  toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBodyBasket);
