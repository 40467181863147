import React from 'react'

import mc from '../../../img/mastercard.svg'
import visa from '../../../img/visa.svg'

const OrderFooter = () => {

    return (
        <div className="order-page__footer">
            <div className="container">
                <div className="order-page__footer-copyrights">
                    © 2020, Ліка Комфорт. Всі права захищено.
                </div>
                <div className="order-page__footer-icons">
                    <img src={mc} alt="mastercard" />
                    <img src={visa} alt="visa" />
                </div>
            </div>
        </div>
    )
}

export default OrderFooter