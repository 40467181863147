import React from "react";
import {Link} from "react-router-dom";
import {_images} from "../../API";

const CasesBodyItem = props => {

    return (
        <Link className="case-item-wrap" to={`/cases_page/${props.data.slug}`}>
            <section className="case-item">
                <div className="case-item__head">
                    <div className={`case-item__label case-item__label--${props.data.tag === 'Кондиціонування та вентиляція' ? 'type1' : 'type2'}`}>
                        {props.data.tag}
                    </div>
                    <img src={`${_images}${props.data.preview_image}`} alt={props.data.title} />
                </div>
                <div className="case-item__description">
                    <h4 className="case-item__title">{props.data.title}</h4>
                    <p className="case-item__subtitle">
                        {props.data.description}
                    </p>
                </div>
            </section>
        </Link>
    )
}

export default CasesBodyItem