import React from 'react'

const OrderBodyBasketPrice = props => {

    const totalPrice = props.products.reduce((i, product) => {
        if(product.new_services) {
            return (
                i + product.final_price * product.quantity + product.new_services.reduce((j, service) => {
                    return (
                        j + service.price
                    )
                }, 0)
            )
        } else {
            return (
                i + product.final_price * product.quantity
            )
        }
    }, 0)

    return (
        <>
            <div className="order-basket__price-item">
                <div className="order-basket__price-item-title">
                    Всього:
                </div>
                <div className="order-basket__price-item-val">
                    {
                        totalPrice === 0 ? (
                            'За домовленістю'
                        ) : `${totalPrice} грн`
                    }
                </div>
            </div>
            <div className="order-basket__price-item">
                <div className="order-basket__price-item-title">
                    Доставка:
                </div>
                <div className="order-basket__price-item-val">
                    За тарифом
                </div>
            </div>
            <div className="order-basket__price-item">
                <div className="order-basket__price-item-title">
                    Знижка:
                </div>
                <div className="order-basket__price-item-val" style={{color: '#257226', fontWeight: 700}}>
                    {
                        props.products.reduce((i, item) => {
                            return i + item.discount * item.quantity
                        }, 0)
                    } грн
                </div>
            </div>
            <div className="order-basket__price-item order-basket__price-item--total">
                <div className="order-basket__price-item-title">
                    Разом до сплати:
                </div>
                <div className="order-basket__price-item-val">
                    {
                        totalPrice === 0 ? (
                            'За домовленістю'
                        ) : `${totalPrice + props.delivery.cost} грн`
                    }
                </div>
            </div>
        </>
    )
}

export default OrderBodyBasketPrice