import React from 'react'
import ScrollToTopOnMount from "../../ScrolToTop";
import '../ServicePage/ServicePage.scss'
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Offer = () => {
    return (
        <div className="content-wrap">
            <ScrollToTopOnMount/>
            <MetaTags>
                <title>Договір публічної оферти | Ліка-Комфорт</title>
                <meta name="description" content={`Договір публічної оферти компанія Ліка-Комфорт ☀ ☁ .`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Договір публічної оферти | Ліка-Комфорт" />
                <meta property="og:description" content="Договір публічної оферти компанія Ліка-Комфорт ☀ ☁ ." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content" style={{marginBottom: '100px'}}>
                <main className="services-info">
                    <svg className="services-info__bg-left" width="200" height="348" viewBox="0 0 200 348" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="51.0536" y="325.443" width="17.5651" height="17.5651"
                              transform="rotate(123.715 51.0536 325.443)" stroke="#43B02A" strokeOpacity="0.3"/>
                        <rect x="92.4013" y="40.7936" width="52.4257" height="52.4257"
                              transform="rotate(123.715 92.4013 40.7936)" stroke="#43B02A"/>
                        <rect x="136.334" y="100.376" width="80.4985" height="80.4985"
                              transform="rotate(123.715 136.334 100.376)" stroke="#DCDCDC"/>
                        <rect x="186.035" y="177.846" width="50.7197" height="50.7197"
                              transform="rotate(123.715 186.035 177.846)" stroke="#43B02A" strokeOpacity="0.5"/>
                        <rect x="133.185" y="254.315" width="59" height="59" transform="rotate(123.715 133.185 254.315)"
                              stroke="#DCDCDC" strokeOpacity="0.4"/>
                        <rect x="63.1298" y="161.89" width="68.8139" height="68.8139"
                              transform="rotate(123.715 63.1298 161.89)" fill="white" fillOpacity="0.1"
                              stroke="#43B02A"/>
                        <rect x="40.6113" y="261.674" width="39.5981" height="39.5981"
                              transform="rotate(123.715 40.6113 261.674)" stroke="#43B02A" strokeOpacity="0.4"/>
                        <rect x="23.0077" y="78.6374" width="59.3526" height="59.3526"
                              transform="rotate(123.715 23.0077 78.6374)" stroke="#DCDCDC"/>
                    </svg>
                    <svg className="services-info__bg-right" width="217" height="362" viewBox="0 0 217 362" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect x="187.814" y="51.2272" width="52.4257" height="52.4257"
                              transform="rotate(123.715 187.814 51.2272)" stroke="#43B02A"/>
                        <rect x="31.832" y="85.6433" width="17.5651" height="17.5651"
                              transform="rotate(123.715 31.832 85.6433)" stroke="#43B02A"/>
                        <rect x="226.134" y="117.668" width="80.4985" height="80.4985"
                              transform="rotate(123.715 226.134 117.668)" stroke="#43B02A"/>
                        <rect x="197.696" y="221.912" width="56.2435" height="56.2435"
                              transform="rotate(123.715 197.696 221.912)" stroke="#DCDCDC"/>
                        <rect x="118.522" y="99.3991" width="59.3526" height="59.3526"
                              transform="rotate(123.715 118.522 99.3991)" fill="white" fillOpacity="0.1"
                              stroke="#DCDCDC"/>
                        <rect x="129.936" y="286.601" width="34.0643" height="34.0643"
                              transform="rotate(123.715 129.936 286.601)" stroke="#43B02A" strokeOpacity="0.5"/>
                        <rect x="188.632" y="331.535" width="20.8121" height="20.8121"
                              transform="rotate(123.715 188.632 331.535)" stroke="#43B02A" strokeOpacity="0.3"/>
                        <rect x="124.931" y="45.2683" width="59.3526" height="59.3526"
                              transform="rotate(123.715 124.931 45.2683)" stroke="white"/>
                    </svg>
                    <div className="container">
                        <h1 className="services-info__title">Договір публічної оферти</h1>
                        <h2 style={{textAlign: 'center'}}>
                            Загальні положення
                        </h2>
                        <br/>
                        <p>
                            1.1. Справжня оферта, є офіційною пропозицією Ліка Комфорт, далі за текстом - «Продавець»,
                            укласти Договір купівлі-продажу товарів дистанційним способом, тобто через Інтернет-магазин,
                            далі по тексту - «Договір», і розміщує Публічну оферту (пропозицію) на офіційному
                            інтернет-сайті Продавця «lika.net.ua (далі -« Інтернет-сайт »).
                        </p>
                        <br/>
                        <p>
                            1.2. Моментом повного і безумовного прийняття Покупцем пропозиції Продавця (акцептом)
                            укласти електронний договір купівлі-продажу товарів, вважається факт оплати Покупцем
                            замовлення на умовах цього Договору, у строки та за цінами, вказаними на Інтернет-сайті
                            Продавця.
                        </p>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Поняття і визначення
                        </h2>
                        <p>
                            2.1. У цій оферті, якщо контекст не вимагає іншого, наведені нижче терміни мають таке
                            значення:
                            <br/><br/>
                            * «Товар» - моделі, аксесуари, комплектуючі та супровідні предмети;
                            <br/><br/>
                            * «Інтернет-магазин» - відповідно до Закону України «про електронну комерцію», засіб для
                            подання або реалізації товару, роботи або послуги шляхом здійснення електронної угоди.
                            <br/><br/>
                            * «Продавець» - компанія, яка реалізує товари, представлені на Інтернет-сайті.
                            <br/><br/>
                            * «Покупець» - фізична особа, що уклала з Продавцем Договір на умовах, викладених нижче.
                            <br/><br/>
                            * «Замовлення» - вибір окремих позицій з переліку товарів, зазначених Покупцем при
                            розміщенні замовлення і проведенні оплати.
                        </p>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Предмет договору
                        </h2>
                        <br/>
                        <p>
                            3.1. Продавець зобов'язується передати у власність Покупця Товар, а Покупець зобов'язується
                            оплатити і прийняти Товар на умовах даного Договору.
                            <br/><br/>
                            Цей Договір регулює купівлю-продаж товарів в Інтернет-магазині, в тому числі:
                            <br/><br/>
                            - добровільний вибір Покупцем товарів в Інтернет-магазині;
                            <br/><br/>
                            - самостійне оформлення Покупцем замовлення в Інтернет-магазині;
                            <br/><br/>
                            - оплата Покупцем замовлення, оформленого в Інтернет-магазині;
                            <br/><br/>
                            - обробка і доставка замовлення Покупцеві у власність на умовах цього Договору.
                        </p>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Порядок оформлення замовлення
                        </h2>
                        <br/>
                        <p>
                            4.1. Покупець має право оформити замовлення на будь-який товар, представлений на Сайті
                            Інтернет-магазину і наявний.
                        </p>
                        <br/>
                        <p>
                            4.2. Кожна позиція може бути представлена ​​в замовленні в будь-якій кількості.
                        </p>
                        <br/>
                        <p>
                            4.3. При відсутності товару на складі, Менеджер компанії зобов'язаний поставити Покупця до
                            відома (по телефону або через електронну пошту).
                        </p>
                        <br/>
                        <p>
                            4.4. При відсутності товару Покупець має право замінити його товаром аналогічної моделі,
                            відмовитися від даного товару, анулювати замовлення.
                        </p>
                        <br/>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Порядок оплати замовлення
                        </h2>
                        <br/>
                        <p>
                            Накладеним платежем
                        </p>
                        <br/>
                        <p>
                            5.1. Оплата здійснюється за фактом отримання товару у відділенні транспортних компанії за
                            готівковий розрахунок в гривнях.
                        </p>
                        <br/>
                        <p>
                            5.2. При не надходження коштів Інтернет-магазин залишає за собою право анулювати замовлення.
                        </p>
                        <br/>
                        <p>
                            Умови доставки замовлення
                        </p>
                        <p>
                            6.1. Доставка товарів, придбаних в Інтернет-магазині, здійснюється до складів транспортних
                            компаній, де і здійснюється видача замовлень.
                        </p>
                        <br/>
                        <p>
                            6.2. Разом із замовленням Покупцеві надаються документи згідно законодавства України.
                        </p>
                        <br/>
                        <p>
                            Права та обов'язки сторін:
                        </p>
                        <br/>
                        <p>
                            7.1. Продавець має право:
                            <br/><br/>
                            - в односторонньому порядку припинити надання послуг за цим договором у разі порушення
                            Покупцем умов цього договору.
                        </p>
                        <br/>
                        <p>
                            7.2. Покупець зобов'язаний:
                            <br/><br/>
                            - своєчасно оплатити та отримати замовлення на умовах цього договору.
                        </p>
                        <br/>
                        <p>
                            7.3. Покупець має право:
                            <br/><br/>
                            - оформити замовлення в Інтернет-магазині;
                            <br/><br/>
                            - оформити електронний договір;
                            <br/><br/>
                            - вимагати від Продавця виконання умов цього Договору.
                        </p>
                        <br/>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Відповідальність сторін
                        </h2>
                        <br/>
                        <p>
                            8.1. Сторони несуть відповідальність за невиконання або неналежне виконання умов цього
                            договору в порядку, передбаченому цим договором та чинним законодавством України.
                        </p>
                        <br/>
                        <p>
                            8.2. Продавець не несе відповідальності за:
                            <br/><br/>
                            - змінений виробником зовнішній вигляд Товару;
                            <br/><br/>
                            - за незначне невідповідність колірної гами товару, що може відрізнятися від оригіналу
                            товару виключно через різній колірній передачі моніторів персональних комп'ютерів окремих
                            моделей;
                            <br/><br/>
                            - за зміст і правдивість інформації, наданої Покупцем при оформленні замовлення;
                            <br/><br/>
                            - за затримку і перебої в наданні Послуг (обробки замовлення та доставки товару), які
                            відбуваються з причин, що знаходяться поза сферою його контролю;
                            <br/><br/>
                            - за протиправні незаконні дії, здійснені Покупцем за допомогою даного доступу до мережі
                            Інтернет;
                            <br/><br/>
                            - за передачу Покупцем своїх мережевих ідентифікаторів - IP, MAC-адреси, логіна і пароля
                            третім особам;
                        </p>
                        <br/>
                        <p>
                            8.3. Покупець, використовуючи наданий йому доступ до мережі Інтернет, самостійно несе
                            відповідальність за шкоду, заподіяну його діями (особисто, навіть якщо під його логіном
                            знаходилося іншу особу) особам або їх майну, юридичним особам, державі чи моральним
                            принципам моральності.
                        </p>
                        <br/>
                        <p>
                            8.4. У разі настання обставин непереборної сили, сторони звільняються від виконання умов
                            цього договору. Під обставинами непереборної сили для цілей цього договору розуміються
                            події, що мають надзвичайний, непередбачений характер, які виключають або об'єктивно
                            заважають виконанню цього договору, настання яких Сторони не могли передбачити і запобігти
                            розумними способами.
                        </p>
                        <br/>
                        <p>
                            8.5. Сторони прикладають максимум зусиль для вирішення будь-яких розбіжностей виключно шляхом переговорів.
                        </p>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Інші умови
                        </h2>
                        <br/>
                        <p>
                            9.1. Інтернет-магазин залишає за собою право в односторонньому порядку вносити зміни до цього договору за умови попередньої публікації його на сайті <a href="lika.net.ua">lika.net.ua</a>
                        </p>
                        <br/>
                        <p>
                            9.2. Інтернет-магазин створений для організації дистанційного способу продажу товарів через Інтернет.
                        </p>
                        <br/>
                        <p>
                            9.3. Покупець несе відповідальність за достовірність інформації, зазначеної при оформленні замовлення інформації. При цьому, при здійсненні акцепту (оформленні замовлення і подальшої оплати товару) Покупець надає Продавцю свою беззастережну згоду на збір, обробку, зберігання, використання своїх персональних даних, в розумінні ЗУ «Про захист персональних даних».
                        </p>
                        <br/>
                        <p>
                            9.4. Оплата Покупцем оформленого в Інтернет-магазині замовлення означає повну згоду Покупця з умовами договору купівлі-продажу (публічної оферти)
                        </p>
                        <br/>
                        <p>
                            9.5. Фактичною датою електронного угоди між сторонами є дата прийняття умов, відповідно до ст. 11 Закону України «Про електронну комерцію»
                        </p>
                        <br/>
                        <p>
                            9.6. Використання ресурсу Інтернет-магазину для попереднього перегляду товару, а також для оформлення замовлення для Покупця є безкоштовним.
                        </p>
                        <br/>
                        <p>
                            9.7. Інформація, яку надає Покупцем є конфіденційною. Інтернет-магазин використовує інформацію про Покупця виключно в цілях обробки замовлення, відправлення повідомлень Покупцеві, доставки товару, здійснення взаєморозрахунків і ін.
                        </p>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Порядок повернення товару належної якості
                        </h2>
                        <br/>
                        <p>
                            10.1. Повернення товару в Інтернет-магазин проводиться згідно чинного законодавства України.
                        </p>
                        <br/>
                        <p>
                            10.2. Повернення товару в Інтернет-магазин проводиться за рахунок Покупця.
                        </p>
                        <br/>
                        <p>
                            10.3. При поверненні Покупцем товару належної якості, Інтернет-магазин повертає йому сплачену за товар грошову суму за фактом повернення товару за вирахуванням компенсації витрат Інтернет-магазину пов'язаних з доставкою товару Покупцеві.
                        </p>
                        <br/>
                        <h2 style={{textAlign: 'center'}}>
                            Термін дії договору
                        </h2>
                        <br/>
                        <p>
                            11.1.Електронний договір вважається укладеним з моменту одержання особою яка направила пропозицію укласти такий договір, відповіді про прийняття цієї пропозиції в порядку, визначеному частиною шостою статті 11 Закону України "Про електронну комерцію".
                        </p>
                        <br/>
                        <p>
                            11.2. До закінчення терміну дії цей Договір може бути розірваний за взаємною згодою сторін до моменту фактичної доставки товару, шляхом повернення грошових коштів
                        </p>
                        <p>
                            11.3. Сторони мають право розірвати цей договір в односторонньому порядку, в разі невиконання однією із сторін умов цього Договору та у випадках передбачених чинним законодавством України.
                        </p>
                        <br/>
                        <p>
                            Звертаємо вашу увагу, що інтернет-магазин «Ліка Комфорт» на офіційному інтернет-сайті <a href="https://lika.net.ua/">lika.net.ua</a>, має право, відповідно до законодавства України, надавати право користування інтернет платформою ФОП і юридичним особам для реалізації товару.
                        </p>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Offer