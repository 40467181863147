import React from 'react'
import {connect} from 'react-redux'
import OrderBodyDataAdditionalServices from './OrderBodyDataAdditionalServices/OrderBodyDataAdditionalServices';

import OrderBodyDataNotLogged from "./OrderBodyDataNotLogged";
import OrderBodyDataDelivery from "./OrderBodyDataDelivery/OrderBodyDataDelivery";
import OrderBodyDataPay from "./OrderBodyDataPay/OrderBodyDataPay";

const OrderBodyData = props => {
    return (
        <div className="order-data">
            <OrderBodyDataNotLogged
                info={props.info}
                userData={props.userData}
                errorName={props.errorName}
                errorLastName={props.errorLastName}
                errorEmail={props.errorEmail}
                errorPhone={props.errorPhone}
                setErrorName={props.setErrorName}
                setErrorEmail={props.setErrorEmail}
                setErrorLastName={props.setErrorLastName}
                setErrorPhone={props.setErrorPhone}
                empty={props.empty}
            />
            <OrderBodyDataAdditionalServices />
            <OrderBodyDataDelivery empty={props.empty} setEmpty={props.setEmpty} />
            <OrderBodyDataPay basket={props.basket} empty={props.empty} setEmpty={props.setEmpty}/>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        info: store.userReducer.info,
    }
}

export default connect(mapStateToProps)(OrderBodyData)
