import React, {useEffect, useState} from 'react'
import ProductPageInfoDelivery from "./ProductPageInfoDelivery";
import ProductPageInfoAdditional from "./ProductPageInfoAdditional";
import ProductPageInfoCompare from "./ProductPageInfoCompare";
import ProductPageInfoOneClick from "./ProductPageInfoOneClick";
import ProductPageInfoBuy from "./ProductPageInfoBuy";
import ProductPageInfoPricing from "./ProductPageInfoPricing/ProductPageInfoPricing";
import ProductPageInfoTop from "./ProductPageInfoTop";

import {Element} from 'react-scroll'
import {useDispatch} from "react-redux";
import {SET_ID_PRODUCT} from "../../../redux/actionConst/action";
import {Link} from "react-router-dom";
import {_images} from "../../../API";

const ProductPageInfo = (props) => {
    const [service, setService] = useState([])
    const [saveBannerData, setSaveBannerData] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ID_PRODUCT, payload: props.product.id})
    }, [])
    useEffect(() => {
        if(props.product.category.id) {
            window.axios.get(`/banners?category=${props.product.category.id}&type=product`)
                .then(res => {
                    setSaveBannerData(res.data.data[0])
                })
        }
    }, [props.product.category.id])

    return (
        <section className={`product-info ${props.info ? 'highlight' : ''}`} id="product-info">
            <Element>
                <h1 className="product-info__title">{props.product.name}</h1>
                <ProductPageInfoTop
                    rating={props.product.rating}
                    feedbacks={props.product.rates_count}
                    brand={props.product.brand}
                />
                <ProductPageInfoPricing product={props.product}/>
                <div className="product-info__buy-section">
                    <ProductPageInfoBuy
                        product={props.product}
                        service={service}
                        setService={setService}
                    />
                    <div className="product-info__buy-section-bottom">
                        <ProductPageInfoOneClick name={props.product.name} id={props.product.id} final_price={props.product.final_price}/>
                        <ProductPageInfoCompare product={props.product}/>
                        { saveBannerData &&
                        <div style={{margin: '10px 0', width: '100%'}}>
                            <Link
                                to={`/${saveBannerData.link_url?.split('/')[ saveBannerData.link_url?.split('/').length - 1]}`}
                                style={{margin: '10px 0', width: '100%'}}
                            >
                                <img src={window.innerWidth < 500 ?  `${_images}${saveBannerData.mobile_image}` : `${_images}${saveBannerData.image}`} alt="" style={{width: 'inherit'}}/>
                            </Link>
                        </div>

                        }

                        {
                            props.product.services.length > 0 && (
                                <ProductPageInfoAdditional
                                    service={service}
                                    setService={setService}
                                    services={props.product.services}
                                />
                            )
                        }
                        <ProductPageInfoDelivery/>
                    </div>
                </div>
            </Element>
        </section>
    )
}

export default ProductPageInfo
