import React, {useState} from 'react'
import {connect} from 'react-redux'
import Rating from "react-rating";
import starIco from "../../../img/product-page/star-ico.svg";
import starCarbon from "../../../img/product-page/carbon_star.svg";
import {Element} from 'react-scroll'
import {
    toggleFeedbackSuccessModal,
    toggleModal,
    toggleLogModal,
    toggleLoginModal,
} from "../../../redux/actionCreators/modalsCreator";

const ProductPageFeedbackAdd = (props) => {

    const [rate, setRate] = useState(0)
    const [comment, setComment] = useState('')
    const [advantages, setAdvantages] = useState('')
    const [disadvantages, setDisadvantages] = useState('')

    const [error, setError] = useState(false)
    const [emptyRate, setEmptyRate] = useState(false)
    const [emptyComment, setEmptyComment] = useState(false)
    const [emptyAdv, setEmptyAdv] = useState(false)
    const [emptyDis, setEmptyDis] = useState(false)

    const postFeedback = () => {
        window.axios.post(`/rates`, {
            product_id: props.id,
            score: rate,
            description: comment.trim(),
            advantages: advantages.trim(),
            disadvantages: disadvantages.trim(),
        })
        .then(() => {
            props.toggleModal(true)
            props.toggleFeedbackSuccessModal(true)
            setError(false)
        })
        .catch(() => setError(true))
    }

    return (
        <section className={`product-page__review-add ${props.addFeed? 'highlight' : ''} ${props.activeReview ? 'active' : ''}`} id="product-review-add">
            <Element name='product-review-add'>
                <h3 className="product-page__container-title">Додати відгук</h3>
                <div className="review-add">
                    <div className="review-add__rating">
                        <div className="review-add__rating-stars">
                            <Rating
                                onChange={setRate}
                                placeholderRating={rate}
                                emptySymbol={<img src={starIco} className="icon" alt='star'/>}
                                placeholderSymbol={<img src={starCarbon} className="icon" alt='star'/>}
                                fullSymbol={<img src={starCarbon} className="icon" alt='star'/>}
                                readonly={!props.isLogged}
                            />
                            {
                                emptyRate ? (
                                    <span className="empty-feedback-text">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z" fill="#DB4915"/>
                                            <path d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z" fill="#DB4915"/>
                                            <path d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z" fill="#DB4915"/>
                                        </svg>
                                        Це поле повинно бути заповнене
                                    </span>
                                ) : ''
                            }
                        </div>
                        <div className="review-add__rating-text">Оцініть якість товару</div>
                    </div>
                    <label className="review-add__review-item">
                        <div className="review-add__review-add-title">Коментар</div>
                        <textarea
                            className={`review-add__textarea ${emptyComment ? 'empty-feedback' : ''}`}
                            name="review"
                            cols="30"
                            rows="5"
                            disabled={!props.isLogged}
                            value={comment}
                            onChange={e => {
                                setComment(e.target.value)
                            }}
                        />
                        {
                            emptyComment ? (
                                <span className="empty-feedback-text">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z" fill="#DB4915"/>
                                        <path d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z" fill="#DB4915"/>
                                        <path d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z" fill="#DB4915"/>
                                    </svg>
                                    Це поле повинно бути заповнене
                                </span>
                            ) : ''
                        }
                    </label>
                    <label className="review-add__review-item">
                        <div className="review-add__review-add-title">Переваги</div>
                        <input
                            className={`review-add__textarea ${emptyAdv ? 'empty-feedback' : ''}`}
                            type="text"
                            name="review-advantages"
                            disabled={!props.isLogged}
                            value={advantages}
                            onChange={e => {
                                setAdvantages(e.target.value)
                            }}
                        />
                        {
                            emptyAdv ? (
                                <span className="empty-feedback-text">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z" fill="#DB4915"/>
                                        <path d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z" fill="#DB4915"/>
                                        <path d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z" fill="#DB4915"/>
                                    </svg>
                                    Це поле повинно бути заповнене
                                </span>
                            ) : ''
                        }
                    </label>
                    <label className="review-add__review-item">
                        <div className="review-add__review-add-title">Недоліки</div>
                        <input
                            className={`review-add__textarea ${emptyDis ? 'empty-feedback' : ''}`}
                            type="text"
                            name="review-disadvantages"
                            disabled={!props.isLogged}
                            value={disadvantages}
                            onChange={e => {
                                setDisadvantages(e.target.value)
                            }}
                        />
                        {
                            emptyDis ? (
                                <span className="empty-feedback-text">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z" fill="#DB4915"/>
                                        <path d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z" fill="#DB4915"/>
                                        <path d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z" fill="#DB4915"/>
                                    </svg>
                                    Це поле повинно бути заповнене
                                </span>
                            ) : ''
                        }
                    </label>
                    {
                        error ? (
                            <p className='not-buy-product'>Щоб додати відгук необхідно купити товар</p>
                        ) : ''
                    }
                    {
                        props.isLogged ? (
                            <button
                                className="review-add__submit-btn btn-white-second"
                                onClick={() => {
                                    if(rate === 0) {
                                        setEmptyRate(true)
                                    } else {
                                        setEmptyRate(false)
                                    }
                                    if(comment === '') {
                                        setEmptyComment(true)
                                    } else {
                                        setEmptyComment(false)
                                    }
                                    if(advantages === '') {
                                        setEmptyAdv(true)
                                    } else {
                                        setEmptyAdv(false)
                                    }
                                    if(disadvantages === '') {
                                        setEmptyDis(true)
                                    } else {
                                        setEmptyDis(false)
                                    }
                                    if(rate !== 0) {
                                        if(comment !== '') {
                                            if(advantages !== '') {
                                                if(disadvantages !== '') {
                                                    postFeedback()
                                                    setRate('')
                                                    setEmptyRate(false)
                                                    setComment('')
                                                    setEmptyComment(false)
                                                    setAdvantages('')
                                                    setEmptyAdv(false)
                                                    setDisadvantages('')
                                                    setEmptyDis(false)
                                                    props.setActiveReview(false)
                                                }
                                            }
                                        }
                                    }
                                }}
                            >
                                Залишити відгук
                            </button>
                        ) : (
                            <button
                                className="review-add__submit-btn btn-white-second"
                                onClick={() => {
                                    props.toggleModal(true)
                                    props.toggleLogModal(true)
                                    props.toggleLoginModal(true)
                                }}
                            >
                                Авторизуйтесь, щоб залишити відгук
                            </button>
                        )
                    }
                </div>
            </Element>
        </section>
    )
}

const mapStateToProps = store => {
    return {
        isLogged: store.userReducer.info.isLogged
    }
}
const mapDispatchToProps = {
    toggleFeedbackSuccessModal,
    toggleModal,
    toggleLoginModal,
    toggleLogModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageFeedbackAdd)