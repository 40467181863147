import React from 'react'

const ProfileHistoryOrder = props => {

    return (
        <div className="history-table__table">
            <div className="history-table__col history-table__col--number">
                #{props.data.id}
            </div>
            <div className="history-table__inner">
                {
                    props.data.products ? (
                        props.data.products.map((data, i) => {
                            return (
                                <div key={i} className="history-table__row">
                                    <div className="history-table__col history-table__col--name">
                                        {data.product_name}
                                    </div>
                                    <div
                                        className="history-table__col history-table__col--quantity">
                                        {data.quantity} шт
                                    </div>
                                    <div className="history-table__col history-table__col--services">
                                        {
                                            data.services.length < 1 ? '-' : (
                                                data.services.map((data, i) => {
                                                    return (
                                                        <div className="history-table__col-item" key={i}>
                                                            {data.service_name}
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    ) : ''
                }
            </div>
            <div
                className="history-table__col history-table__col--date">
                {props.data.created_date}
            </div>
            <div className="history-table__col history-table__col--total">
                {props.data.total_amount} грн
            </div>
            <div className="history-table__col history-table__col--quantity">
                {props.data.cashback_used} грн
            </div>
        </div>
    )
}

export default ProfileHistoryOrder