import {GET_BLOG_ARTICLE, GET_BLOG_ARTICLES, GET_BLOG_CATEGORIES} from "../../actionConst/getActions";

export const getBlogArticles = payload => {
    return {
        type: GET_BLOG_ARTICLES,
        payload,
    }
}
export const getBlogArticle = payload => {
    return {
        type: GET_BLOG_ARTICLE,
        payload,
    }
}
export const getBlogCategories = payload => {
    return {
        type: GET_BLOG_CATEGORIES,
        payload,
    }
}