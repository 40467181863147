import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'
import {getCasesArticles, getCasesCategories} from "../../redux/actionCreators/getCreators/getCases";
import './Cases.scss'
import CasesHeader from "./CasesHeader";
import CasesBody from "./CasesBody";
import ScrollToTopOnMount from "../../ScrolToTop";
import Preloader from "../globalComponents/Preloader/Preloader";
import EmptyPage from "../globalComponents/EmptyCatalog/EmptyPage";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Cases = props => {
    const [tag, setTag] = useState(props.activeCategory)
    const [articles, setArticles] = useState([])
    const [page, setPage] = useState(1)
    const [fetching, setFetching] = useState(true)
    const [pageLength, setPageLength] = useState(1)
    const isMounted = useRef(false);

    useEffect(() => {
        if(fetching && (page <= pageLength)) {
            if(tag !== 'Всі') {
                window.axios(`${encodeURI(`/works?page=${page}&tag=${tag}`)}`)
                    .then(res => {
                        setArticles([...articles, ...res.data.data])
                        setPageLength(res.data.meta.pagesCount)
                        setPage(prevState => prevState+1)
                        props.getCasesCategories(res.data.tags)
                    })
                    .finally(() => setFetching(false))
            } else {
                window.axios.get(`/works?page=${page}`)
                    .then(res => {
                        setArticles([...articles, ...res.data.data])
                        setPageLength(res.data.meta.pagesCount)
                        setPage(prevState => prevState+1)
                        props.getCasesCategories(res.data.tags)
                    })
                    .finally(() => setFetching(false))
            }
        }
        else setFetching(false);
    }, [fetching]);

    useEffect(() => {
        if(isMounted.current) {
            setArticles([])
            setPage(1)
            setPageLength(1)
            setFetching(true)
        }
    }, [tag]);

    useEffect(() => {
        isMounted.current = true;
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollHeight-(e.target.documentElement.scrollTop+window.innerHeight) < 200) {
            setFetching(true)
        }
    }

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Приклади робіт виконаних Ліка-Комфорт ❄️ Зразки робіт зі становлення, чи обслуговування систем кондиціонування, енергозбереження чи вентиляції.</title>
                <meta name="description" content={`Фото робіт виконаних Ліка-Комфорт ❄️ зі становлення, чи обслуговування систем ✔️кондиціонування, ✔️енергозбереження чи ✔️ вентиляція.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Приклади робіт виконаних Ліка-Комфорт ❄️ Зразки робіт зі становлення, чи обслуговування систем кондиціонування, енергозбереження чи вентиляції." />
                <meta property="og:description" content="Фото робіт виконаних Ліка-Комфорт ❄️ зі становлення, чи обслуговування систем ✔️кондиціонування, ✔️енергозбереження чи ✔️ вентиляція." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="cases">
                    <div className="container">
                        <CasesHeader
                            activeCategory={props.activeCategory}
                            categories={props.categories}
                            tag={tag}
                            setTag={setTag}
                            page={page}
                        />
                        {
                            (fetching && (articles.length == 0)) ? (
                                <Preloader />
                            ) : (
                                articles.length > 0 ? <CasesBody articles={articles}/> : <EmptyPage />
                            )
                        }
                        <div className='pagination-wrap'/>
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        articles: store.casesReducer.articles,
        categories: store.casesReducer.categories,
        activeCategory: store.casesReducer.activeCategory,
    }
}

const mapDispatchToProps = {
    getCasesArticles,
    getCasesCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Cases)