import React from "react";
import CatalogFilterBlockItem from "./CatalogFilterBlockItem";

const CatalogFilterBlockBrand = (props) => {
    return (
        <>
            {
                props.brands.length > 0 ? (
                    <div className="filter__section">
                        <div className="filter__title">Бренди</div>
                        <div className="filter__form">
                            {
                                props.brands.map((data, i) => {
                                    return (
                                        <CatalogFilterBlockItem
                                            key={i}
                                            data={data}
                                            checked={props.appliedFilters?.brands}
                                            productCount={props.productCount}
                                            urlFilters={props.urlFilters}
                                            setUrlFilters={props.setUrlFilters}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : ''
            }
        </>
    )
}

export default CatalogFilterBlockBrand