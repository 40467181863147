import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import './Profile.scss'
import ProfileTabs from "./ProfileTabs";
import ProfilePersonal from "./ProfilePersonal/ProfilePersonal";
import ProfileHistory from "./ProfileHistory/ProfileHistory";
import ScrollToTopOnMount from "../../ScrolToTop";
import {log_in} from "../../redux/actionCreators/userCreator";
import {useHistory} from "react-router";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Profile = props => {

    const history = useHistory()

    const [name, setName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    useEffect(() => {
        window.axios.post('/auth/user')
        .then((res) => {
            setName(res.data.data.name)
            setLastName(res.data.data.last_name)
            setEmail(res.data.data.email)
            setPhone(res.data.data.phone)
        })
        .catch(() => {
            history.push('/')
        })
    }, []);

    const [active, setActive] = useState(true)
    const changeActive = () => {
        setActive(!active)
    }

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Особистий кабінет</title>
                <meta name="robots" content={'noindex, nofollow'} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Особистий кабінет" />
                <meta property="og:description" content="Особистий кабінет" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="account">
                    <div className="container">
                        <h1 className="page-title">Особистий кабінет</h1>
                        <div className="account__body">
                            <ProfileTabs active={active} changeActive={changeActive}/>
                            <div className="account__body-tabs-content">
                                <ProfilePersonal
                                    active={active}
                                    info={props.userData}
                                    password={props.password}
                                    log_in={props.log_in}
                                    setName={setName}
                                    setLastName={setLastName}
                                    setEmail={setEmail}
                                    setPhone={setPhone}
                                    name={name}
                                    lastName={lastName}
                                    email={email}
                                    phone={phone}
                                />
                                <ProfileHistory
                                    active={active}
                                    orders={props.orders}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        orders: store.userReducer.userData.orders,
        userData: store.userReducer.userData,
        password: store.userReducer.password,
        isLogged: store.userReducer.info.isLogged,
    }
}

const mapDispatchToProps = {
    log_in,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)