import React from 'react'
import Rating from "react-rating";
import starList from "../../../img/main-page/star-empty.svg";
import star from "../../../img/main-page/star.svg";

const ProductPageFeedbackTrueItem = (props) => {

    return (
        <>
            {
                props.rates.map((data, i) => {
                    if (!props.showAllFeedbacks) {
                        if (i <= 3) {
                            return (
                                <div className="product-page__review review" key={i}>
                                    <div className="review__header">
                                        <div className="review__user-name">{data.user}</div>
                                        <div className="review__user-rating">
                                            <Rating
                                                readonly
                                                fractions={2}
                                                placeholderRating={data.score}
                                                emptySymbol={<img src={starList} className="icon" alt='star'/>}
                                                placeholderSymbol={<img src={star} className="icon" alt='star'/>}
                                                fullSymbol={<img src={star} className="icon" alt='star'/>}
                                            />
                                        </div>
                                        <div className="review__date">{data.created_date}</div>
                                    </div>
                                    <p className="review__review">
                                        {data.description}
                                    </p>
                                    <div className="review__footer">
                                        <div className="review__footer">
                                            <div className="review__features review__features--advantages">
                                                <div className="review__feature-title">Переваги:</div>
                                                <div className="review__feature-text">{data.advantages}</div>
                                            </div>
                                            <div className="review__features review__features--disadvantages">
                                                <div className="review__feature-title">Недоліки:</div>
                                                <div className="review__feature-text">{data.disadvantages}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data.review? <div className="review__feedback">
                                            <div className="review__header">
                                                <div className="review__manager">Менеджер</div>
                                                <div className="review__date">{data.created_date}</div>
                                            </div>
                                            <div className="review__feedback-text">{data.review}</div>
                                        </div> : ''
                                    }
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div className="product-page__review review" key={i}>
                                <div className="review__header">
                                    <div className="review__user-name">{data.user}</div>
                                    <div className="review__user-rating">
                                        <Rating
                                            readonly
                                            fractions={2}
                                            placeholderRating={data.score}
                                            emptySymbol={<img src={starList} className="icon" alt='star'/>}
                                            placeholderSymbol={<img src={star} className="icon" alt='star'/>}
                                            fullSymbol={<img src={star} className="icon" alt='star'/>}
                                        />
                                    </div>
                                    <div className="review__date">{data.created_date}</div>
                                </div>
                                <p className="review__review">
                                    {data.description}
                                </p>
                                <div className="review__footer">
                                    <div className="review__footer">
                                        <div className="review__features review__features--advantages">
                                            <div className="review__feature-title">Переваги:</div>
                                            <div className="review__feature-text">{data.advantages}</div>
                                        </div>
                                        <div className="review__features review__features--disadvantages">
                                            <div className="review__feature-title">Недоліки:</div>
                                            <div className="review__feature-text">{data.disadvantages}</div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    data.review? <div className="review__feedback">
                                        <div className="review__header">
                                            <div className="review__manager">Менеджер</div>
                                            <div className="review__date">{data.created_date}</div>
                                        </div>
                                        <div className="review__feedback-text">{data.review}</div>
                                    </div> : ''
                                }
                            </div>
                        )
                    }
                })
            }
        </>
    )
}

export default ProductPageFeedbackTrueItem