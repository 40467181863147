import {GET_CASES_ARTICLE, GET_CASES_ARTICLES, GET_CASES_CATEGORIES} from "../../actionConst/getActions";

export const getCasesArticles = payload => {
    return {
        type: GET_CASES_ARTICLES,
        payload
    }
}
export const getCasesArticle = payload => {
    return {
        type: GET_CASES_ARTICLE,
        payload
    }
}
export const getCasesCategories = payload => {
    return {
        type: GET_CASES_CATEGORIES,
        payload
    }
}