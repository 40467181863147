import {FIFTH_QUIZ, FIRST_QUIZ, FOURTH_QUIZ, SECOND_QUIZ, THIRD_QUIZ} from "../actionConst/action";

export const addFirstQuiz = (payload) => {
    return {
        type: FIRST_QUIZ,
        payload
    }
}
export const addSecondQuiz = (payload) => {
    return {
        type: SECOND_QUIZ,
        payload
    }
}
export const addThirdQuiz = (payload) => {
    return {
        type: THIRD_QUIZ,
        payload
    }
}
export const addFourthQuiz = (payload) => {
    return {
        type: FOURTH_QUIZ,
        payload
    }
}
export const addFifthQuiz = (payload) => {
    return {
        type: FIFTH_QUIZ,
        payload
    }
}