import React from 'react'
import {_images} from "../../../API";

const ProductPagePhotoSliderAdvantages = (props) => {

    return (
        <>
            {
                props.advantages.map((data, i) => {
                    return (
                        <div className="product-demo__advantages-item">
                            <div className="product-demo__advantages-ico">
                                <img src={`${_images}${data.icon}`} alt={data.text} />
                            </div>
                            <div className="product-demo__advantages-text">{data.text}</div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default ProductPagePhotoSliderAdvantages