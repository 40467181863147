import React from "react";
import ProductCardLink from "./ProductCardLink";
import ProductCardRating from "./ProductCardRating";
import ProductCardPrice from "./ProductCardPrice";
import ProductCardBtn from "./ProductCardBtn";
import ProductCardDescription from "./ProductCardDescription/ProductCardDescription";
import ProductCardTag from "./ProductCardTag";

const ProductCard = (props) => {
    return (
        <>
            {
                props.products ? (
                    props.products.map((data, i) =>{
                        return (
                            <section className="product-card-box" key={i}>
                                <div className="product-card-wrap">
                                    {
                                        data.tags === undefined? '' : <ProductCardTag tag={data.tags}/>
                                    }
                                    <div className="product-card">
                                        <ProductCardLink slug={data.slug} image={data.images} link={`/product_card/${data.slug}`} name={data.name}/>
                                        <ProductCardRating data={data}/>
                                        <ProductCardPrice data={data}/>
                                        <ProductCardBtn data={data} wishlist={false} name={data.name}/>
                                        <ProductCardDescription specifications={data.specifications}/>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                ) : ''
            }
        </>
    )
}

export default ProductCard