import React from 'react'
import {Link} from "react-router-dom";

const TopInfoLink = (props) => {
    return (
        <Link className="header__info-link" to={props.info.link}>
            <img className="header__info-ico" src={props.info.img} alt={props.info.name}/>
            <span className="header__info-text">{props.info.name}</span>
        </Link>
    )
}

export default TopInfoLink