import {GET_CATALOG_BANNERS} from "../../actionConst/getActions";

const initialState = {
    banners: []
}

export const catalogBannerReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_CATALOG_BANNERS:
            return {
                ...state,
                banners: action.payload
            }
        default:
            return state
    }
}