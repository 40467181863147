import {ADD_COMPARE, CLEAN_COMPARE, RELOAD_COMPARE, REMOVE_COMPARE} from "../../actionConst/action";

const initialState = {
    compare: []
}

function addItem(compare, payload) {
    let itemWithIdExist = false;

    compare.map(item => {
        if(item.id === payload.id) {
            itemWithIdExist = true;

            return  {
                ...item,
                quantity: item.quantity + 1
            }
        }
    });

    if(!itemWithIdExist) {
        return {
            compare: [...compare, payload]
        }
    }

    return {
        compare: [...compare]
    };
}

export const compareReducer = (state = initialState, action) => {
    switch(action.type) {
        case CLEAN_COMPARE:
            return {
                ...state,
                compare: []
            }
        case RELOAD_COMPARE:
            return {
                ...state,
                compare: [action.payload].flat()
            }
        case REMOVE_COMPARE:
            return {
                ...state,
                compare: [...state.compare.filter(item => item.id !== action.payload)]
            }
        case ADD_COMPARE:
            return addItem(state.compare, action.payload)
    }
    return state
}