import React from 'react'

import cashback from '../../img/product-page/cashback.svg'
import {Link} from "react-router-dom";
import {declOfNum} from "../FeedbackNumbers";

const BasketTotal = props => {

    const totalPrice = props.products.reduce((i,products) => {
        if(products.new_services) {
            return (
                i + (
                    products.final_price +products.new_services.reduce((j, product) => {
                        return (
                            j + product.price
                        )
                    }, 0)
                ) * products.quantity
            )
        } else {
            return (
                i + products.final_price * products.quantity
            )
        }
    }, 0)

    return (
        <div className="basket-result">
            <div className="basket-result__body">
                <p className="basket-result__title">У кошику {props.products.length} {declOfNum(props.products.length, ['товар', 'товари', 'товарів'])}</p>
                <div className="basket-result__item-sale">
                    {
                        props.products.find(item => item.discount_percent > 0) ? (
                            <>
                                <div className="basket-result__item-old-price">
                                    {
                                        props.products.reduce((i,product) => {
                                            return (
                                                i + product.price * product.quantity
                                            )
                                        }, 0)
                                    }грн
                                </div>
                                <div className="basket-result__item-price-economy">
                                    -{
                                    props.products.reduce((i,product) => {
                                        return (
                                            i + product.discount * product.quantity
                                        )
                                    }, 0)
                                } грн
                                </div>
                            </>
                        ) : ''
                    }
                </div>
                <div className="basket-result__price">
                    {
                        totalPrice === 0 ? 'За домовленістю' : `${totalPrice.toFixed()} грн`
                    }</div>
                <div className="basket-result__cashback">
                    <img src={cashback} alt="Кешбек" />
                    <span className="cashback-text">Кешбек</span>
                    <span className="cashback-val">
                        {
                            props.products.reduce((i, products) => {
                                return i + products.cashback * products.quantity
                            }, 0).toFixed(2)
                        } грн
                    </span>
                </div>
            </div>
            <div className="basket-result__footer">
                <Link className="basket-result__confirm-btn" to='/order'>Оформити замовлення</Link>
            </div>
        </div>
    )
}

export default BasketTotal