import {GET_CATEGORIES, GET_MENU_CATALOG, GET_SHOW_MENU_CATALOG} from "../../actionConst/getActions";

const initialState = {
    categoryData: [],
    menuCatalog: {
        brands: [],
        children: [],
        id: 0,
        link: '',
        name: 'Каталог',
        slug: '',
    },
    showMenuCatalog: false,
}

export const headerCatalogReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categoryData: action.payload
            }
        case GET_MENU_CATALOG:
            return {
                ...state,
                menuCatalog: action.payload
            }
        case GET_SHOW_MENU_CATALOG:
            return {
                ...state,
                showMenuCatalog: action.payload
            }
    }
    return state
}