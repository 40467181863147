export const cleanPhone = (phone) => {

    const newPhone = []

    if((typeof phone !== 'undefined') && (phone !== null) && (!phone.includes('_'))) {
        phone.split('').forEach(data => {
            if (data !== ' ' && data !== '+' && data !== '-' && data !== '_' && data !== '(' && data !== ')') {
                newPhone.push(data)
            }
        })
    }

    return newPhone.join('')

}