import {GET_CATALOG_FILTERS, GET_CATALOG_FILTERS_BRANDS, GET_CATEGORY_SLUG} from "../../actionConst/getActions";
import {
    SET_ALL_FILTERS,
    ADD_ACTIVE_FILTERS,
    ADD_CATEGORY_FILTER,
    ADD_FILTER,
    ADD_FILTER_BRANDS,
    ADD_FILTER_PRICE_MAX,
    ADD_FILTER_PRICE_MIN,
    REMOVE_ALL_FILTERS, REMOVE_CATEGORY_FILTER,
    REMOVE_FILTER,
    REMOVE_FILTER_BLOCK,
    REMOVE_FILTER_BRANDS,
} from "../../actionConst/action";

const initialState = {
    specifications: [],
    brands: [],
    prices: { min: 0, max: 1000000000},
    base: [],
    appliedFilters: [],
    appliedSort: 'expensive',
    preFilter: { position: null, url:  null },
    catalogSlug: '',
}

export const catalogFilterReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ALL_FILTERS:
            return {
                ...state,
                prices: action.prices || {min: 0, max: 1000000000},
                brands: action.brands || [],
                specifications: action.specifications || [],
                base: action.base || [],
                appliedFilters: action.appliedFilters || [],
            }
        case ADD_ACTIVE_FILTERS:
            return {
                ...state,
                activeBrands: action.payload.brands,
                activeTags: action.payload.tags,
            }
        case GET_CATALOG_FILTERS_BRANDS:
            return {
                ...state,
                brands: action.payload
            }
        case ADD_FILTER:
            return {
                ...state,
                tags: [...(state.tags || []), action.value],
                activeTags: [...(state.activeTags || []), action.value],
            }
        case REMOVE_FILTER:
            return {
                ...state,
                tags: state.tags.filter(item => item !== action.value),
                activeTags: state.activeTags.filter(item => item !== action.value),
            }
        case REMOVE_FILTER_BLOCK:
            return {
                ...state,
                tags: {
                    ...state.tags,
                    [action.payload]: []
                }
            }
        case REMOVE_ALL_FILTERS:
            return {
                ...state,
                tags: [],
                brandsFilter: [],
                categoriesFilter: [],
                activeBrands: [],
                activeTags: [],
            }
        case ADD_FILTER_BRANDS:
            return {
                ...state,
                brandsFilter: [...state.brandsFilter, action.payload],
                activeBrands: [...state.brandsFilter, action.payload],
            }
        case REMOVE_FILTER_BRANDS:
            return {
                ...state,
                brandsFilter: [...state.brandsFilter.filter(item => item !== action.payload)]
            }
        case ADD_FILTER_PRICE_MAX:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    prices: {
                        ...state.filters.prices,
                        max: action.payload
                    }
                }
            }
        case ADD_FILTER_PRICE_MIN:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    prices: {
                        ...state.filters.prices,
                        min: action.payload
                    }
                }
            }
        case GET_CATEGORY_SLUG:
            return {
                ...state,
                catalogSlug: action.payload,
            }
        case ADD_CATEGORY_FILTER:
            return {
                ...state,
                categoriesFilter: [...state.categoriesFilter, action.payload]
            }
        case REMOVE_CATEGORY_FILTER:
            return {
                ...state,
                categoriesFilter: state.categoriesFilter.filter(item => item !== action.payload)
            }
    }
    return state
}