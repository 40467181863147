import React from 'react'
import {Link} from "react-router-dom";

const ServicesItem = (props) => {

    window.addEventListener('scroll', function() {
        document.querySelectorAll('.services__item').forEach((data) => {
            return (
                data.className +=' active'
            )
        });
    });

    return (
        <Link className="services__item services__item--middle active" to={props.data.link}>
            <img className="services__img" src={props.data.img} alt={props.data.name}/>
            <div className="services__title-box services__title-box--white">
                <div className="services__title-box-bg">
                    {
                        props.data.dark ? (
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.9">
                                        <rect className="static-block" opacity="0.9" y="114" width="190"
                                              height="136" fill="white"/>
                                        <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                              fill="white"/>
                                        <rect className="row2 col1 cube" opacity="0.9" y="38" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row1 col1 cube" opacity="0.9" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row3 col3 cube" opacity="0.9" x="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                              height="38" fill="white"/>
                                        <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                              height="38" fill="white"/>
                                    </g>
                                </svg>
                        ) : (
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect className="static-block" opacity="0.9" y="114" width="190"
                                      height="136" fill="#43B02A"/>
                                <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col1 cube" opacity="0.9" y="38" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row1 col1 cube" opacity="0.9" y="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col3 cube" opacity="0.9" x="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                            </svg>
                        )
                    }
                </div>
                {
                    props.data.dark ? (
                        <h4 className="services__title">{props.data.name}</h4>
                    ) : (
                        <h4 className="services__title white-service-name">{props.data.name}</h4>
                    )
                }
            </div>
        </Link>
    )
}

export default ServicesItem