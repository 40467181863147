import React from "react";

import CatalogFilterBlockBrand from "./CatalogFilterBlockBrand";
import CatalogFilterBlockCost from "./CatalogFilterBlockCost";
import CatalogFilterBlockSpecification from "./CatalogFilterBlockSpecification";

const CatalogFilterBlocks = (props) => {
    return (
        <>
            {
                props.brands ? (
                    <CatalogFilterBlockBrand
                        urlFilters={props.urlFilters}
                        setUrlFilters={props.setUrlFilters}
                        brands={props.brands}
                        appliedFilters={props.appliedFilters}
                        productCount={props.productCount}
                    />
                ) : ''
            }
            {
                props.prices && (props.prices.max > props.prices.min) ? (
                    <CatalogFilterBlockCost
                        urlFilters={props.urlFilters}
                        setUrlFilters={props.setUrlFilters}
                        prices={props.prices}
                        appliedFilters={props.appliedFilters}
                        productCount={props.productCount}
                    />
                ) : ''
            }
            {
                props.specifications ? (
                    <CatalogFilterBlockSpecification
                        urlFilters={props.urlFilters}
                        setUrlFilters={props.setUrlFilters}
                        specifications={props.specifications}
                        appliedFilters={props.appliedFilters}
                        productCount={props.productCount}
                    />
                ) : ''
            }
        </>
    )
}

export default CatalogFilterBlocks