import React from 'react';
import {Link} from "react-router-dom";

const CatalogCheapProductTable = props => {
    return(
        <div className="cheap-product-table-container__text">
            <h2>Ціни на – { props.catalogName?.toLocaleLowerCase() } в Мукачеве, Ужгороді, Україні</h2>
            <table class="cheap-product-table-container__table">
                <tr>
                    <td><b>Назва</b></td>
                    <td><b>Ціна</b></td>
                </tr>
                {
                    props.cheapProducts.map((data, i) => {
                        return (
                            <tr>
                                <td><Link to={`/product_card/${data.slug}`}>{ i % 2 == 0 ? '❄️' : '🌞' } { data.name }</Link></td>
                                <td>{ data.final_price.toLocaleString() } грн</td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    )
}

export default CatalogCheapProductTable