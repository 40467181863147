import React, {useState} from 'react'
import {connect} from 'react-redux'
import './LogModal.scss'
import LoginModal from "./LoginModal/LoginModal";
import RegModal from "./RegModal/RegModal";
import RegSuccessModal from "./RegModal/RegSuccessModal";
import LoginSuccessModal from "./LoginModal/LoginSuccessModal";
import ForgotModal from "./ForgotModal/ForgotModal";
import ForgotSuccessModal from "./ForgotModal/ForgotSuccessModal";
import {
    toggleRegModal,
    toggleForgotModal,
    toggleLoginModal,
    toggleLogModal,
    toggleLoginSuccessModal,
    toggleRegSuccessModal,
    toggleForgotSuccessModal,
} from "../../../../redux/actionCreators/modalsCreator";

const LogModal = props => {

    const [email, setEmail] = useState()
    const changeEmail = str => {
        setEmail(str)
    }

    return (
        <div className={`entrance-modal ${props.log? 'active' : ''}`}>
            <button
                className="close-btn"
                onClick={() => {
                    props.toggleLogModal(false)
                    props.toggleModal(false)
                    props.toggleLoginModal(true)
                    props.toggleRegModal(false)
                    props.toggleForgotModal(false)
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            {
                props.login? (
                    <>
                        {
                            props.loginSuccess?
                                <LoginSuccessModal />
                                :
                                <LoginModal
                                    toggleLoginSuccessModal={props.toggleLoginSuccessModal}
                                    toggleRegModal={props.toggleRegModal}
                                    toggleForgotModal={props.toggleForgotModal}
                                    toggleLoginModal={props.toggleLoginModal}
                                    toggleLogModal={props.toggleLogModal}
                                    toggleModal={props.toggleModal}
                                />
                        }
                    </>
                ) : props.reg? (
                    <>
                        {
                            props.regSuccess?
                                <RegSuccessModal />
                                :
                                <RegModal
                                    toggleRegSuccessModal={props.toggleRegSuccessModal}
                                    toggleModal={props.toggleModal}
                                    toggleLoginModal={props.toggleLoginModal}
                                    toggleRegModal={props.toggleRegModal}
                                />
                        }
                    </>
                ) : (
                    <>
                        {
                            props.forgotSuccess?
                                <ForgotSuccessModal email={email} />
                                :
                                <ForgotModal
                                    changeEmail={changeEmail}
                                    toggleLoginModal={props.toggleLoginModal}
                                    toggleForgotModal={props.toggleForgotModal}
                                    toggleForgotSuccessModal={props.toggleForgotSuccessModal}
                                />
                        }
                    </>
                )
            }
        </div>
    )
}

const mapStateToProps = store => {
    return {
        login: store.modalReducer.login,
        loginSuccess: store.modalReducer.loginSuccess,
        reg: store.modalReducer.reg,
        regSuccess: store.modalReducer.regSuccess,
        forgot: store.modalReducer.forgot,
        forgotSuccess: store.modalReducer.forgotSuccess,
    }
}
const mapDispatchToProps = {
    toggleRegModal,
    toggleForgotModal,
    toggleLoginModal,
    toggleLogModal,
    toggleLoginSuccessModal,
    toggleRegSuccessModal,
    toggleForgotSuccessModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(LogModal)