import React from 'react'

const FooterItemsInfoWork = (props) => {

    return (
        <>
            {
                props.workTime.map((data, i) => {
                    return (
                        <p key={i} className="footer__schedule-item">
                            {data}
                        </p>
                    )
                })
            }
        </>
    )
}

export default FooterItemsInfoWork