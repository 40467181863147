import React from 'react'

const LoginSuccessModal = props => {

    return (
        <div className="success">
            <div className="success__title">Авторизація пройшла успішно!</div>
            <div className="success__ico">
                <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M51 8.5C27.54 8.5 8.5 27.54 8.5 51C8.5 74.46 27.54 93.5 51 93.5C74.46 93.5 93.5 74.46 93.5 51C93.5 27.54 74.46 8.5 51 8.5ZM51 85C32.2575 85 17 69.7425 17 51C17 32.2575 32.2575 17 51 17C69.7425 17 85 32.2575 85 51C85 69.7425 69.7425 85 51 85ZM67.49 35.2325L42.5 60.2225L34.51 52.2325C33.7153 51.4378 32.6376 50.9914 31.5138 50.9914C30.3899 50.9914 29.3122 51.4378 28.5175 52.2325C27.7228 53.0272 27.2764 54.1049 27.2764 55.2287C27.2764 56.3526 27.7228 57.4303 28.5175 58.225L39.525 69.2325C41.1825 70.89 43.86 70.89 45.5175 69.2325L73.525 41.225C73.919 40.8318 74.2316 40.3648 74.4448 39.8507C74.6581 39.3365 74.7679 38.7854 74.7679 38.2288C74.7679 37.6721 74.6581 37.121 74.4448 36.6068C74.2316 36.0927 73.919 35.6257 73.525 35.2325C71.8675 33.575 69.1475 33.575 67.49 35.2325Z"
                        fill="#43B02A"/>
                </svg>
            </div>
        </div>
    )
}

export default LoginSuccessModal