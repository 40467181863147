import React from 'react'

const ProductsSliderPrev = (props) => {

    return (
        <button className="slider-arrow-left" onClick={props.onClick}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                      d="M18.4479 3.10614C18.6845 3.22496 18.8643 3.43275 18.9479 3.68395C19.0315 3.93514 19.0121 4.20924 18.8939 4.44614L13.1199 16.0001L18.8959 27.5521C18.9559 27.6697 18.9921 27.7979 19.0024 27.9295C19.0126 28.0611 18.9967 28.1934 18.9556 28.3188C18.9144 28.4442 18.8489 28.5602 18.7627 28.6601C18.6765 28.7601 18.5714 28.842 18.4534 28.9011C18.3354 28.9602 18.2069 28.9954 18.0752 29.0046C17.9436 29.0138 17.8114 28.9969 17.6863 28.9548C17.5612 28.9127 17.4457 28.8462 17.3465 28.7593C17.2472 28.6723 17.1661 28.5666 17.1079 28.4481L11.1079 16.4481C11.0382 16.3091 11.0019 16.1557 11.0019 16.0001C11.0019 15.8446 11.0382 15.6912 11.1079 15.5521L17.1079 3.55214C17.2267 3.31556 17.4345 3.13576 17.6857 3.05215C17.9369 2.96855 18.211 2.98796 18.4479 3.10614Z"
                      fill="#182316"/>
            </svg>
        </button>
    )
}

export default ProductsSliderPrev