import React from 'react'
import './FeedbackSuccess.scss'
import {useHistory, useLocation} from "react-router";

const FeedbackSuccess = props => {
    const {pathname} = useLocation()
    const history = useHistory();
    return (
        <div className={`review-sent ${props.feedbackSuccess? 'active' : ''}`}>
            <button className="close-btn" onClick={() => {
                props.toggleModal(false)
                props.toggleFeedbackSuccessModal(false)
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="review-sent__title">Дякуємо за ваш відгук!</div>
            <div className="review-sent__info">Відгук з'явиться після модерації</div>
            <div className="review-sent__done-btn btn-light" onClick={() => {
                props.toggleModal(false)
                props.toggleFeedbackSuccessModal(false)
            }}>Готово</div>
        </div>
    )
}

export default FeedbackSuccess