import React from 'react'

const ServiceTable = props => {

    return (
        <div className="services-info__table-section">
            <table className="services-info__table">
                <tr>
                    <th>{props.title}</th>
                    {/* {
                        props.secondTitle ? (
                            <th>{props.secondTitle}</th>
                        ) : ''
                    }
                    {
                        props.thirdTitle ? (
                            <th>{props.thirdTitle}</th>
                        ) : ''
                    } */}
                </tr>
                {
                    props.elements.map((data, i) => {
                        return (
                            <tr>
                                <td>{data.name}</td>
                                {/* {
                                    data.cost ? (
                                        <td>{data.cost}</td>
                                    ) : ''
                                }
                                {
                                    data.costPdv ? (
                                        <td>{data.costPdv}</td>
                                    ) : ''
                                } */}
                            </tr>
                        )
                    })
                }
            </table>
            {
                props.description ? (
                    <div className="services-info__table-description">
                        {props.description}
                    </div>
                ) : ''
            }
        </div>
    )
}

export default ServiceTable