import React from 'react'

const ProductPageCharacteristicTable = props => {
    return (
        <>
            {
                props.specifications.map((data, i) => {
                    return (
                        <li className={`product-page__characteristics-item ${data.green ? 'green' : ''}`} key={i}>
                            <div className="product-page__characteristics-name">{data.property}</div>
                            <div className="product-page__characteristics-val">{data.value}</div>
                        </li>
                    )
                })
            }
        </>
    )
}

export default ProductPageCharacteristicTable