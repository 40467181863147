import React from 'react'
import Rating from "react-rating";
import starList from "../../../img/main-page/star-empty.svg";
import star from "../../../img/main-page/star.svg";
import {declOfNum} from "../../FeedbackNumbers";
import {Link} from "react-router-dom";
import {_images} from "../../../API";

const ProductPageInfoTop = (props) => {

    return (
        <div className="product-info__top-section">
            <div className="product-info__rating-wrap">
                <div className="product-info__stars">
                    <Rating
                        readonly
                        fractions={2}
                        placeholderRating={props.rating}
                        emptySymbol={<img src={starList} className="icon" alt='star'/>}
                        placeholderSymbol={<img src={star} className="icon" alt='star'/>}
                        fullSymbol={<img src={star} className="icon" alt='star'/>}
                    />
                </div>
                <div className="product-info__feedback-link" >{props.feedbacks}  {declOfNum(props.feedbacks, ['відгук', 'відгуки', 'відгуків'])}</div>
            </div>
            <Link to={`/catalog_brands/${props.brand.id}`} className="product-info__logo-wrap">
                <img src={`${_images}${props.brand.image}`} alt="" />
            </Link>
        </div>
    )
}

export default ProductPageInfoTop