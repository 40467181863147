import React from 'react'
import {connect, useDispatch} from 'react-redux';
import {ADD_BASKET_SERVICE} from '../../../redux/actionConst/action';
import {removeServices} from '../../../redux/actionCreators/basketProducts';

const ProductPageInfoServices = props => {
    const dispatch = useDispatch();

    const handleAddingOfService = () => {
        if (props.isServiceInBasket) {
            props.removeServices({id: props.productId, name: props.data.id});
            props.setService(prevState => prevState.filter(service => service.id !== props.data.id));
        } else {
            dispatch({type: ADD_BASKET_SERVICE, payload: {id: props.productId, service: props.data}});
            if(props.isServiceSaved) {
                props.setService(prevState => prevState.filter(service => service.id !== props.data.id));
            } else if(!props.productInBasket){
                props.setService(prevState => [...prevState.filter(data => data.is_guarantee !== 1), props.data]);
            }
        }
    };

    return (
        <div className="product-info__additional-services-item">
            <label className="checkbox-label">
                <div className="checkbox">
                    <span className="input-text">{props.data.name}</span>
                </div>
            </label>

            <div className="product-info__additional-services-price">
                {
                    props.data.price % 1 === 0 ? props.data.price : props.data.price
                } ₴
            </div>

            <div className={`services-toggle ${props.isServiceSaved || props.isServiceInBasket ? 'active' : ''}`} onClick={handleAddingOfService}>
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.11643 2.20801V7.61668M7.11643 7.61668V13.0253M7.11643 7.61668H1.70776M7.11643 7.61668H12.5251"
                        stroke="white" strokeWidth="1.58148"/>
                </svg>

                <p>
                    {
                        props.isServiceSaved || props.isServiceInBasket ? 'Видалити' : 'Додати'
                    }
                </p>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    removeServices,
};

export default connect(null, mapDispatchToProps)(ProductPageInfoServices);
