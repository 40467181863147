import React from 'react'

import ico1 from '../../../../img/quiz/ico1.svg'
import ico2 from '../../../../img/quiz/ico2.svg'
import ico4 from '../../../../img/quiz/ico4.svg'

const QuizModalSteps = props => {

    return (
        <div className="quiz-modal__steps">
            <div className="quiz-modal__steps-line"/>
            <div
                className={`quiz-modal__step-item ${props.activeQuiz === 'firstQuiz'? 'active' : ''} ${props.firstFilled? 'filled' : ''}`}
                 onClick={() => {
                     props.changeQuiz('firstQuiz')
                     props.setFirstFilled(false)
                 }}
            >
                <div className="quiz-modal__step-ico">
                    <img src={ico1} alt="" />
                </div>
                <div className="quiz-modal__step-number">1</div>
            </div>
            <div
                className={`quiz-modal__step-item ${props.activeQuiz === 'secondQuiz'? 'active' : ''} ${props.secondFilled? 'filled' : ''}`}
                onClick={() => {
                    props.changeQuiz('secondQuiz')
                    props.setSecondFilled(false)
                }}
            >
                <div className="quiz-modal__step-ico">
                    <img src={ico2} alt="" />
                </div>
                <div className="quiz-modal__step-number">2</div>
            </div>
            <div
                className={`quiz-modal__step-item ${props.activeQuiz === 'fourthQuiz'? 'active' : ''} ${props.fourthFilled? 'filled' : ''}`}
                onClick={() => {
                    props.changeQuiz('fourthQuiz')
                    props.setFourthFilled(false)
                }}
            >
                <div className="quiz-modal__step-ico">
                    <img src={ico4} alt="" />
                </div>
                <div className="quiz-modal__step-number">3</div>
            </div>
        </div>
    )
}

export default QuizModalSteps