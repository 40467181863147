import React, {useState} from 'react'
import Nouislider from "nouislider-react";

const QuizModalSecond = props => {

    const [value, setValue] = useState(0)

    const [inputValue, setInputValue] = useState(props.secondQuiz)

    const [inputError, setInputError] = useState(false)

    const changeValue = e => {
        setValue(e.target.value)
    }

    const changeInputValue = () => {
        setInputValue(value)
    }

    return (
        <div className="quiz-modal__form quiz-modal__form--height" id='secondQuiz'>
            <h4 className="quiz-modal__form-title"
                style={{color: inputError ? 'red ': ''}}
            >
                Введіть висоту приміщення, м
            </h4>
            <div className="quiz-modal__input-wrap">
                <input className="quiz-modal__form-input"
                    value={value === 0 ? '' : value}
                    onChange={(event) => {
                        changeValue(event);
                        setInputError(false)
                        }
                    }
                    id="quiz-square-input"
                    type="number"
                    placeholder="0"
                    autoFocus={true}
                />
            </div>
            <div className="quiz-modal__range-input-wrap">
                <Nouislider
                    range={{ min: 0, max: 7 }}
                    start={0}
                    onUpdate={(event) => { 
                        setValue(parseInt(event))
                        setInputError(false)
                        }
                    }
                    connect={[true, false]}
                />
                <div className="quiz-modal__range-values-wrap">
                    <div className="quiz-modal__range-value-min">0</div>
                    <div className="quiz-modal__range-value-max">7</div>
                </div>
            </div>
            <button 
                className="quiz-modal__submit-btn" 
                style={{background: inputError ? "gray": ""}}
                onClick={() => {
                if (Number(value)) {
                    changeInputValue()
                    props.changeQuiz('fourthQuiz')
                    props.setSecondFilled(true)
                    props.addSecondQuiz(value)
                } else {
                    setInputError(true)
                }
            }}>
                Далі
            </button>
        </div>
    )
}

export default QuizModalSecond