import {ADD_WISHLIST, CLEAN_WISHLIST, REMOVE_WISHLIST} from "../actionConst/action";


export const addWishlist = payload => {
    return {
        type: ADD_WISHLIST,
        payload: payload
    }
}

export const removeWishlist = payload => {
    return {
        type: REMOVE_WISHLIST,
        payload: payload
    }
}

export const cleanWishlist = () => {
    return {
        type: CLEAN_WISHLIST,
    }
}