import React from 'react'
import {Link} from 'react-router-dom';
import KondiciyuvannyaList from './KondiciyuvannyaList';

const Kondiciyuvannya = props => {
    return (
        <li className={`catalog-lv1__item ${props.activeCatalog.slug === props.data.slug ? 'active' : ''} ${props.activeCatalog.name === 'Каталог' ? '' : 'hidden-catalog'}`}
            onClick={() => {
                props.setActiveCatalog(props.data)
            }}>
            <div className="catalog-lv1__button">
                <div className="catalog-lv1__ico-box">
                    <svg className="catalog-lv1__ico" width="32" height="32" viewBox="0 0 32 32" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M29.5 6H2.5C2.10231 6.00045 1.72104 6.15863 1.43983 6.43983C1.15863 6.72104 1.00045 7.10231 1 7.5V17.5C1.00045 17.8977 1.15863 18.279 1.43983 18.5602C1.72104 18.8414 2.10231 18.9996 2.5 19H29.5C29.8977 18.9996 30.279 18.8414 30.5602 18.5602C30.8414 18.279 30.9996 17.8977 31 17.5V7.5C30.9996 7.10231 30.8414 6.72104 30.5602 6.43983C30.279 6.15863 29.8977 6.00045 29.5 6ZM4.5 18H2.5C2.36743 17.9999 2.24033 17.9471 2.14659 17.8534C2.05285 17.7597 2.00013 17.6326 2 17.5V16H4.5V18ZM26 18H5.5V16H26V18ZM30 17.5C29.9999 17.6326 29.9471 17.7597 29.8534 17.8534C29.7597 17.9471 29.6326 17.9999 29.5 18H27V16H30V17.5ZM30 15H2V7.5C2.00013 7.36743 2.05285 7.24033 2.14659 7.14659C2.24033 7.05285 2.36743 7.00013 2.5 7H29.5C29.6326 7.00013 29.7597 7.05285 29.8534 7.14659C29.9471 7.24033 29.9999 7.36743 30 7.5V15Z"
                            fill="black"/>
                        <path
                            d="M28.5 17.5C28.7761 17.5 29 17.2761 29 17C29 16.7239 28.7761 16.5 28.5 16.5C28.2239 16.5 28 16.7239 28 17C28 17.2761 28.2239 17.5 28.5 17.5Z"
                            fill="black"/>
                        <path
                            d="M28.5 11H24C23.8674 11 23.7402 11.0527 23.6464 11.1464C23.5527 11.2402 23.5 11.3674 23.5 11.5V13.5C23.5 13.6326 23.5527 13.7598 23.6464 13.8536C23.7402 13.9473 23.8674 14 24 14H28.5C28.6326 14 28.7598 13.9473 28.8536 13.8536C28.9473 13.7598 29 13.6326 29 13.5V11.5C29 11.3674 28.9473 11.2402 28.8536 11.1464C28.7598 11.0527 28.6326 11 28.5 11ZM28 13H24.5V12H28V13Z"
                            fill="black"/>
                        <path
                            d="M13.5 20.5C13.5 20.3674 13.4473 20.2402 13.3536 20.1464C13.2598 20.0527 13.1326 20 13 20C12.8674 20 12.7402 20.0527 12.6464 20.1464C12.5527 20.2402 12.5 20.3674 12.5 20.5C12.5 21.025 12.3306 21.2581 12.0957 21.5808C11.8301 21.9458 11.5 22.4 11.5 23.25C11.5 24.1 11.8301 24.5542 12.0957 24.9192C12.3306 25.2419 12.5 25.475 12.5 26C12.5 26.1326 12.5527 26.2598 12.6464 26.3536C12.7402 26.4473 12.8674 26.5 13 26.5C13.1326 26.5 13.2598 26.4473 13.3536 26.3536C13.4473 26.2598 13.5 26.1326 13.5 26C13.5 25.15 13.1699 24.6958 12.9043 24.3308C12.6694 24.0081 12.5 23.775 12.5 23.25C12.5 22.725 12.6694 22.4919 12.9043 22.1692C13.1699 21.8042 13.5 21.35 13.5 20.5Z"
                            fill="black"/>
                        <path
                            d="M16 20.5C16 20.3674 15.9473 20.2402 15.8536 20.1464C15.7598 20.0527 15.6326 20 15.5 20C15.3674 20 15.2402 20.0527 15.1464 20.1464C15.0527 20.2402 15 20.3674 15 20.5C15 21.025 14.8306 21.2581 14.5957 21.5808C14.3301 21.9458 14 22.4 14 23.25C14 24.1 14.3301 24.5542 14.5957 24.9192C14.8306 25.2419 15 25.475 15 26C15 26.1326 15.0527 26.2598 15.1464 26.3536C15.2402 26.4473 15.3674 26.5 15.5 26.5C15.6326 26.5 15.7598 26.4473 15.8536 26.3536C15.9473 26.2598 16 26.1326 16 26C16 25.15 15.6699 24.6958 15.4043 24.3308C15.1694 24.0081 15 23.775 15 23.25C15 22.725 15.1694 22.4919 15.4043 22.1692C15.6699 21.8042 16 21.35 16 20.5Z"
                            fill="black"/>
                        <path
                            d="M11 20.5C11 20.3674 10.9473 20.2402 10.8536 20.1464C10.7598 20.0527 10.6326 20 10.5 20C10.3674 20 10.2402 20.0527 10.1464 20.1464C10.0527 20.2402 10 20.3674 10 20.5C10 21.025 9.83056 21.2581 9.59569 21.5808C9.33006 21.9458 9 22.4 9 23.25C9 24.1 9.33006 24.5542 9.59569 24.9192C9.83056 25.2419 10 25.475 10 26C10 26.1326 10.0527 26.2598 10.1464 26.3536C10.2402 26.4473 10.3674 26.5 10.5 26.5C10.6326 26.5 10.7598 26.4473 10.8536 26.3536C10.9473 26.2598 11 26.1326 11 26C11 25.15 10.6699 24.6958 10.4043 24.3308C10.1694 24.0081 10 23.775 10 23.25C10 22.725 10.1694 22.4919 10.4043 22.1692C10.6699 21.8042 11 21.35 11 20.5Z"
                            fill="black"/>
                        <path
                            d="M21 20.5C21 20.3674 20.9473 20.2402 20.8536 20.1464C20.7598 20.0527 20.6326 20 20.5 20C20.3674 20 20.2402 20.0527 20.1464 20.1464C20.0527 20.2402 20 20.3674 20 20.5C20 21.025 19.8306 21.2581 19.5957 21.5808C19.3301 21.9458 19 22.4 19 23.25C19 24.1 19.3301 24.5542 19.5957 24.9192C19.8306 25.2419 20 25.475 20 26C20 26.1326 20.0527 26.2598 20.1464 26.3536C20.2402 26.4473 20.3674 26.5 20.5 26.5C20.6326 26.5 20.7598 26.4473 20.8536 26.3536C20.9473 26.2598 21 26.1326 21 26C21 25.15 20.6699 24.6958 20.4043 24.3308C20.1694 24.0081 20 23.775 20 23.25C20 22.725 20.1694 22.4919 20.4043 22.1692C20.6699 21.8042 21 21.35 21 20.5Z"
                            fill="black"/>
                        <path
                            d="M23.5 20.5C23.5 20.3674 23.4473 20.2402 23.3536 20.1464C23.2598 20.0527 23.1326 20 23 20C22.8674 20 22.7402 20.0527 22.6464 20.1464C22.5527 20.2402 22.5 20.3674 22.5 20.5C22.5 21.025 22.3306 21.2581 22.0957 21.5808C21.8301 21.9458 21.5 22.4 21.5 23.25C21.5 24.1 21.8301 24.5542 22.0957 24.9192C22.3306 25.2419 22.5 25.475 22.5 26C22.5 26.1326 22.5527 26.2598 22.6464 26.3536C22.7402 26.4473 22.8674 26.5 23 26.5C23.1326 26.5 23.2598 26.4473 23.3536 26.3536C23.4473 26.2598 23.5 26.1326 23.5 26C23.5 25.15 23.1699 24.6958 22.9043 24.3308C22.6694 24.0081 22.5 23.775 22.5 23.25C22.5 22.725 22.6694 22.4919 22.9043 22.1692C23.1699 21.8042 23.5 21.35 23.5 20.5Z"
                            fill="black"/>
                        <path
                            d="M18.5 20.5C18.5 20.3674 18.4473 20.2402 18.3536 20.1464C18.2598 20.0527 18.1326 20 18 20C17.8674 20 17.7402 20.0527 17.6464 20.1464C17.5527 20.2402 17.5 20.3674 17.5 20.5C17.5 21.025 17.3306 21.2581 17.0957 21.5808C16.8301 21.9458 16.5 22.4 16.5 23.25C16.5 24.1 16.8301 24.5542 17.0957 24.9192C17.3306 25.2419 17.5 25.475 17.5 26C17.5 26.1326 17.5527 26.2598 17.6464 26.3536C17.7402 26.4473 17.8674 26.5 18 26.5C18.1326 26.5 18.2598 26.4473 18.3536 26.3536C18.4473 26.2598 18.5 26.1326 18.5 26C18.5 25.15 18.1699 24.6958 17.9043 24.3308C17.6694 24.0081 17.5 23.775 17.5 23.25C17.5 22.725 17.6694 22.4919 17.9043 22.1692C18.1699 21.8042 18.5 21.35 18.5 20.5Z"
                            fill="black"/>
                    </svg>
                </div>

                <div
                    className="catalog-lv1__item-name"
                    onClick={() => props.desktop ? props.setActive(false) : ''}
                >
                    <Link to={`/catalog/${props.data.slug}`}>
                        {props.data.name}
                    </Link>
                </div>

                <div className="catalog-lv1__arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.77605 14.447C6.65776 14.3876 6.56786 14.2837 6.52606 14.1581C6.48425 14.0325 6.49396 13.8955 6.55305 13.777L9.44005 8L6.55205 2.224C6.52203 2.16524 6.50394 2.10111 6.49882 2.03533C6.4937 1.96954 6.50166 1.90339 6.52222 1.84069C6.54279 1.77799 6.57557 1.71998 6.61866 1.67001C6.66175 1.62003 6.71431 1.57909 6.77331 1.54952C6.8323 1.51996 6.89656 1.50237 6.96239 1.49776C7.02822 1.49315 7.0943 1.50162 7.15684 1.52267C7.21938 1.54372 7.27713 1.57695 7.32677 1.62043C7.3764 1.6639 7.41694 1.71678 7.44605 1.776L10.446 7.776C10.4809 7.84553 10.499 7.92223 10.499 8C10.499 8.07777 10.4809 8.15447 10.446 8.224L7.44605 14.224C7.38664 14.3423 7.28274 14.4322 7.15714 14.474C7.03155 14.5158 6.8945 14.5061 6.77605 14.447Z"
                            fill="#182316"/>
                    </svg>
                </div>
            </div>

            <KondiciyuvannyaList
                brands={props.data.brands}
                setActive={props.setActive}
                subcategories={props.data.children}
                slug={props.data.slug}
                optCategory={props.data.opt_category}
            />
        </li>
    );
};

export default Kondiciyuvannya;
