import React from 'react'
import {connect} from "react-redux";
import './CatalogFilter.scss'
import CatalogFilterTags from "./CatalogFilterTags/CatalogFilterTags";
import CatalogFilterBlocks from "./CatalogFilterBlocks/CatalogFilterBlocks";
import {cleanFilters} from "../../../redux/actionCreators/catalogProducts";
import close from "../../../img/close-btn-24.svg";

const CatalogFilter = (props) => {
    return (
        <div className={`filter-wrap ${props.openFilter? 'active' : ''}`}>
            <aside className="filter">
                <CatalogFilterTags
                    brands={props.brands}
                    specifications={props.specifications}
                    prices={props.prices}
                    base={props.base}
                    appliedFilters={props.appliedFilters}
                    setUrlFilters={props.setUrlFilters}
                />
                <button className="filter__mobile-close-btn" onClick={props.openFilterDrop}>
                    <img src={close} alt="Закрыть"/>
                </button>
                <CatalogFilterBlocks
                    urlFilters={props.urlFilters}
                    setUrlFilters={props.setUrlFilters}
                    prices={props.prices}
                    brands={props.brands}
                    specifications={props.specifications}
                    appliedFilters={props.appliedFilters}
                    productCount={props.productCount}
                />
                <div class={`filter__mobile-btn-wrapper ${props.openFilter? 'active' : ''}`}>
                    <button className="filter__mobile-btn" onClick={props.openFilterDrop}>
                        Показати
                    </button>
                    {
                        Object.keys(props.appliedFilters).length ? (
                            <button className="filter__mobile-btn" onClick={() => {
                                props.setUrlFilters(props.base.url0)
                            }}>
                                Очистити фільтр
                            </button>
                        ) : ''
                    }
                </div>
            </aside>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        prices: store.catalogFilterReducer.prices,
        brands: store.catalogFilterReducer.brands,
        base: store.catalogFilterReducer.base,
        specifications: store.catalogFilterReducer.specifications,
        appliedFilters: store.catalogFilterReducer.appliedFilters,
    }
}

const mapDispatchToProps = {
    cleanFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogFilter)