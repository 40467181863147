import React, { useState } from 'react'
import {Link} from "react-router-dom";
import {_images} from "../../../../../API";


const ProductCardLink = (props) => {
    const [vivibilityImg, setVisibiliityImg] = useState(props.image[0])
    return (
        <Link to={`/product_card/${props.slug}`}>
            <div className="product-card__img-wrap">
                <img 
                    src={_images + vivibilityImg}
                    alt="Фото продукту" 
                    onMouseEnter={() => window.screen.width > 1000 && props.image[1] !== undefined ? setVisibiliityImg(props.image[1]) : ''}
                    onMouseLeave={() => setVisibiliityImg(props.image[0])}
                />
            </div>
            <div className='card-name-box'>
                <p className="product-card__name">{props.name}</p>
            </div>

        </Link>
    )
}

export default ProductCardLink