import React from 'react'

const OrderResultTotal = props => {

    return (
        <div className="order-result__pricing">
            <div className="order-result__pricing-item">
                <div className="order-result__pricing-name">Всього:</div>
                <div className="order-result__pricing-val">
                    {(props.order.total_amount - props.order.delivery_price).toFixed()}грн
                </div>
            </div>
            <div className="order-result__pricing-item">
                <div className="order-result__pricing-name">{props.order.delivery_method_human}:</div>
                <div className="order-result__pricing-val">За тарифом</div>
            </div>
            <div className="order-result__pricing-item order-result__pricing-item--bold">
                <div className="order-result__pricing-name">Разом до сплати:</div>
                <div className="order-result__pricing-val">
                    {
                        props.order.total_amount === 0 ? (
                            'За домовленістю'
                        ) : (
                            `${props.order.total_amount.toFixed()} грн`
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default OrderResultTotal