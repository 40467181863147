import React from 'react'

import arrow from '../../../img/product-page/empty-section-arrow.svg'

const ProductPageFeedbackFalse = (props) => {

    return (
        <section className={`product-page__reviews product-page__reviews--empty active ${props.feed? 'highlight': ''}`} onClick={() => { props.setActiveReview(prev => !prev) }}>
            <h3 className="product-page__container-title">Відгуки про товар</h3>
            <div className="product-page__reviews-empty">
                <div className="product-page__reviews-empty-text">У цього товару поки немає
                    відгуків. Станьте першим!
                </div>
                <div className="product-page__reviews-empty-arrow">
                    <img src={arrow} alt="" />
                </div>
            </div>
        </section>
    )
}

export default ProductPageFeedbackFalse