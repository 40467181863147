import React from 'react'
import ServiceTable from "./ServiceTable";
import ServicePageInfoDescription from "../MontazhKondytsioneriv/ServicePageInfoDescription";
import liDot from "../../../img/li-dot.svg";
import MetaTags from "react-meta-tags";
import {_domain} from "../../../API";
import logo from "../../../img/logoNew/logoLikaComfortLigth.svg";

const ServisneObsluhovuvannia = () => {

    const first = [
        {
            name: 'Виклик фахівця на об\'єкт і визначення несправностей (діагностика): налаштування пульта, заміна батарейок в пульті, чистка дренажки, коректування жалюзі* (в межах міста)',
            cost: '400,00',
            costPdv: '480,00',
        }
    ]

    const second = [
        {name: 'Технічне обслуговування спліт системи/ 07-18 Btu', cost: '660,00', costPdv: '790,00',},
        {name: 'Технічне обслуговування спліт системи/ 07-18 Btu (кухня)', cost: '810,00', costPdv: '970,00',},
        {name: 'Технічне обслуговування спліт системи/ 24-36 Btu', cost: '720,00', costPdv: '860,00',},
        {name: 'Технічне обслуговування спліт системи/ 24- 36 Btu (кухня)', cost: '890,00', costPdv: '1 070,00',},
        {name: 'Технічне обслуговування спліт системи/ 07-18 Btu (внутрішній блок)', cost: '470,00', costPdv: '560,00',},
        {name: 'Технічне обслуговування спліт системи/ 07-18 Btu (зовнішній блок)', cost: '420,00', costPdv: '500,00',},
        {name: 'Технічне обслуговування спліт системи/ 24-36 Btu (внутрішній блок)', cost: '470,00', costPdv: '560,00',},
        {name: 'Технічне обслуговування спліт системи/ 24-36 Btu (зовнішній блок)', cost: '470,00', costPdv: '560,00',},
        {name: 'Технічне обслуговування спліт системи/ 07-18 Btu (кухня) (внутрішній блок)', cost: '580,00', costPdv: '700,00',},
        {name: 'Технічне обслуговування спліт системи/ 07-18 Btu (кухня) (зовнішній блок)', cost: '580,00', costPdv: '700,00',},
        {name: 'Технічне обслуговування спліт системи/ 24-36 Btu (кухня) (зовнішній блок)', cost: '580,00', costPdv: '700,00',},
        {name: '\tТехнічне обслуговування спліт системи/ 24-36 Btu (кухня) (внутрішній блок)', cost: '580,00', costPdv: '700,00',},
        {name: 'Технічне обслуговування мобільного кондиціонера', cost: '610,00', costPdv: '730,00',},
        {name: 'Технічне обслуговування побутового осушувача', cost: '850,00', costPdv: '1 020,00',},
    ]

    const third = [
        {name: 'Технічне обслуговування спліт системи/ 12-18 Btu', cost: '710,00', costPdv: '850,00',},
        {name: 'Технічне обслуговування спліт системи/ 24-60 Btu', cost: '900,00', costPdv: '1 080,00',},
        {name: 'Технічне обслуговування спліт системи/ 12-18 Btu (внутрішній блок)', cost: '520,00', costPdv: '620,00',},
        {name: 'Технічне обслуговування спліт системи/ 12-18 Btu (зовнішній блок)', cost: '520,00', costPdv: '620,00',},
        {name: 'Технічне обслуговування системи/ 24-60 Btu (внутрішній блок)', cost: '650,00', costPdv: '780,00',},
        {name: 'Технічне обслуговування системи / 24-60 Btu (зовнішній блок)', cost: '650,00', costPdv: '780,00',},
        {name: 'Технічне обслуговування промислових осушувачів', cost: '890,00', costPdv: '1 070,00',},
    ]

    const fourth =[
        {name: 'Візуальний огляд з метою виявлення явних дефектів'},
        {name: 'Перевірка тиску та герметичності системи'},
        {name: 'Профілактика та чистка теплообмінника зовнішнього блоку'},
        {name: 'Чистка та дезінфекція фільтрів внутрішнього блоку і теплообмінника'},
        {name: 'Чистка вентилятора барабанного типу внутрішнього блоку'},
        {name: 'Перевірка і чистка дренажу'},
        {name: 'Перевірка і підтяжка різьбових з\'єднань в клемних колодках і на пускозахисній апаратурі'},
        {name: 'Оцінка стану фреономагістралі (при необх. - дозаправка холодоагентом, оплачується додатково)'},
    ]

    const fifth = [
        {name: 'Виклик фахівця на об\'єкт і визначення несправностей (діагностика ПВУ, чіллер, руфтоп) (погодинно)', cost: '630/за 1год.', costPdv: '760/за 1год.',},
    ]

    const sixth = [
        {name: 'Технічне обслуговування вент. установок з рекуперацією до 1500 м3 (побутовий) (чистка вентилятора, теплообмінника, повітряних заслонок, рекуператора)', cost: '1 020,00', costPdv: '1 220,00',},
        {name: 'Технічне обслуговування вент.установок з рекуперацією (промисловий) від 2000 м3 (чистка вентилятора, теплообмінника, повітряних заслонок, рекуператора)', cost: '1 210,00', costPdv: '1 450,00',},
        {name: 'Технічне обслуговування ПУ без рекуперації від 2000 м3 (чистка вентилятора, теплообмінника, повітряних заслонок)', cost: '680,00', costPdv: '820,00',},
        {name: 'Технічне обслуговування вентилятора канальний-улітка (кухонний) від 2000 м3', cost: '1 260,00', costPdv: '1 510,00',},
        {name: 'Технічне обслуговування вентилятора (кухонного) до 2000 м3', cost: '1 020,00', costPdv: '1 220,00',},
        {name: 'Технічне обслуговування жировловлюваних фільтрів кухонного зонта', cost: '3 200,00', costPdv: '3 840,00',},
        {name: 'Технічне обслуговування вентиляційних решіток', cost: '60,00', costPdv: '70,00',},
        {name: 'Технічне обслуговування вентилятора (побутового)', cost: '400,00', costPdv: '480,00',},
        {name: 'Технічне обслуговування вентилятора (канального) до 2000 м3', cost: '520,00', costPdv: '620,00',},
        {name: 'Технічне обслуговування вентилятора (канального) від 2000 м3', cost: '660,00', costPdv: '790,00',},
        {name: 'Заміна фільтра ПВУ (без матеріалу)', cost: '150,00', costPdv: '180,00',},
        {name: 'Фільтр на вентиляційну машину 1 шт. (вартість залежить від розміру та якості матеріалу)', cost: 'від 190,00 до 320,00', costPdv: 'від 230,00 до 380,00',},
    ]

    const itemsLi = [
        'У разі використання спецтехніки (автовишка) або робіт альпініста, ціна обговорюється окремо;',
        'Ціни вказані за проведення робіт (мається на увазі розташування блоків в легкодоступних місцях);',
        'Ремонтні роботи проводяться в робочий час з 9.00 – 18.00;',
        'Робота після 18.00 вираховується з коефіцієнтом 1,5 від суми послуг;',
        'Позачерговий виклик сплачується у подвійному тарифі;',
        'На всі роботи і замінені деталі надається гарантія;',
        'Транспортні витрати 7 грн./один км.в одну сторону (за межами Мукачівського р-н;',
        'При виїзді спеціаліста дальше 30 км сумма ремонту збільшується на коефіціент 1,3;',
        'При виїзді спеціаліста дальше 60 км сумма ремонту збільшується на коефіціент 1,5;',
        'Всі ціни вказані для стандартного устаткування і нормальних умов проведення робіт (розташування устаткування у доступних   для проведення робіт в робочий час);',
        'Ціни вказано без запасних частин та матеріалів;',
        'Збирання-розбирання лісів становить 500 грн - за один ярус.',
    ]

    return (
        <div className="content" style={{marginBottom: '90px'}}>
            <MetaTags>
                <title>Обслуговування кондиціонерів та систем вентиляції повітря в Мукачево, кращі ціни на технічне обслуговування кондиціонерів в Ужгороді</title>
                <meta name="description" content={`Сервісне обслуговування кондиціонерів та систем вентиляції в Ужгороді ☀ ☁ Низкі ціни на обслуговування кондиціонерів і систем вентиляції ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Обслуговування кондиціонерів та систем вентиляції повітря в Мукачево, кращі ціни на технічне обслуговування кондиціонерів в Ужгороді" />
                <meta property="og:description" content="Сервісне обслуговування кондиціонерів та систем вентиляції в Ужгороді ☀ ☁ Низкі ціни на обслуговування кондиціонерів і систем вентиляції ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <main className="services-info">
                <svg className="services-info__bg-left" width="200" height="348" viewBox="0 0 200 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="51.0536" y="325.443" width="17.5651" height="17.5651"
                          transform="rotate(123.715 51.0536 325.443)" stroke="#43B02A" strokeOpacity="0.3"/>
                    <rect x="92.4013" y="40.7936" width="52.4257" height="52.4257"
                          transform="rotate(123.715 92.4013 40.7936)" stroke="#43B02A"/>
                    <rect x="136.334" y="100.376" width="80.4985" height="80.4985"
                          transform="rotate(123.715 136.334 100.376)" stroke="#DCDCDC"/>
                    <rect x="186.035" y="177.846" width="50.7197" height="50.7197"
                          transform="rotate(123.715 186.035 177.846)" stroke="#43B02A" strokeOpacity="0.5"/>
                    <rect x="133.185" y="254.315" width="59" height="59" transform="rotate(123.715 133.185 254.315)"
                          stroke="#DCDCDC" strokeOpacity="0.4"/>
                    <rect x="63.1298" y="161.89" width="68.8139" height="68.8139"
                          transform="rotate(123.715 63.1298 161.89)" fill="white" fillOpacity="0.1"
                          stroke="#43B02A"/>
                    <rect x="40.6113" y="261.674" width="39.5981" height="39.5981"
                          transform="rotate(123.715 40.6113 261.674)" stroke="#43B02A" strokeOpacity="0.4"/>
                    <rect x="23.0077" y="78.6374" width="59.3526" height="59.3526"
                          transform="rotate(123.715 23.0077 78.6374)" stroke="#DCDCDC"/>
                </svg>
                <svg className="services-info__bg-right" width="217" height="362" viewBox="0 0 217 362" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="187.814" y="51.2272" width="52.4257" height="52.4257"
                          transform="rotate(123.715 187.814 51.2272)" stroke="#43B02A"/>
                    <rect x="31.832" y="85.6433" width="17.5651" height="17.5651"
                          transform="rotate(123.715 31.832 85.6433)" stroke="#43B02A"/>
                    <rect x="226.134" y="117.668" width="80.4985" height="80.4985"
                          transform="rotate(123.715 226.134 117.668)" stroke="#43B02A"/>
                    <rect x="197.696" y="221.912" width="56.2435" height="56.2435"
                          transform="rotate(123.715 197.696 221.912)" stroke="#DCDCDC"/>
                    <rect x="118.522" y="99.3991" width="59.3526" height="59.3526"
                          transform="rotate(123.715 118.522 99.3991)" fill="white" fillOpacity="0.1"
                          stroke="#DCDCDC"/>
                    <rect x="129.936" y="286.601" width="34.0643" height="34.0643"
                          transform="rotate(123.715 129.936 286.601)" stroke="#43B02A" strokeOpacity="0.5"/>
                    <rect x="188.632" y="331.535" width="20.8121" height="20.8121"
                          transform="rotate(123.715 188.632 331.535)" stroke="#43B02A" strokeOpacity="0.3"/>
                    <rect x="124.931" y="45.2683" width="59.3526" height="59.3526"
                          transform="rotate(123.715 124.931 45.2683)" stroke="white"/>
                </svg>
                <div className="container">
                    <h1 className="services-info__title">Обслуговування кондиціонерів та систем вентиляції повітря</h1>
                    <ServiceTable
                        title={'Вид обслуговування'}
                        secondTitle={'Ціна, грн'}
                        thirdTitle={'Ціна, грн з ПДВ'}
                        elements={first}
                        description={'* якщо складна діагностика то вартість складає 500 грн (без ПДВ) за кожну наступну годину роботи'}
                    />
                    <br/>
                    <h2 style={{textAlign: 'center'}}>
                        Перелік послуг технічного обслуговування побутових систем кондиціювання
                    </h2>
                    <br/>
                    <ServiceTable
                        title={'Вид обслуговування/Тип системи'}
                        secondTitle={'Ціна, грн'}
                        thirdTitle={'Ціна, грн з ПДВ'}
                        elements={second}
                    />
                    <br/>
                    <h2 style={{textAlign: 'center'}}>
                        Перелік послуг технічного обслуговування напівпромислових систем кондиціювання
                        <br/>
                        (касета, напольно-стельовий, канальний, колонний)
                    </h2>
                    <br/>
                    <ServiceTable
                        title={'Вид обслуговування/Тип системи'}
                        secondTitle={'Ціна, грн'}
                        thirdTitle={'Ціна, грн з ПДВ'}
                        elements={third}
                    />
                    <ServiceTable
                        title={'Перелік робіт, які проводяться при повному технічному обслуговуванні спліт систем'}
                        elements={fourth}
                    />
                    <br/>
                    <h2 style={{textAlign: 'center'}}>
                        Перелік послуг технічного обслуговування систем вентиляції
                    </h2>
                    <br/>
                    <ServiceTable
                        title={'Вид обслуговування/Тип системи'}
                        secondTitle={'Ціна, грн'}
                        thirdTitle={'Ціна, грн з ПДВ'}
                        elements={fifth}
                        description={'* якщо складна діагностика то вартість складає 500 грн (без ПДВ) за кожну наступну годину роботи'}
                    />
                    <ServiceTable
                        title={'Вид обслуговування/Тип системи'}
                        secondTitle={'Ціна, грн'}
                        thirdTitle={'Ціна, грн з ПДВ'}
                        elements={sixth}
                    />
                    <br/>
                    <ServicePageInfoDescription
                        name={'Примітки:'}
                        itemsLi={itemsLi}
                        img={liDot}
                    />
                </div>
            </main>
        </div>
    )
}

export default ServisneObsluhovuvannia
