import React from 'react'
import './EmptyCatalog.scss'

const EmptyCatalogSearch = () => {

    return (
        <div className="empty-catalog-card">
            <svg width="252" height="252" viewBox="0 0 252 252" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M238.423 62.0156H58.0781C56.0382 62.0156 54.3867 63.6671 54.3867 65.707C54.3867 67.7469 56.0382 69.3984 58.0781 69.3984H238.423C241.839 69.3984 244.617 72.1766 244.617 75.5931V148.273H235.327C233.801 148.273 232.559 147.031 232.559 145.505C232.559 139.906 228.004 135.353 222.407 135.353H184.899C182.859 135.353 181.208 137.005 181.208 139.045C181.208 141.083 182.859 142.736 184.899 142.736H222.407C223.934 142.736 225.176 143.976 225.176 145.505C225.176 151.102 229.73 155.656 235.327 155.656H244.606C244.34 170.287 232.362 182.111 217.67 182.111H34.3301C19.6375 182.111 7.65967 170.287 7.39435 155.656H16.6729C22.2696 155.656 26.8242 151.102 26.8242 145.505C26.8242 143.976 28.0662 142.736 29.5928 142.736H171.118C173.156 142.736 174.809 141.083 174.809 139.045C174.809 137.005 173.156 135.353 171.118 135.353H29.5928C23.9961 135.353 19.4414 139.906 19.4414 145.505C19.4414 147.031 18.1994 148.273 16.6729 148.273H7.38281V75.5931C7.38281 72.1766 10.161 69.3984 13.5775 69.3984H44.2969C46.3368 69.3984 47.9883 67.7469 47.9883 65.707C47.9883 63.6671 46.3368 62.0156 44.2969 62.0156H13.5775C6.09082 62.0156 0 68.1064 0 75.5931V155.164C0 174.094 15.4001 189.494 34.3301 189.494H217.67C236.6 189.494 252 174.094 252 155.164V75.5931C252 68.1064 245.909 62.0156 238.423 62.0156V62.0156Z" fill="#257226"/>
                <path d="M238.506 84.369C238.506 78.159 233.451 73.1045 227.239 73.1045C221.027 73.1045 215.975 78.159 215.975 84.369C215.975 90.581 221.027 95.6355 227.239 95.6355C233.451 95.6355 238.506 90.581 238.506 84.369ZM223.357 84.369C223.357 82.2292 225.099 80.4873 227.241 80.4873C229.381 80.4873 231.123 82.2292 231.123 84.369C231.123 86.5108 229.381 88.2527 227.241 88.2527C225.099 88.2527 223.357 86.5108 223.357 84.369Z" fill="#257226"/>
                <path d="M213.613 84.369C213.613 78.159 208.559 73.1045 202.347 73.1045C196.137 73.1045 191.082 78.159 191.082 84.369C191.082 90.581 196.137 95.6355 202.347 95.6355C208.559 95.6355 213.613 90.581 213.613 84.369ZM198.465 84.369C198.465 82.2292 200.207 80.4873 202.347 80.4873C204.488 80.4873 206.23 82.2292 206.23 84.369C206.23 86.5108 204.488 88.2527 202.347 88.2527C200.207 88.2527 198.465 86.5108 198.465 84.369Z" fill="#257226"/>
                <path d="M214.548 148.273H37.4526C35.4128 148.273 33.7612 149.925 33.7612 151.965C33.7612 154.003 35.4128 155.656 37.4526 155.656H214.548C216.588 155.656 218.239 154.003 218.239 151.965C218.239 149.925 216.588 148.273 214.548 148.273Z" fill="#257226"/>
                <path d="M23.9175 165.132C23.9175 167.17 25.569 168.823 27.6089 168.823H224.392C226.431 168.823 228.083 167.17 228.083 165.132C228.083 163.092 226.431 161.44 224.392 161.44H27.6089C25.569 161.44 23.9175 163.092 23.9175 165.132Z" fill="#257226"/>
                <path d="M94.6538 97.1683C99.0911 97.1683 103.221 94.7766 105.436 90.9275C106.451 89.1606 105.841 86.9035 104.075 85.8883C102.308 84.8713 100.051 85.4808 99.0354 87.2476C98.1337 88.8126 96.4552 89.7855 94.6538 89.7855C92.8503 89.7855 91.1719 88.8126 90.2721 87.2476C89.2551 85.4808 86.9999 84.8694 85.2311 85.8864C83.4642 86.9035 82.8547 89.1587 83.8718 90.9275C86.0847 94.7766 90.2164 97.1683 94.6538 97.1683Z" fill="#257226"/>
                <path d="M157.346 97.1683C161.783 97.1683 165.915 94.7766 168.128 90.9275C169.145 89.1606 168.535 86.9035 166.768 85.8883C165 84.8713 162.744 85.4808 161.727 87.2476C160.828 88.8126 159.149 89.7855 157.346 89.7855C155.544 89.7855 153.866 88.8126 152.964 87.2476C151.949 85.4808 149.692 84.8694 147.925 85.8864C146.158 86.9035 145.549 89.1587 146.564 90.9275C148.779 94.7766 152.91 97.1683 157.346 97.1683Z" fill="#257226"/>
                <path d="M113.843 103.615L113.843 109.964C113.843 112.002 115.497 113.655 117.535 113.655L134.465 113.655C136.503 113.655 138.157 112.002 138.157 109.964L138.157 103.615C138.157 96.9113 132.702 91.4588 126 91.4588C119.298 91.4588 113.843 96.9113 113.843 103.615ZM130.774 103.615L130.774 106.272L121.226 106.272L121.226 103.615C121.226 100.983 123.368 98.8416 126 98.8416C128.632 98.8416 130.774 100.983 130.774 103.615Z" fill="#257226"/>
                <path d="M160.142 108.333H154.553C152.513 108.333 150.861 106.681 150.861 104.642C150.861 102.604 152.513 100.95 154.553 100.95H160.142C162.182 100.95 163.833 102.604 163.833 104.642C163.833 106.681 162.182 108.333 160.142 108.333Z" fill="#257226"/>
                <path d="M97.4474 108.333H91.8584C89.8185 108.333 88.167 106.681 88.167 104.642C88.167 102.604 89.8185 100.95 91.8584 100.95H97.4474C99.4873 100.95 101.139 102.604 101.139 104.642C101.139 106.681 99.4873 108.333 97.4474 108.333Z" fill="#257226"/>
            </svg>
            <h2>
                Упс, тут нічого немає
            </h2>
        </div>
    )
}

export default EmptyCatalogSearch