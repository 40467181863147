import React, {Fragment, useState, useEffect} from 'react'
import Slider from 'react-slick'
import ProductPagePhotoSliderMainItem from "./ProductPagePhotoSliderMainItem";
import ProductPagePhotoSliderDemo from "./ProductPagePhotoSliderDemo";
import ProductPagePhotoSliderAdvantages from "./ProductPagePhotoSliderAdvantages";
import ProductsSliderPrev from "../../globalComponents/ProductsSlider/ProductsSliderPrev";
import ProductsSliderNext from "../../globalComponents/ProductsSlider/ProductsSliderNext";

const ProductPagePhotoSlider = (props) => {

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    let slider1 = []
    let slider2 = []

    useEffect(() => {
        setNav1(slider1)
        setNav2(slider2)
    }, [slider1, slider2])

    const settingsMain ={
        infinite: true,
        autoplay: false,
        adaptiveHeight: true,
        speed: 400,
        arrows: true,
        fade: true,
        prevArrow: <ProductsSliderPrev />,
        nextArrow: <ProductsSliderNext />,
        responsive: [
            {
                breakpoint: 740,
                settings: {
                    dots: false
                }
            }
        ]
    }

    const settingsThumbnail = {
        infinite: false,
        slidesToShow: 4,
        adaptiveHeight: true,
        speed: 400,
        arrows: true,
        prevArrow: <ProductsSliderPrev />,
        nextArrow: <ProductsSliderNext />,
        focusOnSelect: true
    }

    return (
        <section className="product-demo">
            <h2 className="product-page__title-mobile">{props.name}</h2>
            <div className="product-demo__slider-wrap">
                <div className="product-demo__main-slider-wrap" >
                    <Slider
                        asNavFor={nav2}
                            ref={slider => (slider1 = slider)}
                            className='product-demo__main-slider'
                            {...settingsMain}>
                        {
                            props.photos.map((data, i) => {
                                return (
                                    <Fragment key={i}>
                                        <ProductPagePhotoSliderMainItem
                                            photo={data}
                                            toggleModal={props.toggleModal}
                                            toggleProductSliderModal={props.toggleProductSliderModal}
                                        />
                                    </Fragment>
                                )
                            })
                        }
                    </Slider>
                </div>
                <Slider
                        asNavFor={nav1}
                        ref={slider => (slider2 = slider)}
                        className='product-demo__thumbnail-slider'
                        {...settingsThumbnail}>
                    {
                        props.photos.map((data, i) => {
                            return (
                                <ProductPagePhotoSliderDemo key={i} photo={data}/>
                            )
                        })
                    }
                </Slider>
            </div>
            <div className="advantages-icons">
                {
                    props.advantages === null ? '' : <ProductPagePhotoSliderAdvantages advantages={props.advantages}/>
                }
            </div>
        </section>
    )
}

export default ProductPagePhotoSlider