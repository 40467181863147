import React from 'react'

const ProfileHistoryHeader = props => {

    return (
        <div className="history-table__row history-table__row--header">
            <div className="history-table__col history-table__col--number">Номер
                замовлення
            </div>
            <div className="history-table__col history-table__col--name">Назва товару
            </div>
            <div className="history-table__col history-table__col--quantity">Кількість
            </div>
            <div className="history-table__col history-table__col--services">Додаткові
                послуги
            </div>
            <div className="history-table__col history-table__col--date">Дата
                замовлення
            </div>
            <div className="history-table__col history-table__col--total">Сума</div>
            <div className="history-table__col history-table__col--quantity">Кешбек</div>
        </div>
    )
}

export default ProfileHistoryHeader