import React from "react";

const ProductCardDescriptionCharacteristic = (props) => {
    return (
        <>
            {
                props.specifications.slice(0, 3).map((data, i) => {
                    return (
                        <div className="characteristics__item" key={i}>
                            <p className="characteristics__item-title">{data.property}</p>
                            <p className="characteristics__item-value">{data.value}</p>
                        </div>
                    )
                })
            }
        </>
    )
}

export default ProductCardDescriptionCharacteristic