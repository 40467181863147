import React from 'react'
import {Link} from "react-router-dom";

const ResultItems = (props) => {

    return (

        <ul className='header-search__drop-items'>
            {
                props.products.map((data, i) => {
                    return (
                        <li key={i} className="header-search__drop-item">
                            <Link to={`/product_card/${data.slug}`}>
                                {data.name}
                            </Link>
                        </li>
                    )
                })
            }
        </ul>

    )
}

export default ResultItems