import React from 'react'
import {useHistory} from "react-router";

const MobileMenuServices = (props) => {

    const history = useHistory()

    return (
        <>
            {
                props.services.map((data, i) => {
                    return (
                        <li key={i} className="mobile-menu__lv2-item" onClick={() => {
                            props.setMobileMenuCategory(false)
                            history.push(data.link)
                        }}>
                            <div>{data.name}</div>
                        </li>
                    )
                })
            }
        </>
    )
}

export default MobileMenuServices