import React from 'react'

const MainBannerNext = (props) => {

    return (
        <button className="main-slider-arrows__arrow-right" onClick={props.onClick}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="40" width="40" height="40" transform="rotate(90 40 0)" fill="#183029" fillOpacity="0.5"/>
                <path  d="M17.7052 32.0879C17.4835 31.9765 17.3149 31.7817 17.2365 31.5462C17.1581 31.3107 17.1763 31.0538 17.2871 30.8317L22.7002 19.9998L17.2852 9.16981C17.229 9.05963 17.195 8.9394 17.1854 8.81604C17.1758 8.69269 17.1908 8.56866 17.2293 8.4511C17.2679 8.33353 17.3293 8.22477 17.4101 8.13107C17.4909 8.03737 17.5895 7.9606 17.7001 7.90517C17.8107 7.84974 17.9312 7.81675 18.0546 7.80811C18.1781 7.79947 18.302 7.81534 18.4192 7.85481C18.5365 7.89429 18.6448 7.95658 18.7378 8.03811C18.8309 8.11963 18.9069 8.21877 18.9615 8.32981L24.5865 19.5798C24.6518 19.7102 24.6858 19.854 24.6858 19.9998C24.6858 20.1456 24.6518 20.2894 24.5865 20.4198L18.9615 31.6698C18.8501 31.8916 18.6553 32.0602 18.4198 32.1385C18.1843 32.2169 17.9273 32.1987 17.7052 32.0879Z" fill="white"/>
            </svg>
        </button>
    )
}

export default MainBannerNext