import React from 'react'
import BasketModalTrue from "./BasketModalTrue";
import BasketModalFalse from "./BasketModalFalse";

const BasketModal = (props) => {


    return (
        <div className="header-actions__modal-wrap" onMouseLeave={() => {
            props.setBasketModal(false)
        }}>
            <section className={`modal modal-basket ${props.basketModal? 'active' : ''}`}>
                <div className="modal__close" onClick={props.openBasket}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 6.974L17.026 5L12 10.026L6.974 5L5 6.974L10.026 12L5 17.026L6.974 19L12 13.974L17.026 19L19 17.026L13.974 12L19 6.974Z"
                            fill="#868686"/>
                    </svg>
                </div>
                {
                    props.products.length > 0? <BasketModalTrue openBasket={props.openBasket} products={props.products}/> : <BasketModalFalse />
                }
            </section>
        </div>
    )
}



export default BasketModal