import React from 'react'
import {Link} from 'react-router-dom';
import TeploviNasosiList from "./TeploviNasosiList";

const TeploviNasosi = props => {

    return (
        <li className={`catalog-lv1__item ${props.activeCatalog.slug === props.data.slug ? 'active' : ''} ${props.activeCatalog.name === 'Каталог' ? '' : 'hidden-catalog'}`} onClick={() => {
            props.setActiveCatalog(props.data)
        }}>
            <div className="catalog-lv1__button">
                <div className="catalog-lv1__ico-box">
                    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px"
                         y="0px"
                         width="32"
                         height="32"
                         viewBox="0 0 134 134" style={{"enableBackground":"new 0 0 32 32"}}>
                        <g>
                            <path fill="#868686" className="st0" d="M113.4,35.8L113.4,35.8c0-2.5-2.1-4.6-4.6-4.6h-27c-2.5,0-4.6,2.1-4.6,4.6v20.1c0,2.5,2.1,4.6,4.6,4.6h27
                                                            c2.5,0,4.6-2.1,4.6-4.6V35.8z M109.5,55.9c0,0.4-0.3,0.7-0.7,0.7h-27c-0.4,0-0.7-0.3-0.7-0.7V52h28.3V55.9z M109.5,48.1H81.1v-4.5
                                                            h28.3V48.1z M109.5,39.7H81.1v-3.9c0-0.4,0.3-0.7,0.7-0.7h27c0.4,0,0.7,0.3,0.7,0.7V39.7z"/>
                            <path fill="#868686" className="st0" d="M81.8,65c-2.5,0-4.6,2.1-4.6,4.6v42.8c0,2.5,2.1,4.6,4.6,4.6h27c2.5,0,4.6-2.1,4.6-4.6V69.6
                                                            c0-2.5-2.1-4.6-4.6-4.6H81.8z M81.1,84.3v-5.7h28.3v5.7L81.1,84.3L81.1,84.3z M109.5,112.5c0,0.4-0.3,0.7-0.7,0.7h-27
                                                            c-0.4,0-0.7-0.3-0.7-0.7v-5h28.3V112.5z M109.5,103.5H81.1v-5.7h28.3V103.5z M109.5,93.9H81.1v-5.7h28.3V93.9z M109.5,69.6v5H81.1
                                                            v-5c0-0.4,0.3-0.7,0.7-0.7h27C109.1,69,109.5,69.3,109.5,69.6z"/>
                            <path fill="#868686" className="st0" d="M46.6,8.5c-14.3,0-26,11.7-26,26c0,14.3,11.7,26,26,26s26-11.7,26-26C72.7,20.1,61,8.5,46.6,8.5z M44.7,56.5
                                                            c-10.6-0.9-19.1-9.4-20-20H34c0.8,5.5,5.2,9.8,10.7,10.7V56.5z M44.7,43.1c-3.3-0.8-5.9-3.4-6.7-6.7h6.7V43.1z M44.7,32.5H38
                                                            c0.8-3.3,3.4-5.9,6.7-6.7V32.5z M44.7,21.8C39.2,22.7,34.8,27,34,32.5h-9.4c0.9-10.6,9.4-19.1,20-20V21.8z M48.6,12.5
                                                            c10.6,0.9,19.1,9.4,20,20h-9.4c-0.8-5.5-5.2-9.8-10.7-10.7V12.5z M48.6,25.8c3.3,0.8,5.9,3.4,6.7,6.7h-6.7V25.8z M48.6,36.4h6.7
                                                            c-0.8,3.3-3.4,5.9-6.7,6.7V36.4z M48.6,56.5v-9.4c5.5-0.8,9.8-5.2,10.7-10.7h9.4C67.7,47.1,59.2,55.5,48.6,56.5z"/>
                            <path fill="#868686" className="st0" d="M20.6,91.1c0,14.3,11.7,26,26,26s26-11.7,26-26c0-14.3-11.7-26-26-26C32.3,65,20.6,76.7,20.6,91.1z
                                                             M44.7,113.1c-10.6-0.9-19.1-9.4-20-20H34c0.8,5.5,5.2,9.8,10.7,10.7V113.1z M44.7,99.7C41.4,99,38.7,96.3,38,93h6.7V99.7z
                                                             M44.7,89.1H38c0.8-3.3,3.4-5.9,6.7-6.7V89.1z M44.7,78.4c-5.5,0.8-9.8,5.2-10.7,10.7h-9.4c0.9-10.6,9.4-19.1,20-20V78.4z
                                                             M48.6,69.1c10.6,0.9,19.1,9.4,20,20h-9.4c-0.8-5.5-5.2-9.8-10.7-10.7V69.1z M48.6,82.4c3.3,0.8,5.9,3.4,6.7,6.7h-6.7V82.4z
                                                             M48.6,93h6.7c-0.8,3.3-3.4,5.9-6.7,6.7V93z M48.6,103.7c5.5-0.8,9.8-5.2,10.7-10.7h9.4c-0.9,10.6-9.4,19.1-20,20V103.7z"/>
                            <path fill="#868686" className="st0" d="M83.4,20.8c3.4,0,6.2-2.8,6.2-6.2c0-3.4-2.8-6.2-6.2-6.2s-6.2,2.8-6.2,6.2C77.2,18.1,80,20.8,83.4,20.8z
                                                            M83.4,12.4c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3S82.1,12.4,83.4,12.4z"/>
                            <path fill="#868686" className="st0" d="M107.2,8.5c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2
                                                            C113.4,11.2,110.6,8.5,107.2,8.5z M107.2,16.9c-1.2,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3S108.4,16.9,107.2,16.9z"/>
                            <path fill="#868686" className="st0" d="M14.1,90c1.1,0,2-0.9,2-2V7.2c0-1.8,1.5-3.3,3.3-3.3h95.2c1.8,0,3.3,1.5,3.3,3.3v15.6c0,1.1,0.9,2,2,2
                                                            s2-0.9,2-2V7.2c0-4-3.3-7.2-7.2-7.2H19.4c-4,0-7.2,3.3-7.2,7.2v80.8C12.2,89.2,13,90,14.1,90z"/>
                            <path fill="#868686" className="st0" d="M123,121.6h-1.2V32.1c0-1.1-0.9-2-2-2s-2,0.9-2,2v89.6H16.1V97.2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v24.4H11
                                                            c-2.2,0-4.1,1.8-4.1,4.1v4.2c0,2.2,1.8,4.1,4.1,4.1h86.5c1.1,0,2-0.9,2-2s-0.9-2-2-2H11c-0.1,0-0.2-0.1-0.2-0.2v-4.2
                                                            c0-0.1,0.1-0.2,0.2-0.2H123c0.1,0,0.2,0.1,0.2,0.2v4.2c0,0.1-0.1,0.2-0.2,0.2h-16.4c-1.1,0-2,0.9-2,2s0.9,2,2,2H123
                                                            c2.2,0,4.1-1.8,4.1-4.1v-4.2C127.1,123.4,125.3,121.6,123,121.6z"/>
                        </g>
                    </svg>
                </div>
                <div
                    className="catalog-lv1__item-name"
                    onClick={() => props.desktop ? props.setActive(false) : ''}
                >
                    <Link to={`/catalog/${props.data.slug}`}>
                        {props.data.name}
                    </Link>
                </div>
                <div className="catalog-lv1__arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.77605 14.447C6.65776 14.3876 6.56786 14.2837 6.52606 14.1581C6.48425 14.0325 6.49396 13.8955 6.55305 13.777L9.44005 8L6.55205 2.224C6.52203 2.16524 6.50394 2.10111 6.49882 2.03533C6.4937 1.96954 6.50166 1.90339 6.52222 1.84069C6.54279 1.77799 6.57557 1.71998 6.61866 1.67001C6.66175 1.62003 6.71431 1.57909 6.77331 1.54952C6.8323 1.51996 6.89656 1.50237 6.96239 1.49776C7.02822 1.49315 7.0943 1.50162 7.15684 1.52267C7.21938 1.54372 7.27713 1.57695 7.32677 1.62043C7.3764 1.6639 7.41694 1.71678 7.44605 1.776L10.446 7.776C10.4809 7.84553 10.499 7.92223 10.499 8C10.499 8.07777 10.4809 8.15447 10.446 8.224L7.44605 14.224C7.38664 14.3423 7.28274 14.4322 7.15714 14.474C7.03155 14.5158 6.8945 14.5061 6.77605 14.447Z"
                            fill="#182316"/>
                    </svg>
                </div>
            </div>
            <TeploviNasosiList
                brands={props.data.brands}
                setActive={props.setActive}
                subcategories={props.data.children}
                slug={props.data.slug}
                optCategory={props.data.opt_category}
            />
        </li>
    );
};

export default TeploviNasosi;
