import React from 'react'

const CatalogFilterTagsItem = (props) => {
    return (
        <div className="filter__picked-item">
            <span className="filter__picked-val">
                {props.name}
            </span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                props.setUrlFilters(props.url)
            }}>
                <path
                    d="M14.1667 7.00801L12.9917 5.83301L10 8.82467L7.00837 5.83301L5.83337 7.00801L8.82504 9.99967L5.83337 12.9913L7.00837 14.1663L10 11.1747L12.9917 14.1663L14.1667 12.9913L11.175 9.99967L14.1667 7.00801Z"
                    fill="#182316"/>
            </svg>
        </div>
    )
}

export default CatalogFilterTagsItem