import React from 'react'
import {connect} from 'react-redux'
import './BasketModal.scss'
import BasketModalItem from "./BasketModalItem";
import {Link} from "react-router-dom";
import EmptyPage from "../../EmptyCatalog/EmptyPage";
import CleanPage from "../../CleanPage/CleanPage";

const BasketModal = props => {

    const totalPrice = props.basket.reduce((i,products) => {
        if(products.new_services) {
            return (
                i + (
                    products.final_price +products.new_services.reduce((j, product) => {
                        return (
                            j + product.price
                        )
                    }, 0)
                ) * products.quantity
            )
        } else {
            return (
                i + products.final_price * products.quantity
            )
        }
    }, 0)

    return (
        <section
            className={`modal-basket--credit ${props.basketModal ? 'active' : ''}`}
        >
            <div className="modal__close" onClick={() => {
                props.toggleBasketModal(false)
                props.toggleModal(false)
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.974L17.026 5L12 10.026L6.974 5L5 6.974L10.026 12L5 17.026L6.974 19L12 13.974L17.026 19L19 17.026L13.974 12L19 6.974Z"
                        fill="#868686"/>
                </svg>
            </div>
            <h3 className="modal-basket__title">Корзина</h3>
            <div className="modal-basket__body modal-basket__body--filled">
                <div className="modal-basket__items-wrap">
                    {
                        props.basket.length > 0 ? (
                            props.basket.map(data => {
                                return (
                                    <BasketModalItem basket={props.basket} key={data.id} data={data}/>
                                )
                            })
                        ) : (
                            <>
                                <EmptyPage />
                                <CleanPage name={' '} title={' '}/>
                            </>
                        )
                    }
                </div>
                <div className="modal-basket__footer">
                    {
                        props.basket.length > 0 ? (
                            <div className="modal-basket__footer-top">
                                <div className="modal-basket__price-total">
                                    <span className="modal-basket__price-total-text">Всього:</span>
                                    <span className="modal-basket__price-total-val">
                            {
                                totalPrice === 0 ? (
                                    'За домовленістю'
                                ) : (
                                    `${totalPrice.toFixed(2)} грн`
                                )
                            }
                                    </span>
                                </div>
                            </div>
                        ) : ''
                    }
                    <div className="modal-basket__footer-bottom">
                        <button className="modal-basket__back-link" onClick={() => {
                            props.toggleModal(false)
                            props.toggleBasketModal(false)
                        }}>
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path  d="M9.22395 1.55295C9.34224 1.61236 9.43214 1.71625 9.47394 1.84185C9.51575 1.96745 9.50604 2.1045 9.44695 2.22295L6.55995 7.99995L9.44795 13.7759C9.47797 13.8347 9.49606 13.8988 9.50118 13.9646C9.5063 14.0304 9.49834 14.0966 9.47778 14.1593C9.45721 14.222 9.42443 14.28 9.38134 14.3299C9.33825 14.3799 9.28569 14.4209 9.22669 14.4504C9.1677 14.48 9.10344 14.4976 9.03761 14.5022C8.97178 14.5068 8.9057 14.4983 8.84316 14.4773C8.78062 14.4562 8.72287 14.423 8.67323 14.3795C8.6236 14.336 8.58306 14.2832 8.55395 14.2239L5.55395 8.22395C5.51911 8.15442 5.50097 8.07772 5.50097 7.99995C5.50097 7.92218 5.51911 7.84548 5.55395 7.77595L8.55395 1.77595C8.61336 1.65766 8.71726 1.56776 8.84286 1.52595C8.96845 1.48415 9.1055 1.49386 9.22395 1.55295Z" fill="#257226" stroke="#257226"/>
                                </svg>
                            </span>
                            Продовжити покупки
                        </button>
                        {
                            props.basket.length > 0 ? (
                                <Link to={`/order`} className="modal-basket__confirm-btn" onClick={() => {
                                    props.toggleModal(false)
                                    props.toggleBasketModal(false)
                                }}>Оформити замовлення</Link>
                            ) : ''
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = store => {
    return {
        basket: store.basketReducer.basket
    }
}

export default connect(mapStateToProps)(BasketModal)