import React from 'react'
import cashback from "../../../../../img/header/cashback.svg";

const BasketModalCashBack = (props) => {

    return (
        <div className="modal-basket__cashback">
            <img src={cashback} alt='кэшбэк'/>
            <span className="modal-basket__cashback-text">Кешбек</span>
            <span className="modal-basket__cashback-val">
                {
                    props.products.reduce((i, products) => {
                        return i + products.cashback * products.quantity
                    }, 0).toFixed(2)
                } грн
            </span>
        </div>
    )
}

export default BasketModalCashBack