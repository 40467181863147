import React from 'react'

const BasketModalPriceTotal = (props) => {

    const totalPrice = props.products.reduce((i,products) => {
        if(products.new_services) {
            return (
                i + (
                    products.final_price +products.new_services.reduce((j, product) => {
                        return (
                            j + product.price
                        )
                    }, 0)
                ) * products.quantity
            )
        } else {
            return (
                i + products.final_price * products.quantity
            )
        }
    }, 0)

    return (
        <div className="modal-basket__price-total">
            <span className="modal-basket__price-total-text">Всього:</span>
            <span className="modal-basket__price-total-val">
                {
                    totalPrice === 0 ? (
                        'За домовленістю'
                    ) : (
                       `${totalPrice.toFixed(2)} грн`
                    )
                }
            </span>
        </div>
    )
}

export default BasketModalPriceTotal