import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import ProductsSlider from "../globalComponents/ProductsSlider/ProductsSlider";
import {addQuantityBasket} from "../../redux/actionCreators/basketProducts";
import {removeQuantityBasket} from "../../redux/actionCreators/basketProducts";
import {removeBasket, removeServices} from "../../redux/actionCreators/basketProducts";

import './Basket.scss'
import BasketTotal from "./BasketTotal";
import BasketItem from "./BasketItem";
import CleanPage from "../globalComponents/CleanPage/CleanPage";
import ScrollToTopOnMount from "../../ScrolToTop";
import {analyticEvents} from '../../utils/googleAnalytics/events';

const Basket = props => {

    useEffect(() => {
        document.title = 'Кошик';
        if(localStorage.getItem('basket')) analyticEvents.basket(JSON.parse(localStorage.getItem('basket')));
    });

    const items = [
        {name: 'Головна', link: '/',},
        {name: 'Кошик', link: '/basket',},
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <div className="content">
                <main className="basket-page">
                    <div className="container">
                        <BreadCrumbs items={items}/>
                        <h1 className="page-title">Кошик</h1>
                        <div className="basket">
                            {
                                props.products.length > 0 ? (
                                    <>
                                        <div className="basket__items-wrap">
                                            {
                                                props.products.map(data => {
                                                    return (
                                                        <BasketItem
                                                            removeBasket={props.removeBasket}
                                                            removeQuantityBasket={props.removeQuantityBasket}
                                                            addQuantityBasket={props.addQuantityBasket}
                                                            data={data}
                                                            key={data.id}
                                                            basket={props.products}
                                                            removeServices={props.removeServices}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                        <BasketTotal products={props.products}/>
                                    </>
                                ) : <CleanPage />
                            }
                        </div>
                        <div className="container">
                            <h3 className="main-page__title">Рекомендовані товари</h3>
                        </div>
                        <ProductsSlider feedbacks={props.feedbacks} products={props.productsNew}/>
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        productsNew: store.newProductsSliderReducer.newProducts,
        feedbacks: store.productPageReducer.feedbacks,
        products: store.basketReducer.basket,
    }
}

const mapDispatchToProps = {addQuantityBasket, removeQuantityBasket, removeBasket, removeServices}

export default connect(mapStateToProps, mapDispatchToProps)(Basket)