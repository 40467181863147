import React from "react";

const CatalogBannerPrev = (props) => {

    return (
        <button className="slider-arrow-left" onClick={props.onClick}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="32" width="32" height="32" transform="rotate(-90 0 32)" fill="white"
                      fillOpacity="0.5"/>
                <path
                      d="M17.8358 6.32985C18.0132 6.41897 18.1481 6.57481 18.2108 6.7632C18.2735 6.9516 18.2589 7.15717 18.1703 7.33485L13.8398 16.0003L18.1718 24.6643C18.2168 24.7525 18.244 24.8487 18.2516 24.9474C18.2593 25.046 18.2474 25.1453 18.2165 25.2393C18.1857 25.3334 18.1365 25.4204 18.0719 25.4953C18.0072 25.5703 17.9284 25.6317 17.8399 25.6761C17.7514 25.7204 17.655 25.7468 17.5563 25.7537C17.4576 25.7606 17.3584 25.7479 17.2646 25.7163C17.1708 25.6848 17.0842 25.6349 17.0097 25.5697C16.9353 25.5045 16.8745 25.4252 16.8308 25.3363L12.3308 16.3363C12.2785 16.2321 12.2513 16.117 12.2513 16.0003C12.2513 15.8837 12.2785 15.7686 12.3308 15.6643L16.8308 6.66435C16.9199 6.48692 17.0758 6.35206 17.2642 6.28936C17.4526 6.22665 17.6581 6.24121 17.8358 6.32985Z"
                      fill="white"/>
            </svg>
        </button>
    )
}

export default CatalogBannerPrev