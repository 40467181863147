import React from 'react'
import ProfileHistoryHeader from "./ProfileHistoryHeader";
import ProfileHistoryOrder from "./ProfileHistoryOrder/ProfileHistoryOrder";
import EmptyPage from "../../globalComponents/EmptyCatalog/EmptyPage";
import CleanPage from "../../globalComponents/CleanPage/CleanPage";

const ProfileHistory = props => {

    return (
        <div className={`account-history ${props.active? '' : 'active'}`}>
            <div className="history-table">
                <ProfileHistoryHeader />
                <div className="history-table__wrap">
                    {
                        props.orders.length > 0 ? (
                                props.orders.map((data, i) => {
                                    return (
                                        <ProfileHistoryOrder key={i} data={data} />
                                    )
                                })
                        ) : (
                            <>
                                <EmptyPage />
                                <CleanPage name={' '} title={' '}/>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default ProfileHistory