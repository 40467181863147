import React from 'react'

const ProfileTabs = props => {

    return (
        <div className="account__body-tabs">
            <div className={`account__tab ${props.active? 'active' : ''}`} onClick={props.changeActive}>Профіль</div>
            <div className={`account__tab ${props.active? '' : 'active'}`} onClick={props.changeActive}>Історія замовлень</div>
        </div>
    )
}

export default ProfileTabs