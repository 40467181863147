import React from 'react'
import './SubscribeModal.scss'

const SubscribeModal = props => {

    return (
        <div className={`subs-done ${props.subscribeModal? 'active' : ''}`}>
            <button className="close-btn" onClick={() => {
                props.toggleModal(false)
                props.toggleSubscribeModal(false)
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="subs-done__title">Дякуємо за підписку!</div>
            <div className="subs-done__subtitle">На вашу електронну пошту надходитимуть актуальні знижки та новини
                компанії
            </div>
            <button className="subs-done__done-btn btn-light" onClick={() => {
                props.toggleModal(false)
                props.toggleSubscribeModal(false)
            }}>Готово</button>
        </div>
    )
}

export default SubscribeModal