import React from 'react'
import OrderResultProductServices from "./OrderResultProductServices";
import {_images} from "../../API";

const OrderResultProduct = props => {

    return (
        <div className="order-result__product">
            <div className="order-result__product-body">
                <div className="order-result__product-img">
                    <img src={`${_images}${props.data.image}`} alt={props.data.product_name} />
                </div>
                <div className="order-result__product-info">
                    <div className="order-result__product-name">
                        {props.data.product_name}
                    </div>
                    <div className="order-result__product-price">
                        {
                            props.data.price_per_item === 0 ? (
                                'За домовленістю'
                            ) : (
                                `${props.data.price_per_item} грн`
                            )
                        }
                    </div>
                </div>
            </div>
            {
                props.data.services? (
                    <div className="order-result__product-services">
                        {
                            props.data.services.map((data, i) => {
                                return (
                                    <OrderResultProductServices key={i} data={data} />
                                )
                            })
                        }
                    </div>
                ) : ''
            }
        </div>
    )
}

export default OrderResultProduct