import React from 'react'

const OrderResultProductServices = props => {

    return (
        <div className="order-result__service-item">
            <div className="order-result__service-name">
                {props.data.service_name}
            </div>
            <div className="order-result__service-price">{props.data.price} грн</div>
        </div>
    )
}

export default OrderResultProductServices