import React from 'react'
import {connect} from 'react-redux'
import {setSortName, setSortDownUp} from "../../../../redux/actionCreators/catalogProducts";

import './SortModal.scss'

const SortModal = props => {

    return (
        <div className={`sort-modal ${props.sortModal? 'active' : ''}`}>
            <button
                className="close-btn"
                onClick={() => {
                    props.toggleModal(false)
                    props.toggleSortModal(false)
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="sort-modal__header">Сортувати</div>
            <div className="sort-modal__body">
                <div className="sort-modal__items">
                    <div
                        className={`sort-modal__item  
                        ${props.sortName === 'price'? (props.sortDownUp === 'asc' ? 'active': '') : ''}
                        `}
                        onClick={() => {
                            props.toggleModal(false)
                            props.toggleSortModal(false)
                            props.setSortName('price')
                            props.setSortDownUp('asc')
                        }}
                    >за зростанням ціни</div>
                    <div
                        className={`sort-modal__item 
                        ${props.sortName === 'price'? (props.sortDownUp === 'desc' ? 'active': '') : ''}
                        `}
                        onClick={() => {
                            props.toggleModal(false)
                            props.toggleSortModal(false)
                            props.setSortName('price')
                            props.setSortDownUp('desc')
                        }}
                    >за зменшенням ціни</div>
                    <div
                        className={`sort-modal__item
                         ${props.sortName === 'popularity' ? 'active' : ''}
                         `}
                        onClick={() => {
                            props.toggleModal(false)
                            props.toggleSortModal(false)
                            props.setSortName('popularity')
                        }}
                    >за популярністю</div>
                    <div
                        className={`sort-modal__item 
                        ${props.sortName === 'discount' ? 'active' : ''}
                        `}
                        onClick={() => {
                            props.toggleModal(false)
                            props.toggleSortModal(false)
                            props.setSortName('discount')
                        }}
                    >акційні</div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        sortName: store.catalogProductsReducer.sortName,
        sortDownUp: store.catalogProductsReducer.sortDownUp,
    }
}

const mapDispatchToProps = {
    setSortName,
    setSortDownUp,
}

export default connect(mapStateToProps ,mapDispatchToProps)(SortModal)