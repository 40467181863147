import itemImage from "../../../img/header/modals/item_image.jpg";
import {ADD_WISHLIST, CLEAN_WISHLIST, RELOAD_WISHLIST, REMOVE_WISHLIST} from "../../actionConst/action";

const initialState = {
    wishlist: []
}

const addItem = (wishlist, payload) => {
    let itemWithId = false
    wishlist.map(item => {
        if(item.id === payload.id) {
            itemWithId = true

            return  {
                ...item,
                quantity: item.quantity + 1
            }
        }
    })
    if(!itemWithId) {
        return {
            wishlist: [...wishlist, payload].flat()
        }
    }
    return {
        wishlist: [...wishlist]
    }
}

export const wishlistReducer = (state = initialState, action) => {
    switch(action.type) {
        case RELOAD_WISHLIST:
            return {
                ...state,
                wishlist: [action.payload].flat()
            }
        case ADD_WISHLIST:
            return addItem(state.wishlist, action.payload)
        case REMOVE_WISHLIST:
            return {
                ...state,
                wishlist: [...state.wishlist.filter(item => item.id !== action.payload)]
            }
        case CLEAN_WISHLIST:
            return {
                ...state,
                wishlist: [],
            }
    }
    return state
}