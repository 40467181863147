import {GET_NEW_PRODUCTS} from "../../actionConst/getActions";

const initialState = {
    newProducts: []
}

export const newProductsSliderReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_NEW_PRODUCTS:
            return {
                ...state,
                newProducts: action.payload
            }
    }
    return state
}