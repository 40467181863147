import {
    GET_CATALOG_BANNERS,
    GET_CATALOG_FILTERS,
    GET_CATALOG_FILTERS_BRANDS,
    GET_CATEGORIES,
    GET_CATEGORY_SLUG,
    GET_MENU_CATALOG,
    GET_SHOW_MENU_CATALOG
} from "../../actionConst/getActions";

export const getCategories = payload => {
    return {
        type: GET_CATEGORIES,
        payload
    }
}
export const getMenuCatalog = payload => {
    return {
        type: GET_MENU_CATALOG,
        payload
    }
}
export const getShowMenuCatalog = payload => {
    return {
        type: GET_SHOW_MENU_CATALOG,
        payload
    }
}
export const getFilters = (payload, specifications) => {
    return {
        type: GET_CATALOG_FILTERS,
        payload,
        specifications,
    }
}
export const getFiltersBrands = payload => {
    return {
        type: GET_CATALOG_FILTERS_BRANDS,
        payload
    }
}
export const getCatalogBanners = payload => {
    return {
        type: GET_CATALOG_BANNERS,
        payload
    }
}
export const getCatalogSlug = payload => {
    return {
        type: GET_CATEGORY_SLUG,
        payload,
    }
}