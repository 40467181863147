import {GET_BLOG_ARTICLE, GET_BLOG_ARTICLES, GET_BLOG_CATEGORIES} from "../../actionConst/getActions";

const initialState = {
    articles: [],
    article: {},
    categories: [
        'Всі', 'Очистка повітря', 'Енергозбереження', 'Опалення', 'Осушувачі повітря', 'Центральні пилососи', 'Вентиляція', 'Кондиціонування',
    ]
}

export const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOG_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case GET_BLOG_ARTICLES:
            return {
                ...state,
                articles: action.payload,
            }
        case GET_BLOG_ARTICLE:
            return {
                ...state,
                article: action.payload,
            }
    }
    return state
}