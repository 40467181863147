import React from 'react'

const FooterItemsInfoAddress = (props) => {
    return (
        <div>
            <p className="footer__address-item">
                {props.address}
            </p>
            <p className="footer__city-item">
                {props.city}
            </p>
        </div>
    )
}

export default FooterItemsInfoAddress