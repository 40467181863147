import React from 'react'
import {_images} from "../../../API";

const MainBannerSlide = props => {

    return (
        <a className="main-sl-item" href={props.data.link_url}>
            <span style={{display: 'none'}}>
                Рекламная информация
            </span>
            <div className="main-sl-item__bg">
                <div className="main-sl-item__bg-left">
                    <img src={`${_images}${props.data.image}`} alt=''/>
                </div>
                <div className="main-sl-item__bg-left mobile">
                    <img src={`${_images}${props.data.mobile_image}`} alt=''/>
                </div>
            </div>
        </a>
    )
}

export default MainBannerSlide