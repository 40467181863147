import React from 'react'
import CompareBodyCard from "./CompareBodyCard/CompareBodyCard";
import 'simplebar/dist/simplebar.min.css';

const CompareBody = props => {

    return (
        <div className={`${props.productsCompare.length === 1 ? 'solo-product' : ''}`}>
            <table className="compare-table__list compare-table__list--cards sticky">
                <tbody>
                <tr>
                    {
                        props.productsCompare.map((data, i) => {
                            return (
                                <CompareBodyCard products={props.products} productsCompare={props.productsCompare} key={i} data={data}/>
                            )
                        })
                    }
                </tr>
                </tbody>
            </table>
            <table className="compare-table" id='compare-table'>
                <tbody>
                <tr>
                    {
                        props.productsCompare.length === 1 ? (
                            <td>
                                У порівняння повинно бути <br className='visible-mobile'/> більше одного товару
                            </td>
                        ) : (
                            <td>
                                {
                                    props.specificationList.map((item, i) => {
                                        return (
                                            <table className="compare-table__list" key={i}>
                                                <tbody>
                                                <tr className="compare-page__list-title-wrap">
                                                    <td className="compare-page__list-title">{item}</td>
                                                </tr>
                                                <tr>
                                                    {
                                                        props.productsCompare.map((data, i) => {
                                                            if(data.specifications.main && data.specifications.secondary) {
                                                                if(data.specifications.main[item]) {
                                                                    return (
                                                                        <td className="compare-table__item" key={i}>{data.specifications.main[item]}</td>
                                                                    )
                                                                }
                                                                if(data.specifications.secondary[item]) {
                                                                    return (
                                                                        <td className="compare-table__item" key={i}>{data.specifications.secondary[item]}</td>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <td className="compare-table__item" key={i}>—</td>
                                                                    )
                                                                }
                                                            }
                                                            if(!data.specifications.main && !data.specifications.secondary) {
                                                                if(data.specifications[item]) {
                                                                    return (
                                                                        <td className="compare-table__item" key={i}>{data.specifications[item]}</td>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <td className="compare-table__item" key={i}>—</td>
                                                                    )
                                                                }
                                                            }
                                                        })
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                        )
                                    })
                                }
                            </td>
                        )
                    }
                </tr>
                </tbody>
            </table>
            {
                props.productsCompare.length < 2 ? '' : (
                    <table className="compare-table__list compare-table__list--cards compare-table__list--cards-bottom">
                        <tbody>
                        <tr>
                            {
                                props.productsCompare.map((data, i) => {
                                    return (
                                        <CompareBodyCard products={props.products} productsCompare={props.productsCompare} key={i} data={data}/>
                                    )
                                })
                            }
                        </tr>
                        </tbody>
                    </table>
                )
            }
        </div>
    )
}

export default CompareBody