import React from "react";
import ProductCard from "./ProductCard/ProductCard";
import EmptyPage from "../../../globalComponents/EmptyCatalog/EmptyPage";

const CatalogBody = (props) => {
    return (
        <>
            {
                props.products.length < 1 ? (
                    <EmptyPage />
                ) : (
                    <div className="catalog__body">
                        <ProductCard slug={props.slug} products={props.products}/>
                    </div>
                )
            }
        </>
    )
}

export default CatalogBody