import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {searchProducts, setSearchUrlFilters} from "../../../../redux/actionCreators/searchReducer";
import {changeSearchText} from "../../../../redux/actionCreators/getCreators/getGlobal";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";

const HeaderInput = props => {

    const history = useHistory()
    useEffect(() => {
        props.setSearch(props.searchData)
    }, [props.searchData])

    return (
        <>
            <input
                className="header-search__bar"
                type="search"
                name="search-bar"
                placeholder="Пошук"
                autoComplete="off"
                value={props.searchData}
                onChange={e => {
                    props.setSearch(e.target.value)
                    props.changeSearchText(e.target.value)
                    props.searchProducts(e.target.value)
                }}
                onKeyPress={e => {
                    if(e.key === 'Enter') {
                        props.searchProducts(props.searchData)
                        props.setSearchUrlFilters(`/search?q=${props.searchData}`)
                        history.push(`/search?q=${props.searchData}`)
                    }
                }}
            />
            <Link to={`/search?q=${props.searchData}`} className="header__search-btn" onClick={() => {
                props.searchProducts(props.searchData)
                props.setSearchUrlFilters(`/search?q=${props.searchData}`)
            }}>
                <svg className="header-search__ico" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path  d="M20.3665 17.6756C22.7323 14.356 22.4261 9.71775 19.4479 6.73959C16.1285 3.42014 10.7465 3.42014 7.42709 6.73959C4.10764 10.059 4.10764 15.441 7.42709 18.7604C10.4052 21.7386 15.0435 22.0448 18.3631 19.679L26.4601 27.776C26.4601 27.776 27.427 28.8125 28.4635 27.776C29.5 26.7395 28.4635 25.7726 28.4635 25.7726L20.3665 17.6756ZM17.4444 8.74306C19.6574 10.956 19.6574 14.544 17.4444 16.7569C15.2315 18.9699 11.6435 18.9699 9.43056 16.7569C7.21759 14.544 7.21759 10.956 9.43056 8.74306C11.6435 6.53009 15.2315 6.53009 17.4444 8.74306Z" fill="white" stroke="white"/>
                </svg>
            </Link>
        </>
    )
}

const mstp = store => {
    return {
        searchData: store.searchReducer.search,
        searchUrlFilters: store.searchReducer.searchUrlFilters,
    }
}

const mapDispatchToProps = {
    searchProducts,
    setSearchUrlFilters,
    changeSearchText,
}

export default connect(mstp, mapDispatchToProps)(HeaderInput)