import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router";
import {connect, useDispatch} from 'react-redux'
import '../globalComponents/ProductsSlider/ProductsSlider.scss'
import './Catalog.scss'
import BreadCrumbs from '../globalComponents/BreadCrumbs/BreadCrumbs';
import CatalogBanner from './CatalogBanner/CatalogBanner';
import CatalogContent from './CatalogContent/CatalogContent';
import CatalogFilter from './CatalogFilter/CatalogFilter';
import ScrollToTopOnMount from '../../ScrolToTop';
import CatalogSEO from './CatalogSEO';
import CatalogCheapProductTable from './CatalogCheapProductTable';
import {getProducts} from '../../redux/actionCreators/getCreators/getProducts';
import {
    getCatalogBanners,
    getCatalogSlug,
} from '../../redux/actionCreators/getCreators/getCategories';
import Preloader from '../globalComponents/Preloader/Preloader';
import {setSortName, cleanFilters} from '../../redux/actionCreators/catalogProducts';
import MetaTags from 'react-meta-tags';
import {analyticEvents} from '../../utils/googleAnalytics/events';
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";
import {SET_ALL_FILTERS, REMOVE_ALL_FILTERS} from "../../redux/actionConst/action";

const Catalog = props => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [urlFilters, setUrlFilters] = useState(props.location.pathname.replace('/catalog', ''))
    const [page, setPage] = useState(1)
    const [pageLength, setPageLength] = useState(1)
    const [pageSize, setPageSize] = useState(24)
    const [loading, setLoading] = useState(true)
    const [loadingProducts, setLoadingProducts] = useState(false)

    const [seoTitle, setSeoTitle] = useState('')
    const [seoText, setSeoText] = useState('')
    const [seoKeys, setSeoKeys] = useState('')

    const [productCount] = useState(0)
    const [categoryId, setCategoryId] = useState(0)

    const [seoDescription, setSeoDescription] = useState()
    const [seoTitlePage, setSeoTitlePage] = useState()
    const [seoH1Page, setSeoH1Page] = useState()

    const cleanAllFilters = () => {
        dispatch({type: REMOVE_ALL_FILTERS});
    }

    // Информация по разделу
    useEffect(() => {
        window.axios.get(`/categories/${props.match.params.slug}`)
        .then(res => {
            setName(res.data.name)
            setSeoTitle(res.data.metadata.title)
            setSeoText(res.data.metadata.description)
            setCategoryId(res.data.id)
            setSeoDescription(res.data.metadata['seo-description'])
            setSeoTitlePage(res.data.metadata['seo-title'])
            setSeoH1Page(res.data.metadata['seo-h1'])
            setSeoKeys(res.data.metadata['seo-keywords'])
            analyticEvents.showCategoryCatalog(res.data);
            window.axios.get(`/banners`, {
                params: {
                    type: 'catalog',
                    category: res.data.id,
                },
            })
            .then(secondRes => {
                props.getCatalogBanners(secondRes.data.data)
            })
            setPage(1)
            if(!urlFilters.startsWith(`/`+props.match.params.slug)) setUrlFilters(`/`+props.match.params.slug)
        })
    }, [props.match.params.slug])

    // Товары с учетом фильтра
    useEffect(() => {
        setLoadingProducts(true)
        window.axios.get(`/categories/${props.match.params.slug}/products`, {
            params: {
                'pageSize': pageSize,
                'urlFilters': urlFilters,
            },
        })
        .then(res => {
            history.push('/catalog'+urlFilters)
            dispatch({
                type: SET_ALL_FILTERS,
                prices: res.data.filters.prices,
                brands: res.data.filters.brands,
                specifications: res.data.filters.specifications,
                base: res.data.filters.base,
                appliedFilters: res.data.appliedFilters,
            });
            props.getProducts(res.data.data)
            setPageLength(res.data.meta.pagesCount)
            props.setSortName(res.data.appliedSort)
            setPage(parseInt(res.data.appliedPage))
        })
        .finally(() => {
            setLoading(false)
            setLoadingProducts(false)
        })
    }, [urlFilters])

    useEffect(() => {
        window.axios.get(`/categories/${props.match.params.slug}/cheap-products`)
        .then(res => {
            setCheapProducts(res.data.data)
        })
    }, [props.match.params.slug])

    const [openFilter, setOpenFilter] = useState(false)
    const [name, setName] = useState()
    const openFilterDrop = () => {setOpenFilter(!openFilter)}
    const [cheapProducts, setCheapProducts] = useState([])

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: name, link: `/catalog/${props.match.params.slug}`},
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount/>
            <MetaTags>
                {
                    seoTitlePage !== null && seoTitlePage !== undefined ? (
                        <>
                            <title>{seoTitlePage}</title>
                            <meta property="og:title" content={seoTitlePage} />
                        </>
                    ) : (
                        <>
                            <title>{name}</title>
                            <meta property="og:title" content={name} />
                        </>
                    )
                }
                {
                    seoDescription !== null && seoDescription !== undefined ? (
                        <>
                            <meta name="description" content={seoDescription}/>
                            <meta property="og:description" content={seoDescription} />
                        </>
                    ) : (
                        <>
                            <meta name="description" content={`Купити товари ${name}`}/>
                            <meta property="og:description" content={`Купити товари ${name}`} />
                        </>
                    )
                }
                {
                    props.match.params.slug === '39-teploventilyatori' ? (
                        <meta name="robots" content={'noindex, nofollow'} />
                    ) : ''
                }

                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="catalog">
                    <div className="container">
                        <BreadCrumbs items={crumbs}/>
                        <CatalogBanner banners={props.banners}/>
                        <h1 className="catalog__title">
                            {(seoH1Page !== null && seoH1Page !== undefined) ? seoH1Page : name}
                        </h1>
                        {
                            loading ? (
                                <Preloader/>
                            ) : (
                                <div className="catalog-wrap">
                                    <CatalogFilter
                                        urlFilters={urlFilters}
                                        setUrlFilters={setUrlFilters}
                                        openFilterDrop={openFilterDrop}
                                        openFilter={openFilter}
                                        productCount={productCount}
                                        cleanAllFilters={cleanAllFilters}
                                    />
                                    <CatalogContent
                                        categoryId={categoryId}
                                        loadingProducts={loadingProducts}
                                        setPageSize={setPageSize}
                                        pageSize={pageSize}
                                        sortName={props.sortName}
                                        setSortName={props.setSortName}
                                        page={page}
                                        setPage={setPage}
                                        pageLength={pageLength}
                                        slug={props.match.params.slug}
                                        openFilterDrop={openFilterDrop}
                                        products={props.products}
                                        urlFilters={urlFilters}
                                        setUrlFilters={setUrlFilters}
                                    />
                                </div>
                            )
                        }
                        {
                            cheapProducts.length || ((seoText !== null) && (seoTitle !== '')) ? (
                                <div className="description-container">
                                    {
                                        cheapProducts.length ? (
                                            <CatalogCheapProductTable catalogName={name} cheapProducts={cheapProducts} />
                                        ) : ''
                                    }
                                    {
                                        seoText == null ? '' : (seoTitle === '' ? '' :
                                            <CatalogSEO seoTitle={seoTitle} seoText={seoText} seoDescription={seoDescription} seoKeys={seoKeys}/>)
                                    }
                                </div>
                            ) : ''
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        banners: store.catalogBannerReducer.banners,
        products: store.catalogProductsReducer.products,
        sortName: store.catalogProductsReducer.sortName,
        sortDownUp: store.catalogProductsReducer.sortDownUp,
        preFilter: store.catalogFilterReducer.preFilter,
    }
}

const mapDispatchToProps = {
    getCatalogBanners,
    cleanFilters,
    getCatalogSlug,
    getProducts,
    setSortName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
