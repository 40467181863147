export const getDeliveryName = (id) => {
  switch (id) {
    case 1:
      return `Доставка у відділеня Нової Пошти`;
    case 2:
      return `Адресна доставка Нової Пошти`;
    case 3:
      return `Доставка у відділення SAT`;
    case 4:
      return `Адресна доставка SAT`;
    case 5:
      return `Самовивіз з нашого магазину`;
    default:
      return;
  }
};