import React, {useState} from 'react'
import InputMask from "react-input-mask";

const CallBackForm = props => {

    const [phone, setPhone] = useState()

    const backCall = () => {
        window.axios.post(`/back-call`, {
            "type": "back_call",
            "phone": phone,
            "comment": props.title,
        })
    }

    return (
        <div className="callback">
            <div className="container">
                <div className="callback__title-section">
                    <div className="callback__title">Цікавить пропозиція?</div>
                    <div className="callback__title">Наші спеціалісти зв’яжуться з вами</div>
                </div>
                <div className="callback__form">
                    <InputMask
                        mask="+38 (099) 999-99-99"
                        defaultValue={phone}
                        onChange={e => setPhone(e.target.value)}
                        required
                        className="callback__tel-input"
                        placeholder="Ваш номер телефону"
                    />
                    <input className="callback__submit-btn" type="submit" value="Зворотній дзвінок" onClick={backCall}/>
                </div>
            </div>
        </div>
    )
}

export default CallBackForm