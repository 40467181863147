import React, {useState} from 'react'
import ArticleSliderSlide from "./ArticleSliderSlide";
import Slider from "react-slick";
import ArticleSliderPrev from "./ArticleSliderPrev";
import ArticleSliderNext from "./ArticleSliderNext";

const ArticleSlider = props => {

    const [count, setCount] = useState(1)

    const settings = {
        responsive: [
            {
                breakpoint: 6290,
                settings: "unslick"
            },
            {
                breakpoint: 1290,
                settings: {
                    infinite: true,
                    slidesToShow: props.slider.length === 1 ? 1 : props.slider.length === 2 ? 2 : 3,
                    slidesToScroll: 1,
                    autoplay: false,
                    speed: 500,
                    autoplaySpeed: 3000,
                    waitForAnimate: true,
                    arrows: true,
                    dots: false,
                    // cssEase: '',
                    prevArrow: <ArticleSliderPrev/>,
                    nextArrow: <ArticleSliderNext/>,
                }
            },
            {
                breakpoint: 1082,
                settings: {
                    beforeChange: (current, next) => setCount(next + 1),
                    slidesToShow: props.slider.length === 1 ? 1 : 2,
                    prevArrow: <ArticleSliderPrev/>,
                    nextArrow: <ArticleSliderNext/>,
                }
            },
            {
                breakpoint: 698,
                settings: {
                    beforeChange: (current, next) => setCount(next + 1),
                    slidesToShow: 1,
                    arrows: false
                }
            },
        ]
    }

    return (
        <div className="photos-section-wrap">
            <Slider {...settings} className='photos-section'>
                {
                    props.slider.map((data, i) => {
                        return (
                            <ArticleSliderSlide key={i} data={data}/>
                        )
                    })
                }
            </Slider>
            <div className="slider-pagination">
                <svg className="slider-pagination__arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                          d="M9.22322 14.4474C9.34151 14.388 9.43141 14.2841 9.47321 14.1585C9.51501 14.0329 9.50531 13.8959 9.44622 13.7774L6.55922 8.00042L9.44722 2.22442C9.47724 2.16566 9.49533 2.10153 9.50045 2.03574C9.50557 1.96996 9.49761 1.90381 9.47704 1.84111C9.45648 1.77841 9.4237 1.7204 9.38061 1.67043C9.33751 1.62045 9.28495 1.57951 9.22596 1.54994C9.16697 1.52038 9.1027 1.50279 9.03688 1.49818C8.97105 1.49357 8.90497 1.50204 8.84243 1.52309C8.77989 1.54414 8.72214 1.57737 8.6725 1.62084C8.62286 1.66432 8.58232 1.7172 8.55322 1.77642L5.55322 7.77642C5.51838 7.84595 5.50023 7.92265 5.50023 8.00042C5.50023 8.07819 5.51838 8.15489 5.55322 8.22442L8.55322 14.2244C8.61263 14.3427 8.71653 14.4326 8.84212 14.4744C8.96772 14.5162 9.10477 14.5065 9.22322 14.4474Z"
                          fill="#182316"/>
                </svg>
                <span className="slider-pagination__current">{count}</span>
                <span className="slider-pagination__amount">/{props.slider.length}</span>
                <svg className="slider-pagination__arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                          d="M6.77678 14.4474C6.65849 14.388 6.56859 14.2841 6.52679 14.1585C6.48499 14.0329 6.49469 13.8959 6.55378 13.7774L9.44078 8.00042L6.55278 2.22442C6.52276 2.16566 6.50467 2.10153 6.49955 2.03574C6.49443 1.96996 6.50239 1.90381 6.52296 1.84111C6.54352 1.77841 6.5763 1.7204 6.61939 1.67043C6.66249 1.62045 6.71505 1.57951 6.77404 1.54994C6.83303 1.52038 6.8973 1.50279 6.96312 1.49818C7.02895 1.49357 7.09503 1.50204 7.15757 1.52309C7.22011 1.54414 7.27786 1.57737 7.3275 1.62084C7.37714 1.66432 7.41768 1.7172 7.44678 1.77642L10.4468 7.77642C10.4816 7.84595 10.4998 7.92265 10.4998 8.00042C10.4998 8.07819 10.4816 8.15489 10.4468 8.22442L7.44678 14.2244C7.38737 14.3427 7.28347 14.4326 7.15788 14.4744C7.03228 14.5162 6.89523 14.5065 6.77678 14.4474Z"
                          fill="#182316"/>
                </svg>
            </div>
        </div>
    )
}

export default ArticleSlider