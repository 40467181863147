import React from 'react'
import dot from '../../img/li-dot.svg'
import pb from '../../img/delivery-payment/pb.png'
import monobank from '../../img/delivery-payment/monobank.svg'

const DeliveryPaymentCredit = () => {

    return (
        <section className="delivery-payment__section-wrap">
            <h2 className="page-title">Придбати товар в кредит</h2>
            <div className="delivery-payment__section delivery-payment__section--column">
                <ul className="delivery-payment__list">
                    <li className="delivery-payment__list-item">
                        <div className="delivery-payment__li-dot">
                            <img src={dot} alt="" />
                        </div>
                        <span>Виберіть потрібні Вам товари на сайті і оформіть замовлення, вибравши метод оплати - кредит / розстрочка. Співробітник контакт-центру в момент підтвердження замовлення запросить у Вас відсутні дані і відправить заявку в банк.</span>
                    </li>
                    <li className="delivery-payment__list-item">
                        <div className="delivery-payment__li-dot">
                            <img src={dot} alt="" />
                        </div>
                        <span>Після прийняття Банком рішення про видачу кредиту (як правило, протягом години), співробітник інтернет-магазину зв'язується з покупцем, щоб підтвердити оформлення по кредиту та узгодити зручний час і місце доставки.</span>
                    </li>
                </ul>
                <div className="delivery-payment__partners">
                    <h5 className="delivery-payment__partners-title">Партнери «Ліка-Комфорт»</h5>
                    <div className="delivery-payment__partners-logos">
                        <div className="delivery-payment__partners-logo">
                            <img src={pb} alt="ПриватБанк" />
                        </div>
                        <div className="delivery-payment__partners-logo">
                            <img src={monobank} alt="monobank" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DeliveryPaymentCredit