import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import './Services.scss'
import '../Main/MainServices/MainServices.scss'
import ServicesItem from "./ServicesItem";
import ScrollToTopOnMount from "../../ScrolToTop";



const Services = (props) => {

    useEffect(() => {
        document.title = 'Наші послуги';
    });

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <div className="content">
                <main className="services-page">
                    <div className="container">
                        <h1 className="page-title">Наші послуги</h1>
                        <section className="services">
                            {
                                props.services.map((data, i) => {
                                    return (
                                        <ServicesItem data={data} key={i}/>
                                    )
                                })
                            }
                        </section>
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        services: store.servicesReducer.services
    }
}

export default connect(mapStateToProps)(Services)