import {
  ADD_BASKET,
  ADD_BASKET_SERVICE,
  ADD_CASHBACK,
  ADD_DELIVERY_AT,
  ADD_DELIVERY_BRANCH,
  ADD_DELIVERY_CITY,
  ADD_DELIVERY_CODE,
  ADD_DELIVERY_COST,
  ADD_DELIVERY_PERIOD_PRIVAT,
  ADD_DELIVERY_PERIOD_MONO,
  ADD_DELIVERY_EMAIL,
  ADD_DELIVERY_FLOOR,
  ADD_DELIVERY_HOUSE,
  ADD_DELIVERY_NAME,
  ADD_DELIVERY_PAY_BITRIX,
  ADD_DELIVERY_PAY,
  ADD_DELIVERY_STREET,
  ADD_DELIVERY_WAY,
  ADD_QUANTITY_BASKET,
  CLEAR_BASKET,
  REMOVE_BASKET,
  REMOVE_QUANTITY_BASKET,
  REMOVE_SERVICE,
} from "../../actionConst/action";
import { GET_LOCAL_BASKET } from "../../actionConst/getActions";

const initialState = {
  basket: [],
  delivery: {
    useCashback: 0,
    city: "",
    cost: 0,
    deliveryWay: 1,
    deliveryPay: {
      name: 0,
      bitrixMethodId: 0,
      email: "",
      code: "",
      userName: "",
      periodPrivat: 3,
      periodMono: 3,
    },
    branch: "",
    address: {
      street: "",
      house: "",
      at: "",
      floor: "",
    },
  },
};

const addItem = (basket, payload, state) => {
  let itemWithId = false;

  basket.map((item) => {
    if (item.id === payload.id) {
      itemWithId = true;

      return {
        ...item,
        quantity: item.quantity + 1,
      };
    }
  });

  if (!itemWithId) {
    if (payload.new_services) {
      if (payload.new_services_id) {
        return {
          ...state,
          basket: [...basket, { ...payload }],
        };
      } else {
        return {
          ...state,
          basket: [...basket, { ...payload, new_services_id: [] }],
        };
      }
    } else {
      if (payload.new_services_id) {
        return {
          ...state,
          basket: [...basket, { ...payload, new_services: [] }],
        };
      } else {
        return {
          ...state,
          basket: [
            ...basket,
            { ...payload, new_services_id: [], new_services: [] },
          ],
        };
      }
    }
  }

  return {
    ...state,
    basket: [...basket],
  };
};

const addService = (id, service, state) => {
  let product;

  state.basket.forEach((item) => {
    if (item.id === id) {
      let newServices = item.new_services;
      let newServicesId = item.new_services_id;

      if (!newServicesId.includes(service.id)) {
        if (service.is_guarantee === 1) {
          newServices = newServices.filter((data) => data.is_guarantee !== 1);
          newServices = [...newServices, service];
          newServicesId = newServices.map((data) => data.id);
        } else {
          newServices.push(service);
          newServicesId.push(service.id);
        }
      }

      product = {
        ...item,
        new_services: newServices,
        new_services_id: newServicesId,
      };
    }
  });

  return {
    ...state,
    basket: state.basket.map((item) => {
      if (item.id === id) {
        return product;
      } else {
        return item;
      }
    }),
  };
};

const removeService = (id, serviceId, state) => {
  let product;

  state.basket.map((item) => {
    if (item.id === id) {
      product = {
        ...item,
        new_services: item.new_services.filter(
          (service) => service.id !== serviceId
        ),
        new_services_id: item.new_services_id.filter(
          (service) => service !== serviceId
        ),
      };
    }
  });

  return {
    ...state,
    basket: state.basket.map((item) => {
      if (item.id === id) {
        return product;
      } else {
        return item;
      }
    }),
  };
};

export const basketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CASHBACK:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          useCashback: action.payload,
        },
      };
    case CLEAR_BASKET:
      return {
        ...state,
        basket: [],
      };
    case GET_LOCAL_BASKET:
      return {
        ...state,
        basket: action.payload,
      };
    case REMOVE_BASKET:
      return {
        ...state,
        basket: [...state.basket.filter((item) => item.id !== action.payload)],
      };
    case ADD_BASKET:
      return addItem(state.basket, action.payload, state);
    case ADD_QUANTITY_BASKET:
      return {
        ...state,
        basket: state.basket.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              quantity: action.quantity + 1,
            };
          }
          return item;
        }),
      };
    case REMOVE_QUANTITY_BASKET:
      return {
        ...state,
        basket: state.basket.map((item) => {
          if (item.id === action.payload) {
            if (action.quantity === 1) {
              return {
                ...item,
                quantity: action.quantity,
              };
            }
            return {
              ...item,
              quantity: action.quantity - 1,
            };
          }
          return item;
        }),
      };
    case ADD_BASKET_SERVICE:
      return addService(action.payload.id, action.payload.service, state);
    case REMOVE_SERVICE:
      return removeService(action.payload.id, action.payload.name, state);
    case ADD_DELIVERY_CITY:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          city: action.payload,
        },
      };
    case ADD_DELIVERY_COST:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          cost: action.payload,
        },
      };
    case ADD_DELIVERY_WAY:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryWay: action.payload,
        },
      };
    case ADD_DELIVERY_BRANCH:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          branch: action.payload,
        },
      };
    case ADD_DELIVERY_STREET:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          address: {
            ...state.delivery.address,
            street: action.payload,
          },
        },
      };
    case ADD_DELIVERY_HOUSE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          address: {
            ...state.delivery.address,
            house: action.payload,
          },
        },
      };
    case ADD_DELIVERY_AT:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          address: {
            ...state.delivery.address,
            at: action.payload,
          },
        },
      };
    case ADD_DELIVERY_FLOOR:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          address: {
            ...state.delivery.address,
            floor: action.payload,
          },
        },
      };
    case ADD_DELIVERY_PAY:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            name: action.payload,
          },
        },
      };

    case ADD_DELIVERY_PAY_BITRIX:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            bitrixMethodId: action.payload,
          },
        },
      };

    case ADD_DELIVERY_EMAIL:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            email: action.payload,
          },
        },
      };
    case ADD_DELIVERY_CODE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            code: action.payload,
          },
        },
      };
    case ADD_DELIVERY_NAME:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            userName: action.payload,
          },
        },
      };
    case ADD_DELIVERY_PERIOD_PRIVAT:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            periodPrivat: action.payload,
          },
        },
      };
    case ADD_DELIVERY_PERIOD_MONO:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          deliveryPay: {
            ...state.delivery.deliveryPay,
            periodMono: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
