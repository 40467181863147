import React from 'react'
import {Link} from "react-router-dom";
import {_images} from "../../API";

const BlogBodyItem = (props) => {

    return (
        <Link className="blog-item-wrap" to={`blog_page/${props.data.slug}`}>
            <section className="blog-item">
                <div className="blog-item__img">
                    <img src={`${_images}${props.data.main_image}`} alt={props.data.title} />
                </div>
                <div className="blog-item__description">
                    <div className="blog-item__info">
                        <div className={`blog-item__category blog-item__category--${
                            props.data.tag === 'Очистка повітря' ? 'air-clean' :
                            props.data.tag === 'Енергозбереження' ? 'energy-saving' :
                            props.data.tag === 'Опалення' ? 'heating' :
                            props.data.tag === 'Осушувачі повітря' ? 'dehumidifier' :
                            props.data.tag === 'Центральні пилососи' ? 'vac-clean' :
                            props.data.tag === 'Вентиляція' ? 'ventilation' :
                            props.data.tag === 'Кондиціонування' ? 'conditioning' : 'air-clean'
                        }`}>
                            {props.data.tag}
                        </div>
                        <div className="blog-item__publication-date">{props.data.date}</div>
                    </div>
                    <h4 className="blog-item__title">{props.data.title}</h4>
                    <p className="blog-item__subtitle" dangerouslySetInnerHTML={{__html:[props.data.announce]}} />
                </div>
            </section>
        </Link>
    )
}

export default BlogBodyItem