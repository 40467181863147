import React, { useEffect, useState } from "react";
import OrderBodyDataDeliveryNpAddress from "./OrderBodyDataDeliveryNpAddress";
import OrderBodyDataDeliveryNpBrabch from "./OrderBodyDataDeliveryNpBranch";
import OrderBodyDataDeliverySatAddress from "./OrderBodyDataDeliverySatAddress";
import OrderBodyDeliverySatBranch from "./OrderBodyDeliverySatBranch";
import OrderBodyDeliveryLikaAddress from "./OrderBodyDeliveryLikaAddress";
import {
  addDeliveryCost,
  addDeliveryWay,
  addDeliveryPay,
  addDeliveryBranch,
  addDeliveryStreet,
  addDeliveryHouse,
  addDeliveryAt,
  addDeliveryFloor,
  addDeliveryCity,
} from "../../../../../redux/actionCreators/orderCreator";
import { connect } from "react-redux";
import axios from "axios";
import { getCity } from "../../../../../redux/actionCreators/getCreators/getOrder";
import {_npAPIkey} from "../../../../../API";

const OrderBodyDataDelivery = (props) => {
  const [delivery, setDelivery] = useState("");

  useEffect(() => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/", {
        apiKey: _npAPIkey,
        modelName: "Address",
        calledMethod: "searchSettlements",
        methodProperties: {
          CityName: "",
          Limit: 5,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          props.getCity(res.data.data[0].Addresses);
        }
      });
  }, []);
  const getEnterCity = (data) => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/", {
        apiKey: _npAPIkey,
        modelName: "Address",
        calledMethod: "searchSettlements",
        methodProperties: {
          CityName: data,
          Limit: 5,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          props.getCity(res.data.data[0].Addresses);
        }
      });
  };

  const [city, setCity] = useState(" ");
  //main select
  const [active, setActive] = useState(false);
  const openSelect = () => {
    setActive(!active);
  };
  const chooseCity = (data) => {
    setDelivery(data);
  };
  //logic for radio button
  const [activeRadio, setInputRadio] = useState("npAddress");
  const openSubSelect = (e) => {
    setInputRadio(e.target.id);
  };

  return (
    <div className="order-data__section">
      <div className="order-data__delivery-form form">
        <h5 className="order-data__section-title">Вибір способу доставки</h5>

        <OrderBodyDataDeliveryNpBrabch
          cityList={props.city}
          getEnterCity={getEnterCity}
          openSelect={openSelect}
          setActive={setActive}
          chooseCity={chooseCity}
          active={active}
          setCity={setCity}
          delivery={delivery}
          addDeliveryCity={props.addDeliveryCity}
          city={city}
          openSubSelect={openSubSelect}
          activeRadio={activeRadio}
          addDeliveryCost={props.addDeliveryCost}
          addDeliveryWay={props.addDeliveryWay}
          addDeliveryBranch={props.addDeliveryBranch}
          empty={props.empty}
          setEmpty={props.setEmpty}
        />

        <OrderBodyDataDeliveryNpAddress
          cityList={props.city}
          getEnterCity={getEnterCity}
          openSelect={openSelect}
          setActive={setActive}
          chooseCity={chooseCity}
          active={active}
          setCity={setCity}
          delivery={delivery}
          addDeliveryCity={props.addDeliveryCity}
          city={city}
          openSubSelect={openSubSelect}
          activeRadio={activeRadio}
          addDeliveryCost={props.addDeliveryCost}
          addDeliveryWay={props.addDeliveryWay}
          addDeliveryStreet={props.addDeliveryStreet}
          addDeliveryHouse={props.addDeliveryHouse}
          addDeliveryAt={props.addDeliveryAt}
          addDeliveryFloor={props.addDeliveryFloor}
          empty={props.empty}
          setEmpty={props.setEmpty}
        />

        <OrderBodyDeliverySatBranch
          cityList={props.city}
          getEnterCity={getEnterCity}
          openSelect={openSelect}
          setActive={setActive}
          chooseCity={chooseCity}
          active={active}
          setCity={setCity}
          delivery={delivery}
          addDeliveryCity={props.addDeliveryCity}
          openSubSelect={openSubSelect}
          activeRadio={activeRadio}
          addDeliveryCost={props.addDeliveryCost}
          addDeliveryWay={props.addDeliveryWay}
          addDeliveryBranch={props.addDeliveryBranch}
          city={city}
          empty={props.empty}
          setEmpty={props.setEmpty}
        />

        <OrderBodyDataDeliverySatAddress
          cityList={props.city}
          getEnterCity={getEnterCity}
          openSelect={openSelect}
          setActive={setActive}
          chooseCity={chooseCity}
          active={active}
          setCity={setCity}
          delivery={delivery}
          addDeliveryCity={props.addDeliveryCity}
          openSubSelect={openSubSelect}
          activeRadio={activeRadio}
          addDeliveryCost={props.addDeliveryCost}
          addDeliveryWay={props.addDeliveryWay}
          addDeliveryStreet={props.addDeliveryStreet}
          addDeliveryHouse={props.addDeliveryHouse}
          addDeliveryAt={props.addDeliveryAt}
          addDeliveryFloor={props.addDeliveryFloor}
          empty={props.empty}
          setEmpty={props.setEmpty}
        />

        <OrderBodyDeliveryLikaAddress
          openSubSelect={openSubSelect}
          activeRadio={activeRadio}
          addDeliveryCost={props.addDeliveryCost}
          addDeliveryWay={props.addDeliveryWay}
          addDeliveryStreet={props.addDeliveryStreet}
          addDeliveryHouse={props.addDeliveryHouse}
          addDeliveryAt={props.addDeliveryAt}
          addDeliveryFloor={props.addDeliveryFloor}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    city: store.orderReducer.city,
  };
};

const mapDispatchToProps = {
  addDeliveryCost,
  addDeliveryWay,
  addDeliveryPay,
  addDeliveryBranch,
  addDeliveryStreet,
  addDeliveryHouse,
  addDeliveryAt,
  addDeliveryFloor,
  addDeliveryCity,
  getCity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderBodyDataDelivery);
