import React, {useEffect, useState} from 'react'
import Nouislider from "nouislider-react";

import "nouislider/distribute/nouislider.css";

const CatalogFilterCheckboxCost = (props) => {

    const [value, setValue] = useState([props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.min) : parseInt(props.prices.min), props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.max) : parseInt(props.prices.max)])
    const [cost, setCost] = useState([props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.min) : parseInt(props.prices.min), props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.max) : parseInt(props.prices.max)])

    useEffect(() => {
        setValue([props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.min) : parseInt(props.prices.min), props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.max) : parseInt(props.prices.max)])
        setCost([props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.min) : parseInt(props.prices.min), props.appliedFilters.prices ? parseInt(props.appliedFilters.prices.max) : parseInt(props.prices.max)])
    }, [props.prices])

    const changeFrom = event => {
        setCost([parseInt(event.target.value), cost[1]])
    }
    const changeTo = event => {
        setCost([cost[0], parseInt(event.target.value)])
    }

    return (
        <div className="filter__section">
            {
                props.prices.min === props.prices.max ? '' : (
                    <>
                        <div className="filter__title">
                            Ціна, ₴
                        </div>
                        <div className="filter__form">
                            <div className="filter__price-input-wrap">
                                <label className="filter__from-wrap">
                                    <span>От</span>
                                    <input type="number" name="from-value" id="range-from" value={parseInt(cost[0])} onChange={changeFrom} />
                                </label>
                                <label className="filter__to-wrap">
                                    <span>До</span>
                                    <input type="number" name="to-value" id="range-to" value={parseInt(cost[1])} onChange={changeTo} />
                                </label>
                            </div>
                            <div className="filter__range-input">
                                <Nouislider step={1} range={{ min: parseInt(props.prices.min), max: parseInt(props.prices.max) }} start={[value[0], value[1]]} onUpdate={setCost} connect />
                            </div>
                        </div>
                        <button onClick={() => {
                            const minCost = Math.max(cost[0], props.prices.min);
                            const maxCost = Math.min(cost[1], props.prices.max);
                            setCost([minCost, maxCost])
                            if((minCost == props.prices.min) && (maxCost == props.prices.max)) props.setUrlFilters(props.prices.url1.replace(/\/price=(\d+)-(\d+)/g, ''));
                            else props.setUrlFilters(props.prices.url1.replace(/\/price=(\d+)-(\d+)/g, '\/price='+minCost+'-'+maxCost));
                        }} className='filter__title-btn'>
                            OK
                        </button>
                    </>
                )
            }
        </div>
    )
}

export default CatalogFilterCheckboxCost