import React, {useEffect} from 'react'
import OrderFooter from "./OrderFooter/OrderFooter";
import OrderHeader from "./OrderHeader/OrderHeader";
import OrderBody from "./OrderBody/OrderBody";
import {connect} from 'react-redux'
import './Order.scss'
import ScrollToTopOnMount from "../../ScrolToTop";
import {analyticEvents} from '../../utils/googleAnalytics/events';
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Order = props => {

    useEffect(() => {
        if(localStorage.getItem('basket')) analyticEvents.beginOrder(JSON.parse(localStorage.getItem('basket')));
    }, []);

    return (
        <div className="content">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Оформлення замовлення</title>
                <meta name="robots" content={'noindex, nofollow'} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Оформлення замовлення" />
                <meta property="og:description" content="Оформлення замовлення" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <main className="order-page">
                <div className="container">
                    <OrderHeader />
                    <h1 className="page-title">Оформлення замовлення</h1>
                    <OrderBody basket={props.basket}/>
                </div>
                <OrderFooter />
            </main>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        basket: store.basketReducer.basket
    }
}

export default connect(mapStateToProps)(Order)
