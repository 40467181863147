import {GET_DISCOUNT_PRODUCTS} from "../../actionConst/getActions";

const initialState = {
    discountSlider: []
}


export const discountProductsSliderReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_DISCOUNT_PRODUCTS:
            return {
                ...state,
                discountSlider: action.payload
            }
    }
    return state
}