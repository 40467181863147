import React from 'react'
import CasesBodyItem from "./CasesBodyItem";

const CasesBody = (props) => {

    return (
        <div className="cases__body">
            {
                props.articles.map((data, i) => {
                    return (
                        <CasesBodyItem key={i} data={data}/>
                    )
                })
            }
        </div>
    )
}



export default CasesBody
