import React from 'react'
import {Link} from "react-scroll";

const ProductPageAnchors = (props) => {

    return (
        <div className="product-page__anchors-wrap">
            <Link spy={true} smooth={true} duration={500} onClick={props.infoSet} offset={-200} className="product-page__anchor" to="product-info">Все про товар</Link>
            <Link spy={true} smooth={true} duration={500} onClick={props.charSet} offset={-200} className="product-page__anchor" to="product-characteristics">Характеристики</Link>
            <Link spy={true} smooth={true} duration={500} onClick={props.descSet} offset={-200} className="product-page__anchor" to="product-description">Опис</Link>
            <Link spy={true} smooth={true} duration={500} onClick={props.feedSet} offset={-200} className="product-page__anchor" to="product-reviews">Відгуки про товар
                <span className="product-page__anchor-reviews-counter">({props.rates_count})</span>
            </Link>
            <Link spy={true} smooth={true} duration={500} onClick={props.addFeedSet} offset={-200} className="product-page__anchor product-page__anchor--add-review" to="product-review-add">Додати відгук</Link>
        </div>
    )
}

export default ProductPageAnchors