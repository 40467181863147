import React from 'react'
import Slider from 'react-slick'

import './ProductsSlider.scss'
import ProductsSliderSlide from "./ProductsSliderSlide";
import ProductsSliderNext from "./ProductsSliderNext";
import ProductsSliderPrev from "./ProductsSliderPrev";

const ProductsSlider = (props) => {

    const settings = {
        infinite: false,
        slidesToShow: 5,
        adaptiveHeight: true,
        slidesToScroll: 2,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        // cssEase: '',
        className: 'products-slider products-slider--new-products',
        prevArrow: <ProductsSliderPrev />,
        nextArrow: <ProductsSliderNext />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 590,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 410,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }

    return (
        <div className="slider-container slider-container--product">
            <div className="container">
                <h3 className="main-page__title">{props.name}</h3>
            </div>
            <div className="products-slider-wrap products-slider-wrap--new-products">
                <Slider {...settings}>
                        {
                            props.products.map((data, i) => {
                                return (
                                    <ProductsSliderSlide key={i} data={data}/>
                                )
                            })
                        }
                </Slider>
            </div>
        </div>
    )
}

export default ProductsSlider