import React from 'react'

import './CategoriesList.scss'

import cond from '../../../img/main-page/category-section/cond.svg'
import teplobiNasosi from '../../../img/main-page/category-section/teplobi-nasosi.svg'
import vent from '../../../img/main-page/category-section/vent.svg'
import heater from '../../../img/main-page/category-section/heater.svg'
import energozberezennya from '../../../img/main-page/category-section/energozberezennya.svg'
import hvac from '../../../img/main-page/category-section/hvac.svg'
import {connect} from 'react-redux';
import {getMenuCatalog, getShowMenuCatalog} from "../../../redux/actionCreators/getCreators/getCategories";

const CategoriesList = props => {

    const categories = [
        {name: 'Кондиціонери', image: cond, id: 1, link: '/catalog/1-kondiciyuvannya'},
        {name: 'Теплові насоси', image: teplobiNasosi, id: 47, link: '/catalog/47-teplovi-nasosi'},
        {name: 'Вентиляція', image: vent, id: 11, link: '/catalog/11-ventilyaciya'},
        {name: 'Осушувачі', image: heater, id: 29, link: '/catalog/29-osusuvaci'},
        {name: 'Cистеми енергозбереження', image: energozberezennya, id: 69, link: '/catalog/69-cistemi-energozberezennya'},
        {name: 'Все для інсталяторів', image: hvac, id: 73, link: '/catalog/73-vse-dlya-instalyatoriv'},
    ]

    return (
        <div className="category-section">
            {
                categories.map((data) => {
                    return (
                        <a className="category-section__item" onClick={() => {
                            if(document.documentElement.clientWidth <= 868) {
                                props.getMenuCatalog(props.categoryData[props.categoryData.map(item => item.id).indexOf(data.id)])
                                props.getShowMenuCatalog(true)
                            }
                            else window.location.href = data.link;
                        }}>
                            <div className="category-section__item-body">
                                <div className="category-section__item-img">
                                    <img src={data.image} alt={data.name}/>
                                </div>
                                <h4 className="category-section__item-title">{data.name}</h4>
                            </div>
                        </a>
                    )
                })
            }
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        menuCatalog: store.headerCatalogReducer.menuCatalog,
        showMenuCatalog: store.headerCatalogReducer.showMenuCatalog,
        categoryData: store.headerCatalogReducer.categoryData,
    }
}

const mapDispatchToProps = {
    getMenuCatalog,
    getShowMenuCatalog,
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList)