export const getPaymentName = (id) => {
  switch (id) {
    case 0:
      return `Оплата готівкою`;
    case 1:
      return `Карта Visa і MasterCard`;
    case 2:
      return `Рахунок-фактура (безготівково для юридичних осіб)`;
    case 5:
      return `Оплата частинами від ПриватБанку`;
    case 6:
      return `Покупка частинами від Monobank`;
    default:
      return;
  }
};