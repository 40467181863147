import {GET_BRANCH_NP, GET_BRANCH_SAT, GET_CITY, GET_ORDER_DATA} from "../../actionConst/getActions";

export const getCity = payload => {
    return {
        type: GET_CITY,
        payload
    }
}

export const getBranchNP = payload => {
    return {
        type: GET_BRANCH_NP,
        payload
    }
}
export const getBranchSAT = payload => {
    return {
        type: GET_BRANCH_SAT,
        payload
    }
}

export const getOrderData = payload => {
    return {
        type: GET_ORDER_DATA,
        payload
    }
}