import React from 'react'
import Slider from 'react-slick'
import MainBannerSlide from "./MainBannerSlide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './MainBanner.scss'
import MainBannerPrev from "./MainBannerPrev";
import MainBannerNext from "./MainBannerNext";

const MainBanner = props => {

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 8000,
        waitForAnimate: true,
        arrows: true,
        dots: true,
        prevArrow: <MainBannerPrev />,
        nextArrow: <MainBannerNext />,
        dotsClass: 'main-sl-dots',
        className: 'main-slider',
        responsive: [
            {
                breakpoint: 976,
                settings: {
                    arrows: false,
                }
            }
        ]
    }

    return (
        <div className="main-page__main-slider-wrap">
            <Slider {...settings}>
                {
                    props.banners.map((data, i) => {
                        return (
                            <MainBannerSlide key={i} data={data}/>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default MainBanner