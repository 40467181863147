import React, {useState} from 'react'
import {connect} from 'react-redux'
import {addFirstQuiz} from "../../../../redux/actionCreators/quizCreactor";
import {addSecondQuiz} from "../../../../redux/actionCreators/quizCreactor";
import {addThirdQuiz} from "../../../../redux/actionCreators/quizCreactor";
import {addFourthQuiz} from "../../../../redux/actionCreators/quizCreactor";
import {addFifthQuiz} from "../../../../redux/actionCreators/quizCreactor";
import {
    toggleModal, toggleQuiz
} from "../../../../redux/actionCreators/modalsCreator";
import bg1 from '../../../../img/quiz/bg1.png'
import bg2 from '../../../../img/quiz/bg2.png'
import bg4 from '../../../../img/quiz/bg4.png'
import './QuizModal.scss'
import QuizModalSteps from "./QuizModalSteps";
import QuizModalStart from "./QuizModalStart";
import QuizModalFirst from "./QuizModalFirst";
import QuizModalSecond from "./QuizModalSecond";
import QuizModalFourth from "./QuizModalFourth";

const QuizModal = props => {

    const [activeQuiz, setActiveQuiz] = useState('firstQuiz')

    const [firstFilled, setFirstFilled] = useState(false)
    const [secondFilled, setSecondFilled] = useState(false)
    const [thirdFilled, setThirdFilled] = useState(false)
    const [fourthFilled, setFourthFilled] = useState(false)

    const changeQuiz = (data) => {
        setActiveQuiz(data)
    }

    const returnQuiz = () => {
        switch(activeQuiz) {
            case 'firstQuiz':
                return <QuizModalFirst
                    addFirstQuiz={props.addFirstQuiz}
                    firstQuiz={props.firstQuiz}
                    setFirstFilled={setFirstFilled}
                    changeQuiz={changeQuiz}
                />
            case 'secondQuiz':
                return <QuizModalSecond
                    addSecondQuiz={props.addSecondQuiz}
                    secondQuiz={props.secondQuiz}
                    setSecondFilled={setSecondFilled}
                    changeQuiz={changeQuiz}
                />
            case 'fourthQuiz':
                return <QuizModalFourth
                    addFourthQuiz={props.addFourthQuiz}
                    fourthQuiz={props.fourthQuiz}
                    setFourthFilled={setFourthFilled}
                    changeQuiz={changeQuiz}
                    toggleModal={props.toggleModal}
                    toggleQuiz={props.toggleQuiz}
                />
            default:
                return ''
        }
    }
    const returnImage = () => {
        switch(activeQuiz) {
            case 'firstQuiz':
                return <img src={bg1} alt="" />
            case 'secondQuiz':
                return <img src={bg2} alt="" />
            case 'fourthQuiz':
                return <img src={bg4} alt="" />
            default:
                return ''
        }
    }

    return (
        <div className={`quiz-modal-wrap ${props.quiz? 'active' : ''}`}>
            {
                activeQuiz === 'firstQuiz' ? '' : (
                    <button className="quiz-back-btn" onClick={() => {
                        if(activeQuiz === 'secondQuiz') {
                            setActiveQuiz('firstQuiz')
                            setFirstFilled(false)
                        }  else if(activeQuiz === 'fourthQuiz') {
                            setActiveQuiz('secondQuiz')
                            setSecondFilled(false)
                        }
                    }}>
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1 6L6 11" stroke="#999999" strokeWidth="1.5" strokeLinejoin="round"/>
                        </svg>
                        Назад
                    </button>
                )
            }
                <div className="modal-close" >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                        props.toggleQuiz(false)
                        props.toggleModal(false)
                    }}>
                        <path
                            d="M25.3332 8.54602L23.4532 6.66602L15.9998 14.1193L8.5465 6.66602L6.6665 8.54602L14.1198 15.9993L6.6665 23.4527L8.5465 25.3327L15.9998 17.8793L23.4532 25.3327L25.3332 23.4527L17.8798 15.9993L25.3332 8.54602Z"
                            fill="#868686"/>
                    </svg>
                </div>
                <div className="quiz-modal-bg">
                    {returnImage()}
                </div>
                <div className="quiz-modal">
                    <h2 className="quiz-modal__title">3 прості кроки для підбору необхідного кондиціонера</h2>
                    <QuizModalSteps
                        setFirstFilled={setFirstFilled}
                        setSecondFilled={setSecondFilled}
                        setThirdFilled={setThirdFilled}
                        setFourthFilled={setFourthFilled}
                        firstFilled={firstFilled}
                        secondFilled={secondFilled}
                        thirdFilled={thirdFilled}
                        fourthFilled={fourthFilled}
                        activeQuiz={activeQuiz}
                        changeQuiz={changeQuiz}
                    />
                    {returnQuiz()}
                </div>
                <QuizModalStart />
            </div>
    )
}

const mapStateToProps = store => {
    return {
        firstQuiz: store.quizReducer.firstQuiz,
        secondQuiz: store.quizReducer.secondQuiz,
        thirdQuiz: store.quizReducer.thirdQuiz,
        fourthQuiz: store.quizReducer.fourthQuiz,
        fifthQuiz: store.quizReducer.fifthQuiz
    }
}

const mapDispatchToProps = {
    addFirstQuiz,
    addSecondQuiz,
    addThirdQuiz,
    addFourthQuiz,
    addFifthQuiz,
    toggleModal,
    toggleQuiz,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizModal)