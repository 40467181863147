import React from 'react'
import {Link} from "react-router-dom";

const BreadCrumbsItem = props => {
    return (
        (typeof props.data.link !== 'undefined')
        ?
        <Link className="bread-crumbs__item" to={props.data.link}>{props.data.name}</Link>
        :
        <div className="bread-crumbs__item">{props.data.name}</div>
    );
};

export default BreadCrumbsItem;
