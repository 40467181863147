import React from 'react'

const ServicePageInfoDescription = props => {



    return (
        <ul className="services-info__descr-list">
            <h6 className="services-info__descr-list-title">{props.name}</h6>
            {
                props.title ? (
                    <li className="services-info__descr-list-item">
                        <div className="services-info__li-dot">
                            <img src={props.img} alt="" />
                        </div>
                        <span>{props.title}</span>
                    </li>
                ) : ''
            }
            {
                props.itemsLi.map((data, i) => {
                    return (
                        <li className="services-info__descr-list-item" key={i}>
                            <div className="services-info__li-dot">
                                <img src={props.img} alt="" />
                            </div>
                            <span>{data}</span>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default ServicePageInfoDescription