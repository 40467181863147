import React, {useEffect, useState} from 'react'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import {connect, useDispatch} from 'react-redux'
import './Compare.scss'
import CompareHeader from "./CompareHeader/CompareHeader";
import CompareBody from "./CompareBody/CompareBody";
import ScrollToTopOnMount from "../../ScrolToTop";
import CleanPage from "../globalComponents/CleanPage/CleanPage";
import {RELOAD_COMPARE} from "../../redux/actionConst/action";
import CompareBodyVarious from "./CompareBody/CompareBodyVarious";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Compare = props => {

    const categories = []

    useEffect(() => {
        if(!props.isLogged && localStorage.getItem('compare')) {
            dispatch({type: RELOAD_COMPARE, payload: JSON.parse(localStorage.getItem('compare'))})
            choose(JSON.parse(localStorage.getItem('compare'))[0].category.name)
        }
        window.axios.post(`/auth/user`)
            .then(res => {
                dispatch({type: RELOAD_COMPARE, payload: res.data.data.compares})
                choose(res.data.data.compares[0].category.name)
            })
    }, []);

    const [reverse, setReverse] = useState(false)

    props.products.map(data => {
        if(categories.includes(data.category.name)) {
            return categories
        } else {
            categories.push(data.category.name)
        }
    })


    const dispatch = useDispatch()
    const [select, setSelect] = useState(categories[0])
    const choose = data => {
        setSelect(data)
    }

    const items = [
        {name: 'Головна', link: '/'},
        {name: 'Порівняння товарів', link: '/compare'},
        {name: select, link: '/compare'},
    ]

    const productsCompare = []

    props.products.map(data => {
        if(data.category.name === select) {
            productsCompare.push(data)
        }
    })

    const specificationList = []

    productsCompare.map(data => {
        if(data.specifications.main && data.specifications.secondary) {
            Object.keys(data.specifications.main).map(item => {
                if(specificationList.includes(item)) {
                    return specificationList
                } else if(data.category.name === select){
                    specificationList.push(item)
                }
            })
            Object.keys(data.specifications.secondary).map(item => {
                if(specificationList.includes(item)) {
                    return specificationList
                } else if(data.category.name === select){
                    specificationList.push(item)
                }
            })
        } else {
            Object.keys(data.specifications).map(item => {
                if(specificationList.includes(item)) {
                    return specificationList
                } else if(data.category.name === select){
                    specificationList.push(item)
                }
            })
        }
    })

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Порівняння товарів</title>
                <meta name="robots" content={'noindex, nofollow'} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Порівняння товарів" />
                <meta property="og:description" content="Порівняння товарів" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="compare-page">
                    <div className="container">
                        <BreadCrumbs items={items}/>
                        {
                            props.products.length > 0 ? (
                                <>
                                    <CompareHeader
                                        categories={categories}
                                        productsCompare={productsCompare}
                                        select={select}
                                        choose={choose}
                                        reverse={reverse}
                                        setReverse={setReverse}
                                        products={props.products}
                                    />
                                    {
                                        reverse ? (
                                            productsCompare.length > 4 ? (
                                                <div style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                                    <CompareBodyVarious
                                                        products={props.products}
                                                        productsCompare={productsCompare}
                                                        specificationList={specificationList}
                                                    />
                                                </div>
                                            ) : (
                                                <div style={{overflowX: 'auto', overflowY: 'hidden', display: 'flex', justifyContent: 'center'}}>
                                                    <CompareBodyVarious
                                                        products={props.products}
                                                        productsCompare={productsCompare}
                                                        specificationList={specificationList}
                                                    />
                                                </div>
                                            )
                                        ) : (
                                            productsCompare.length > 4 ? (
                                                <div style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                                    <CompareBody
                                                        products={props.products}
                                                        productsCompare={productsCompare}
                                                        specificationList={specificationList}
                                                    />
                                                </div>
                                            ) : (
                                                <div style={{overflowX: 'auto', overflowY: 'hidden', display: 'flex', justifyContent: 'center'}}>
                                                    <CompareBody
                                                        products={props.products}
                                                        productsCompare={productsCompare}
                                                        specificationList={specificationList}
                                                    />
                                                </div>
                                            )
                                        )
                                    }
                                </>
                            ) : <CleanPage />
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        products: store.compareReducer.compare,
        isLogged: store.userReducer.isLogged,
    }
}

export default connect(mapStateToProps)(Compare)