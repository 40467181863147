import React, {useEffect} from 'react'
import './Sitemap.scss'
import {Link} from "react-router-dom"
import {connect} from 'react-redux'
import {getMainPagePartners} from "../../redux/actionCreators/getCreators/getMainPage"
import {getBlogArticles} from "../../redux/actionCreators/getCreators/getBlog";
import {getCasesArticles} from "../../redux/actionCreators/getCreators/getCases";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

function visualLink(data) {
    return <div class="col"><Link to={data.url}>{data.name}</Link></div>
}

function getAllCategory(data) {
    let res = [];
    data.map((val) => {
        res.push({url: 'catalog/'+val.slug, name: val.name});
        if(val.children !== undefined) {
            getAllCategory(val.children).map((childrenData) => {
                res.push(childrenData)
            })
        }
    })
    return res;
}

const Sitemap = props => {
    useEffect(() => {
        window.axios.get('/brands').then(res => {
            props.getMainPagePartners(res.data.data)
        })

        window.axios.get('/posts').then(res => {
            props.getBlogArticles(res.data.data)
        })

        window.axios.get('/works').then(res => {
            props.getCasesArticles(res.data.data)
        })
    }, []);

    return (
        <div className="content-wrap">
            <MetaTags>
                <title>Карта сайту</title>
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Карта сайту" />
                <meta property="og:description" content="Карта сайту" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div class="container sitemap">
                <div class="row">
                    <h2>Категорії товарів</h2>
                    {
                        getAllCategory(props.categoryData).map((data) => {
                            return visualLink(data)
                        })
                    }
                    <h2>Гуртові категорії</h2>
                    {
                        props.categoryData.map((data) => {
                            if(data.opt_category !== null) {
                                return visualLink({name: data.opt_category.title, url: '/'+data.opt_category.slug});
                            }
                        })
                    }
                </div>
                <div class="row">
                    <h2>Інформаційні сторінки</h2>
                    {
                        visualLink({name: 'Головна', url: '/'})
                    }
                    {
                        visualLink({name: 'Наші послуги', url: '/services'})
                    }
                    {
                        visualLink({name: 'Монтаж кондиціонерів', url: '/services_page/montazh_kondytsioneriv'})
                    }
                    {
                        visualLink({name: 'Обслуговування кондиціонерів та систем вентиляції повітря', url: '/services_page/servisne_obsluhovuvannia'})
                    }
                    {
                        visualLink({name: 'Монтаж теплового обладнання', url: '/services_page/montazh_teplovoho_obladnannia'})
                    }
                    {
                        visualLink({name: 'Монтаж вентиляційних систем', url: '/services_page/montazh_ventyliatsii'})
                    }
                    {
                        visualLink({name: 'Проектування систем вентиляції і кондиціонування', url: '/services_page/proektuvannia_system'})
                    }
                    {
                        visualLink({name: 'Монтаж центральних пилососів', url: '/services_page/montazh_tsentralnykh_pylososiv'})
                    }
                    {
                        visualLink({name: 'Акції', url: '/catalog_discount'})
                    }
                    {
                        visualLink({name: 'Хіти продаж', url: '/catalog_day'})
                    }
                    {
                        visualLink({name: 'Найкращі пропозиції', url: '/catalog_best'})
                    }
                    {
                        visualLink({name: 'Доставка', url: '/delivery_payment'})
                    }
                    {
                        visualLink({name: 'Про нас', url: '/about_us'})
                    }
                    {
                        visualLink({name: 'Контактна інформація', url: '/contacts'})
                    }
                    {
                        visualLink({name: 'Договір публічної оферти', url: '/offer'})
                    }
                    <h2>Бренди</h2>
                    {
                        props.partners.map((data) => {
                            return visualLink({name: data.name, url: '/catalog_brands/'+data.id})
                        })
                    }
                    <h2>Блог</h2>
                    {
                        props.blogArticles.map((data) => {
                            return visualLink({name: data.title, url: '/blog_page/'+data.slug})
                        })
                    }
                    <h2>Наші роботи</h2>
                    {
                        props.caseArticles.map((data) => {
                            return visualLink({name: data.title, url: '/cases_page/'+data.slug})
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        partners: store.partnerSliderReducer.partners,
        blogArticles: store.blogReducer.articles,
        caseArticles: store.casesReducer.articles,
        categoryData: store.headerCatalogReducer.categoryData,
    }
}

const mapDispatchToProps = {
    getMainPagePartners,
    getBlogArticles,
    getCasesArticles,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sitemap)