import React from 'react'

const ServicePageHeadAlert = () => {

    const items = [
        '— Доступ до електромережі.',
        '— Вільний доступ до місць, де будуть проводитись роботи по монтажу кондиціонера.',
        '— В кімнаті, де буде проводитись монтаж, зібрати або накрити всі речі і поверхні, котрим може зашкодити цегляний або бетонний пил.',
    ]

    return (
        <div className="services-info__head-alert">
            <h5 className="services-info__alert-title">УВАГА! До початку монтажу потрібно
                забезпечити:</h5>
            <ul className="services-info__alert-list">
                {
                    items.map((data, i) => {
                        return (
                            <li className="services-info__alert-list-item" key={i}>{data}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default ServicePageHeadAlert