//catalog sort
export const SORT_NAME = "SORT_NAME";
export const SORT_DOWN_UP = "SORT_DOWN_UP";

//wishlist
export const RELOAD_WISHLIST = "RELOAD_WISHLIST";
export const ADD_WISHLIST = "ADD_WISHLIST";
export const REMOVE_WISHLIST = "REMOVE_WISHLIST";
export const CLEAN_WISHLIST = "CLEAN_WISHLIST";
//basket
export const ADD_BASKET = "ADD_BASKET";
export const REMOVE_BASKET = "REMOVE_BASKET";
export const ADD_QUANTITY_BASKET = "ADD_QUANTITY_BASKET";
export const REMOVE_QUANTITY_BASKET = "REMOVE_QUANTITY_BASKET";
export const ADD_SERVICE = "ADD_SERVICE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const CLEAR_BASKET = "CLEAR_BASKET";
export const ADD_CASHBACK = "ADD_CASHBACK";
export const ADD_BASKET_SERVICE = "ADD_BASKET_SERVICE";
//compare
export const RELOAD_COMPARE = "RELOAD_COMPARE";
export const ADD_COMPARE = "ADD_COMPARE";
export const REMOVE_COMPARE = "REMOVE_COMPARE";
export const CLEAN_COMPARE = "CLEAN_COMPARE";
//catalog filter
export const SET_ALL_FILTERS = "SET_ALL_FILTERS";
export const ADD_FILTER_PRICE_MIN = "ADD_FILTER_PRICE_MIN";
export const ADD_FILTER_PRICE_MAX = "ADD_FILTER_PRICE_MAX";
export const ADD_FILTER_BRANDS = "ADD_FILTER_BRANDS";
export const REMOVE_FILTER_BRANDS = "REMOVE_FILTER_BRANDS";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const FILTER = "FILTER";
export const REMOVE_FILTER_BLOCK = "REMOVE_FILTER_BLOCK";
export const REMOVE_ALL_FILTERS = "REMOVE_ALL_FILTERS";
export const ADD_CATALOG_SLUG = "ADD_CATALOG_SLUG";
export const ADD_CATEGORY_FILTER = "ADD_CATEGORY_FILTER";
export const REMOVE_CATEGORY_FILTER = "REMOVE_CATEGORY_FILTER";
export const ADD_ACTIVE_FILTERS = "ADD_ACTIVE_FILTERS";
//sort cases
export const CHANGE_CASES_SORT = "CHANGE_CASES_SORT";
//checkout
export const ADD_DELIVERY_CITY = "ADD_DELIVERY_CITY";
export const ADD_DELIVERY_COST = "ADD_DELIVERY_COST";
export const ADD_DELIVERY_PERIOD_PRIVAT = "ADD_DELIVERY_PERIOD_PRIVAT";
export const ADD_DELIVERY_PERIOD_MONO = "ADD_DELIVERY_PERIOD_MONO";
export const ADD_DELIVERY_WAY = "ADD_DELIVERY_WAY";
export const ADD_DELIVERY_BRANCH = "ADD_DELIVERY_BRANCH";
export const ADD_DELIVERY_STREET = "ADD_DELIVERY_STREET";
export const ADD_DELIVERY_HOUSE = "ADD_DELIVERY_HOUSE";
export const ADD_DELIVERY_AT = "ADD_DELIVERY_AT";
export const ADD_DELIVERY_FLOOR = "ADD_DELIVERY_FLOOR";
export const ADD_DELIVERY_PAY = "ADD_DELIVERY_PAY";
export const ADD_DELIVERY_PAY_BITRIX = "ADD_DELIVERY_PAY_BITRIX";
export const ADD_DELIVERY_EMAIL = "ADD_DELIVERY_EMAIL";
export const ADD_DELIVERY_NAME = "ADD_DELIVERY_NAME";
export const ADD_DELIVERY_CODE = "ADD_DELIVERY_CODE";
//Quiz
export const FIRST_QUIZ = "FIRST_QUIZ";
export const SECOND_QUIZ = "SECOND_QUIZ";
export const THIRD_QUIZ = "THIRD_QUIZ";
export const FOURTH_QUIZ = "FOURTH_QUIZ";
export const FIFTH_QUIZ = "FIFTH_QUIZ";
//user
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const CHANGE_USER_NAME = "CHANGE_USER_NAME";
export const CHANGE_USER_PHONE = "CHANGE_USER_PHONE";
export const CHANGE_USER_LASTNAME = "CHANGE_USER_LASTNAME";
export const CHANGE_USER_EMAIL = "CHANGE_USER_EMAIL";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
//modals
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const TOGGLE_QUIZ = "TOGGLE_QUIZ";
export const TOGGLE_PRODUCT_SLIDER_MODAL = "TOGGLE_PRODUCT_SLIDER_MODAL";
export const TOGGLE_SORT_MODAL = "TOGGLE_SORT_MODAL";
export const TOGGLE_LOG = "TOGGLE_LOG";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const TOGGLE_LOGIN_SUCCESS = "TOGGLE_LOGIN_SUCCESS";
export const TOGGLE_REG = "TOGGLE_REG";
export const TOGGLE_REG_SUCCESS = "TOGGLE_REG_SUCCESS";
export const TOGGLE_FORGOT = "TOGGLE_FORGOT";
export const TOGGLE_FORGOT_SUCCESS = "TOGGLE_FORGOT_SUCCESS";
export const TOGGLE_FEEDBACK_SUCCESS = "TOGGLE_FEEDBACK_SUCCESS";
export const TOGGLE_FEEDBACK_MODAL = "TOGGLE_FEEDBACK_MODAL";
export const TOGGLE_BUY_ONE_CLICK = "TOGGLE_BUY_ONE_CLICK";
export const TOGGLE_BUY_ONE_CLICK_MOBILE = "TOGGLE_BUY_ONE_CLICK_MOBILE";
export const TOGGLE_SUBSCRIBE_MODAL = "TOGGLE_SUBSCRIBE_MODAL";
export const TOGGLE_PHONE_MODAL = "TOGGLE_PHONE_MODAL";
export const TOGGLE_CALL_ME_BACK = "TOGGLE_CALL_ME_BACK";
export const TOGGLE_BASKET_MODAL = "TOGGLE_BASKET_MODAL";
export const TOGGLE_ORDER_CONFIRM = "TOGGLE_ORDER_CONFIRM";
export const TOGGLE_RESET_PASSWORD_MODAL = "TOGGLE_RESET_PASSWORD_MODAL";

//product page
export const SET_ID_PRODUCT = "SET_ID_PRODUCT";

//search
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SET_SEARCH_URL_FILTERS = "SET_SEARCH_URL_FILTERS";

export const CHANGE_USER_LOCATION = "CHANGE_USER_LOCATION";
