//import BLL
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from "./store/configureStore";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import axios from 'axios'
import {_api} from "./API";

window.axios = axios.create({
    baseURL: _api,
    timeout: 10000,
    headers: {
        Authorization: localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ''
    }
})


const rootElement = document.getElementById('root')
ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </React.StrictMode>,
    rootElement
)