import React, {useEffect} from 'react'
import {connect} from 'react-redux'

import './BlogPageSlider.scss'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import ArticleSlider from "../globalComponents/ArticleSlider/ArticleSlider";
import ScrollToTopOnMount from "../../ScrolToTop";

const BlogPageSlider = props => {

    useEffect(() => {
        document.title = 'Блог';
    });

    const crumbs = [
        {name: 'Головна', link: '/'}, {name: 'Блог', link: '/blog'}, {name: '21 лютого 2020 Семінар Samsung', link: '/blog_page'}
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <div className="content">
                <main className="seminar-page">
                    <div className="container">
                        <BreadCrumbs items={crumbs}/>
                        <div className="seminar-page__body">
                            <h2 className="seminar-page__title">21 лютого 2020 Семінар Samsung</h2>
                            <article className="seminar-page__article">
                                <p className="seminar-page__paragraph">"Постійно навчатися!" - це одне з правил нашої
                                    компанії. І ми його дотримуємося! </p>
                                <p className="seminar-page__paragraph">Сьогодні наша команда відвідала головний офіс
                                    кліматичної техніки Samsung та пройшла навчання в Академії SAMSUNG у Києві.</p>
                                <ul className="seminar-page__list">
                                    <p className="seminar-page__paragraph">Говорили про:</p>
                                    <li className="seminar-page__list-item">- Побутові спліт і мульти-спліт системи
                                        Samsung 2020;
                                    </li>
                                    <li className="seminar-page__list-item">- Унікальні напівпромислові спліт-системи
                                        Samsung 2020;
                                    </li>
                                    <li className="seminar-page__list-item">- Мультизональні системи кондиціювання
                                        Samsung: модельний ряд, особливості встановлення і налаштування;
                                    </li>
                                    <li className="seminar-page__list-item">- Використання зовнішніх блоків
                                        напівпромислових і мультизональних систем в якості ККБ для ПВУ;
                                    </li>
                                    <li className="seminar-page__list-item">- Теплові насоси на основі гідромодуля без
                                        використання TЕНа;
                                    </li>
                                    <li className="seminar-page__list-item">- Налагодження та пошук несправностей
                                        сервісної програмою S-Net 2.0;
                                    </li>
                                    <li className="seminar-page__list-item">- Використання програми DVM Pro. Для підбору
                                        та перевірки мультиспліт і мультизональних системи Samsung;
                                    </li>
                                </ul>
                            </article>
                            <ArticleSlider slider={props.slider}/>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        slider: store.blogPageReducer.slider
    }
}

export default connect(mapStateToProps)(BlogPageSlider)