import React from 'react'
import {_images} from "../../../API";

const ArticleSliderSlide = props => {

    return (
        <div className="photos-section__photo">
            <img src={`${_images}${props.data}`} alt="photo" />
        </div>
    )
}

export default ArticleSliderSlide