import React from 'react'
import im1 from '../../img/delivery-payment/payment1.svg'
import im2 from '../../img/delivery-payment/payment2.svg'
import im3 from '../../img/delivery-payment/payment3.svg'
import im4 from '../../img/delivery-payment/payment4.svg'

const DeliveryPaymentWayPay = () => {

    return (
        <section className="delivery-payment__section-wrap">
            <h2 className="page-title">Способи оплати</h2>
            <div className="delivery-payment__section delivery-payment__section--tabs">
                <div className="delivery-payment__tab">
                    <img src={im1} alt="" />
                    <h5 className="delivery-payment__tab-title">Розрахунок готівкою</h5>
                    <p className="delivery-payment__tab-text">Оплата відбувається після доставки товару.
                        Якщо товар купується з послугою монтажу, розрахунок проводиться з нашими
                        монтажними бригадами ПІСЛЯ запуску обладнання.</p>
                </div>
                <div className="delivery-payment__tab">
                    <img src={im2} alt="" />
                    <h5 className="delivery-payment__tab-title">Оплата післяплатою</h5>
                    <p className="delivery-payment__tab-text">Оплата відбувається через транспортні
                        компанії «Нова пошта» або «CAT» (при отриманні посилки).</p>
                </div>
                <div className="delivery-payment__tab">
                    <img src={im3} alt="" />
                    <h5 className="delivery-payment__tab-title">Оплата за замовлення товару</h5>
                    <p className="delivery-payment__tab-text">Оплата відбувається при отриманні товару у
                        відділеннях транспортних компаній «Нова пошта» та «CAT». Оплата за переказ
                        грошових коштів оплачується клієнтом при отриманні замовлення окремо.</p>
                </div>
                <div className="delivery-payment__tab">
                    <img src={im4} alt="" />
                    <h5 className="delivery-payment__tab-title">Безготівкова оплата</h5>
                    <p className="delivery-payment__tab-text">Проводиться 100% передоплата на
                        розрахунковий рахунок. Всі необхідні документи надаються разом з товаром.</p>
                </div>
            </div>
        </section>
    )
}

export default DeliveryPaymentWayPay