import React from "react";
import ProductCardDescriptionCharacteristic from "./ProductCardDescriptionCharacteristic";

const ProductCardDescription = (props) => {
    return (
        <div className="product-card__characteristics-wrap">
            {
                props.specifications.length ? (
                    <div className="product-card__characteristics">
                        <div className="characteristics">
                            <p className="characteristics__char-title">Характеристики</p>
                            <ProductCardDescriptionCharacteristic specifications={props.specifications}/>
                        </div>
                    </div>
                ) : (<></>)
            }
        </div>
    )
}

export default ProductCardDescription