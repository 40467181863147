import axios from "axios";
import { getDeliveryName } from "./utils/bitrix/getDeliveryName";
import { getPaymentName } from "./utils/bitrix/getPaymentName";

const endpoint = "https://bitrix.lika.net.ua/rest/2443/mo6gsj3s75oka6g2";

const addLead = `${endpoint}/crm.lead.add.json`;
const assignedById = 32; // Ольга Шляховенко

export const createLeadOneClick = async (phone, product) => {
  let fields = {
    TITLE: 'Замовлення в один клік',
    OPENED: 'Y',
    ASSIGNED_BY_ID: assignedById,
    PHONE: [{ VALUE: phone }],
    COMMENTS: product,
    SOURCE_ID: 'CALLBACK'
  };

  return axios.post(`${addLead}`, {fields}).catch((error) => console.log(error));
};

export const createLeadOrder = async (phone, name, lastName, products, deliveryId, paymentId) => {
  let comments = [];
  products.map((el) => {
    comments = [
      ...comments,
      `${el?.name} (Ціна: ${el?.final_price} грн., Кількість: ${el?.quantity} шт.)`
    ];
    if(el.new_services && el.new_services.length > 0) {
      const servicesNew = el.new_services.map((el) => (
        `Послуга: ${el.name} (Ціна: ${el?.price} грн.)`
      ));
      comments = [...comments, ...servicesNew];
    }
  });

  comments.push(getDeliveryName(deliveryId));
  comments.push(getPaymentName(paymentId));

  let fields = {
    TITLE: 'Оформлення замовлення',
    OPENED: 'Y',
    ASSIGNED_BY_ID: assignedById,
    NAME: name,
    LAST_NAME: lastName,
    PHONE: [{ VALUE: phone }],
    COMMENTS: comments.join(', '),
    SOURCE_ID: 'CALLBACK'
  };

  return axios.post(`${addLead}`, {fields}).catch((error) => console.log(error));
};