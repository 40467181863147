import React, {useState} from 'react'
import './BuyOneClick.scss'
import {useSelector} from "react-redux";
import InputMask from "react-input-mask";
import {cleanPhone} from "../../../CleanPhone";

const BuyOneClick = props => {

    const [phone, setPhone] = useState()
    const [name, setName] = useState()
    const [inputError, setInputError] = useState(false)
    const productId = useSelector(store => store.productPageReducer.id)

    const callBack = () => {
        window.axios.post(`/back-call`, {
            "type": "buy_one_click",
            "phone": cleanPhone(phone),
            "product_id": productId,
            "comment": `Ім'я: ${name}`
        })
            .then(() => {
                props.toggleBuyOneClickMobile(false)
                props.toggleBuyOneClick(true)
            })
    }

    return (
        <div className={`buy-on-click-phone ${props.buyOneClickMobile? 'active' : ''}`}>
            <button className="close-btn" onClick={() => {
                props.toggleBuyOneClickMobile(false)
                props.toggleModal(false)
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="buy-on-click-phone__title">Купити в 1 клік</div>
            <div className="buy-on-click-phone__subtitle">
                Залиште ваш телефон і наш спеціаліст зателефонує вам для оформлення замовлення
            </div>
            <div className="buy-on-click-phone__form">
                <div className="input-wrap">
                    <label className="form__label">
                        <input className="form__input"  type="text" name="name" placeholder=" " required onChange={e => {
                            setName(e.target.value)
                        }}/>
                        <span className="standart" style={{color: inputError && !name ? 'red' : ''}}>І’мя</span>
                    </label>
                </div>
                <div className="input-wrap">
                    <label className="form__label">
                        <InputMask
                            mask="+38 (099) 999-99-99"
                            defaultValue={phone}
                            onChange={e => {
                                setPhone(e.target.value)
                                setInputError(false)
                            }}
                            required
                            className="form__input"
                            placeholder=" "
                        />
                        <span className="standart" style={{color: inputError && !phone ? 'red' : ''}}>Телефон</span>
                    </label>
                </div>
            </div>
            <div className="buy-on-click-phone__done-btn btn-light" onClick={() => {
                if(phone && name){
                    callBack()
                    setPhone('')
                } else {
                    setInputError(true)
                }
            }}>Відправити</div>
        </div>
    )
}

export default BuyOneClick