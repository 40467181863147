import React from 'react'
import BlogPageOffersItem from "./BlogPageOffersItem";

const BlogPageOffers = props => {

    return (
        <section className="blog-article__offers">
            <div className="sticky">
                <div className="white_bg">
                    <div className="blog-article__offer-title">Пропозиції</div>
                    <div className="blog-article__offer-items">
                        {
                            props.offers.map((data, i) => {
                                return (
                                    <BlogPageOffersItem data={data} key={i} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogPageOffers