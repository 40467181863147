import {GET_SETTINGS} from "../../actionConst/getActions";

const initialState = {
    settings: {}
}

export const settingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_SETTINGS:

            let showCreditForActions = false;
            action.payload.forEach((item) => {
                if(item.key === 'show_credit_for_actions') showCreditForActions = item.value == '1' ? true : false
            })

            return {
                ...state,
                showCreditForActions: showCreditForActions
            }
        default:
            return state
    }
}