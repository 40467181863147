import React from 'react'
import DeliveryPaymentDelivery from "./DeliveryPaymentDelivery";
import DeliveryPaymentWayPay from "./DeliveryPaymentWayPay";
import DeliveryPaymentCredit from "./DeliveryPaymentCredit";
import './DeliveryPayment.scss'
import ScrollToTopOnMount from "../../ScrolToTop";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const DeliveryPayment = props => {

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Доставка та способи оплати | Ліка-Комфорт</title>
                <meta name="description" content={`Доставка та способи оплати компанія Ліка-Комфорт ☀ ☁.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Доставка та способи оплати | Ліка-Комфорт" />
                <meta property="og:description" content="Доставка та способи оплати компанія Ліка-Комфорт ☀ ☁." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="delivery-payment">
                    <div className="container">
                        <DeliveryPaymentDelivery />
                        <DeliveryPaymentWayPay />
                        <DeliveryPaymentCredit />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DeliveryPayment