import React from 'react'

const MainBannerPrev = (props) => {

    return (
        <button className="main-slider-arrows__arrow-left" onClick={props.onClick}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="40" width="40" height="40" transform="rotate(-90 0 40)" fill="#183029" fillOpacity="0.5"/>
                <path  d="M22.2948 7.91207C22.5165 8.02346 22.6851 8.21827 22.7635 8.45376C22.8419 8.68926 22.8237 8.94622 22.7129 9.16832L17.2998 20.0002L22.7148 30.8302C22.771 30.9404 22.805 31.0606 22.8146 31.184C22.8242 31.3073 22.8092 31.4313 22.7707 31.5489C22.7321 31.6665 22.6707 31.7752 22.5899 31.8689C22.5091 31.9626 22.4105 32.0394 22.2999 32.0948C22.1893 32.1503 22.0688 32.1833 21.9454 32.1919C21.8219 32.2005 21.698 32.1847 21.5808 32.1452C21.4635 32.1057 21.3552 32.0434 21.2622 31.9619C21.1691 31.8804 21.0931 31.7812 21.0385 31.6702L15.4135 20.4202C15.3482 20.2898 15.3142 20.146 15.3142 20.0002C15.3142 19.8544 15.3482 19.7106 15.4135 19.5802L21.0385 8.33019C21.1499 8.1084 21.3447 7.93983 21.5802 7.86145C21.8157 7.78307 22.0727 7.80127 22.2948 7.91207Z" fill="white"/>
            </svg>
        </button>
    )
}

export default MainBannerPrev