import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import HeaderTop from "./HeaderTop/HeaderTop";
import HeaderMain from "./HeaderMain/HeaderMain";
import HeaderBottom from "./HeaderBottom/HeaderBottom";
import './Header.scss'
import './HeaderModal.scss'
import {getCategories} from "../../redux/actionCreators/getCreators/getCategories";
import {toggleModal, toggleQuiz} from "../../redux/actionCreators/modalsCreator";
import ScrollHeader from "./ScrollHeader";
import JsonLd from '../globalComponents/JsonLd/JsonLd';
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

let datePublished = new Date();
datePublished.setMonth(datePublished.getMonth()-3);

let img = new Image();
img.src = logo;

const jsonLd = {
    "@context": "http://schema.org",
    "@type": "ImageObject",
    "contentUrl": _domain+logo,
    "datePublished": datePublished.toISOString(),
    "name": "Логотип",
    "description": "Логотип",
    "width": img.width,
    "height": img.height,
    "author": "Ліка Комфорт"
};

const Header = (props) => {

    useEffect(() => {
        window.axios.get(`/categories`)
            .then(res => {
                props.getCategories(res.data.data)
            })
    }, [])

    return (
        <>
            <JsonLd data={jsonLd} />
            <header className="header">
                <HeaderTop phones={props.phones}/>
                <HeaderMain />
                <HeaderBottom
                    toggleQuiz={props.toggleQuiz}
                    toggleModal={props.toggleModal}
                    categoryData={props.categoryData}
                />
            </header>
            <ScrollHeader
                categoryData={props.categoryData}
            />
        </>
    )
}

const mapStateToProps = (store) => {
    return {
        phones: store.phoneReducer.phones,
        categoryData: store.headerCatalogReducer.categoryData,
        quiz: store.modalReducer.quiz,
    }
}

const mapDispatchToProps = {
    getCategories,
    toggleQuiz,
    toggleModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)