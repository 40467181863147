import {
    ADD_BASKET,
    ADD_QUANTITY_BASKET,
    ADD_SERVICE, CLEAR_BASKET,
    REMOVE_BASKET,
    REMOVE_QUANTITY_BASKET, REMOVE_SERVICE
} from "../actionConst/action";

export const clearBasket = () => {
    return {
        type: CLEAR_BASKET
    }
}

export const addBasket = (payload) => {
    return {
        type: ADD_BASKET,
        payload
    }
}

export const removeBasket = (payload) => {
    return {
        type: REMOVE_BASKET,
        payload: payload
    }
}

export const addQuantityBasket = (payload, quantity) => {
    return {
        type: ADD_QUANTITY_BASKET,
        payload: payload,
        quantity: quantity
    }
}

export const removeQuantityBasket = (payload, quantity) => {
    return {
        type: REMOVE_QUANTITY_BASKET,
        payload: payload,
        quantity: quantity
    }
}

export const addServices = payload => {
    return {
        type: ADD_SERVICE,
        payload: payload,
    }
}

export const removeServices = payload => {
    return {
        type: REMOVE_SERVICE,
        payload,
    }
}