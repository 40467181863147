import React from 'react'

const ProductPageInfoPricingDiscount = (props) => {

    return (
        <div className="product-info__discount">
            <div className="product-info__discount-text">Знижка</div>
            <div className="product-info__discount-val">{props.discount.toLocaleString()} грн</div>
        </div>
    )
}

export default ProductPageInfoPricingDiscount