import {phoneReducer} from "./globalReducers/phoneReducer";
import {workTimeReducer} from "./globalReducers/workTimeReducer";
import {addressReducer} from "./globalReducers/addressReducer";
import {headerCatalogReducer} from "./headerReducers/headerCatalogReducer";
import {partnerSliderReducer} from "./mainPageReducers/partnerSliderReducer";
import {combineReducers} from "redux";
import {newProductsSliderReducer} from "./mainPageReducers/newProductsSliderReducer";
import {newInstalyatorProductsSliderReducer} from "./mainPageReducers/newInstalyatorProductsSliderReducer";
import {discountProductsSliderReducer} from "./mainPageReducers/discountProductsSliderReducer";
import {catalogBannerReducer} from "./catalogPageReducers/catalogBannerReducer";
import {catalogFilterReducer} from "./catalogPageReducers/catalogFilterReducer";
import {catalogProductsReducer} from "./catalogPageReducers/catalogProductsReducer";
import {productPageReducer} from "./productPageReducers/productPageReducer";
import {filterReducer} from "./catalogPageReducers/filterReducer";
import {basketReducer} from "./headerReducers/basketReducer";
import {compareReducer} from "./headerReducers/compareReducer";
import {wishlistReducer} from "./headerReducers/wishListReducer";
import {servicesReducer} from "./servicesReducers/servicesReducer";
import {blogReducer} from "./blogReducers/blogReducer";
import {casesReducer} from "./casesReducers/casesReducer";
import {blogPageReducer} from "./blogReducers/blogPageReducer";
import {aboutUsReducer} from "./globalReducers/aboutUsReducer";
import {quizReducer} from "./modalReducers/quizReducer";
import {orderReducer} from "./orderReducers/orderReducer";
import {userReducer} from "./userReducers/userReducer";
import {modalReducer} from "./modalReducers/modalReducer";
import {bannersReducer} from "./mainPageReducers/bannersReducer";
import {searchReducer} from "./headerReducers/searchReducer";
import {optCategoriesReducer} from "./optCategoriesReducer/optCategoriesReducer";
import {settingsReducer} from "./settingsReducer/settingsReducer";

export const rootReducer = combineReducers({
    //globalReducers
    phoneReducer,
    workTimeReducer,
    addressReducer,
    basketReducer,
    compareReducer,
    wishlistReducer,
    modalReducer,
    searchReducer,
    //headerReducer
    headerCatalogReducer,
    //mainPageReducers
    partnerSliderReducer,
    newProductsSliderReducer,
    newInstalyatorProductsSliderReducer,
    discountProductsSliderReducer,
    bannersReducer,
    //catalogPageReducers
    catalogBannerReducer,
    catalogFilterReducer,
    catalogProductsReducer,
    //productPageReducers
    productPageReducer,
    filterReducer,
    //servicesReducers
    servicesReducer,
    //blogReducers
    blogReducer,
    blogPageReducer,
    //casesReducers
    casesReducer,
    //aboutUsReducer
    aboutUsReducer,
    //modalReducers
    quizReducer,
    //orderReducers
    orderReducer,
    //userReducers
    userReducer,
    //optCategoriesReducer
    optCategoriesReducer,
    //settingsReducer
    settingsReducer,
})