import React from "react";
import left from "../../../img/ar-left-16.svg";
import right from "../../../img/ar-right-16.svg";

import './Pagination.scss'

const Pagination = props => {

    function changePage(newPage) {
        if(newPage !== props.page) {
            if(props.urlFilters === undefined) {
                props.setPage(newPage);
            }
            else {
                var newUrlFilters = props.urlFilters;
                if(newUrlFilters.search(/\/page=(\d+)/g) >= 0) {
                    if(newPage == 1) newUrlFilters = newUrlFilters.replace(/\/page=(\d+)/g, '');
                    else newUrlFilters = newUrlFilters.replace(/\/page=(\d+)/g, '/page='+newPage);
                }
                else {
                    var url = newUrlFilters.split('?');
                    var filter = url[0].split('/');
                    filter.shift();
                    var slug = filter.shift();
                    filter.push('page='+newPage);
                    newUrlFilters = '/'+slug+'/'+filter.sort().join('/');
                    if(url[1]) newUrlFilters = newUrlFilters+'?'+url[1];

                }
                props.setUrlFilters(newUrlFilters)
            }
        }
    }

    return (
        <div className="pagination-wrap">
            <div className="pagination" onClick={() => window.scrollTo(0, 0)}>
                <div className="pagination__arrow pagination__arrow--left" onClick={() => {
                    props.page === 1 ? changePage(1) : changePage(props.page - 1)
                }}>
                    <img src={left} alt='left'/>
                </div>
                {
                    props.pageLength <= 5 ? (
                        Array(props.pageLength).fill('').map((data, i) => {
                            return (
                                <div key={i} className={`pagination__item ${props.page === i + 1 ? 'active' : ''}`} onClick={() => changePage(i + 1)}>
                                    {i + 1}
                                </div>
                            )
                        })
                    ) : (
                        <>
                            {
                                props.pageLength === 1 ? '' : props.pageLength === 2 ? '' : (
                                    props.page === props.pageLength ? (
                                        <>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page - 4)}>
                                                {props.page - 4}
                                            </div>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page - 3)}>
                                                {props.page - 3}
                                            </div>
                                        </>
                                    ) : props.page === props.pageLength -1 ? (
                                        <div className={`pagination__item`} onClick={() => changePage(props.page - 3)}>
                                            {props.page - 3}
                                        </div>
                                    ) : ''
                                )
                            }
                            {
                                props.page === 1 ? '' : props.page === 2 ? (
                                    <div className={`pagination__item`} onClick={() => changePage(props.page - 1)}>
                                        {props.page - 1}
                                    </div>
                                ) : (
                                    <>
                                        <div className={`pagination__item`} onClick={() => changePage(props.page - 2)}>
                                            {props.page - 2}
                                        </div>
                                        <div className={`pagination__item`} onClick={() => changePage(props.page - 1)}>
                                            {props.page - 1}
                                        </div>
                                    </>
                                )
                            }
                            <div className={`pagination__item active`}>
                                {props.page}
                            </div>
                            {
                                props.pageLength === 1 ? '' : props.pageLength === 2 ? (
                                    <div className={`pagination__item`} onClick={() => changePage(props.page + 1)}>
                                        {props.page + 1}
                                    </div>
                                ) : (
                                    props.page === 1 ? (
                                        <>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 1)}>
                                                {props.page + 1}
                                            </div>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 2)}>
                                                {props.page + 2}
                                            </div>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 3)}>
                                                {props.page + 3}
                                            </div>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 4)}>
                                                {props.page + 4}
                                            </div>
                                        </>
                                    ) : props.page === 2 ? (
                                        <>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 1)}>
                                                {props.page + 1}
                                            </div>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 2)}>
                                                {props.page + 2}
                                            </div>
                                            <div className={`pagination__item`} onClick={() => changePage(props.page + 3)}>
                                                {props.page + 3}
                                            </div>
                                        </>
                                    ) : props.page > 2 ? (
                                        <>
                                            {
                                                props.page === props.pageLength - 1 ? (
                                                    <>
                                                        <div className={`pagination__item`} onClick={() => changePage(props.page + 1)}>
                                                            {props.page + 1}
                                                        </div>
                                                    </>
                                                ) : props.page === props.pageLength - 2 ? (
                                                    <>
                                                        <div className={`pagination__item`} onClick={() => changePage(props.page + 1)}>
                                                            {props.page + 1}
                                                        </div>
                                                        <div className={`pagination__item`} onClick={() => changePage(props.page + 2)}>
                                                            {props.page + 2}
                                                        </div>
                                                    </>
                                                ) : props.page === props.pageLength ? '' : (
                                                    <>
                                                        <div className={`pagination__item`} onClick={() => changePage(props.page + 1)}>
                                                            {props.page + 1}
                                                        </div>
                                                        <div className={`pagination__item`} onClick={() => changePage(props.page + 2)}>
                                                            {props.page + 2}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    ) : ''
                                )
                            }
                        </>
                    )
                }
                <div className="pagination__arrow pagination__arrow--right" onClick={() => {
                    props.page === props.pageLength ? changePage(props.pageLength) : changePage(props.page + 1)
                }}>
                    <img src={right} alt="right" />
                </div>
            </div>
        </div>
    )
}

export default Pagination