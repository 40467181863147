import React from "react"
import CatalogHeader from "./CatalogHeader/CatalogHeader";
import CatalogMobileHeader from "./CatalogHeader/CatalogMobileHeader";
import CatalogBody from "./CatalogBody/CatalogBody";
import Pagination from "../../globalComponents/Pagination/Pagination";
import Preloader from "../../globalComponents/Preloader/Preloader";

const CatalogContent = (props) => {

    return (
        <section className="catalog__section">
            <CatalogHeader
                categoryId={props.categoryId}
                sort={props.sort}
                setSort={props.setSort}
                sortName={props.sortName}
                setSortName={props.setSortName}
                urlFilters={props.urlFilters}
                setUrlFilters={props.setUrlFilters}
            />
            <CatalogMobileHeader categoryId={props.categoryId} openFilterDrop={props.openFilterDrop}/>
            {
                props.loadingProducts ? (
                    <Preloader />
                ) : (
                    <CatalogBody
                        pageSize={props.pageSize}
                        setPageSize={props.setPageSize}
                        slug={props.slug}
                        products={props.products}
                        pageLength={props.pageLength}
                    />
                )
            }
            {
                props.pageLength === 1 ? '' : (
                    <Pagination pageLength={props.pageLength} page={props.page} setPage={props.setPage} urlFilters={props.urlFilters} setUrlFilters={props.setUrlFilters} />
                )
            }
        </section>
    )
}

export default CatalogContent