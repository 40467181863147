import React from 'react'

const ProductPageInfoPricingPrice = (props) => {

    return (
        <div className="product-info__price-wrap">
            {
                props.product.discount > 0? <p className="product-info__discounted-price">{props.product.price.toLocaleString()} грн</p> : ''
            }
            {
                props.product.final_price === 0 ? (
                    <h4 className="product-info__price">За домовленістю</h4>
                ) : (
                    <h4 className="product-info__price">{props.product.final_price.toLocaleString()} грн</h4>
                )
            }
        </div>
    )
}

export default ProductPageInfoPricingPrice