import React from 'react'
import {Link} from "react-router-dom";
import {connect} from 'react-redux'
import {toggleModal, toggleQuiz} from "../../../../../../redux/actionCreators/modalsCreator";
import PartnersSlider from "../PartnenrsSlider";
import hvacinstrumentimg from "../../../../../../img/hvac-instrument.svg";
import hvacserviceimg from "../../../../../../img/hvac-service.svg";
import hvactrybuimg from "../../../../../../img/hvac-trybu.svg";
import hvacisolyationimg from "../../../../../../img/hvac-isolyation.svg";
import hvackronshteinimg from "../../../../../../img/hvac-kronshtein.svg";
import hvacplasticimg from "../../../../../../img/hvac-plastic.svg";
import hvacdrenazimg from "../../../../../../img/hvac-drenaz.svg";
import hvacfreonimg from "../../../../../../img/hvac-freon.svg";

const HvacList = props => {

    return (
        <div className="catalog-lv2">
            <div className="catalog-lv2-content">
                <ul className="catalog-lv2__list">
                    {
                        props.subcategories.map((item, i) => {
                            switch (item.id) {
                                case 74:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvacinstrumentimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                case 75:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvacserviceimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                            }
                        })
                    }
                </ul>
                <ul className="catalog-lv2__list">
                    {
                        props.subcategories.map((item, i) => {
                            switch (item.id) {
                                case 76:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvactrybuimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                case 77:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvacisolyationimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                case 78:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvackronshteinimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                            }
                        })
                    }
                </ul>
                <ul className="catalog-lv2__list">
                    {
                        props.subcategories.map((item, i) => {
                            switch (item.id) {
                                case 79:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvacplasticimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                case 80:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvacdrenazimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                case 81:
                                    return (
                                        <li className="catalog-lv2__list-item" key={i}>
                                            <img src={hvacfreonimg}/>
                                            <Link to={`/catalog/${item.slug}`} onClick={() => props.setActive(false)}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                            }
                        })
                    }
                </ul>
            </div>
            {
                props.optCategory !== null && (
                    <div class="opt-category"><a href={`/${props.optCategory.slug}`}>{props.optCategory.title}</a></div>
                )
            }
            <PartnersSlider setActive={props.setActive} partners={props.brands}/>
        </div>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleQuiz,
};

export default connect(null, mapDispatchToProps)(HvacList);
