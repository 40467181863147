import React from 'react'
import CategoriesList from "../CategoriesList/CategoriesList";
import {Link} from "react-router-dom";

const CleanPage = props => {

    return (
        <div className="products-empty">
            <Link className="products-empty__to-main" to="/">На головну</Link>
            {
                props.name ? (
                    <p className="products-empty__title">{props.name}</p>
                ) : (
                    <p className="products-empty__title">Упс, ви видалили всі товари!</p>
                )
            }
            {
                props.title ? (
                    <p className="products-empty__subtitle">{props.title}</p>
                ) : (
                    <p className="products-empty__subtitle">Перейдіть у категорію та додайте товари до кошику</p>
                )
            }
            <CategoriesList />
        </div>
    )
}

export default CleanPage