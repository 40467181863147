import React, {useState} from 'react'
import Nouislider from "nouislider-react";
import {useHistory} from "react-router";

const QuizModalFourth = props => {
    const [value, setValue] = useState(0)

    const [inputValue, setInputValue] = useState(props.fourthQuiz)

    const [inputError, setInputError] = useState(false)

    const changeValue = e => {
        setValue(e.target.value)
    }

    const changeInputValue = () => {
        setInputValue(value)
    }

    const history = useHistory()

    return (
        <div className="quiz-modal__form quiz-modal__form--square" id='firstQuiz'>
            <h4
                className="quiz-modal__form-title"
                style={{color: inputError ? 'red ': ''}}
            >
                Кількість людей в приміщенні
            </h4>
            <div className="quiz-modal__input-wrap">
                <input
                    className="quiz-modal__form-input"
                    value={value === 0 ? '' : parseInt(value)}
                    onChange={(event) => {
                        changeValue(event);
                        setInputError(false)
                        }
                    }
                    id="quiz-square-input"
                    type="number"
                    placeholder="0"
                    autoFocus={true}
                />
            </div>
            <div className="quiz-modal__range-input-wrap">
                <Nouislider
                    step={1}
                    range={{ min: 0, max: 50 }}
                    start={0}
                    onUpdate={(event) => { 
                        setValue(parseInt(event))
                        setInputError(false)
                        }
                    }
                    connect={[true, false]} />
                <div className="quiz-modal__range-values-wrap">
                    <div className="quiz-modal__range-value-min">0</div>
                    <div className="quiz-modal__range-value-max">50</div>
                </div>
            </div>
            <button
                className="quiz-modal__submit-btn"
                style={{background: inputError ? "gray": ""}}
                onClick={() => {
               if  (Number(value)) {
                changeInputValue()
                props.changeQuiz('fifthQuiz')
                props.setFourthFilled(true)
                props.addFourthQuiz(parseInt(value))
                props.toggleQuiz(false)
                props.toggleModal(false)
                history.push('/quiz_catalog')
               } else {
                setInputError(true)
            }
            }}>
                Далі
            </button>
        </div>
    )
}

export default QuizModalFourth