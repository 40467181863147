import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router";
import {connect, useDispatch} from 'react-redux'
import '../Catalog/Catalog.scss'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import CatalogContent from "../Catalog/CatalogContent/CatalogContent";
import CatalogFilter from "../Catalog/CatalogFilter/CatalogFilter";
import ScrollToTopOnMount from "../../ScrolToTop";
import {getProducts} from "../../redux/actionCreators/getCreators/getProducts";
import {
    getCatalogBanners,
    getCatalogSlug
} from "../../redux/actionCreators/getCreators/getCategories";
import Preloader from "../globalComponents/Preloader/Preloader";
import {setSortName, cleanFilters} from "../../redux/actionCreators/catalogProducts";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";
import {SET_ALL_FILTERS, REMOVE_ALL_FILTERS} from "../../redux/actionConst/action";

const DayCatalog = props => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [urlFilters, setUrlFilters] = useState(props.location.pathname)

    const [page, setPage] = useState(1)
    const [pageLength, setPageLength] = useState(1)
    const [pageSize, setPageSize] = useState(24)
    const [loading, setLoading] = useState(true)
    const [loadingProducts, setLoadingProducts] = useState(false)

    const [productCount] = useState(0)

    const cleanAllFilters = () => {
        dispatch({type: REMOVE_ALL_FILTERS});
    }

    // Товары с учетом фильтра
    useEffect(() => {
        setLoadingProducts(true)
        window.axios.get(`/categories/catalog_day/products`, {
            params: {
                'pageSize': pageSize,
                'urlFilters': urlFilters,
            },
        })
        .then(res => {
            history.push(urlFilters)
            dispatch({
                type: SET_ALL_FILTERS,
                prices: res.data.filters.prices,
                brands: res.data.filters.brands,
                specifications: res.data.filters.specifications,
                base: res.data.filters.base,
                appliedFilters: res.data.appliedFilters,
            });
            props.getProducts(res.data.data)
            setPageLength(res.data.meta.pagesCount)
            props.setSortName(res.data.appliedSort)
            setPage(parseInt(res.data.appliedPage))
        })
        .finally(() => {
            setLoading(false)
            setLoadingProducts(false)
        })
    }, [urlFilters])

    const [openFilter, setOpenFilter] =useState(false)
    const openFilterDrop = () => {
        setOpenFilter(!openFilter)
    }

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: 'Хіти продаж', link: `/catalog_day`},
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Хіти продаж магазину Lika Comfort</title>
                <meta name="description" content={`Хіти продаж магазину Lika Comfort`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Хіти продаж магазину Lika Comfort" />
                <meta property="og:description" content="Хіти продаж магазину Lika Comfort" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="catalog">
                    <div className="container">
                        <BreadCrumbs items={crumbs}/>
                        <h1 className="catalog__title">
                            Хіти продаж
                        </h1>
                        {
                            loading ? (
                                <Preloader />
                            ) : (
                                <div className="catalog-wrap">
                                    <CatalogFilter
                                        urlFilters={urlFilters}
                                        setUrlFilters={setUrlFilters}
                                        openFilterDrop={openFilterDrop}
                                        openFilter={openFilter}
                                        productCount={productCount}
                                        cleanAllFilters={cleanAllFilters}
                                    />
                                    <CatalogContent
                                        loadingProducts={loadingProducts}
                                        setPageSize={setPageSize}
                                        pageSize={pageSize}
                                        sortName={props.sortName}
                                        setSortName={props.setSortName}
                                        page={page}
                                        setPage={setPage}
                                        pageLength={pageLength}
                                        slug={props.match.params.slug}
                                        openFilterDrop={openFilterDrop}
                                        products={props.products}
                                        urlFilters={urlFilters}
                                        setUrlFilters={setUrlFilters}
                                    />
                                </div>
                            )
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        banners: store.catalogBannerReducer.banners,
        products: store.catalogProductsReducer.products,
        sortName: store.catalogProductsReducer.sortName,
        sortDownUp: store.catalogProductsReducer.sortDownUp,
        preFilter: store.catalogFilterReducer.preFilter,
    }
}

const mapDispatchToProps = {
    getCatalogBanners,
    cleanFilters,
    getCatalogSlug,
    getProducts,
    setSortName,
}

export default connect(mapStateToProps, mapDispatchToProps)(DayCatalog)