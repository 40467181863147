import React from 'react'

const OrderResultInfo = props => {

    return (
        <div className="order-result__info-section">
            <h3 className="order-result__title">Інформація про замовлення</h3>
            <div className="order-result__info-items">
                <div className="order-result__info-item">
                    <div className="order-result__info-name">Спосіб доставки:</div>
                    <div className="order-result__info-val">{props.order.delivery_method_human}</div>
                </div>
                {
                    props.order.delivery_method_human === 'Доставка у відділення Нової Пошти'? (
                        <div className="order-result__info-item">
                            <div className="order-result__info-name">Відділення Нової Пошти:</div>
                            <div className="order-result__info-val">{props.order.ship_to.office}</div>
                        </div>
                    ) : props.order.delivery_method_human === 'Адресна доставка Нової Пошти'? (
                        <div className="order-result__info-item">
                            <div className="order-result__info-name">Доставка на адресу:</div>
                            <div className="order-result__info-val">
                                м. {props.order.ship_to.city}, вул. {props.order.ship_to.street}, {props.order.ship_to.house}, кв. {props.order.ship_to.flat}
                            </div>
                        </div>
                    ) : props.order.delivery_method_human === 'Доставка у відділення SAT'? (
                        <div className="order-result__info-item">
                            <div className="order-result__info-name">Відділення SAT:</div>
                            <div className="order-result__info-val">{props.order.ship_to.office}</div>
                        </div>
                    ) : props.order.delivery_method_human === 'Адресна доставка SAT'? (
                        <div className="order-result__info-item">
                            <div className="order-result__info-name">Доставка на адресу:</div>
                            <div className="order-result__info-val">
                                м. {props.order.ship_to.city}, вул. {props.order.ship_to.street}, {props.order.ship_to.house}, кв. {props.order.ship_to.flat}
                            </div>
                        </div>
                    ) : (
                        props.order.delivery_method_human === 'Доставка кур’єром на адресу'? (
                            <div className="order-result__info-item">
                                <div className="order-result__info-name">Доставка кур’єром на адресу:</div>
                                <div className="order-result__info-val">
                                    м. {props.order.ship_to.city}, вул. {props.order.ship_to.street}, {props.order.ship_to.house}, кв. {props.order.ship_to.flat}
                                </div>
                            </div>
                        ) : ''
                    )
                }
                <div className="order-result__info-item">
                    <div className="order-result__info-name">Спосіб оплати:</div>
                    <div className="order-result__info-val">{props.order.payment_method_human}</div>
                </div>
            </div>
        </div>
    )
}

export default OrderResultInfo