import React from 'react'
import ServicePageInfoDescription from "../MontazhKondytsioneriv/ServicePageInfoDescription";
import liDot from "../../../img/li-dot.svg";
import MetaTags from "react-meta-tags";
import {_domain} from "../../../API";
import logo from "../../../img/logoNew/logoLikaComfortLigth.svg";

const  MontazhVentyliatsii = () => {
    const itemsLi = [
        'Підготовчий. Інженери оцінюють проект приміщення та системи вентиляції, виконують необхідні розрахунки. Замовник і фірма обговорюють вартість і підписують договір. На цьому етапі встановлюють терміни.',
        'Проектування. Якщо ви вже маєте готовий проект, наші спеціалісти оцінять можливість його реалізації в існуючому приміщенні. Якщо проекту немає, ми створимо його.',
        'Виїзд на об’єкт. Перед початком робіт наші майстри оглядають приміщення й оцінюють стан комунікацій.',
        'Доставка обладнання. Виконується замовником або відповідальним менеджером нашої компанії.',
        'Встановлення. Виконання монтажних робіт.',
        'Перевірка. Фінальний етап – перевірка роботи системи з нормальним та максимальним навантаженням. При виявленні несправностей, їх відразу виправляють.',
    ]

    const itemsLiSecond = [
        'Припливна. Найпростіший варіант. Для створення використовують вентилятори і невеликі системи, що забезпечують потрапляння свіжого повітря до приміщення. Головна перевага припливної вентиляції – низька вартість. Роботи виконують впродовж 2-3 діб. Недолік системи – зниження температури в приміщенні в зимовий час. Рекомендується встановлювати на складах, у сховищах і гаражних кооперативах.',
        'Витяжна. Примусова система вентиляції швидко очищує повітря від вуглекислого газа та дрібних часточок пилу. До її переваг відносять можливість нагрівання повітря і невисоку вартість. Використовується в житлових будівлях, невеликих комерційних приміщеннях (приватні кабінети, магазини).',
        'Припливно-витяжна. Забезпечує швидку циркуляцію повітря в приміщенні. Тепловий обігрівач підтримує постійну температуру при середніх витратах електроенергії. Швидке встановлення і низька вартість роблять цю систему популярною серед клієнтів. Ми рекомендуємо використовувати її на промислових складах, в магазинах та автомайстернях.',
        'Припливно-витяжна з рекуперацією тепла. Найбільш сучасна система, що перетворює енергію виведеного повітря. Вона дозволяє охолодити чи нагріти повітря в приміщенні з мінімальними витратами енергії. Недоліком системи є складність монтажу і висока вартість. Встановлюється в аграрних комплексах, на фермах та виробництвах.',
    ]

    return (
        <div className="content">
            <MetaTags>
                <title>Монтаж вентиляційних систем в Мукачево, кращі ціни на встановлення вентиляційних систем в Ужгороді</title>
                <meta name="description" content={`Встановлення вентиляційних систем в Ужгороді ☀ ☁ Низкі ціни на монтаж систем вентиляції  ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Монтаж вентиляційних систем в Мукачево, кращі ціни на встановлення вентиляційних систем в Ужгороді" />
                <meta property="og:description" content="Встановлення вентиляційних систем в Ужгороді ☀ ☁ Низкі ціни на монтаж систем вентиляції  ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <main className="services-info">
                <svg className="services-info__bg-left" width="200" height="348" viewBox="0 0 200 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="51.0536" y="325.443" width="17.5651" height="17.5651"
                          transform="rotate(123.715 51.0536 325.443)" stroke="#43B02A" strokeOpacity="0.3"/>
                    <rect x="92.4013" y="40.7936" width="52.4257" height="52.4257"
                          transform="rotate(123.715 92.4013 40.7936)" stroke="#43B02A"/>
                    <rect x="136.334" y="100.376" width="80.4985" height="80.4985"
                          transform="rotate(123.715 136.334 100.376)" stroke="#DCDCDC"/>
                    <rect x="186.035" y="177.846" width="50.7197" height="50.7197"
                          transform="rotate(123.715 186.035 177.846)" stroke="#43B02A" strokeOpacity="0.5"/>
                    <rect x="133.185" y="254.315" width="59" height="59" transform="rotate(123.715 133.185 254.315)"
                          stroke="#DCDCDC" strokeOpacity="0.4"/>
                    <rect x="63.1298" y="161.89" width="68.8139" height="68.8139"
                          transform="rotate(123.715 63.1298 161.89)" fill="white" fillOpacity="0.1"
                          stroke="#43B02A"/>
                    <rect x="40.6113" y="261.674" width="39.5981" height="39.5981"
                          transform="rotate(123.715 40.6113 261.674)" stroke="#43B02A" strokeOpacity="0.4"/>
                    <rect x="23.0077" y="78.6374" width="59.3526" height="59.3526"
                          transform="rotate(123.715 23.0077 78.6374)" stroke="#DCDCDC"/>
                </svg>
                <svg className="services-info__bg-right" width="217" height="362" viewBox="0 0 217 362" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="187.814" y="51.2272" width="52.4257" height="52.4257"
                          transform="rotate(123.715 187.814 51.2272)" stroke="#43B02A"/>
                    <rect x="31.832" y="85.6433" width="17.5651" height="17.5651"
                          transform="rotate(123.715 31.832 85.6433)" stroke="#43B02A"/>
                    <rect x="226.134" y="117.668" width="80.4985" height="80.4985"
                          transform="rotate(123.715 226.134 117.668)" stroke="#43B02A"/>
                    <rect x="197.696" y="221.912" width="56.2435" height="56.2435"
                          transform="rotate(123.715 197.696 221.912)" stroke="#DCDCDC"/>
                    <rect x="118.522" y="99.3991" width="59.3526" height="59.3526"
                          transform="rotate(123.715 118.522 99.3991)" fill="white" fillOpacity="0.1"
                          stroke="#DCDCDC"/>
                    <rect x="129.936" y="286.601" width="34.0643" height="34.0643"
                          transform="rotate(123.715 129.936 286.601)" stroke="#43B02A" strokeOpacity="0.5"/>
                    <rect x="188.632" y="331.535" width="20.8121" height="20.8121"
                          transform="rotate(123.715 188.632 331.535)" stroke="#43B02A" strokeOpacity="0.3"/>
                    <rect x="124.931" y="45.2683" width="59.3526" height="59.3526"
                          transform="rotate(123.715 124.931 45.2683)" stroke="white"/>
                </svg>
                <div className="container">
                    <h1 className="services-info__title">Монтаж вентиляційних систем</h1>
                    <div className="services-info__table-section services-info__table-section--type2">
                        <div className="services-info__description">
                            <h6 className="services-info__description-title">﻿Монтаж вентиляції</h6>
                            <p className="services-info__description-text">﻿Монтаж вентиляції – важлива частина створення промислових об’єктів та житлових приміщень. Непомітна система видаляє вуглекислий газ, пил та дрібні часточки сміття, наповнюючи кімнату свіжим повітрям. Компанія "Ліка-Комфорт" надає послуги монтажу вентиляційних систем в житлових та промислових приміщеннях. Виконання робіт зі встановлення вентиляційної системи відбувається в кілька етапів:</p>
                            <ServicePageInfoDescription
                                itemsLi={itemsLi}
                                img={liDot}
                            />
                            <p style={{textIndent: '1.5em'}}>
                                Після передачі об’єкта замовник отримує сертифікат і гарантію.
                            </p>
                            <br/>
                            <p style={{textIndent: '1.5em'}}>
                                Наша команда виконує профілактичне обслуговування системи та ремонтні роботи (за необхідності).
                            </p>
                            <br/>
                            <p style={{textIndent: '1.5em'}}>
                                Компанія "Ліка-Комфорт" проектує і встановлює наступні системи примусової вентиляції:
                            </p>
                            <ServicePageInfoDescription
                                itemsLi={itemsLiSecond}
                                img={liDot}
                            />
                            <p style={{textIndent: '1.5em'}}>
                                Незалежно від вашого вибору, наші майстри проведуть установку і нададуть консультацію щодо подальшої експлуатації системи. За детальною консультацією про вартість монтажу системи вентиляції та нюанси роботи звертайтесь за номером +380669929111 або залиште заявку на зворотний дзвінок на нашому сайті.
                            </p>
                            <br/>
                            <p style={{textAlign: 'right', fontStyle: 'italic'}}>
                                Живіть з комфортом!
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default MontazhVentyliatsii