import React from 'react'
import {Link} from 'react-router-dom';
import OsusuvaciList from "./OsusuvaciList";

const Osusuvaci = props => {

    return (
        <li className={`catalog-lv1__item ${props.activeCatalog.slug === props.data.slug ? 'active' : ''} ${props.activeCatalog.name === 'Каталог' ? '' : 'hidden-catalog'}`} onClick={() => {
            props.setActiveCatalog(props.data)
        }}>
            <div className="catalog-lv1__button">
                <div className="catalog-lv1__ico-box">
                    <svg className="catalog-lv1__ico" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.2 22.4C15.9627 22.4 15.7307 22.4704 15.5333 22.6022C15.336 22.7341 15.1822 22.9215 15.0913 23.1408C15.0005 23.3601 14.9768 23.6013 15.0231 23.8341C15.0694 24.0669 15.1836 24.2807 15.3515 24.4485C15.5193 24.6164 15.7331 24.7306 15.9659 24.7769C16.1987 24.8232 16.44 24.7995 16.6592 24.7087C16.8785 24.6178 17.0659 24.464 17.1978 24.2667C17.3296 24.0693 17.4 23.8373 17.4 23.6C17.3996 23.2818 17.2731 22.9768 17.0481 22.7519C16.8232 22.5269 16.5182 22.4004 16.2 22.4ZM16.2 24C16.1209 24 16.0436 23.9765 15.9778 23.9326C15.912 23.8886 15.8607 23.8262 15.8304 23.7531C15.8002 23.68 15.7923 23.5996 15.8077 23.522C15.8231 23.4444 15.8612 23.3731 15.9172 23.3172C15.9731 23.2612 16.0444 23.2231 16.122 23.2077C16.1996 23.1923 16.28 23.2002 16.3531 23.2304C16.4262 23.2607 16.4886 23.312 16.5326 23.3778C16.5765 23.4436 16.6 23.5209 16.6 23.6C16.5999 23.7061 16.5578 23.8078 16.4828 23.8828C16.4078 23.9578 16.3061 23.9999 16.2 24Z"
                            fill="#182316"/>
                        <path
                            d="M16.2 19.2C15.9627 19.2 15.7307 19.2704 15.5333 19.4022C15.336 19.5341 15.1822 19.7215 15.0913 19.9408C15.0005 20.1601 14.9768 20.4013 15.0231 20.6341C15.0694 20.8669 15.1836 21.0807 15.3515 21.2485C15.5193 21.4164 15.7331 21.5306 15.9659 21.5769C16.1987 21.6232 16.44 21.5995 16.6592 21.5087C16.8785 21.4178 17.0659 21.264 17.1978 21.0667C17.3296 20.8693 17.4 20.6373 17.4 20.4C17.3996 20.0819 17.2731 19.7768 17.0481 19.5519C16.8232 19.3269 16.5182 19.2004 16.2 19.2ZM16.2 20.8C16.1209 20.8 16.0436 20.7765 15.9778 20.7326C15.912 20.6886 15.8607 20.6262 15.8304 20.5531C15.8002 20.48 15.7923 20.3996 15.8077 20.322C15.8231 20.2444 15.8612 20.1731 15.9172 20.1172C15.9731 20.0612 16.0444 20.0231 16.122 20.0077C16.1996 19.9923 16.28 20.0002 16.3531 20.0305C16.4262 20.0607 16.4886 20.112 16.5326 20.1778C16.5765 20.2436 16.6 20.3209 16.6 20.4C16.5999 20.5061 16.5578 20.6078 16.4828 20.6828C16.4078 20.7578 16.3061 20.7999 16.2 20.8Z"
                            fill="#182316"/>
                        <path
                            d="M24.2 6C24.1993 5.46976 23.9884 4.96141 23.6135 4.58647C23.2385 4.21154 22.7302 4.00062 22.2 4H10.2C9.66971 4.00062 9.16136 4.21154 8.78642 4.58647C8.41149 4.96141 8.20057 5.46976 8.19995 6V24.4C8.20059 24.8608 8.36007 25.3073 8.65152 25.6643C8.94297 26.0212 9.34857 26.2668 9.79995 26.3596V27.6C9.79995 27.7061 9.84209 27.8078 9.91711 27.8828C9.99212 27.9579 10.0939 28 10.2 28H11.8C11.906 28 12.0078 27.9579 12.0828 27.8828C12.1578 27.8078 12.2 27.7061 12.2 27.6V26.4H20.2V27.6C20.2 27.7061 20.2421 27.8078 20.3171 27.8828C20.3921 27.9579 20.4939 28 20.6 28H22.2C22.306 28 22.4078 27.9579 22.4828 27.8828C22.5578 27.8078 22.6 27.7061 22.6 27.6V26.3596C23.0513 26.2668 23.4569 26.0212 23.7484 25.6643C24.0398 25.3073 24.1993 24.8608 24.2 24.4V6ZM11.4 27.2H10.6V26.4H11.4V27.2ZM21.8 27.2H21V26.4H21.8V27.2ZM23.4 24.4C23.3996 24.7182 23.2731 25.0232 23.0481 25.2481C22.8231 25.4731 22.5181 25.5996 22.2 25.6H10.2C9.8818 25.5996 9.57678 25.4731 9.35182 25.2481C9.12685 25.0232 9.00031 24.7182 8.99995 24.4V6C9.00031 5.68185 9.12685 5.37683 9.35182 5.15187C9.57678 4.9269 9.8818 4.80036 10.2 4.8H22.2C22.5181 4.80036 22.8231 4.9269 23.0481 5.15187C23.2731 5.37683 23.3996 5.68185 23.4 6V24.4Z"
                            fill="#182316"/>
                        <path
                            d="M22.2 20.8H19C18.8939 20.8 18.7921 20.8421 18.7171 20.9172C18.6421 20.9922 18.6 21.0939 18.6 21.2C18.6 21.3061 18.6421 21.4078 18.7171 21.4828C18.7921 21.5579 18.8939 21.6 19 21.6H22.2C22.3061 21.6 22.4078 21.5579 22.4828 21.4828C22.5578 21.4078 22.6 21.3061 22.6 21.2C22.6 21.0939 22.5578 20.9922 22.4828 20.9172C22.4078 20.8421 22.3061 20.8 22.2 20.8Z"
                            fill="#182316"/>
                        <path
                            d="M22.2 19.2H19C18.8939 19.2 18.7921 19.2421 18.7171 19.3172C18.6421 19.3922 18.6 19.4939 18.6 19.6C18.6 19.7061 18.6421 19.8078 18.7171 19.8828C18.7921 19.9579 18.8939 20 19 20H22.2C22.3061 20 22.4078 19.9579 22.4828 19.8828C22.5578 19.8078 22.6 19.7061 22.6 19.6C22.6 19.4939 22.5578 19.3922 22.4828 19.3172C22.4078 19.2421 22.3061 19.2 22.2 19.2Z"
                            fill="#182316"/>
                        <path
                            d="M22.2 22.4H19C18.8939 22.4 18.7921 22.4421 18.7171 22.5172C18.6421 22.5922 18.6 22.6939 18.6 22.8C18.6 22.9061 18.6421 23.0078 18.7171 23.0828C18.7921 23.1579 18.8939 23.2 19 23.2H22.2C22.3061 23.2 22.4078 23.1579 22.4828 23.0828C22.5578 23.0078 22.6 22.9061 22.6 22.8C22.6 22.6939 22.5578 22.5922 22.4828 22.5172C22.4078 22.4421 22.3061 22.4 22.2 22.4Z"
                            fill="#182316"/>
                        <path
                            d="M22.2 24H19C18.8939 24 18.7921 24.0421 18.7171 24.1172C18.6421 24.1922 18.6 24.2939 18.6 24.4C18.6 24.5061 18.6421 24.6078 18.7171 24.6828C18.7921 24.7579 18.8939 24.8 19 24.8H22.2C22.3061 24.8 22.4078 24.7579 22.4828 24.6828C22.5578 24.6078 22.6 24.5061 22.6 24.4C22.6 24.2939 22.5578 24.1922 22.4828 24.1172C22.4078 24.0421 22.3061 24 22.2 24Z"
                            fill="#182316"/>
                        <path
                            d="M13.3999 20.8H10.1999C10.0938 20.8 9.9921 20.8421 9.91708 20.9172C9.84207 20.9922 9.79993 21.0939 9.79993 21.2C9.79993 21.3061 9.84207 21.4078 9.91708 21.4828C9.9921 21.5579 10.0938 21.6 10.1999 21.6H13.3999C13.506 21.6 13.6078 21.5579 13.6828 21.4828C13.7578 21.4078 13.7999 21.3061 13.7999 21.2C13.7999 21.0939 13.7578 20.9922 13.6828 20.9172C13.6078 20.8421 13.506 20.8 13.3999 20.8Z"
                            fill="#182316"/>
                        <path
                            d="M13.3999 19.2H10.1999C10.0938 19.2 9.9921 19.2421 9.91708 19.3172C9.84207 19.3922 9.79993 19.4939 9.79993 19.6C9.79993 19.7061 9.84207 19.8078 9.91708 19.8828C9.9921 19.9579 10.0938 20 10.1999 20H13.3999C13.506 20 13.6078 19.9579 13.6828 19.8828C13.7578 19.8078 13.7999 19.7061 13.7999 19.6C13.7999 19.4939 13.7578 19.3922 13.6828 19.3172C13.6078 19.2421 13.506 19.2 13.3999 19.2Z"
                            fill="#182316"/>
                        <path
                            d="M13.3999 22.4H10.1999C10.0938 22.4 9.9921 22.4421 9.91708 22.5172C9.84207 22.5922 9.79993 22.6939 9.79993 22.8C9.79993 22.9061 9.84207 23.0078 9.91708 23.0828C9.9921 23.1579 10.0938 23.2 10.1999 23.2H13.3999C13.506 23.2 13.6078 23.1579 13.6828 23.0828C13.7578 23.0078 13.7999 22.9061 13.7999 22.8C13.7999 22.6939 13.7578 22.5922 13.6828 22.5172C13.6078 22.4421 13.506 22.4 13.3999 22.4Z"
                            fill="#182316"/>
                        <path
                            d="M13.3999 24H10.1999C10.0938 24 9.9921 24.0421 9.91708 24.1172C9.84207 24.1922 9.79993 24.2939 9.79993 24.4C9.79993 24.5061 9.84207 24.6078 9.91708 24.6828C9.9921 24.7579 10.0938 24.8 10.1999 24.8H13.3999C13.506 24.8 13.6078 24.7579 13.6828 24.6828C13.7578 24.6078 13.7999 24.5061 13.7999 24.4C13.7999 24.2939 13.7578 24.1922 13.6828 24.1172C13.6078 24.0421 13.506 24 13.3999 24Z"
                            fill="#182316"/>
                        <path
                            d="M22.1999 5.6H10.1999C10.0938 5.6 9.9921 5.64214 9.91708 5.71716C9.84207 5.79217 9.79993 5.89391 9.79993 6V18C9.79993 18.1061 9.84207 18.2078 9.91708 18.2828C9.9921 18.3579 10.0938 18.4 10.1999 18.4H22.1999C22.306 18.4 22.4078 18.3579 22.4828 18.2828C22.5578 18.2078 22.5999 18.1061 22.5999 18V6C22.5999 5.89391 22.5578 5.79217 22.4828 5.71716C22.4078 5.64214 22.306 5.6 22.1999 5.6ZM11.7999 17.6H10.5999V16.4H11.7999V17.6ZM11.7999 15.6H10.5999V14.4H11.7999V15.6ZM11.7999 13.6H10.5999V12.4H11.7999V13.6ZM11.7999 11.6H10.5999V10.4H11.7999V11.6ZM11.7999 9.6H10.5999V8.4H11.7999V9.6ZM11.7999 7.6H10.5999V6.4H11.7999V7.6ZM13.7999 17.6H12.5999V16.4H13.7999V17.6ZM13.7999 15.6H12.5999V14.4H13.7999V15.6ZM13.7999 13.6H12.5999V12.4H13.7999V13.6ZM13.7999 11.6H12.5999V10.4H13.7999V11.6ZM13.7999 9.6H12.5999V8.4H13.7999V9.6ZM13.7999 7.6H12.5999V6.4H13.7999V7.6ZM15.7999 17.6H14.5999V16.4H15.7999V17.6ZM15.7999 15.6H14.5999V14.4H15.7999V15.6ZM15.7999 13.6H14.5999V12.4H15.7999V13.6ZM15.7999 11.6H14.5999V10.4H15.7999V11.6ZM15.7999 9.6H14.5999V8.4H15.7999V9.6ZM15.7999 7.6H14.5999V6.4H15.7999V7.6ZM17.7999 17.6H16.5999V16.4H17.7999V17.6ZM17.7999 15.6H16.5999V14.4H17.7999V15.6ZM17.7999 13.6H16.5999V12.4H17.7999V13.6ZM17.7999 11.6H16.5999V10.4H17.7999V11.6ZM17.7999 9.6H16.5999V8.4H17.7999V9.6ZM17.7999 7.6H16.5999V6.4H17.7999V7.6ZM19.7999 17.6H18.5999V16.4H19.7999V17.6ZM19.7999 15.6H18.5999V14.4H19.7999V15.6ZM19.7999 13.6H18.5999V12.4H19.7999V13.6ZM19.7999 11.6H18.5999V10.4H19.7999V11.6ZM19.7999 9.6H18.5999V8.4H19.7999V9.6ZM19.7999 7.6H18.5999V6.4H19.7999V7.6ZM21.7999 17.6H20.5999V16.4H21.7999V17.6ZM21.7999 15.6H20.5999V14.4H21.7999V15.6ZM21.7999 13.6H20.5999V12.4H21.7999V13.6ZM21.7999 11.6H20.5999V10.4H21.7999V11.6ZM21.7999 9.6H20.5999V8.4H21.7999V9.6ZM21.7999 7.6H20.5999V6.4H21.7999V7.6Z"
                            fill="#182316"/>
                    </svg>
                </div>

                <div
                    className="catalog-lv1__item-name"
                    onClick={() => props.desktop ? props.setActive(false) : ''}
                >
                    <Link to={`/catalog/${props.data.slug}`}>
                        {props.data.name}
                    </Link>
                </div>

                <div className="catalog-lv1__arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                              d="M6.77605 14.447C6.65776 14.3876 6.56786 14.2837 6.52606 14.1581C6.48425 14.0325 6.49396 13.8955 6.55305 13.777L9.44005 8L6.55205 2.224C6.52203 2.16524 6.50394 2.10111 6.49882 2.03533C6.4937 1.96954 6.50166 1.90339 6.52222 1.84069C6.54279 1.77799 6.57557 1.71998 6.61866 1.67001C6.66175 1.62003 6.71431 1.57909 6.77331 1.54952C6.8323 1.51996 6.89656 1.50237 6.96239 1.49776C7.02822 1.49315 7.0943 1.50162 7.15684 1.52267C7.21938 1.54372 7.27713 1.57695 7.32677 1.62043C7.3764 1.6639 7.41694 1.71678 7.44605 1.776L10.446 7.776C10.4809 7.84553 10.499 7.92223 10.499 8C10.499 8.07777 10.4809 8.15447 10.446 8.224L7.44605 14.224C7.38664 14.3423 7.28274 14.4322 7.15714 14.474C7.03155 14.5158 6.8945 14.5061 6.77605 14.447Z"
                              fill="#182316"/>
                    </svg>
                </div>
            </div>

            <OsusuvaciList
                brands={props.data.brands}
                setActive={props.setActive}
                subcategories={props.data.children}
                slug={props.data.slug}
                optCategory={props.data.opt_category}
            />
        </li>
    );
};

export default Osusuvaci;
