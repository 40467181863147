import ReactGA from 'react-ga';

export const analyticEvents = {
    buyOneClick: () => ReactGA.event({
        category: 'Order',
        action: 'buy-one-click',
        label: 'купити в один клік',
    }),
    addWishlist: () => ReactGA.event({
        category: 'Order',
        action: 'add-favourite',
        label: 'додати в обране',
    }),
    subscribeToNews: () => ReactGA.event({
        category: 'Subscribe',
        action: 'subscribe',
        label: 'підписка на новини',
    }),

    // Додавання товару до кошика
    addBasket: (productInfo, pageType) => {
        ReactGA.event({
            category: 'Order',
            action: 'add-basket',
            label: 'додати в кошик',
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'add_to_cart',
            'ecommerce': {
                'items': [
                    {
                        'item_name': String(productInfo['name']),
                        'item_id': String(productInfo['id']),
                        'price': String(productInfo['final_price']),
                        'currency': 'UAH',
                        'item_brand': String(productInfo['brand']['name']),
                        'item_category': String(productInfo['category']['name']),
                        'item_list_name': pageType,
                        'index': 1,
                        'quantity': String(productInfo['quantity']),
                    }]
            }
        });

        window.fbq('track', 'AddToCart', {
            content_type: 'product',
            content_ids: [String(productInfo['id'])],
            content_name: String(productInfo['name']),
            content_category: String(productInfo['category']['name']),
            value: productInfo['final_price'],
            currency: 'UAH'
        });
    },

    // Додавання сервісу до кошика
    addServiceBasket: (serviceInfo, pageType) => {
        // Підготовка данних для передачі в аналітику
        var items = [];
        var index = 1;

        // Сервіси
        serviceInfo.forEach(function(itemService) {
            items.push({
                'item_name': String(itemService.name),
                'item_id': String(itemService.id),
                'price': String(itemService.price),
                'currency': 'UAH',
                'item_list_name': 'Basket',
                'index': index,
                'quantity': '1',
            });
            index = index+1;
        });

        if(items.length > 0) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'add_to_cart',
                'ecommerce': {
                    'items': items
                }
            });
        }
    },

    // Видалення товару з кошика
    removeBasket: (productInfo) => {
        // Підготовка данних для передачі в аналітику
        var items = [];
        var index = 1;

        // Сервіси
        productInfo.new_services.forEach(function(itemService) {
            items.push({
                'item_name': String(itemService.name),
                'item_id': String(itemService.id),
                'price': String(itemService.price),
                'currency': 'UAH',
                'item_list_name': 'Basket',
                'index': index,
                'quantity': '1',
            });
            index = index+1;
        });

        // Товар
        items.push({
            'item_name': String(productInfo['name']),
            'item_id': String(productInfo['id']),
            'price': String(productInfo['final_price']),
            'currency': 'UAH',
            'item_brand': String(productInfo['brand']['name']),
            'item_category': String(productInfo['category']['name']),
            'item_list_name': 'Basket',
            'index': 1,
            'quantity': String(productInfo['quantity']),
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'remove_from_cart',
            'ecommerce': {
                'items': items
            }
        });
    },

    // Видалення сервісу з кошика
    removeServiceBasket: (serviceInfo) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'remove_from_cart',
            'ecommerce': {
                'items': [
                    {
                        'item_name': String(serviceInfo.name),
                        'item_id': String(serviceInfo.id),
                        'price': String(serviceInfo.price),
                        'currency': 'UAH',
                        'item_list_name': 'Basket',
                        'index': 1,
                        'quantity': '1',
                    }
                ]
            }
        });
    },

    successOrder: () => ReactGA.event({
       category: 'Order',
       action: 'order-product',
       label: 'замовлення товару',
    }),

    // Перегляд детальної карточки товару
    showProductDetail: (productInfo) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'view_item',
            'ecommerce': {
                'items': [
                    {
                        'item_name': String(productInfo['name']),
                        'item_id': String(productInfo['id']),
                        'price': String(productInfo['final_price']),
                        'currency': 'UAH',
                        'item_brand': String(productInfo['brand']['name']),
                        'item_category': String(productInfo['category']['name']),
                        'item_list_name': 'Detail',
                    }
                ]
            }
        });

        window.gtag('event','view_item', {
            'send_to': 'AW-10978254399',
            'value': productInfo['final_price'],
            'items': [{
                'id': productInfo['id'],
                'google_business_vertical': 'retail'
            }]
        });

        window.fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: [String(productInfo['id'])],
            content_name: String(productInfo['name']),
            content_category: String(productInfo['category']['name']),
            value: productInfo['final_price'],
            currency: 'UAH'
        });
    },

    // Перегляд розділу каталога
    showCategoryCatalog: (categoryInfo) => {
        window.gtag('event','view_item_list', {
            'send_to': 'AW-10978254399',
        });
    },

    // Сторінка результатів пошуку товарів
    showSearchPage: () => {
        window.gtag('event','view_search_results', {
            'send_to': 'AW-10978254399',
        });
    },

    // Початок оформлення замовлення
    beginOrder: (basket) => {
        // Підготовка данних для передачі в аналітику
        var basketItems = [];
        var gtagItems = [];
        var gtagValue = [];
        var fbqItems = [];
        var fbqValue = 0;
        var fbqQuantity = 0;
        var index = 1;
        basket.forEach(function(item) {
            // Сервіси
            item.new_services.forEach(function(itemService) {
                basketItems.push({
                    'item_name': String(itemService.name),
                    'item_id': String(itemService.id),
                    'price': String(itemService.price),
                    'currency': 'UAH',
                    'item_list_name': 'Order',
                    'index': index,
                    'quantity': '1',
                });
                index = index+1;
            });

            // Звичайні товари
            basketItems.push({
                'item_name': String(item.name),
                'item_id': String(item.id),
                'price': String(item.final_price),
                'currency': 'UAH',
                'item_brand': String(item.brand.name),
                'item_category': String(item.category.name),
                'item_list_name': 'Order',
                'index': index,
                'quantity': String(item.quantity),
            });
            index = index+1;

            gtagValue.push(item.final_price);
            gtagItems.push({
                'id': String(item.id),
                'google_business_vertical': 'retail'
            });

            fbqItems.push(String(item.id));
            fbqValue = fbqValue+item.final_price;
            fbqQuantity = fbqQuantity+item.quantity;
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'begin_checkout',
            'ecommerce': {
                'items': basketItems
            }
        });

        window.gtag('event','add_to_cart', {
            'send_to': 'AW-10978254399',
            'value': gtagValue,
            'items': gtagItems,
        });

        window.fbq('track', 'InitiateCheckout', {
            content_ids: [fbqItems],
            content_name: 'Початок оформлення замовлення',
            content_category: 'Початок оформлення замовлення',
            value: fbqValue,
            currency: 'UAH',
            num_items: fbqQuantity,
        });
    },

    // Кошик
    basket: (basket) => {
        // Підготовка данних для передачі в аналітику
        var gtagItems = [];
        var gtagValue = [];
        basket.forEach(function(item) {
            gtagValue.push(item.final_price);
            gtagItems.push({
                'id': String(item.id),
                'google_business_vertical': 'retail'
            });
        });

        window.gtag('event','add_to_cart', {
            'send_to': 'AW-10978254399',
            'value': gtagValue,
            'items': gtagItems,
        });
    },

    // Створення замовлення
    doOrder: (order) => {
        // Підготовка данних для передачі в аналітику
        var orderItems = [];
        var index = 1;
        order.products.forEach(function(item) {
            // Сервіси
            item.services.forEach(function(itemService) {
                orderItems.push({
                    'item_name': String(itemService.service_name),
                    'item_id': String(itemService.id),
                    'price': String(itemService.price),
                    'currency': 'UAH',
                    'item_list_name': 'Order',
                    'index': index,
                    'quantity': '1',
                });
                index = index+1;
            });

            // Звичайні товари
            orderItems.push({
                'item_name': String(item.product_name),
                'item_id': String(item.id),
                'price': String(item.price_per_item),
                'currency': 'UAH',
                'item_brand': String(item.brand),
                'item_category': String(item.category),
                'item_list_name': 'Order',
                'index': index,
                'quantity': String(item.quantity),
            });
            index = index+1;
        });

        // Вибір доставки
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'add_shipping_info',
            'ecommerce': {
                'shipping_tier': String(order.delivery_method_human),
                'value': order.total_amount,
                'currency': 'UAH',
                'items': orderItems,
            }
        });

        // Вибір оплати
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'add_payment_info',
            'ecommerce': {
                'payment_type': String(order.payment_method_human),
                'value': order.total_amount,
                'currency': 'UAH',
                'items': orderItems,
            }
        });

        // Підготовка данних для передачі в аналітику
        orderItems = [];
        order.products.forEach(function(item) {
            // Сервіси
            item.services.forEach(function(itemService) {
                orderItems.push({
                    'item_name': String(itemService.service_name),
                    'item_id': String(itemService.id),
                    'price': String(itemService.price),
                    'currency': 'UAH',
                    'quantity': '1',
                });
            });

            // Звичайні товари
            orderItems.push({
                'item_name': String(item.product_name),
                'item_id': String(item.id),
                'price': String(item.price_per_item),
                'currency': 'UAH',
                'item_brand': String(item.brand),
                'item_category': String(item.category),
                'quantity': String(item.quantity),
            });
        });

        // Оформлення замовлення
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'purchase',
            'ecommerce': {
                'transaction_id': String(order.id),
                'value': order.total_amount,
                'currency': 'UAH',
                'tax': 0,
                'shipping': 0,
                'items': orderItems,
            }
        });

        // Підготовка данних для передачі в аналітику
        orderItems = [];
        var gtagItems = [];
        var gtagValue = [];
        var fbqItems = [];
        var fbqValue = 0;
        var fbqQuantity = 0;
        order.products.forEach(function(item) {
            // Сервіси
            item.services.forEach(function(itemService) {
                orderItems.push({
                    'sku': String(itemService.id),
                    'name': String(itemService.service_name),
                    'price': String(itemService.price),
                    'quantity': '1',
                });
            });

            // Звичайні товари
            orderItems.push({
                'sku': String(item.id),
                'name': String(item.product_name),
                'category': String(item.category),
                'price': String(item.price_per_item),
                'quantity': String(item.quantity),
            });

            gtagValue.push(item.price_per_item*item.quantity);
            gtagItems.push({
                'id': String(item.id),
                'google_business_vertical': 'retail'
            });

            fbqItems.push(String(item.id));
            fbqValue = fbqValue+item.price_per_item*item.quantity;
            fbqQuantity = fbqQuantity+item.quantity;
        });

        // Успішне оформлення замовлення
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'currencyCode': 'UAH',
            'event': 'transactionComplete',
            'transactionId': String(order.id),
            'transactionAffiliation': 'lika.net.ua',
            'transactionTax': '0',
            'transactionShipping': '0',
            'transactionTotal': String(order.total_amount),
            'transactionProducts': orderItems,
        });

        window.gtag('event','purchase', {
            'send_to': 'AW-10978254399',
            'value': gtagValue,
            'items': gtagItems,
        });

        window.fbq('track', 'Purchase', {
            content_ids: [fbqItems],
            content_type: 'product',
            content_name: 'Успішне оформлення замовлення',
            content_category: 'Успішне оформлення замовлення',
            value: fbqValue,
            currency: 'UAH',
            num_items: fbqQuantity,
        });
    },
};
