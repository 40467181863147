import React from 'react'

const CatalogHeader = (props) => {

    function changeSort(newSort) {
        if(newSort !== props.sortName) {
            var newUrlFilters = props.urlFilters;
            newUrlFilters = newUrlFilters.replace(/\/page=(\d+)/g, '');
            if(newUrlFilters.search(/\/sort=(popularity|expensive|cheap|discount)/g) >= 0) {
                if(newSort == 'expensive') newUrlFilters = newUrlFilters.replace(/\/sort=(popularity|expensive|cheap|discount)/g, '');
                else newUrlFilters = newUrlFilters.replace(/\/sort=(popularity|expensive|cheap|discount)/g, '/sort='+newSort);
            }
            else {
                var url = newUrlFilters.split('?');
                var filter = url[0].split('/');
                filter.shift();
                var slug = filter.shift();
                filter.push('sort='+newSort);
                newUrlFilters = '/'+slug+'/'+filter.sort().join('/');
                if(url[1]) newUrlFilters = newUrlFilters+'?'+url[1];
            }
            props.setUrlFilters(newUrlFilters)
        }
    }

    return (
        <div className="catalog__header">
            <div className="catalog__sort">
                <div className="catalog__sort-title">Сортувати:</div>
                <div
                    className={`catalog__sort-item ${props.sortName === 'popularity'? 'active' : ''}`}
                    onClick={() => {
                        changeSort('popularity')
                    }}>
                    за популярністю
                </div>
                <div
                    className={`catalog__sort-item ${props.sortName === 'expensive' ? 'active' : ''}`}
                    onClick={() => {
                        changeSort('expensive')
                    }}>
                    за зростанням ціни
                </div>
                <div
                    className={`catalog__sort-item ${props.sortName === 'cheap' ? 'active' : ''}`}
                    onClick={() => {
                        changeSort('cheap')
                    }}>
                    за зменшенням ціни
                </div>
                <div
                    className={`catalog__sort-item ${props.sortName === 'discount'? 'active' : ''}`}
                    onClick={() => {
                        changeSort('discount')
                    }}>
                    акційні
                </div>
            </div>
        </div>
    )
}

export default CatalogHeader