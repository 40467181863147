import React from 'react'
import {_images} from "../../../API";

const ProductPagePhotoSliderDemo = (props) => {

    return (
        <div className="product-demo__slide-item">
            <img src={`${_images}${props.photo}`} alt="" />
        </div>
    )
}

export default ProductPagePhotoSliderDemo