import {SEARCH_PRODUCTS, SET_SEARCH_URL_FILTERS} from "../actionConst/action";

export const searchProducts = payload => {
    return {
        type: SEARCH_PRODUCTS,
        payload
    }
}

export const setSearchUrlFilters = payload => {
    return {
        type: SET_SEARCH_URL_FILTERS,
        payload
    }
}