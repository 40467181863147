import React, {Fragment, useState} from 'react'
import JsonLd from '../JsonLd/JsonLd';
import BreadCrumbsItem from "./BreadCrumbsItem";
import {_domain} from "../../../API";

import './BreadCrumbs.scss'

const BreadCrumbs = ({items}) => {
    const [isShow, setIsShow] = useState(false);

    const isShowClick = () => {
        setIsShow(!isShow);
    };

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'BreadCrumbList',
        'itemListElement': items?.map((item, index) => ({
            '@type': 'ListItem',
            'position': index+1,
            'item': {
                '@id': typeof item.link !== 'undefined' ? _domain+item.link : _domain+window.location.pathname,
                'name': item.name,
            },
        })),
    };

    return (
        <div className="bread-crumbs">
            <JsonLd data={jsonLd} />
            {
                items?.map((data, i) => {
                    return (
                        <Fragment key={i}>
                            {
                                i == items.length-1 ? (
                                    <span className={isShow ? 'bread-crumbs__dots show' : 'bread-crumbs__dots'} onClick={isShowClick}>...</span>
                                ) : (
                                    <></>
                                )
                            }
                            <BreadCrumbsItem data={data}/>
                        </Fragment>
                    )
                })
            }
        </div>
    );
};

export default BreadCrumbs