import {
    CHANGE_USER_EMAIL,
    CHANGE_USER_LASTNAME, CHANGE_USER_LOCATION,
    CHANGE_USER_NAME, CHANGE_USER_PASSWORD, CHANGE_USER_PHONE,
    USER_LOGIN,
    USER_LOGOUT
} from "../../actionConst/action";
import {GET_USER_DATA} from "../../actionConst/getActions";

const initialState = {
    userData: {orders: []},
    info: {
        isLogged: false,
        name: '',
        phone: '',
        lastName: '',
        email: '',
        userLocation: '',
    },
    orders: [
        {id: '03004', date: '01.02.2020', cost: 20000,
            products: [
                {id: '11234', quantity: 2, name: 'Кондиціонер AirCool — G-07HHK',
                    services: [
                        {name: 'Розширена гарантія (+2 роки)'},
                        {name: 'Монтаж'},
                    ],
                },
            ],
        },
        {id: '02004', date: '01.02.2020', cost: 40000,
            products: [
                {id: '11234', quantity: 2, name: 'Кондиціонер AirCool 1',
                    services: [
                        {name: 'Монтаж'},
                    ],
                },
            ],
        },
    ],
}

export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            }
        case USER_LOGIN:
            return {
                ...state,
                info: {
                    ...state.info,
                    isLogged: action.payload,
                }
            }
        case USER_LOGOUT:
            return {
                ...state,
                info: {
                    ...state.info,
                    isLogged: false,
                }
            }
        case CHANGE_USER_NAME:
            return {
                ...state,
                info: {
                    ...state.info,
                    name: action.payload
                }
            }
        case CHANGE_USER_PHONE:
            return {
                ...state,
                info: {
                    ...state.info,
                    phone: action.payload
                }
            }
        case CHANGE_USER_LASTNAME:
            return {
                ...state,
                info: {
                    ...state.info,
                    lastName: action.payload
                }
            }
        case CHANGE_USER_EMAIL:
            return {
                ...state,
                info: {
                    ...state.info,
                    email: action.payload
                }
            }
        case CHANGE_USER_LOCATION:
            return {
                ...state,
                info: {
                    ...state.info,
                userLocation: action.payload,
                }
            };
        case CHANGE_USER_PASSWORD:
            return {
                ...state,
                password: action.payload
            }
            default:
                return state;
    }
}