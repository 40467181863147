import {
    createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from '../redux/reducers/rootReducer'

let store;

if(process.env.NODE_ENV === 'development') {
    store = createStore(
        rootReducer,
        composeWithDevTools()
    );
} else {
    store = createStore(rootReducer);
}
store.subscribe(() => {
    if(store.getState().basketReducer.basket.length > 0) {
        localStorage.setItem('basket', JSON.stringify(store.getState().basketReducer.basket))
    }
    if(store.getState().compareReducer.compare.length > 0) {
        localStorage.setItem('compare', JSON.stringify(store.getState().compareReducer.compare))
    }
})

export default store
