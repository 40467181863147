import React from 'react'

import './MainServices.scss'
import {Link} from "react-router-dom";

import service1 from '../../../img/main-page/services/1.webp'
import service2 from '../../../img/main-page/services/2.webp'
import service3 from '../../../img/main-page/services/3.webp'
import service4 from '../../../img/main-page/services/4.webp'
import service5 from '../../../img/main-page/services/5.webp'
import service6 from '../../../img/main-page/services/6.webp'

const MainServices = () => {

    return (
        <div className="services-wrap">
            <h3 className="main-page__title">Наші послуги</h3>
            <section className="services">
                <Link className="services__item services__item--big" to='/services_page/montazh_kondytsioneriv'>
                    <img className="services__img" src={service1} alt='Монтаж кондиціонерів'/>
                    <div className="services__title-box">
                        <div className="services__title-box-bg">
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect className="static-block" opacity="0.9" y="114" width="190"
                                      height="136" fill="#43B02A"/>
                                <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col1 cube" opacity="0.9" y="38" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row1 col1 cube" opacity="0.9" y="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col3 cube" opacity="0.9" x="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                            </svg>
                        </div>
                        <h4 className="services__title">Монтаж кондиціонерів</h4>
                    </div>
                </Link>
                <Link className="services__item services__item--big services__item--center-bg" to='/services_page/servisne_obsluhovuvannia'>
                    <img className="services__img" src={service6} alt='Сервісне обслуговування'/>
                    <div className="services__title-box services__title-box--white">
                        <div className="services__title-box-bg">
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.9">
                                    <rect className="static-block" opacity="0.9" y="114" width="190"
                                          height="136" fill="white"/>
                                    <rect className="row3 col1 cube" opacity="0.9" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row2 col1 cube" opacity="0.9" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col1 cube" opacity="0.9" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row2 col2 cube" opacity="0.9" x="38" y="38"
                                          width="38" height="38" fill="white"/>
                                    <rect className="row1 col2 cube" opacity="0.9" x="38" y="76"
                                          width="38" height="38" fill="white"/>
                                    <rect className="row3 col3 cube" opacity="0.9" x="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col3 cube" opacity="0.9" x="76" y="76"
                                          width="38" height="38" fill="white"/>
                                    <rect className="row2 col4 cube" opacity="0.9" x="114" y="38"
                                          width="38" height="38" fill="white"/>
                                    <rect className="row1 col4 cube" opacity="0.9" x="114" y="76"
                                          width="38" height="38" fill="white"/>
                                    <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38"
                                          width="38" height="38" fill="white"/>
                                    <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76"
                                          width="38" height="38" fill="white"/>
                                </g>
                            </svg>
                        </div>
                        <h4 className="services__title">Сервісне обслуговування</h4>
                    </div>
                </Link>
                <Link className="services__item services__item--middle" to='/services_page/montazh_teplovoho_obladnannia'>
                    <img className="services__img" src={service3} alt='онтаж теплового обладнання'/>
                    <div className="services__title-box services__title-box--white">
                        <div className="services__title-box-bg">
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.9">
                                    <rect className="static-block" opacity="0.9" y="114" width="190"
                                          height="136" fill="white"/>
                                    <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                          fill="white"/>
                                    <rect className="row2 col1 cube" opacity="0.9" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col1 cube" opacity="0.9" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row3 col3 cube" opacity="0.9" x="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                          height="38" fill="white"/>
                                </g>
                            </svg>
                        </div>
                        <h4 className="services__title">Монтаж теплового обладнання</h4>
                    </div>
                </Link>
                <Link className="services__item services__item--middle" to='/services_page/montazh_ventyliatsii'>
                    <img className="services__img" src={service2} alt='Монтаж вентиляції'/>
                    <div className="services__title-box services__title-box--white">
                        <div className="services__title-box-bg">
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.9">
                                    <rect className="static-block" opacity="0.9" y="114" width="190"
                                          height="136" fill="white"/>
                                    <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                          fill="white"/>
                                    <rect className="row2 col1 cube" opacity="0.9" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col1 cube" opacity="0.9" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row3 col3 cube" opacity="0.9" x="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                          height="38" fill="white"/>
                                    <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                          height="38" fill="white"/>
                                </g>
                            </svg>
                        </div>
                        <h4 className="services__title">Монтаж вентиляції</h4>
                    </div>
                </Link>
                <div className="services__item-wrap">
                    <Link className="services__item services__item--small services__item--center-bg" to='/services_page/proektuvannia_system'>
                    <img className="services__img" src={service5} alt='Проектування систем'/>
                    <div className="services__title-box">
                        <div className="services__title-box-bg">
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect className="static-block" opacity="0.9" y="114" width="190"
                                      height="136" fill="#43B02A"/>
                                <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col1 cube" opacity="0.9" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col1 cube" opacity="0.9" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col3 cube" opacity="0.9" x="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                            </svg>
                        </div>
                        <h4 className="services__title">Проектування систем</h4>
                    </div>
                </Link>
                <Link className="services__item services__item--small services__item--center-bg" to='/services_page/montazh_tsentralnykh_pylososiv'>
                    <img className="services__img" src={service4} alt='Монтаж центральних пилососів'/>
                    <div className="services__title-box">
                        <div className="services__title-box-bg">
                            <svg width="190" height="250" viewBox="0 0 190 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect className="static-block" opacity="0.9" y="114" width="190"
                                      height="136" fill="#43B02A"/>
                                <rect className="row3 col1 cube" opacity="0.9" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col1 cube" opacity="0.9" y="38" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row1 col1 cube" opacity="0.9" y="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row2 col2 cube" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col2 cube" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col3 cube" opacity="0.9" x="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="row1 col3 cube" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row2 col4 cube" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row1 col4 cube" opacity="0.9" x="114" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="row3 col5 cube" opacity="0.9" x="152" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c5 cube1" opacity="0.9" x="150" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r1 c1 cube1" opacity="0.9" y="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r2 c2 cube1" opacity="0.9" x="38" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c2 cube1" opacity="0.9" x="38" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r3 c3 cube1" opacity="0.9" x="76" width="38" height="38"
                                      fill="#43B02A"/>
                                <rect className="r1 c3 cube1" opacity="0.9" x="76" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r2 c4 cube1" opacity="0.9" x="114" y="38" width="38"
                                      height="38" fill="#43B02A"/>
                                <rect className="r1 c5 cube1" opacity="0.9" x="152" y="76" width="38"
                                      height="38" fill="#43B02A"/>
                            </svg>
                        </div>
                        <h4 className="services__title">Монтаж центральних пилососів</h4>
                    </div>
                </Link>
                </div>
            </section>
        </div>
    )
}

export default MainServices