import React, {useState} from 'react'
import np from "../../../../../img/order-page/np.svg";
import arrow from "../../../../../img/arrow-down-18.svg";

const OrderBodyDataDeliveryNpAddress = props => {

    const [active, setActive] = useState(false)
    const [streetValue, setStreetValue] = useState('')
    const [houseValue, setHouseValue] = useState('')
    const [apartmantValue, setApartmamValue] = useState('')
    const [floorValue, setFloorValue] = useState('')

    return (
        <>
            <label className="radio-wrap">
                <input
                    id='npBranch'
                    type="radio"
                    name="post"
                    checked={props.activeRadio === 'npBranch'}
                    onChange={props.openSubSelect}
                    onClick={() => {
                        props.addDeliveryCost(0)
                        props.addDeliveryWay(2)
                    }}
                />
                <span className="radio">
                        <img src={np} alt=""/>
                        Адресна доставка
                        <span className="order-data__post">
                            Нової Пошти
                        </span>
                    </span>
                <span className="order-data__delivery-price">
                    За тарифом
                </span>
            </label>
            <div className={`block-input ${props.activeRadio === 'npBranch'? 'active' : ''}`}>
                <div
                    className={`order-data__dropdown-selector order-data__dropdown-selector--city ${active? 'active' : ''}`}
                    style={{borderColor: !props.empty || props.delivery? '' : 'red'}}
                    onMouseLeave={() => {
                        setActive(false)
                    }}
                >
                    <input
                        className="order-data__select-input"
                        type="text" placeholder="Ваше місто"
                        value={props.delivery}
                        onClick={() => {
                            setActive(true)
                        }}
                        onChange={e => {
                            props.chooseCity(e.target.value)
                            props.getEnterCity(props.delivery)
                            props.setEmpty(false)
                        }}
                    />
                    <img className="arrow-down" src={arrow} alt=""/>
                    <ul className={`order-data__dropdown ${active? 'active' : ''}`}>
                        {
                            props.cityList.map((data, i) => {
                                return (
                                    <li className="order-data__dropdown-item" key={i} onClick={() => {
                                        props.chooseCity(data.Present)
                                        props.addDeliveryCity(data.Present)
                                        props.setCity(data.MainDescription)
                                        setActive(false)
                                    }}>
                                        {data.Present}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div 
                    className={`inline-input  ${props.activeRadio === 'npBranch'? 'active' : ''}`}
                    
                >
                    <label className="form__label street">
                        <input
                            style={{borderColor: !props.empty || streetValue ? '' : 'red'}}
                            className="form__input"
                            type="text"
                            placeholder=" "
                            required
                            value={streetValue}
                            onChange={e => {
                                props.addDeliveryStreet(e.target.value)
                                setStreetValue(e.target.value)
                                props.setEmpty(false)
                            }}
                        />
                        <span className="standart">
                        Вулиця
                    </span>
                    </label>
                    <label className="form__label building">
                        <input
                            style={{borderColor: !props.empty || houseValue ? '' : 'red'}}
                            className="form__input"
                            type="text"
                            value={houseValue}
                            placeholder=" "
                            required
                            onChange={e => {
                                props.addDeliveryHouse(e.target.value)
                                setHouseValue(e.target.value)
                                props.setEmpty(false)
                            }}
                        />
                        <span className="standart">
                        Дім
                    </span>
                    </label>
                    <label className="form__label apartment">
                        <input
                            style={{borderColor: !props.empty || apartmantValue ? '' : 'red'}}
                            className="form__input"
                            type="text"
                            placeholder=" "
                            required
                            onChange={e => {
                                props.addDeliveryAt(e.target.value)
                                setApartmamValue(e.target.value)
                                props.setEmpty(false)
                            }}
                        />
                        <span className="standart">
                        Кв.
                    </span>
                    </label>
                    <label className="form__label floor">
                        <input
                            style={{borderColor: !props.empty || floorValue ? '' : 'red'}}
                            className="form__input"
                            type="text"
                            placeholder=" "
                            required
                            onChange={e => {
                                props.addDeliveryFloor(e.target.value)
                                setFloorValue(e.target.value)
                                props.setEmpty(false)
                            }}
                        />
                        <span className="standart">
                        Поверх
                    </span>
                    </label>
                </div>
            </div>
        </>
    )
}

export default OrderBodyDataDeliveryNpAddress
