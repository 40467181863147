import React from 'react'

import BlogBodyItem from "./BlogBodyItem";

const BlogBody = (props) => {

    return (
        <>
            <div className="blog__body">
                {
                    props.articles.map((data, i) => {
                        return (
                            <BlogBodyItem data={data} key={i}/>
                        )
                    })
                }
            </div>
        </>
    )
}

export default BlogBody