import React from 'react'

const BasketModalFalse = () => {

    return (
        <>
            <h3 className="modal-basket__title">Кошик</h3>
            <div className="modal-basket__body modal-basket__body--empty">
                <p className="modal-basket__body-title">Ваш кошик порожній!</p>
                <p className="modal-basket__body-subtitle">Додайте товари до кошику зі сторінки каталогу чи сторінки
                    товару</p>
            </div>
        </>
    )
}

export default BasketModalFalse