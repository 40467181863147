import React from 'react'
import Slider from 'react-slick'

import '../../../../Main/MainPartner/MainPartners.scss'
import './PartnersSlider.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {Link} from "react-router-dom";
import MainPartnerPrev from "../../../../Main/MainPartner/MainPartnerPrev";
import MainPartnerNext from "../../../../Main/MainPartner/MainPartnerNext";
import {_images} from "../../../../../API";


const PartnersSlider = (props) => {

    const settings = {
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 3,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        waitForAnimate: true,
        className: 'brands-slider',
        prevArrow: <MainPartnerPrev />,
        nextArrow: <MainPartnerNext />,
        responsive: [
            {
                breakpoint: 1384,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
        ]
    }

    return (
        <div className="slider-container">
            <div className="brands-slider-wrap">
                <Slider {...settings}>
                    {
                        props.partners.map((data, i) => {
                            return (
                                <Link to={`/catalog_brands/${data.id}`} key={i} className="brands-slider__item" onClick={() => {
                                    props.setActive(false)
                                }}>
                                    <img src={`${_images}${data.image}`} alt={data.name}/>
                                </Link>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default PartnersSlider