import React from 'react'
import {Link} from 'react-router-dom';
import HvacList from './HvacList';
import hvacimg from "../../../../../../img/hvac.svg";

const Hvac = props => {
    return (
        <li className={`catalog-lv1__item ${props.activeCatalog.slug === props.data.slug ? 'active' : ''} ${props.activeCatalog.name === 'Каталог' ? '' : 'hidden-catalog'}`}
            onClick={() => {
                props.setActiveCatalog(props.data)
            }}>
            <div className="catalog-lv1__button">
                <div className="catalog-lv1__ico-box">
                    <img src={hvacimg}/>
                </div>

                <div
                    className="catalog-lv1__item-name"
                    onClick={() => props.desktop ? props.setActive(false) : ''}
                >
                    <Link to={`/catalog/${props.data.slug}`}>
                        {props.data.name}
                    </Link>
                </div>

                <div className="catalog-lv1__arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.77605 14.447C6.65776 14.3876 6.56786 14.2837 6.52606 14.1581C6.48425 14.0325 6.49396 13.8955 6.55305 13.777L9.44005 8L6.55205 2.224C6.52203 2.16524 6.50394 2.10111 6.49882 2.03533C6.4937 1.96954 6.50166 1.90339 6.52222 1.84069C6.54279 1.77799 6.57557 1.71998 6.61866 1.67001C6.66175 1.62003 6.71431 1.57909 6.77331 1.54952C6.8323 1.51996 6.89656 1.50237 6.96239 1.49776C7.02822 1.49315 7.0943 1.50162 7.15684 1.52267C7.21938 1.54372 7.27713 1.57695 7.32677 1.62043C7.3764 1.6639 7.41694 1.71678 7.44605 1.776L10.446 7.776C10.4809 7.84553 10.499 7.92223 10.499 8C10.499 8.07777 10.4809 8.15447 10.446 8.224L7.44605 14.224C7.38664 14.3423 7.28274 14.4322 7.15714 14.474C7.03155 14.5158 6.8945 14.5061 6.77605 14.447Z"
                            fill="#182316"/>
                    </svg>
                </div>
            </div>

            <HvacList
                brands={props.data.brands}
                setActive={props.setActive}
                subcategories={props.data.children}
                slug={props.data.slug}
                optCategory={props.data.opt_category}
            />
        </li>
    );
};

export default Hvac;
