import React, {useEffect, useState} from 'react'
import BreadCrumbs from '../globalComponents/BreadCrumbs/BreadCrumbs';
import ProductsSlider from '../globalComponents/ProductsSlider/ProductsSlider';
import {connect} from 'react-redux'
import ArticleSlider from '../globalComponents/ArticleSlider/ArticleSlider';
import {getCasesArticle} from '../../redux/actionCreators/getCreators/getCases';
import bg1 from '../../img/cases/case-article-bg1.svg'
import bg2 from '../../img/cases/case-article-bg2.svg'
import callback from '../../img/cases/callback.svg'
import './CasesPage.scss'
import ScrollToTopOnMount from '../../ScrolToTop';
import {_api, _images} from '../../API';
import {
    toggleModal,
    toggleCallMeBack,
} from '../../redux/actionCreators/modalsCreator';
import Preloader from '../globalComponents/Preloader/Preloader';
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";

const CasesPage = props => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        window.axios.get(`${_api}/works/${props.match.params.slug}`)
            .then(res => props.getCasesArticle(res.data.data))
            .finally(() => {
                setLoading(false)
            })
    }, []);

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: 'Наші роботи', link: '/cases'},
        {name: props.article.title},
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount/>
            <MetaTags>
                <title>{`Наші роботи з ${props.article.title}. Зразки робіт зі встановлення, чи обслуговування систем кондиціонування, енергозбереження чи вентиляції.`}</title>
                <meta name="description" content={`Портфоліо ☆ наших робіт ☆ зі встановлення, чи обслуговування систем ✔️кондиціонування, ✔️енергозбереження чи ✔️ вентиляція. ❄❄❄ Наша робота ${props.article.title}.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`Наші роботи з ${props.article.title}. Зразки робіт зі встановлення, чи обслуговування систем кондиціонування, енергозбереження чи вентиляції.`} />
                <meta property="og:description" content={`Портфоліо ☆ наших робіт ☆ зі встановлення, чи обслуговування систем ✔️кондиціонування, ✔️енергозбереження чи ✔️ вентиляція. ❄❄❄ Наша робота ${props.article.title}.`} />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={`${_images}${props.article.main_image}`} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="case-article">
                    <div className="container">
                        <BreadCrumbs items={crumbs}/>
                    </div>
                    {
                        loading ? (
                            <Preloader/>
                        ) : (
                            <>
                                <div className="case-header">
                                    <div className="case-header__title-section">
                                        <div className="case-header__title-section-bg">
                                            <img className="case-header__bg-img-left" src={bg1} alt=""/>
                                            <img className="case-header__bg-img-right" src={bg2} alt=""/>
                                        </div>
                                        <h1 className="case-header__title">{props.article.title}</h1>
                                    </div>
                                    <div className="case-header__img-section">
                                        <img src={`${_images}${props.article.main_image}`} alt=""/>
                                    </div>
                                </div>
                                <div className="container">
                                    <article className="case-article__body"
                                             dangerouslySetInnerHTML={{__html: [props.article.text]}}/>
                                    <div className="photos-section-wrap">
                                        {
                                            props.article.images ? <ArticleSlider slider={props.article.images}/> : ''
                                        }
                                    </div>
                                    {
                                        props.article.products.length > 0 ? (
                                            <div className="slider-container slider-container--product">
                                                <ProductsSlider
                                                    name={'Товари цього рішення'}
                                                    products={props.article.products}
                                                />
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </>
                        )
                    }
                    <div className="callback-container">
                        <div className="container">
                            <h4 className="callback-container__title">Цікавить пропозиція?<br/>Залиште заявку на
                                консультацію</h4>
                            <div className="callback-container__call-wrap">
                                <div className="callback-container__img">
                                    <img src={callback} alt="Заявка на консультацію"/>
                                </div>
                                <button className="callback-container__sumbit btn-dark" onClick={() => {
                                    props.toggleModal(true)
                                    props.toggleCallMeBack(true)
                                }}>Залишити заявку
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
const mapStateToProps = (store) => {
    return {
        productsNew: store.newProductsSliderReducer.newProducts,
        feedbacks: store.productPageReducer.feedbacks,
        slider: store.casesReducer.slider,
        article: store.casesReducer.article,
    }
}

const mapDispatchToProps = {
    getCasesArticle,
    toggleModal,
    toggleCallMeBack,
}

export default connect(mapStateToProps, mapDispatchToProps)(CasesPage)
