import {ADD_COMPARE, CLEAN_COMPARE, RELOAD_COMPARE, REMOVE_COMPARE} from "../actionConst/action";


export const addCompare = (payload) => {
    return {
        type: ADD_COMPARE,
        payload: payload
    }
}

export const removeCompare = (payload) => {
    return {
        type: REMOVE_COMPARE,
        payload: payload
    }
}

export const reloadCompare = payload => {
    return {
        type: RELOAD_COMPARE,
        payload
    }
}

export const cleanCompare = () => {
    return {
        type: CLEAN_COMPARE
    }
}