import {
    TOGGLE_BASKET_MODAL,
    TOGGLE_BUY_ONE_CLICK, TOGGLE_BUY_ONE_CLICK_MOBILE, TOGGLE_CALL_ME_BACK, TOGGLE_FEEDBACK_MODAL,
    TOGGLE_FEEDBACK_SUCCESS,
    TOGGLE_FORGOT, TOGGLE_FORGOT_SUCCESS,
    TOGGLE_LOG, TOGGLE_LOGIN, TOGGLE_LOGIN_SUCCESS,
    TOGGLE_MODAL, TOGGLE_ORDER_CONFIRM, TOGGLE_PHONE_MODAL,
    TOGGLE_PRODUCT_SLIDER_MODAL,
    TOGGLE_QUIZ, TOGGLE_REG, TOGGLE_REG_SUCCESS, TOGGLE_RESET_PASSWORD_MODAL,
    TOGGLE_SORT_MODAL, TOGGLE_SUBSCRIBE_MODAL
} from "../actionConst/action";

export const toggleModal = payload => {
    return {
        type: TOGGLE_MODAL,
        payload
    }
}
export const toggleQuiz = payload => {
    return {
        type: TOGGLE_QUIZ,
        payload
    }
}
export const toggleProductSliderModal = payload => {
    return {
        type: TOGGLE_PRODUCT_SLIDER_MODAL,
        payload
    }
}
export const toggleSortModal = payload => {
    return {
        type: TOGGLE_SORT_MODAL,
        payload
    }
}
export const toggleLogModal = payload => {
    return {
        type: TOGGLE_LOG,
        payload
    }
}
export const toggleLoginModal = payload => {
    return {
        type: TOGGLE_LOGIN,
        payload
    }
}
export const toggleLoginSuccessModal = payload => {
    return {
        type: TOGGLE_LOGIN_SUCCESS,
        payload
    }
}
export const toggleRegModal = payload => {
    return {
        type: TOGGLE_REG,
        payload
    }
}
export const toggleRegSuccessModal = payload => {
    return {
        type: TOGGLE_REG_SUCCESS,
        payload
    }
}
export const toggleForgotModal = payload => {
    return {
        type: TOGGLE_FORGOT,
        payload
    }
}
export const toggleForgotSuccessModal = payload => {
    return {
        type: TOGGLE_FORGOT_SUCCESS,
        payload
    }
}
export const toggleFeedbackSuccessModal = payload => {
    return {
        type: TOGGLE_FEEDBACK_SUCCESS,
        payload
    }
}
export const toggleFeedbackModal = payload => {
    return {
        type: TOGGLE_FEEDBACK_MODAL,
        payload
    }
}
export const toggleBuyOneClick = payload => {
    return {
        type: TOGGLE_BUY_ONE_CLICK,
        payload
    }
}
export const toggleBuyOneClickMobile = payload => {
    return {
        type: TOGGLE_BUY_ONE_CLICK_MOBILE,
        payload
    }
}
export const toggleSubscribeModal = payload => {
    return {
        type: TOGGLE_SUBSCRIBE_MODAL,
        payload
    }
}
export const togglePhoneModal = payload => {
    return {
        type: TOGGLE_PHONE_MODAL,
        payload
    }
}
export const toggleCallMeBack = payload => {
    return {
        type: TOGGLE_CALL_ME_BACK,
        payload
    }
}
export const toggleBasketModal = payload => {
    return {
        type: TOGGLE_BASKET_MODAL,
        payload,
    }
}
export const toggleOrderConfirm = payload => {
    return {
        type: TOGGLE_ORDER_CONFIRM,
        payload
    }
}
export const toggleResetPasswordModal = payload => {
    return {
        type: TOGGLE_RESET_PASSWORD_MODAL,
        payload,
    }
}