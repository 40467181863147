import React, {useEffect} from 'react'
import {_images} from "../../../API";
import {Link} from "react-router-dom";

const CatalogBannerSlide = (props) => {

    const links = props.data.link_url?.split('/')[ props.data.link_url?.split('/').length - 1]

    return (
        <div className="catalog-slider__item">
            <Link to={`/${links}`}>
                <img src={`${_images}${props.data.image}`} alt="Cпеціальна пропозиція" />
            </Link>
        </div>
    )
}

export default CatalogBannerSlide