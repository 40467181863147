import React from 'react'

const ArticleSliderNext = props => {
    return (
        <button className="slider-arrow-right" onClick={props.onClick}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                      d="M13.5521 28.8939C13.3155 28.775 13.1357 28.5672 13.0521 28.3161C12.9685 28.0649 12.9879 27.7908 13.1061 27.5539L18.8801 15.9999L13.1041 4.44786C13.0441 4.33034 13.0079 4.20209 12.9976 4.07051C12.9874 3.93894 13.0033 3.80663 13.0444 3.68124C13.0856 3.55584 13.1511 3.43982 13.2373 3.33988C13.3235 3.23993 13.4286 3.15804 13.5466 3.09891C13.6646 3.03979 13.7931 3.0046 13.9248 2.99538C14.0564 2.98616 14.1886 3.00309 14.3137 3.0452C14.4388 3.08731 14.5543 3.15376 14.6535 3.24071C14.7528 3.32767 14.8339 3.43342 14.8921 3.55186L20.8921 15.5519C20.9618 15.6909 20.9981 15.8443 20.9981 15.9999C20.9981 16.1554 20.9618 16.3088 20.8921 16.4479L14.8921 28.4479C14.7733 28.6844 14.5655 28.8642 14.3143 28.9478C14.0631 29.0315 13.789 29.012 13.5521 28.8939Z"
                      fill="#182316"/>
            </svg>
        </button>
    )
}

export default ArticleSliderNext