import React from 'react'
import {connect} from "react-redux";

import './MainPartnerBlock.scss'

import {_images} from "../../../API";
import {Link} from "react-router-dom";

const MainPartnerBlock = (props) => {

    return (
        <div className="container">
            <div class="mainpartner-section">
                {
                    props.partners.map((data) => {
                        return (
                            <Link to={`/catalog_brands/${data.id}`} className="mainpartner-section__item">
                                <img src={`${_images}${data.image}`} alt={data.name}/>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        partners: store.partnerSliderReducer.partners
    }
}

export default connect(mapStateToProps)(MainPartnerBlock)