import React from 'react'

const CatalogSEO = props => {

    return(
        <div className="description-container__text">
            <h3 className="description-container__title">{props.seoTitle}</h3>
            <div dangerouslySetInnerHTML={{__html:[props.seoText]}} />
        </div>
    )
}

export default CatalogSEO