import React from 'react'

const FooterItemsInfoPhone = (props) => {

    return (
        <>
            {
                props.phones.map((data, i) => {
                    return (
                        <a key={i} className="footer__contacts-phone" href={`tel:${data}`}>{data}</a>
                    )
                })
            }
        </>
    )
}

export default FooterItemsInfoPhone