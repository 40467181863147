import React from 'react'

import './Preloader.scss'

const Preloader = () => {
    return (
        <div className="loader">
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--dot"/>
            <div className="loader--text"/>
        </div>
    )
}

export default Preloader