import service1 from "../../../img/main-page/services/1.webp";
import service2 from "../../../img/main-page/services/2.webp";
import service3 from "../../../img/main-page/services/3.webp";
import service4 from "../../../img/main-page/services/4.webp";
import service5 from "../../../img/main-page/services/5.webp";
import service6 from "../../../img/main-page/services/6.webp";

const initialState = {
    services: [
        {name: 'Монтаж кондиціонерів', img: service1, link: '/services_page/montazh_kondytsioneriv', dark: false},
        {name: 'Сервісне обслуговування', img: service6, link: '/services_page/servisne_obsluhovuvannia', dark: true},
        {name: 'Монтаж теплового обладнання', img: service3, link: '/services_page/montazh_teplovoho_obladnannia', dark: false},
        {name: 'Монтаж вентиляції', img: service2, link: '/services_page/montazh_ventyliatsii', dark: true},
        {name: 'Проектування систем', img: service5, link: '/services_page/proektuvannia_system', dark: false},
        {name: 'Монтаж центральних пилососів', img: service4, link: '/services_page/montazh_tsentralnykh_pylososiv', dark: true},
    ]
}

export const servicesReducer = (state = initialState) => {
    return state
}