import {
    ADD_SERVICE,
    SET_ID_PRODUCT
} from "../../actionConst/action";
import {GET_PRODUCT_PAGE_DATA} from "../../actionConst/getActions";

const initialState = {
    id: '',
    product: {
        id: 1,
        name: "",
        slug: "",
        description: "",
        specifications: [],
        cashback: 0,
        final_price: 0,
        price: "0",
        discount: 0,
        discount_percent: 0,
        recommended_products: [],
        link: "",
        quantity: 1,
        rating: "0.00",
        rates_count: 0,
        images: [],
        video_link: "",
        tags: [],
        rates: [],
        services: [
            {
                is_guarantee: 0,
            },
        ],
        advantages: [],
        category: {
            slug: '',
            name: '',
        },
        brand: {
            name: "",
            image: ""
        },
    },
}

export const productPageReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ID_PRODUCT:
            return {
                ...state,
                id: action.payload
            }
        case ADD_SERVICE:
            return {
                ...state,
                product: {
                    ...state.product,
                    services: action.payload
                }
            }
        case GET_PRODUCT_PAGE_DATA:
            return {
                ...state,
                id: action.payload.id,
                product: action.payload
            }
        default:
            return state
    }
}