import React, { useEffect, useState } from "react";
import marker from "../../img/mpPoint.svg";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import "./Contacts.scss";
import { useHistory } from "react-router";
import ContactsInfo from "./ContactsInfo";
import ContactsForm from "./ContactsForm";
import { connect } from "react-redux";
import axios from "axios";
import MetaTags from "react-meta-tags";
import {_domain, _npAPIkey} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const locations = [
  {
    title: "Мукачево",
    address1: "вулиця Автомобілістів, 22, Мукачево",
    address2: "Закарпатська область, 89611",
    coords: { lat: 48.446344833672214, lng: 22.685534135568336 },
    placeId: "ChIJFVRaz3msOUcR3l50HkZQ72Y",
  },
  {
    title: "Берегово",
    address1: "Берегово",
    address2: "Закарпатская область, Украина, 90200",
    coords: { lat: 48.19720348845512, lng: 22.637120134472386 },
    placeId: "ChIJbc2BeNRaOEcRt2EBkKfEE6w",
  },
  {
    title: "Виноградов",
    address1: "Виноградов",
    address2: "Закарпатская область, Украина",
    coords: { lat: 48.146345720373404, lng: 23.030322965160888 },
    placeId: "ChIJfWm-Il46OEcRW-fiEqgMNfU",
  },
  {
    title: "Иршава",
    address1: "Иршава",
    address2: "Закарпатская область, Украина",
    coords: { lat: 48.311814598938874, lng: 23.041918734472407 },
    placeId: "ChIJr63R8Uc1OEcRXrPmZTMybhg",
  },
  {
    title: "Рахов",
    address1: "Рахов",
    address2: "Закарпатская область, Украина, 90600",
    coords: { lat: 48.054622670478096, lng: 24.213004865160865 },
    placeId: "ChIJE3zWf_WpN0cRgDDZDpurPSw",
  },
  {
    title: "Свалява",
    address1: "Свалява",
    address2: "Закарпатская область, Украина",
    coords: { lat: 48.54305874729909, lng: 22.99508799584933 },
    placeId: "ChIJa74kWge7OUcRVsakX_rYtFY",
  },
  {
    title: "Тячев",
    address1: "Тячев",
    address2: "Закарпатская область, Украина",
    coords: { lat: 48.01735513862065, lng: 23.572577465160876 },
    placeId: "ChIJ433JsFmQN0cRiqu-oNsmnDw",
  },
  {
    title: "Ужгород",
    address1: "Ужгород",
    address2: "Закарпатская область, Украина",
    coords: { lat: 48.62103759151853, lng: 22.287821903783914 },
    placeId: "ChIJ2eO2RLkZOUcRPCoEMAGupg0",
  },
  {
    title: "Хуст",
    address1: "Хуст",
    address2: "Закарпатская область, Украина, 90400",
    coords: { lat: 48.17360659579312, lng: 23.297358165160873 },
    placeId: "ChIJS-oU1kIpOEcRojgYcgYxiao",
  },
  {
    title: "Перечин",
    address1: "Перечин",
    address2: "Закарпатская область, Украина",
    coords: { lat: 48.73617425131362, lng: 22.472520695849333 },
    placeId: "ChIJDQKKqwsTOUcRfG7GBA548kw",
  },
  {
    title: "Чоп",
    address1: "Чоп",
    address2: "Закарпатская область, Украина, 89500",
    coords: { lat: 48.43422175415922, lng: 22.210214765160874 },
    placeId: "ChIJhRX59cLjOEcR96W_yASXNhE",
  },
  {
    title: "Великий Березный",
    address1: "Великий Березный",
    address2: "Закарпатская область, Украина, 89000",
    coords: { lat: 48.88552234287742, lng: 22.454451387914784 },
    placeId: "ChIJR4m3Id1pOUcRBG6k1R9db2I",
  },
];

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    const defcentr = {
      lat: 48.44472410435226,
      lng: window.innerWidth < 800 ? 22.72906447421519 : 22.22406447421519,
    };
    const [center, setCenter] = useState(defcentr);
    const defzoom = 9.5;
    const [zoom, setZoom] = useState(defzoom);
    const mapOptions = {
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
      gestureHandling: "cooperative",
    };

    return (
      <>
        <GoogleMap
          defaultZoom={defzoom}
          defaultCenter={defcentr}
          zoom={zoom}
          center={center}
          onZoomChanged={() => setZoom(defzoom)}
          options={mapOptions}
        >
          <Marker
            position={locations[0].coords}
            icon={marker}
            onClick={() => props.setShow(true)}
          />
        </GoogleMap>
      </>
    );
  })
);
const MyMapComponentForm = withScriptjs(
  withGoogleMap((props) => {
    const defcentr = {
      lat: window.innerWidth < 800 ? 48.470147 : 48.470147,
      lng: window.innerWidth < 800 ? 22.72906447421519 : 22.22406447421519,
    };
    const [center, setCenter] = useState(defcentr);
    const defzoom = 9.5;
    const mapOptions = {
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
    };

    return (
      <>
        <GoogleMap
          defaultZoom={defzoom}
          defaultCenter={defcentr}
          center={center}
          options={mapOptions}
        >
          {locations.map((el) => (
            <Marker
              onClick={() => {
                props.setShow(true);
                props.setSelectdMark(el);
              }}
              icon={marker}
              position={el.coords}
            />
          ))}
        </GoogleMap>
      </>
    );
  })
);

const Contacts = (props) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    setName(props.user.name);
    setPhone(props.user.phone);
  }, [props.user]);

  const [selectedMark, setSelectdMark] = useState({
    title: "Мукачево",
    address1: "вул. Автомобілістів, 22 б",
    address2: "Мукачево",
    coords: { lat: 48.44472410435226, lng: 22.72906447421519 },
    placeId: "ChIJFVRaz3msOUcR3l50HkZQ72Y",
  });

  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [home, setHome] = useState("");
  const [apartmantValue, setApartmantValue] = useState("");
  const [cityList, setCityList] = useState([]);
  const [adressList, setAdressList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.user.userLocation === "") {
      getCityByString(selectedMark.title);
    } else {
      getCityByString(props.user.userLocation);
    }
  }, [props.user.userLocation]);
  useEffect(() => {
    getCityByString(selectedMark.title);
  }, [selectedMark]);
  useEffect(() => {
    getEnterCity(city);
  }, [city]);
  useEffect(() => {
    getEnterAddress(street, city);
  }, [street]);

  const getCityByString = (data) => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/", {
        apiKey: _npAPIkey,
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: data,
          Limit: 10,
          Warehouse: 0,
          Page: 1,
        },
      })
      .then((res) => {
        if (res.data.data[0]?.AreaDescription !== "Закарпатська") {
          getEnterCity("Мукачево");
        } else {
          setCity(res.data.data[0]);
        }
      });
  };
  const getEnterAddress = (data, city) => {
    if (city) {
      axios
        .post(
          "https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/",
          {
            apiKey: _npAPIkey,
            modelName: "Address",
            calledMethod: "searchSettlements",
            methodProperties: {
              CityName: city.Description,
              Ref: city.Ref,
              Limit: 5,
            },
          }
        )
        .then((res) => {
          if (city.Ref) {
            const findCity = res.data.data[0].Addresses[0];
            if (findCity["Ref"]) {
              axios
                .post(
                  "https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/",
                  {
                    apiKey: _npAPIkey,
                    modelName: "Address",
                    calledMethod: "searchSettlementStreets",
                    methodProperties: {
                      StreetName: data,
                      SettlementRef: findCity["Ref"],
                      Limit: 10,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.data.length > 0) {
                    setAdressList(res.data.data);
                  }
                });
            }
          }
        });
    }
  };
  const getEnterCity = (data) => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/", {
        apiKey: _npAPIkey,
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: data,
          Limit: 10,
          Warehouse: 0,
          Page: 1,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          setCityList(res.data.data);
        }
      });
  };
  const { location } = useHistory();
  return (
    <div className="content-wrap contacts">
      <MetaTags>
          <title>Контакти компанії "Ліка-Комфорт" ➤ Адреса ➤ Номери телефонів ➤ Карта ➤ Графік роботи</title>
          <meta name="description" content={`Контактні дані компанія Ліка-Комфорт ☀ ☁  лідера постачання та монтажу кліматичної техніки, вентиляції та енергозберігаючих систем від провідних світових виробників.`} />
          <meta property="og:locale" content="ua_UA" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Контакти компанії "Ліка-Комфорт" ➤ Адреса ➤ Номери телефонів ➤ Карта ➤ Графік роботи`} />
          <meta property="og:description" content="Контактні дані компанія Ліка-Комфорт ☀ ☁  лідера постачання та монтажу кліматичної техніки, вентиляції та енергозберігаючих систем від провідних світових виробників." />
          <meta property="og:url" content={_domain+window.location.pathname} />
          <meta property="og:image" content={_domain+logo} />
          <meta property="og:site_name" content="Ліка Комфорт" />
      </MetaTags>
      {location.pathname === "/contacts" ? (
        <>
          <ContactsInfo
            selectedMark={selectedMark}
            setShow={setShow}
            show={show}
          />
          <div>
            <MyMapComponent
              isMarkerShown={false}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRMWEQGQC7vstTklvmAxS8G9eKTQfPqFk&language=uk&callback=initMap&libraries=places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `100vh`, width: "100vw" }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
              setSelectdMark={setSelectdMark}
              setShow={setShow}
              show={show}
            />
          </div>
        </>
      ) : (
        <div style={{ position: "relative", height: "100%" }}>
          <ContactsForm
            selectedMark={selectedMark}
            name={name}
            setName={setName}
            phone={phone}
            setPhone={setPhone}
            city={city}
            setCity={setCity}
            street={street}
            setStreet={setStreet}
            home={home}
            setHome={setHome}
            locations={locations}
            apartmantValue={apartmantValue}
            setApartmantValue={setApartmantValue}
            cityList={cityList}
            adressList={adressList}
            setShow={setShow}
            show={show}
          />
          <div>
            <MyMapComponentForm
              isMarkerShown
              style={{ overflow: "visible" }}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBRMWEQGQC7vstTklvmAxS8G9eKTQfPqFk&language=uk&callback=initMap&libraries=places"
              loadingElement={<div style={{ height: `100vh` }} />}
              containerElement={
                <div
                  style={{
                    height: ` ${window.innerWidth < 500 ? "105vh" : "100vh"}}}`,
                  }}
                />
              }
              mapElement={<div style={{ height: `100vh` }} />}
              setSelectdMark={setSelectdMark}
              setShow={setShow}
              show={show}
            />
          </div>
        </div>
      )}
    </div>
  );
};
const mstp = (store) => {
  return {
    user: store.userReducer.info,
  };
};
export default connect(mstp)(Contacts);
