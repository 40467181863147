import offer1 from "../../../img/blog/offer-item-1.jpg";
import offer2 from "../../../img/blog/offer-item-2.jpg";
import offer3 from "../../../img/blog/offer-item-3.jpg";
import mount from "../../../img/blog/mounting.jpg";
import slide1 from '../../../img/seminar/seminar1.jpg'
import slide2 from '../../../img/seminar/seminar2.jpg'
import slide3 from '../../../img/seminar/seminar3.jpg'
import slide4 from '../../../img/seminar/seminar4.jpg'
import slide5 from '../../../img/seminar/seminar5.jpg'
import slide6 from '../../../img/seminar/seminar6.jpg'
import slide7 from '../../../img/seminar/seminar7.jpg'

const initialState = {
    products: [
        {name: 'Зволожувач повітря Electrolux EHU-3710D', link: '/product_page', image: offer1, cost: 2875, quantity: 1, id: '23wefds', discount: 0, cashback: 2},
        {name: 'Зволожувач повітря Ballu UHB-300 White', link: '/product_page', image: offer2, cost: 725, quantity: 1, id: '23wefwqs', discount: 2, cashback: 3},
        {name: 'Зволожувач повітря ультразвуковий Ballu UHB-205 Black/Green', link: '/product_page', image: offer3, cost: 950, quantity: 1, id: '23weqwe', discount: 0, cashback: 1},
    ],
    banner: {image: mount, name: 'Монтаж кліматичного обладнання',},
    slider: [
        slide1, slide2, slide3, slide4, slide5, slide6, slide7
    ]
}

export const blogPageReducer = (state = initialState) => {
    return state
}