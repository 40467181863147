import React from "react";
import ServicePageHeadList from "./ServicePageHeadList";
import ServicePageHeadAlert from "./ServicePageHeadAlert";
import ServicePageInfoDescription from "./ServicePageInfoDescription";
import liDotBlack from "../../../img/li-dot-black.svg";
import MetaTags from "react-meta-tags";
import {_domain} from "../../../API";
import logo from "../../../img/logoNew/logoLikaComfortLigth.svg";

const MontazhKondisijneriv = () => {
  const items = [
    "Доставка кондиціонера до місця установки (в межах міста безкоштовно) за межі міста 20 грн/км;",
    "Монтаж внутрішнього і зовнішнього блоку в один етап, один отвір в стіні (без застосування автовишки та послуг альпіністів);",
    "Установка зовнішнього блоку виконується в доступному місці;",
    "Прокладка дренажної системи до 1,5 метрів, слив дренажу відбувається самопливом;",
    "Підключення до електромережі стандартним шнуром;",
    "Наладка та перевірка працездатності кондиціонера;",
    "Проведення вступного інструктажу з правил користування кондиціонером.",
  ];

  const itemsLi = [
    "В разі використання спецтехніки (автовежі) або висотних робіт ціна обумовлюється додатково;",
    "Розташування блоків в важкодоступних місцях - ціна обумовлюється додатково;",
    "Необхідністіь додаткових робіт – модернізації електропроводки (якщо існуюча мережа не витримає навантаження кондиціонера) згідно прайсу;",
    "Штробління згідно прайсу;",
    "Повторний виклик бригади з вини клієнта – 100 грн;",
    "Транспортні витрати 20 грн/км (за межі міста Мукачево).",
  ];

  return (
    <div className="content">
      <MetaTags>
          <title>Встановлення кондиціонерів в Мукачево ❄️ Монтаж кондиціонерів, вартість установки кондиціонера в Ужгороді</title>
          <meta name="description" content={`Монтаж кондиціонерів в Мукачево ➤➤➤ Встановлення кондиціонера в Ужгороді ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів.`} />
          <meta property="og:locale" content="ua_UA" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Встановлення кондиціонерів в Мукачево ❄️ Монтаж кондиціонерів, вартість установки кондиціонера в Ужгороді" />
          <meta property="og:description" content="Монтаж кондиціонерів в Мукачево ➤➤➤ Встановлення кондиціонера в Ужгороді ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів." />
          <meta property="og:url" content={_domain+window.location.pathname} />
          <meta property="og:image" content={_domain+logo} />
          <meta property="og:site_name" content="Ліка Комфорт" />
      </MetaTags>
      <main className="services-info">
        <svg
          className="services-info__bg-left"
          width="200"
          height="348"
          viewBox="0 0 200 348"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="51.0536"
            y="325.443"
            width="17.5651"
            height="17.5651"
            transform="rotate(123.715 51.0536 325.443)"
            stroke="#43B02A"
            strokeOpacity="0.3"
          />
          <rect
            x="92.4013"
            y="40.7936"
            width="52.4257"
            height="52.4257"
            transform="rotate(123.715 92.4013 40.7936)"
            stroke="#43B02A"
          />
          <rect
            x="136.334"
            y="100.376"
            width="80.4985"
            height="80.4985"
            transform="rotate(123.715 136.334 100.376)"
            stroke="#DCDCDC"
          />
          <rect
            x="186.035"
            y="177.846"
            width="50.7197"
            height="50.7197"
            transform="rotate(123.715 186.035 177.846)"
            stroke="#43B02A"
            strokeOpacity="0.5"
          />
          <rect
            x="133.185"
            y="254.315"
            width="59"
            height="59"
            transform="rotate(123.715 133.185 254.315)"
            stroke="#DCDCDC"
            strokeOpacity="0.4"
          />
          <rect
            x="63.1298"
            y="161.89"
            width="68.8139"
            height="68.8139"
            transform="rotate(123.715 63.1298 161.89)"
            fill="white"
            fillOpacity="0.1"
            stroke="#43B02A"
          />
          <rect
            x="40.6113"
            y="261.674"
            width="39.5981"
            height="39.5981"
            transform="rotate(123.715 40.6113 261.674)"
            stroke="#43B02A"
            strokeOpacity="0.4"
          />
          <rect
            x="23.0077"
            y="78.6374"
            width="59.3526"
            height="59.3526"
            transform="rotate(123.715 23.0077 78.6374)"
            stroke="#DCDCDC"
          />
        </svg>
        <svg
          className="services-info__bg-right"
          width="217"
          height="362"
          viewBox="0 0 217 362"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="187.814"
            y="51.2272"
            width="52.4257"
            height="52.4257"
            transform="rotate(123.715 187.814 51.2272)"
            stroke="#43B02A"
          />
          <rect
            x="31.832"
            y="85.6433"
            width="17.5651"
            height="17.5651"
            transform="rotate(123.715 31.832 85.6433)"
            stroke="#43B02A"
          />
          <rect
            x="226.134"
            y="117.668"
            width="80.4985"
            height="80.4985"
            transform="rotate(123.715 226.134 117.668)"
            stroke="#43B02A"
          />
          <rect
            x="197.696"
            y="221.912"
            width="56.2435"
            height="56.2435"
            transform="rotate(123.715 197.696 221.912)"
            stroke="#DCDCDC"
          />
          <rect
            x="118.522"
            y="99.3991"
            width="59.3526"
            height="59.3526"
            transform="rotate(123.715 118.522 99.3991)"
            fill="white"
            fillOpacity="0.1"
            stroke="#DCDCDC"
          />
          <rect
            x="129.936"
            y="286.601"
            width="34.0643"
            height="34.0643"
            transform="rotate(123.715 129.936 286.601)"
            stroke="#43B02A"
            strokeOpacity="0.5"
          />
          <rect
            x="188.632"
            y="331.535"
            width="20.8121"
            height="20.8121"
            transform="rotate(123.715 188.632 331.535)"
            stroke="#43B02A"
            strokeOpacity="0.3"
          />
          <rect
            x="124.931"
            y="45.2683"
            width="59.3526"
            height="59.3526"
            transform="rotate(123.715 124.931 45.2683)"
            stroke="white"
          />
        </svg>
        <div className="container">
          <h1 className="services-info__title">Монтаж кондиціонерів</h1>
          <section className="services-info__head">
            <ServicePageHeadList items={items} />
            <ServicePageHeadAlert />
          </section>
          <div className="services-info__table-section">
            <table className="services-info__table">
              <tr>
                <th>Ціни на монтаж кондиціонера (без матеріалів)</th>
                <th>Модель</th>
                <th>Вартість*, грн</th>
              </tr>
              <tr>
                <td>Кондиціонер (Спліт-система)</td>
                <td>07-09</td>
                <td>1 980</td>
              </tr>
              <tr>
                <td>Кондиціонер (Спліт-система)</td>
                <td>12</td>
                <td>1 980</td>
              </tr>
              <tr>
                <td>Кондиціонер (Спліт-система)</td>
                <td>18</td>
                <td>2 100</td>
              </tr>
              <tr>
                <td>Кондиціонер (Спліт-система)</td>
                <td>24-36</td>
                <td>2 460</td>
              </tr>
              <tr>
                <td>Кондиціонер (Спліт-система Полупром)</td>
                <td>12-24</td>
                <td>2 460</td>
              </tr>
              <tr>
                <td>Кондиціонер (Спліт-система Полупром)</td>
                <td>36-60</td>
                <td>2 460</td>
              </tr>
            </table>
            <div className="services-info__table-description">
              Ціни вказані для стандартного обладнання і нормальних умов
              проведення робіт.*
            </div>
          </div>
          <ServicePageInfoDescription
            title={
              "Попередній виклик майстра (без монтажу кондиціонера) – безкоштовно."
            }
            itemsLi={itemsLi}
            name={"Випадки зміни цін на послуги:"}
            img={liDotBlack}
          />
        </div>
      </main>
    </div>
  );
};

export default MontazhKondisijneriv;
