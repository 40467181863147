import React, {useState} from 'react'
import ProfilePersonalInfo from "./ProfilePersonalInfo";
import ProfilePersonalPassword from "./ProfilePersonalPassword";
import {CLEAN_COMPARE, CLEAN_WISHLIST, CLEAR_BASKET} from "../../../redux/actionConst/action";
import {useDispatch} from "react-redux";
import { cleanPhone } from '../../CleanPhone';

const ProfilePersonal = props => {
    const dispatch = useDispatch()

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

    const [errorPassword, setErrorPassword] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)

    const changeInfo = () => {
        window.axios.put(`/users/${props.info.id}`, {
            data: {
                name: props.name,
                last_name: props.lastName,
                email: props.email,
                phone: cleanPhone(props.phone),
            }
        })
        .then(() => {
            window.location.reload()
        })
        .catch(error => {
            if(typeof error.response.data.reason['data.name'] !== 'undefined') setErrorName(true)
            if(typeof error.response.data.reason['data.last_name'] !== 'undefined') setErrorLastName(true)
            if(typeof error.response.data.reason['data.email'] !== 'undefined') setErrorEmail(true)
            if(typeof error.response.data.reason['data.phone'] !== 'undefined') setErrorPhone(true)
        })
    }

    const changePassword = () => {
        window.axios.put(`/users/${props.info.id}`, {
            old_password: oldPassword,
            new_password: newPassword,
            new_password_confirmation: newPasswordConfirmation,
        })
        .then(() => {
            setOldPassword('')
            setNewPassword('')
            setNewPasswordConfirmation('')
            window.location.reload()
        })
        .catch(error => {
            setErrorPassword(true)
        })
    }

    const logout = () => {
        window.axios.post(`/auth/logout`)
            .then(() => {
                props.log_in(false)
                localStorage.removeItem('token')
                window.location.reload()
            })
    }

    return (
        <div className={`account-profile ${props.active ? 'active' : ''}`}>
            <div className="account-profile-flex">
                <ProfilePersonalInfo
                    changeInfo={changeInfo}
                    setName={props.setName}
                    setLastName={props.setLastName}
                    setEmail={props.setEmail}
                    setPhone={props.setPhone}
                    name={props.name}
                    lastName={props.lastName}
                    email={props.email}
                    phone={props.phone}
                    errorName={errorName}
                    errorLastName={errorLastName}
                    errorEmail={errorEmail}
                    errorPhone={errorPhone}
                    info={props.info}
                    log_in={props.log_in}
                />
                <ProfilePersonalPassword
                    errorPassword={errorPassword}
                    oldPassword={oldPassword}
                    newPassword={newPassword}
                    newPasswordConfirmation={newPasswordConfirmation}
                    setOldPassword={setOldPassword}
                    setNewPassword={setNewPassword}
                    setNewPasswordConfirmation={setNewPasswordConfirmation}
                    changeInfo={changePassword}
                />
            </div>
            <div className="logout-btn" onClick={() => {
                logout()
                localStorage.removeItem('basket')
                dispatch({type: CLEAR_BASKET})
                localStorage.removeItem('compare')
                dispatch({type: CLEAN_COMPARE})
                localStorage.removeItem('favourite')
                dispatch({type: CLEAN_WISHLIST})
                localStorage.removeItem('token')
            }}>
                <div className="logout-btn__ico">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={logout}>
                        <path
                            d="M22.5455 11.2049L18.3029 6.96234C18.1984 6.85787 18.0744 6.77499 17.9379 6.71845C17.8014 6.66191 17.6551 6.63281 17.5073 6.63281C17.3596 6.63281 17.2133 6.66191 17.0768 6.71845C16.9403 6.77499 16.8163 6.85787 16.7118 6.96234C16.5009 7.17332 16.3823 7.45946 16.3823 7.75783C16.3823 8.0562 16.5009 8.34234 16.7118 8.55332L19.034 10.8755H6.75C6.45163 10.8755 6.16548 10.994 5.9545 11.205C5.74353 11.4159 5.625 11.7021 5.625 12.0005C5.625 12.2988 5.74353 12.585 5.9545 12.796C6.16548 13.0069 6.45163 13.1255 6.75 13.1255H19.034L16.7118 15.4476C16.5009 15.6586 16.3823 15.9447 16.3823 16.2431C16.3823 16.5415 16.5009 16.8276 16.7119 17.0386C16.9228 17.2496 17.209 17.3681 17.5074 17.3681C17.8057 17.3681 18.0919 17.2496 18.3029 17.0386L22.5455 12.7959C22.7565 12.5849 22.875 12.2988 22.875 12.0004C22.875 11.7021 22.7565 11.4159 22.5455 11.2049Z"
                            fill="#257226"/>
                        <path
                            d="M14.25 18.375C13.9516 18.375 13.6655 18.4935 13.4545 18.7045C13.2435 18.9155 13.125 19.2016 13.125 19.5V20.625H3.375V3.375H13.125V4.5C13.125 4.79837 13.2435 5.08452 13.4545 5.2955C13.6655 5.50647 13.9516 5.625 14.25 5.625C14.5484 5.625 14.8345 5.50647 15.0455 5.2955C15.2565 5.08452 15.375 4.79837 15.375 4.5V3C15.375 2.75377 15.3265 2.50995 15.2323 2.28247C15.138 2.05498 14.9999 1.84828 14.8258 1.67417C14.6517 1.50006 14.445 1.36195 14.2175 1.26773C13.99 1.1735 13.7462 1.125 13.5 1.125H3C2.50272 1.125 2.02581 1.32254 1.67417 1.67417C1.32254 2.02581 1.125 2.50272 1.125 3V21C1.125 21.4973 1.32254 21.9742 1.67417 22.3258C2.02581 22.6775 2.50272 22.875 3 22.875H13.5C13.7462 22.875 13.99 22.8265 14.2175 22.7323C14.445 22.638 14.6517 22.4999 14.8258 22.3258C14.9999 22.1517 15.138 21.945 15.2323 21.7175C15.3265 21.49 15.375 21.2462 15.375 21V19.5C15.375 19.2016 15.2565 18.9155 15.0455 18.7045C14.8345 18.4935 14.5484 18.375 14.25 18.375Z"
                            fill="#257226"/>
                    </svg>
                </div>
                <div className="logout-btn__text">Вийти з особистого кабінету</div>
            </div>
        </div>
    )
}

export default ProfilePersonal