import React from 'react'
import liDot from "../../../img/li-dot.svg";
import liDotBlack from "../../../img/li-dot-black.svg";
import MetaTags from "react-meta-tags";
import {_domain} from "../../../API";
import logo from "../../../img/logoNew/logoLikaComfortLigth.svg";

import ServicePageInfoDescription from "../MontazhKondytsioneriv/ServicePageInfoDescription";

const ProektuvanniaSystem = () => {

    const itemsLi = [
        'побудова кліматичних умов в комплексі;',
        'комп\'ютерне проектування з можливою візуалізацією майбутньої системи;',
        'розробка основних енергоспоживаючих мереж;',
        'зонування з обладнання;',
        'розділ енергозбереження та витрат на експлуатацію.'
    ]

    const itemsLiSecond = [
        'Пояснювальна записка з описом загальних принципів з опалення, вентиляції та кондиціювання',
        'Схеми з розведенням і прив\'язками системи опалення',
        'Схеми з розведенням і прив\'язками системи вентиляції та кондиціонування',
        'Принципова схема підключення приладів',
        'Принципова схема вентиляції',
        'Відомість обладнання',
        'Розрахунок з опалення, вентиляції, кондиціонування та обладнанню',
    ]

    const itemsLiThird = [
        'Пояснювальна записка з описом загальних принципів з опалення, вентиляції та кондиціювання',
        'Робочі креслення, призначені для виконання будівельно-монтажних робіт',
        'Ескізні креслення загальних видів нетипових виробів, конструкцій, пристроїв, монтажних блоків',
        'Специфікацію обладнання, виробів і матеріалів',
        'Опитувальні листи та габаритні креслення',
    ]

    return (
        <div className="content" style={{marginBottom: '100px'}}>
            <MetaTags>
                <title>Проектування систем вентиляції і кондиціонування в Мукачево, кращі ціни на розрахунок систем вентиляції в Ужгороді</title>
                <meta name="description" content={`Розрахунок систем вентиляції та кондиціювання в Ужгороді ☀ ☁ Низкі ціни на проектування вентиляції ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Проектування систем вентиляції і кондиціонування в Мукачево, кращі ціни на розрахунок систем вентиляції в Ужгороді" />
                <meta property="og:description" content="Розрахунок систем вентиляції та кондиціювання в Ужгороді ☀ ☁ Низкі ціни на проектування вентиляції ☝ Досвід 18 років ☝ Кваліфіковані інженери і монтажники ✴ ☂ Широкий асортимент сертифікованих товарів." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <main className="services-info">
                <svg className="services-info__bg-left" width="200" height="348" viewBox="0 0 200 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="51.0536" y="325.443" width="17.5651" height="17.5651"
                          transform="rotate(123.715 51.0536 325.443)" stroke="#43B02A" strokeOpacity="0.3"/>
                    <rect x="92.4013" y="40.7936" width="52.4257" height="52.4257"
                          transform="rotate(123.715 92.4013 40.7936)" stroke="#43B02A"/>
                    <rect x="136.334" y="100.376" width="80.4985" height="80.4985"
                          transform="rotate(123.715 136.334 100.376)" stroke="#DCDCDC"/>
                    <rect x="186.035" y="177.846" width="50.7197" height="50.7197"
                          transform="rotate(123.715 186.035 177.846)" stroke="#43B02A" strokeOpacity="0.5"/>
                    <rect x="133.185" y="254.315" width="59" height="59" transform="rotate(123.715 133.185 254.315)"
                          stroke="#DCDCDC" strokeOpacity="0.4"/>
                    <rect x="63.1298" y="161.89" width="68.8139" height="68.8139"
                          transform="rotate(123.715 63.1298 161.89)" fill="white" fillOpacity="0.1"
                          stroke="#43B02A"/>
                    <rect x="40.6113" y="261.674" width="39.5981" height="39.5981"
                          transform="rotate(123.715 40.6113 261.674)" stroke="#43B02A" strokeOpacity="0.4"/>
                    <rect x="23.0077" y="78.6374" width="59.3526" height="59.3526"
                          transform="rotate(123.715 23.0077 78.6374)" stroke="#DCDCDC"/>
                </svg>
                <svg className="services-info__bg-right" width="217" height="362" viewBox="0 0 217 362" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="187.814" y="51.2272" width="52.4257" height="52.4257"
                          transform="rotate(123.715 187.814 51.2272)" stroke="#43B02A"/>
                    <rect x="31.832" y="85.6433" width="17.5651" height="17.5651"
                          transform="rotate(123.715 31.832 85.6433)" stroke="#43B02A"/>
                    <rect x="226.134" y="117.668" width="80.4985" height="80.4985"
                          transform="rotate(123.715 226.134 117.668)" stroke="#43B02A"/>
                    <rect x="197.696" y="221.912" width="56.2435" height="56.2435"
                          transform="rotate(123.715 197.696 221.912)" stroke="#DCDCDC"/>
                    <rect x="118.522" y="99.3991" width="59.3526" height="59.3526"
                          transform="rotate(123.715 118.522 99.3991)" fill="white" fillOpacity="0.1"
                          stroke="#DCDCDC"/>
                    <rect x="129.936" y="286.601" width="34.0643" height="34.0643"
                          transform="rotate(123.715 129.936 286.601)" stroke="#43B02A" strokeOpacity="0.5"/>
                    <rect x="188.632" y="331.535" width="20.8121" height="20.8121"
                          transform="rotate(123.715 188.632 331.535)" stroke="#43B02A" strokeOpacity="0.3"/>
                    <rect x="124.931" y="45.2683" width="59.3526" height="59.3526"
                          transform="rotate(123.715 124.931 45.2683)" stroke="white"/>
                </svg>
                <div className="container">
                    <h1 className="services-info__title">Проектування систем вентиляції і кондиціонування</h1>
                    <div style={{textAlign: 'center'}}>
                        <img src="http://s04.radikal.ru/i177/1412/9d/dbb2b8ebc0c4.jpg" alt=""/>
                    </div>
                    <br/><br/>
                    <p style={{textIndent: '1.5em'}}>
                        Компанія Ліка Комфот пропонує комплексне рішення завдань з проектування систем вентиляції та кондиціонування, підбір обладнання для систем промислової вентиляції та побутової вентиляції відповідно до технічного завдання та побажаннями Замовника.
                    </p>
                    <br/>
                    <p style={{textIndent: '1.5em'}}>
                        Процес створення проектної документації здійснюється висококваліфікованими інженерами із застосуванням сучасних програмних технологій, що дозволяє максимально автоматизувати розрахунки та оптимізувати технічні рішення для досягнення високої якості проектної документації.
                    </p>
                    <br/>
                    <p style={{textIndent: '1.5em'}}>
                        Кваліфіковані фахівці компанії можуть не тільки запропонувати технічно та економічно виправдані проектні рішення по системах вентиляції, але й провести експертизу готових проектів у відповідності з діючими нормативами і враховуючи побажання замовника по вентиляції приміщень.
                    </p>
                    <ServicePageInfoDescription
                        name={'Розробка концепції:'}
                        itemsLi={itemsLi}
                        img={liDot}
                    />
                    <ServicePageInfoDescription
                        name={'Розробка проекту стадії П:'}
                        itemsLi={itemsLiSecond}
                        img={liDotBlack}
                    />
                    <ServicePageInfoDescription
                        name={'Розробка проекту стадії РД:'}
                        itemsLi={itemsLiThird}
                        img={liDot}
                    />
                    <p style={{textAlign: 'right', fontStyle: 'italic'}}>
                        Живіть з комфортом!
                    </p>
                </div>
            </main>
        </div>
    )
}

export default ProektuvanniaSystem