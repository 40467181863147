import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import '../Catalog/Catalog.scss'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import CatalogContent from "../Catalog/CatalogContent/CatalogContent";
import CatalogFilter from "../Catalog/CatalogFilter/CatalogFilter";
import ScrollToTopOnMount from "../../ScrolToTop";
import {getProducts} from "../../redux/actionCreators/getCreators/getProducts";
import {
    getFilters,
    getFiltersBrands,
    getCatalogBanners,
    getCatalogSlug,
} from "../../redux/actionCreators/getCreators/getCategories";
import Preloader from "../globalComponents/Preloader/Preloader";
import {setSortName, setSortDownUp, cleanFilters, addActiveFilters} from "../../redux/actionCreators/catalogProducts";
import axios from 'axios'
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const BestCatalog = props => {

    const brand = true

    const [page, setPage] = useState(1)
    const [pageLength, setPageLength] = useState(1)
    const [pageSize, setPageSize] = useState(24)
    const [loading, setLoading] = useState(true)
    const [loadingProducts, setLoadingProducts] = useState(false)

    const [productCount, setProductCount] = useState(0)

    const getProductsWithFilters = () => {
        window.axios.get(`/products?section=the_best_proposition`, {
            params: {
                "sortBy": props.sortName,
                "direction": props.sortDownUp,
                "page": 1,
                "pageSize": pageSize,
                "prices": {
                    "min": parseInt(props.prices.min),
                    "max": parseInt(props.prices.max),
                },
                "brands": props.brandsFilter,
                "categories": props.categoriesFilter,
            }
        })
            .then(res => {
                props.getProducts(res.data.data);
                setPageLength(res.data.meta.pagesCount)
                props.getFilters(res.data.filters, res.data.filters.categories)
                props.getFiltersBrands(res.data.filters.brands)
                props.addActiveFilters({brands: props.brandsFilter, tags: props.tags})
            })
    }
    useEffect(() => {
        window.axios.get(`/products?section=the_best_proposition`, {
            params: {
                "onlyCount": 1,
                "sortBy": props.sortName,
                "direction": props.sortDownUp,
                "page": 1,
                "pageSize": pageSize,
                "prices": {
                    "min": parseInt(props.prices.min),
                    "max": parseInt(props.prices.max),
                },
                "categories": props.categoriesFilter,
                "brands": props.brandsFilter,
            }
        })
            .then(res => {
                setProductCount(res.data.data)
            })
    }, [props.brandsFilter, props.tags])
    // useEffect( () => {
    //     window.axios.get(`/banners?type=catalog`)
    //         .then( res => {
    //              props.getCatalogBanners(res.data.data)
    //         })
    // }, [])
    useEffect(() => {
        if (props.catalogSlug !== 'best') {
            props.cleanFilters()
        }
        props.getCatalogSlug('best')
        window.axios.get(`/products?section=the_best_proposition`, {
            params: {
                "sortBy": props.sortName,
                "direction": props.sortDownUp,
                "page": 1,
                "pageSize": 24,
                "prices": {
                    "min": parseInt(props.prices.min),
                    "max": parseInt(props.prices.max),
                },
            },
        })
            .then(  res => {
                 props.getProducts(res.data.data);
                 setPageLength(res.data.meta.pagesCount)
                props.getFilters(res.data.filters, res.data.filters.categories)
                props.getFiltersBrands(res.data.filters.brands)
            })
            .finally(() => setLoading(false))
    }, [])
    useEffect(() => {
        setLoadingProducts(true)
        const source = axios.CancelToken.source();
        window.axios.get(`/products?section=the_best_proposition`, {
            cancelToken: source.token,
            params: {
                "sortBy": props.sortName,
                "direction": props.sortDownUp,
                "page": page,
                "pageSize": 24,
                "prices": {
                    "min": parseInt(props.prices.min),
                    "max": parseInt(props.prices.max),
                },
                "categories": props.categoriesFilter,
                "brands": props.brandsFilter,
            },
        })
            .then(  res => {
                props.getProducts(res.data.data);
            })
            .finally(() => setLoadingProducts(false))
    }, [page])
    useEffect(() => {
        setLoadingProducts(true)
        window.axios.get(`/products?section=the_best_proposition`, {
            params: {
                "sortBy": props.sortName,
                "direction": props.sortDownUp,
                "page": 1,
                "pageSize": 24,
                "prices": {
                    "min": parseInt(props.prices.min),
                    "max": parseInt(props.prices.max),
                },
                "categories": props.categoriesFilter,
                "brands": props.brandsFilter,
            }
        })
            .then(  res => {
                 props.getProducts(res.data.data);
                 setPageLength(res.data.meta.pagesCount)
                 setPage(1)
        })
            .finally(() => {
                setLoadingProducts(false)
            })
    }, [props.prices])
    useEffect(() => {
        setLoadingProducts(true)
        window.axios.get(`/products?section=the_best_proposition`, {
            params: {
                "sortBy": props.sortName,
                "direction": props.sortDownUp,
                "page": 1,
                "pageSize": 24,
                "prices": {
                    "min": parseInt(props.prices.min),
                    "max": parseInt(props.prices.max),
                },
                "categories": props.categoriesFilter,
                "brands": props.brandsFilter,
            }
        })
            .then( res => {
                 props.getProducts(res.data.data);
                 setPageLength(res.data.meta.pagesCount)
                 setPage(1)
            })
            .finally(() => {
                setLoadingProducts(false)
            })
    }, [props.sortName, props.sortDownUp,])

    const [openFilter, setOpenFilter] =useState(false)
    const openFilterDrop = () => {
        setOpenFilter(!openFilter)
    }


    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: "Найкращі пропозиції", link: `/catalog_best/${props.match.params.slug}`},
    ]

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Спеціальні пропозиції на кліматичну техніку, вентиляцію та енергозберігаючі системи | Ліка-Комфорт</title>
                <meta name="description" content={`Найкращі пропозиції від компанії Ліка-Комфорт на ☀ ☁ кліматичну техніку, вентиляцію та енергозберігаючі системи від провідних світових виробників.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Спеціальні пропозиції на кліматичну техніку, вентиляцію та енергозберігаючі системи | Ліка-Комфорт" />
                <meta property="og:description" content="Найкращі пропозиції від компанії Ліка-Комфорт на ☀ ☁ кліматичну техніку, вентиляцію та енергозберігаючі системи від провідних світових виробників." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="catalog">
                    <div className="container">
                        <BreadCrumbs items={crumbs}/>
                        {/*<CatalogBanner banners={props.banners}/>*/}
                        <h1 className="catalog__title">
                            Найкращі пропозиції
                        </h1>
                        {
                            loading ? (
                                <Preloader />
                            ) : (
                                <div className="catalog-wrap">
                                    <CatalogFilter
                                        brand={brand}
                                        openFilterDrop={openFilterDrop}
                                        openFilter={openFilter}
                                        productCount={productCount}
                                        getProductsWithFilters={getProductsWithFilters}
                                        isCategory={true}
                                    />
                                    <CatalogContent
                                        loadingProducts={loadingProducts}
                                        setPageSize={setPageSize}
                                        pageSize={pageSize}
                                        sort={props.sortDownUp}
                                        setSort={props.setSortDownUp}
                                        sortName={props.sortName}
                                        setSortName={props.setSortName}
                                        page={page}
                                        setPage={setPage}
                                        pageLength={pageLength}
                                        slug={props.match.params.slug}
                                        openFilterDrop={openFilterDrop}
                                        products={props.products}
                                    />
                                </div>
                            )
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        banners: store.catalogBannerReducer.banners,
        products: store.catalogProductsReducer.products,
        tags: store.catalogFilterReducer.tags,
        brandsFilter: store.catalogFilterReducer.brandsFilter,
        costFilter: store.catalogFilterReducer.costFilter,
        sortName: store.catalogProductsReducer.sortName,
        sortDownUp: store.catalogProductsReducer.sortDownUp,
        prices: store.catalogFilterReducer.filters.prices,
        catalogSlug: store.catalogFilterReducer.catalogSlug,
        categoriesFilter: store.catalogFilterReducer.categoriesFilter,
    }
}

const mapDispatchToProps = {
    getProducts,
    getFilters,
    getFiltersBrands,
    getCatalogBanners,
    getCatalogSlug,
    setSortName,
    setSortDownUp,
    cleanFilters,
    addActiveFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)(BestCatalog)