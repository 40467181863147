import React, {useEffect} from 'react'
import {analyticEvents} from '../../utils/googleAnalytics/events';
import OrderHeader from "../Order/OrderHeader/OrderHeader";
import {connect} from 'react-redux'
import './OrderResult.scss'
import OrderResultProduct from "./OrderResultProduct";
import OrderResultTotal from "./OrderResultTotal";
import OrderFooter from "../Order/OrderFooter/OrderFooter";
import {getOrderData} from "../../redux/actionCreators/getCreators/getOrder";
import {toggleModal, toggleOrderConfirm} from "../../redux/actionCreators/modalsCreator";
import OrderResultInfo from "./OrderResultInfo";
import ScrollToTopOnMount from "../../ScrolToTop";
import {clearBasket} from "../../redux/actionCreators/basketProducts";
import {useHistory} from "react-router";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const OrderResult = props => {

    const history = useHistory()

    useEffect(() => {
        analyticEvents.successOrder();
        if(localStorage.getItem('order')) {
            props.getOrderData(JSON.parse(localStorage.getItem('order')))
        }
        props.toggleModal(false)
        props.toggleOrderConfirm(false)
        localStorage.setItem('basket', JSON.stringify([]))
        props.clearBasket()
    }, []);

    return (
        <div className="content">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Дякуємо!</title>
                <meta name="robots" content={'noindex, nofollow'} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Дякуємо!" />
                <meta property="og:description" content="Дякуємо!" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <main className="order-page order-result">
                <div className="container">
                    <OrderHeader />
                    <h1 className="page-title">Оформлення замовлення</h1>
                    <div className="order-result__thanks-text">Дякуємо!</div>
                    <div className="order-result__number-text">Номер вашого замовлення {props.order.id}</div>
                    <div className="order-result__body">
                        <OrderResultInfo order={props.order}/>
                        <div className="order-result__products">
                            <h3 className="order-result__title">Товари в замовленні</h3>
                            <div className="order-result__products-wrap">
                                {
                                    props.order.products.map((data, i) => {
                                        return (
                                            <OrderResultProduct key={i} data={data} />
                                        )
                                    })
                                }
                            </div>
                            <OrderResultTotal order={props.order}/>
                        </div>
                    </div>
                    <div className="order-result__back-link" onClick={() => {
                        history.push(`/`)
                        localStorage.setItem('order', JSON.stringify([]))
                    }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.459 1.6601C12.3259 1.55635 12.162 1.5 11.9932 1.5C11.8245 1.5 11.6606 1.55635 11.5275 1.6601L0.75 10.0639L1.68225 11.2429L3 10.2154V19.4996C3.00079 19.8972 3.15908 20.2782 3.44022 20.5594C3.72135 20.8405 4.10242 20.9988 4.5 20.9996H19.5C19.8976 20.9988 20.2787 20.8405 20.5598 20.5594C20.8409 20.2782 20.9992 19.8972 21 19.4996V10.2221L22.3177 11.2496L23.25 10.0706L12.459 1.6601ZM13.5 19.4996H10.5V13.4996H13.5V19.4996ZM15 19.4996V13.4996C14.9996 13.1019 14.8414 12.7206 14.5602 12.4394C14.279 12.1582 13.8977 12 13.5 11.9996H10.5C10.1023 12 9.721 12.1582 9.43978 12.4394C9.15856 12.7206 9.0004 13.1019 9 13.4996V19.4996H4.5V9.0461L12 3.2036L19.5 9.0536V19.4996H15Z"
                                fill="white"/>
                        </svg>
                        Повернутись на головну
                    </div>
                </div>
                <OrderFooter />
            </main>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        order: store.orderReducer.order
    }
}

const mapDispatchToProp = {
    getOrderData,
    toggleModal,
    toggleOrderConfirm,
    clearBasket,
}

export default connect(mapStateToProps, mapDispatchToProp)(OrderResult)
