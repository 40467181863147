import React from 'react'
import {_images} from "../../../API";


const ProductPagePhotoSliderMainItem = props => {

    return (
        <div className="product-demo__slide-item"
            onClick={() => {
                props.toggleProductSliderModal(true)
                props.toggleModal(true)
            }}
        >
            <img src={`${_images}${props.photo}`} alt="" />
        </div>
    )
}

export default ProductPagePhotoSliderMainItem
