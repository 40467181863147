import React, {useEffect, useState} from "react";
import {connect} from 'react-redux'
import './ProductPage.scss'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import ProductPageAnchors from "./ProductPageAnchors/ProductPageAnchors";
import ProductPagePhotoSlider from "./ProductPagePhotoSlider/ProductPagePhotoSlider";
import ProductPageInfo from "./ProductPageInfo/ProductPageInfo";
import ProductPageCharacteristic from "./ProductPageCharacteristic/ProductPageCharacteristic";
import ProductPageRecommendedSlider from "./ProductPageRecommendedSlider/ProductPageRecommendedSlider";
import ProductPageMobileBuy from "./ProductPageMobileBuy/ProductPageMobileBuy";
import ProductPageDescription from "./ProductPageDescription/ProductPageDescription";
import ProductPageFeedback from "./ProductPageFeedback/ProductPageFeedback";
import ScrollToTopOnMount from "../../ScrolToTop";
import {
    toggleModal,
    toggleProductSliderModal,
} from "../../redux/actionCreators/modalsCreator";
import {getProductData} from "../../redux/actionCreators/getCreators/getProductPage";
import ProductsSlider from "../globalComponents/ProductsSlider/ProductsSlider";
import {analyticEvents} from '../../utils/googleAnalytics/events';
import {Redirect} from 'react-router';
import MetaTags from 'react-meta-tags';
import {_domain, _images} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";
import JsonLd from '../globalComponents/JsonLd/JsonLd';

const ProductPage = (props) => {
    const [info, setInfo] = useState(false)
    const [char, setChar] = useState(false)
    const [desc, setDesc] = useState(false)
    const [feed, setFeed] = useState(false)
    const [addFeed, setAddFeed] = useState(false)
    const [isProductNotFound, setIsProductNotFound] = useState(false);

    const charSet = () => {
        setChar(!char)
        setTimeout(() => {
            setChar(true)
        }, 3)
    }

    const infoSet = () => {
        setInfo(!info)
        setTimeout(() => {
            setInfo(true)
        }, 3)
    }

    const descSet = () => {
        setDesc(!desc)
        setTimeout(() => {
            setDesc(true)
        }, 3)
    }

    const feedSet = () => {
        setFeed(!feed)
        setTimeout(() => {
            setFeed(true)
        }, 3)
    }

    const addFeedSet = () => {
        setAddFeed(!addFeed)
        setTimeout(() => {
            setAddFeed(true)
        }, 3)
    }

    const [dayProducts, setDayProducts] = useState([])
    useEffect(() => {
        window.axios.get(`/products?section=product_of_the_day`, {
            params: {
                "sortBy": 'price',
                "direction": 'asc',
                "page": 1,
                "pageSize": 12,
            },
        })
            .then(res => {
                setDayProducts(res.data.data)
            })
    }, [])

    const [seoDescription, setSeoDescription] = useState()
    const [seoTitle, setSeoTitle] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)
        window.axios.get(`/products/${props.match.params.slug}`)
            .then(res => {
                props.getProductData(res.data)
                setSeoDescription(res.data.metadata['seo-description'])
                setSeoTitle(res.data.metadata['seo-title'])
                analyticEvents.showProductDetail(res.data);

                let hash = window.location.hash.slice(1);
                if(hash.length) {
                    setTimeout(function() {
                        const hashElement = document.getElementById(hash);
                        hashElement.scrollIntoView({block: "start", behavior: "smooth"});
                    }, 500);
                }
            })
            .catch(() => {
                setIsProductNotFound(true);
            })
    }, [props.match.params.slug]);

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: props.product.category.name, link: `/catalog/${props.product.category.slug}`},
        {name: props.product.name},
    ]

    const datePublished = new Date();
    datePublished.setMonth(datePublished.getMonth()-3);

    let img;
    const jsonLdImg = props.product.images.map((val, index) => {
        img = new Image();
        img.src = _images+val;

        return {
            "@context": "http://schema.org",
            "@type": "ImageObject",
            "contentUrl": _images+val,
            "datePublished": datePublished.toISOString(),
            "name": props.product.name+' №'+(index+1),
            "description": props.product.name+' №'+(index+1),
            "width": img.width,
            "height": img.height,
            "author": "Ліка Комфорт"
        }
    });

    const jsonLdVideo = {
        "@context": "http://schema.org",
        "@type": "VideoObject",
        "contentUrl": props.product.video_link,
        "name": props.product.name,
        "description": props.product.name,
        "uploadDate": datePublished,
    };

    const jsonLdProduct = {
        "@context": "http://schema.org",
        "@type": "Product",
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": props.product.rating,
            "reviewCount": props.product.rates_count,
        },
        "description": typeof props.product.description !== 'undefined' ? props.product.description.replace(/<\/?[^>]+>/gi, '') : '',
        "name": props.product.name,
        "image": props.product.images.length ? _images+props.product.images[0] : _domain+logo,
        "brand": props.product.brand.name,
        "sku": props.product.id,
        "offers": {
            "@type": "Offer",
            "availability": "http://schema.org/InStock",
            "price": props.product.final_price,
            "priceCurrency": "UAH",
            "url": _domain+'/product_card/'+props.product.slug,
        },
    };

    jsonLdProduct['review'] = props.product.rates.map(rate => {
        var date = rate.created_date.split('.');
        return {
            "@type": "Review",
            "author": rate.user,
            "datePublished": new Date(date[2], date[1]-1, date[0]).toISOString(),
            "description": rate.description,
            "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": rate.score,
                "worstRating": "1"
            }
        }
    });

    const jsonLdRating = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "aggregateRating": {
            "@type": "AggregateRating",
            "itemReviewed": {
                "@type": "Organization",
                "name": "Ліка Комфорт"
            },
            "name": props.product.name,
            "ratingValue": props.product.rating,
            "reviewCount": props.product.rates_count,
        },
    };

    jsonLdRating['review'] = props.product.rates.map(rate => {
        var date = rate.created_date.split('.');
        return {
            "@type": "Review",
            "itemReviewed": {
                "@type": "Organization",
                "name": "Ліка Комфорт"
            },
            "author": rate.user,
            "datePublished": new Date(date[2], date[1]-1, date[0]).toISOString(),
            "description": rate.description,
            "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": rate.score,
                "worstRating": "1"
            }
        }
    });

    return (
        <div className="content-wrap">
            <JsonLd data={jsonLdImg} />
            {
                props.product.video_link ? (<JsonLd data={jsonLdVideo} />) : ''
            }
            <JsonLd data={jsonLdProduct} />
            <JsonLd data={jsonLdRating} />
            {
                isProductNotFound
                    ? (<Redirect to='/404' />)
                    : (
                        <>
                            <MetaTags>
                                <title>{seoTitle || props.product.name}</title>
                                <meta name="description" content={seoDescription || props.product.name} />
                                <meta property="og:locale" content="ua_UA" />
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content={seoTitle || props.product.name} />
                                <meta property="og:description" content={seoDescription || props.product.name} />
                                <meta property="og:url" content={_domain+window.location.pathname} />
                                <meta property="og:image" content={props.product.images.length ? _images+props.product.images[0] : _domain+logo} />
                                <meta property="og:site_name" content="Ліка Комфорт" />
                            </MetaTags>
                            <ScrollToTopOnMount />
                            <div className="content">
                                <main className="product-page">
                                    <div className="container">
                                        <BreadCrumbs items={crumbs} />
                                        <ProductPageAnchors
                                            infoSet={infoSet}
                                            addFeedSet={addFeedSet}
                                            feedSet={feedSet}
                                            descSet={descSet}
                                            charSet={charSet}
                                            rates_count={props.product.rates_count}
                                        />
                                        <div className="product-page__content-wrap not-white">
                                            <ProductPagePhotoSlider
                                                toggleModal={props.toggleModal}
                                                toggleProductSliderModal={props.toggleProductSliderModal}
                                                photos={props.product.images}
                                                advantages={props.product.advantages}
                                                name={props.product.name}
                                            />
                                            <ProductPageInfo
                                                info={info}
                                                product={props.product}
                                            />
                                        </div>
                                        <div className="product-page__content-wrap">
                                            <ProductPageCharacteristic
                                                char={char}
                                                specifications={props.product.specifications}
                                            />
                                            <ProductPageDescription
                                                desc={desc}
                                                description={props.product.description}
                                                video={props.product.video_link}
                                            />
                                        </div>
                                        <ProductPageFeedback
                                            addFeed={addFeed}
                                            feed={feed}
                                            rates={props.product.rates}
                                            id={props.product.id}
                                        />
                                        {
                                            props.product.recommended_products.length > 0 && (
                                                <ProductPageRecommendedSlider
                                                    products={props.product.recommended_products}
                                                />
                                            )
                                        }
                                        {
                                            props.discountProducts.length > 0 && (
                                                <ProductsSlider
                                                    name={'Акційні пропозиції'}
                                                    products={props.discountProducts}
                                                />
                                            )
                                        }
                                        {
                                            dayProducts.length > 0 && (
                                                <ProductsSlider
                                                    name={'Хіти продаж'}
                                                    products={dayProducts}
                                                />
                                            )
                                        }
                                    </div>
                                    <ProductPageMobileBuy
                                        product={props.product}
                                    />
                                </main>
                            </div>
                        </>
                    )
            }

        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        mainCharacteristics: store.productPageReducer.mainCharacteristics,
        secondCharacteristics: store.productPageReducer.secondCharacteristics,
        description: store.productPageReducer.description,
        feedbacks: store.productPageReducer.feedbacks,
        productInfo: store.productPageReducer.productInfo,
        product: store.productPageReducer.product,
        discountProducts: store.discountProductsSliderReducer.discountSlider,
    }
}

const mapDispatchToProps = {
    getProductData,
    toggleModal,
    toggleProductSliderModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage)
