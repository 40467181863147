import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import Slider from 'react-slick'
import './Wishlist.scss'
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import WishlistSlide from "./WishlistSlide";
import ProductsSliderPrev from "../globalComponents/ProductsSlider/ProductsSliderPrev";
import ProductsSliderNext from "../globalComponents/ProductsSlider/ProductsSliderNext";
import {removeWishlist} from "../../redux/actionCreators/wishlistProducts";
import CleanPage from "../globalComponents/CleanPage/CleanPage";
import ScrollToTopOnMount from "../../ScrolToTop";
import {RELOAD_WISHLIST} from "../../redux/actionConst/action";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Wishlist = props => {

    const dispatch = useDispatch()


    useEffect(() => {
        window.axios.post(`/auth/user`)
            .then(res => {
                dispatch({type: RELOAD_WISHLIST, payload: res.data.data.wishes})
            })
    }, []);

    const items = [
        {name: 'Головна', link: '/'},
        {name: 'Список бажань', link: '/wishlist'},
    ]

    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        adaptiveHeight: true,
        speed: 500,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        // // cssEase: 'ease-in-out',
        prevArrow: <ProductsSliderPrev/>,
        nextArrow: <ProductsSliderNext/>,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 590,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 410,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }


    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Список бажань</title>
                <meta name="robots" content={'noindex, nofollow'} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Список бажань" />
                <meta property="og:description" content="Список бажань" />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="favorite">
                    <div className="container">
                        <BreadCrumbs items={items} />
                        <h1 className="page-title">Список бажань</h1>
                        {
                            props.products.length > 0 ? (
                                <div className="catalog__body">
                                    {
                                        props.products.map((data, i) => {
                                            return (
                                                <WishlistSlide removeWishlist={props.removeWishlist} key={i} data={data} />
                                            )
                                        })
                                    }
                                </div>
                            ) : <CleanPage />
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        products: store.wishlistReducer.wishlist
    }
}

const mapDispatchToProps = {
    removeWishlist,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist)