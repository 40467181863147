import React, {useState} from 'react'
import {connect} from 'react-redux'
import {toggleModal, toggleLogModal, toggleLoginSuccessModal, toggleLoginModal} from "../../../../redux/actionCreators/modalsCreator";
import {log_in} from "../../../../redux/actionCreators/userCreator";
import {useDispatch} from "react-redux";
import {
    CHANGE_USER_EMAIL,
    CHANGE_USER_LASTNAME,
    CHANGE_USER_NAME,
    CHANGE_USER_PHONE,
} from "../../../../redux/actionConst/action";
import InputMask from "react-input-mask";
import {_api, _facebookAuthKey, _googleAuthKey} from "../../../../API";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";

const OrderBodyDataNotLogged = props => {

    const dispatch = useDispatch()

    const login = (email, password, name) => {
        window.axios.post(`/auth/login`, {
            email,
            password,
        })
            .then(res => {
                localStorage.setItem('token', res.data.access_token)
                window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
                props.log_in(true)
                props.toggleLoginModal(true)
                props.toggleLoginSuccessModal(true)
                setTimeout(() => window.location.reload(), 500)
            })
            .catch(() => {
                reg(email, password, name)
            })
    }
    const reg = (email, password, name) => {
        window.axios.post(`/auth/register`, {
            name,
            email,
            password,
        }).then(() => {
            window.axios.post(`${_api}/auth/login`, {
                email,
                password,
            })
                .then(res => {
                    localStorage.setItem('token', res.data.access_token)
                    window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
                    props.log_in(true)
                    setTimeout(() => window.location.reload(), 1000)
                })
            props.toggleRegSuccessModal(true)
        })
    }


      const [newPhone, setNewPhone] = useState(props.info.phone)


    return (
        <div className="order-data__section order-data__section--double">
            <div className="order-data__user-form form" >
                <h5 className="order-data__section-title">
                    Контактна інформація
                </h5>
                <label className="form__label">
                    <input
                        className={`form__input ${props.errorName ? 'empty-feedback' : ''}`}
                        type="text"
                        name="name"
                        placeholder=" "
                        required
                        defaultValue={props.info.name}
                        onChange={e => {
                            dispatch({type: CHANGE_USER_NAME, payload: e.target.value})
                            props.setErrorName(false)
                        }}
                    />
                    <span className="required">
                            Ім’я
                        </span>
                </label>
                <label className="form__label">
                    <input
                        className={`form__input ${props.errorLastName ? 'empty-feedback' : ''}`}
                        type="text"
                        name="name"
                        placeholder=" "
                        required
                        defaultValue={props.info.lastName}
                        onChange={e => {
                            dispatch({type: CHANGE_USER_LASTNAME, payload: e.target.value})
                            props.setErrorLastName(false);
                        }}
                    />
                    <span className="required">
                            Прізвище
                        </span>
                </label>
                <label className="form__label phone-input">
                    <InputMask
                        mask="+38 (099) 999-99-99"
                        value={newPhone || props.info.phone}
                        onChange={e => {
                            dispatch({type: CHANGE_USER_PHONE, payload: e.target.value})
                            props.setErrorPhone(false);
                            setNewPhone(e.target.value)
                        }}
                        required
                        className={`form__input ${props.errorPhone ? 'empty-feedback' : ''}`}
                        placeholder="+38 (0__) ___-__-__"
                    />
                    <span className="required">
                        Телефон
                    </span>
                </label>
                <label className="form__label form__label--email">
                    <input
                        className={`form__input ${props.errorEmail ? 'empty-feedback' : ''}`}
                        type="email"
                        name="email"
                        placeholder=" "
                        required
                        defaultValue={props.info.email}
                        onChange={e => {
                            dispatch({type: CHANGE_USER_EMAIL, payload: e.target.value})
                            props.setErrorEmail(false)
                        }}
                    />
                    <span className="required">
                            Електронна адреса
                        </span>
                </label>
                <div className="order-data__form-subtitle">
                    Електронна адреса необхідна для отримання статусу вашого замовлення
                </div>
            </div>
            {
                props.info.isLogged? null : (
                    <div className="order-data__login-section">
                        <div className="order-data__login-title">
                            Маєте акаунт?
                        </div>
                        <div className="order-data__login-subtitle">
                            Авторизуйтесь і ваші дані будуть автоматично заповнені та збережені.
                        </div>
                        <button className="order-data__login-btn btn-dark" onClick={() => {
                            props.toggleModal(true)
                            props.toggleLogModal(true)
                        }}>
                            Увійти
                        </button>
                        <h6>
                            Або авторизуйтесь  через соціальні мережі:
                        </h6>
                        <div className="socials">
                            <FacebookLogin
                                appId={_facebookAuthKey}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={res => {
                                    login(res.email, res.userID, res.name)
                                }}
                                tag={'div'}
                                cssClass={'social-item-facebook'}
                                textButton={
                                    <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.13991 21.9997V11.9667H10.383L10.8686 8.05674H7.13991V5.56032C7.13991 4.42831 7.44267 3.65678 9.00609 3.65678L11 3.65583V0.158785C10.655 0.111308 9.47149 0.00488281 8.09453 0.00488281C5.21972 0.00488281 3.25156 1.82696 3.25156 5.17329V8.05685H3.05176e-05V11.9668H3.25146V21.9998L7.13991 21.9997Z" fill="#3C5A9A"/>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="11" height="22" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                }
                            />
                            <GoogleLogin
                                clientId={_googleAuthKey}
                                onSuccess={res => {
                                    login(res.profileObj.email, res.profileObj.googleId, res.profileObj.name)
                                }}
                                onFailure={res => console.log(res)}
                                cookiePolicy={'single_host_origin'}
                                tag={'div'}
                                className={'social-item-google'}
                                buttonText={''}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const mdtp = {
    toggleModal,
    toggleLogModal,
    toggleLoginSuccessModal,
    log_in,
    toggleLoginModal,
}

export default connect(null, mdtp)(OrderBodyDataNotLogged)