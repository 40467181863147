import React from 'react'
import {_api, _facebookAuthKey, _googleAuthKey} from "../../../../../API";
import {connect} from 'react-redux'
import {toggleModal, toggleLogModal, toggleLoginSuccessModal, toggleLoginModal} from "../../../../../redux/actionCreators/modalsCreator";
import {log_in} from "../../../../../redux/actionCreators/userCreator";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";

const LoginSocial = props => {

    const login = (email, password, name) => {
        window.axios.post(`/auth/login`, {
            email,
            password,
        })
            .then(res => {
                localStorage.setItem('token', res.data.access_token)
                window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
                props.log_in(true)
                props.toggleLoginModal(true)
                props.toggleLoginSuccessModal(true)
                setTimeout(() => window.location.reload(), 500)
            })
            .catch(() => {
                reg(email, password, name)
            })
    }
    const reg = (email, password, name) => {
        window.axios.post(`/auth/register`, {
            name,
            email,
            password,
        }).then(() => {
            window.axios.post(`${_api}/auth/login`, {
                email,
                password,
            })
                .then(res => {
                    localStorage.setItem('token', res.data.access_token)
                    window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
                    props.log_in(true)
                    setTimeout(() => window.location.reload(), 1000)
                })
            props.toggleRegSuccessModal(true)
        })
    }

    return (
        <div className='login-social-block'>
            <div className="grey-line"/>
            <p className="social-title">
                або
            </p>
            <p className="social-text">
                Продовжити з
            </p>
            <div className="socials">
                <FacebookLogin
                    appId={_facebookAuthKey}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={res => {
                        login(res.email, res.userID, res.name)

                    }}
                    tag={'div'}
                    cssClass={'social-item-facebook'}
                    textButton={
                        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.13991 21.9997V11.9667H10.383L10.8686 8.05674H7.13991V5.56032C7.13991 4.42831 7.44267 3.65678 9.00609 3.65678L11 3.65583V0.158785C10.655 0.111308 9.47149 0.00488281 8.09453 0.00488281C5.21972 0.00488281 3.25156 1.82696 3.25156 5.17329V8.05685H3.05176e-05V11.9668H3.25146V21.9998L7.13991 21.9997Z" fill="#3C5A9A"/>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="11" height="22" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    }
                />
                <GoogleLogin
                    clientId={_googleAuthKey}
                    onSuccess={res => {
                        login(res.profileObj.email, res.profileObj.googleId, res.profileObj.name)
                    }}
                    onFailure={res => console.log(res)}
                    cookiePolicy={'single_host_origin'}
                    tag={'div'}
                    className={'social-item-google'}
                    buttonText={''}
                />
            </div>
        </div>
    )
}

const mdtp = {
    toggleModal,
    toggleLogModal,
    toggleLoginSuccessModal,
    log_in,
    toggleLoginModal,
}

export default connect(null, mdtp)(LoginSocial)