import React from "react";
import CatalogFilterBlockItem from "./CatalogFilterBlockItem";

const CatalogFilterBlockSpecification = (props) => {
    return (
        <>
            {
                Object.keys(props.specifications).map((data, i) => {
                    return (
                        <div className="filter__section">
                            <div className="filter__title">{ props.specifications[data].property.title }</div>
                            <div className="filter__form">
                                {
                                    props.specifications[data].values.map((dataValue, i) => {
                                        return (
                                            <CatalogFilterBlockItem
                                                key={i}
                                                data={dataValue}
                                                checked={props.appliedFilters?.specifications}
                                                productCount={props.productCount}
                                                urlFilters={props.urlFilters}
                                                setUrlFilters={props.setUrlFilters}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default CatalogFilterBlockSpecification