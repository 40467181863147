import React from 'react'
import ProductPageInfoPricingPrice from "./ProductPageInfoPricingPrice";
import ProductPageInfoPricingDiscount from "./ProductPageInfoPricingDiscount";

const ProductPageInfoPricing = (props) => {

    return (
        <div className="product-info__pricing">
            <ProductPageInfoPricingPrice product={props.product}/>
            {
                props.product.discount > 0 ? (
                    <ProductPageInfoPricingDiscount discount={props.product.discount.toLocaleString()}/>
                ) : ''
            }
        </div>
    )
}

export default ProductPageInfoPricing