import React from 'react'

const   ProductCardTag = (props) => {

    return (
        <div className="product-card__label-wrap">
            {
                props.tag.map((data, i) => {
                    return (
                        <div key={i} className={`product-card__label product-card__label--${data === 'new' ? 'new' : 'discount'}`}>
                            {data === 'new' ? 'Новинки' : 'Акція'}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ProductCardTag