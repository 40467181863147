import React from "react";
import Rating from "react-rating";
import starList from "../../../../../img/main-page/star-empty.svg";
import star from "../../../../../img/main-page/star.svg";
import {declOfNum} from "../../../../FeedbackNumbers";
import {Link} from "react-router-dom";

const ProductCardRating = (props) => {

    return (
        <div className="product-card__rating">
            <div className="product-card__stars">
                <Rating
                    readonly
                    fractions={2}
                    placeholderRating={props.data.rating}
                    emptySymbol={<img src={starList} className="icon" alt='star'/>}
                    placeholderSymbol={<img src={star} className="icon" alt='star'/>}
                    fullSymbol={<img src={star} className="icon" alt='star'/>}
                />
            </div>
            <Link className="product-card__response-link" to={`/product_card/${props.data.slug}#product-reviews`}>{props.data.rates_count} {declOfNum(props.data.rates_count, ['відгук', 'відгуки', 'відгуків'])}</Link>
        </div>
    )
}

export default ProductCardRating