import React from "react";

import { Link } from "react-router-dom";

import logo from "../../../img/logoNew/logoLikaComfortLigth.svg";

const LogoHead = () => {
  return (
    <div className="header__logo-wrap">
      <Link to="/">
        <img className="header__logo" src={logo} alt="Логотип" />
      </Link>
    </div>
  );
};

export default LogoHead;
