import {CHANGE_CASES_SORT} from "../../actionConst/action";
import slide1 from '../../../img/cases/slide-img1.jpg'
import slide2 from '../../../img/cases/slide-img2.jpg'
import slide3 from '../../../img/cases/slide-img3.jpg'
import slide4 from '../../../img/cases/slide-img4.jpg'
import slide5 from '../../../img/cases/slide-img5.jpg'
import slide6 from '../../../img/cases/slide-img6.jpg'
import slide7 from '../../../img/cases/slide-img7.jpg'
import slide8 from '../../../img/cases/slide-img8.jpg'
import {GET_CASES_ARTICLE, GET_CASES_ARTICLES, GET_CASES_CATEGORIES} from "../../actionConst/getActions";

const initialState = {
    activeCategory: 'Всі',
    articles: [],
    categories: [
        'Всі', 'Кондиціонування та вентиляція', 'Енергозбереження',
    ],
    slider: [
        slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8
    ],
    article: {}
}

export const casesReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_CASES_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case GET_CASES_ARTICLES:
            return {
                ...state,
                articles: action.payload
            }
        case GET_CASES_ARTICLE:
            return {
                ...state,
                article: action.payload
            }
        case CHANGE_CASES_SORT:
            return {
                ...state,
                activeCategory: action.payload
            }
        default:
            return state
    }
}