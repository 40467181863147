import React, {useEffect, useState} from 'react'

const OrderConfirm = props => {

    const orderId = JSON.parse(localStorage.getItem('order')).id
    const [payButton, setPayButton] = useState()

    useEffect(() => {
        window.axios.get(`/orders/${orderId}/payment`, {
            params: {
                button_text: 'Підтвердіть оплату картою',
                button_class: 'entrance-modal__btn',
            }
        })
            .then(res => {
                setPayButton(res.data.data)
            })
    }, [])

    return (
        <div className={`entrance-modal ${props.orderConfirm? 'active' : ''}`}>
            <button
                className="close-btn"
                onClick={() => {
                    props.toggleModal(false)
                    props.toggleOrderConfirm(false)
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="success__title">Підтвердіть оплату картою</div>
            <div dangerouslySetInnerHTML={{__html: [payButton]}}/>
        </div>
    )
}

export default OrderConfirm