import React, {Fragment} from 'react'
import BasketModalItem from "./BasketModalItem/BasketModalItem";
import BasketModalCashBack from "./BasketModalCashBack";
import BasketModalPriceTotal from "./BasketModalPriceTotal";
import {Link} from "react-router-dom";

const BasketModalTrue = (props) => {

    return (
        <>
            <h3 className="modal-basket__title">Кошик</h3>
            <div className="modal-basket__body modal-basket__body--filled">
                <div className="modal-basket__items-wrap">
                    {
                        props.products.length > 0 ? (
                            props.products.map((data, i) => {
                                return (
                                    <BasketModalItem key={data.id} basket={props.products} num={i} products={data}/>
                                )
                            })
                        ) : ''
                    }
                </div>
                <div className="modal-basket__footer">
                    <div className="modal-basket__footer-top">
                        <BasketModalCashBack products={props.products}/>
                        <BasketModalPriceTotal products={props.products}/>
                    </div>
                    <div className="modal-basket__footer-bottom">
                        <div className="modal-basket__footer-links">
                            <div className="modal-basket__footer-link modal-basket__footer-link--continue-shopping"
                                 onClick={props.openBasket}>
                                Продовжити покупки
                            </div>
                            <Link className="modal-basket__footer-link modal-basket__footer-link--to-basket"
                                  to="/basket" onClick={props.openBasket}>До кошику</Link>
                        </div>
                        <Link to='/order' className="modal-basket__confirm-btn btn-light">
                            Оформити замовлення
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BasketModalTrue