import {GET_PRODUCTS_FROM_SEARCH} from "../../actionConst/getActions";
import {SEARCH_PRODUCTS, SET_SEARCH_URL_FILTERS} from "../../actionConst/action";

const urlParams = new URLSearchParams(window.location.search);

const initialState = {
    products: [],
    search: urlParams.get('q')??'',
    searchUrlFilters: window.location.pathname+window.location.search,
}

export const searchReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_PRODUCTS_FROM_SEARCH:
            return {
                ...state,
                products: action.payload
            }
        case SEARCH_PRODUCTS:
            return {
                ...state,
                search: action.payload
            }
        case SET_SEARCH_URL_FILTERS:
            return {
                ...state,
                searchUrlFilters: action.payload
            }
        default:
            return state
    }
}