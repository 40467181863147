import React, {useEffect, useState} from 'react'
import {blogPageReducer} from "../../redux/reducers/blogReducers/blogPageReducer";
import {connect} from 'react-redux'
import {getBlogArticle} from "../../redux/actionCreators/getCreators/getBlog";
import './BlogPage.scss'
import BlogPageOffers from "./BlogPageOffers";
import CallBackForm from "../globalComponents/CallBackForm/CallBackForm";
import BreadCrumbs from "../globalComponents/BreadCrumbs/BreadCrumbs";
import ScrollToTopOnMount from "../../ScrolToTop";
import Preloader from "../globalComponents/Preloader/Preloader";
import ArticleSlider from "../globalComponents/ArticleSlider/ArticleSlider";
import JsonLd from '../globalComponents/JsonLd/JsonLd';
import {_domain, _images} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const BlogPage = props => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        document.title = props.article.title
    })

    const [slider, setSlider] = useState([])

    useEffect(() => {
        setLoading(true)
        window.axios.get(`/posts/${props.match.params.slug}`)
            .then(res => {
                props.getBlogArticle(res.data.data)
                setSlider(res.data.data.images)
            })
            .finally(() => setLoading(false))
    }, []);

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: 'Блог', link: '/blog'},
        {name: props.article.title, link: `/blog_page/${props.match.params.slug}`}
    ]

    const jsonLd = {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "headline": props.article.title,
        "image": _images+props.article.banner_image,
        "alternativeHeadline": props.article.title,
        "description": props.article.schema_text,
        "datePublished": props.article.schema_date_created,
        "dateCreated": props.article.schema_date_created,
        "dateModified": props.article.schema_date_modified,
        "articleBody": props.article.schema_text,
        "wordcount": props.article.schema_text_count,
        "mainEntityOfPage":{
            "@type": "WebPage",
            "@id": props.article.link
         },
        "publisher": {
            "@type": "Organization",
            "name": "Ліка Комфорт",
            "logo": {
                "@type": "ImageObject",
                "url": _domain+logo
            }
        },
        "author":{
            "@type": "Person",
            "name": "Ліка Комфорт"
        }
    };

    const datePublished = new Date();
    datePublished.setMonth(datePublished.getMonth()-3);

    let img;
    const jsonLdImg = (slider !== null) ? slider.map((val, index) => {
        img = new Image();
        img.src = _images+val;

        return {
            "@context": "http://schema.org",
            "@type": "ImageObject",
            "contentUrl": _images+val,
            "datePublished": datePublished.toISOString(),
            "name": props.article.title+' №'+(index+1),
            "description": props.article.title+' №'+(index+1),
            "width": img.width,
            "height": img.height,
            "author": "Ліка Комфорт"
        }
    }) : [];

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <JsonLd data={jsonLd} />
            <JsonLd data={jsonLdImg} />
            <div className="content">
                <main className="blog-article">
                    <div className="container">
                        <BreadCrumbs items={crumbs}/>
                        {
                            loading ? (
                                <Preloader />
                            ) : (
                                <>
                                    <h1 className="blog-article__title">{props.article.title}</h1>
                                    <main className="blog-article__body">
                                        <article className="blog-article__content" dangerouslySetInnerHTML={{__html:[props.article.text]}} />
                                        {
                                            props.article.products ? (
                                                props.article.products.length > 0 ? (
                                                    <BlogPageOffers
                                                        offers={props.article.products}
                                                        banner_image={props.article.banner_image}
                                                        banner_title={props.article.banner_title}
                                                    />
                                                ) : ''
                                            ) : ''
                                        }
                                    </main>
                                </>
                            )
                        }
                        {
                            slider ? (
                                slider.length > 0 ? <ArticleSlider slider={slider}/> : ''
                            ) : ''
                        }
                    </div>
                    <CallBackForm title={props.article.title}/>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        products: store.blogPageReducer.products,
        banner: store.blogPageReducer.banner,
        article: store.blogReducer.article,
    }
}

const mapDispatchToProps = {
    blogPageReducer,
    getBlogArticle,
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage)
