import {
  ADD_CASHBACK,
  ADD_DELIVERY_AT,
  ADD_DELIVERY_BRANCH,
  ADD_DELIVERY_CITY,
  ADD_DELIVERY_CODE,
  ADD_DELIVERY_COST,
  ADD_DELIVERY_PERIOD_PRIVAT,
  ADD_DELIVERY_PERIOD_MONO,
  ADD_DELIVERY_EMAIL,
  ADD_DELIVERY_FLOOR,
  ADD_DELIVERY_HOUSE,
  ADD_DELIVERY_NAME,
  ADD_DELIVERY_PAY,
  ADD_DELIVERY_PAY_BITRIX,
  ADD_DELIVERY_STREET,
  ADD_DELIVERY_WAY,
} from "../actionConst/action";

export const addCashback = (payload) => {
  return {
    type: ADD_CASHBACK,
    payload,
  };
};
export const addDeliveryCity = (payload) => {
  return {
    type: ADD_DELIVERY_CITY,
    payload,
  };
};
export const addDeliveryCost = (payload) => {
  return {
    type: ADD_DELIVERY_COST,
    payload,
  };
};
export const addDeliveryPeriodPrivat = (payload) => {
  return {
    type: ADD_DELIVERY_PERIOD_PRIVAT,
    payload,
  };
};
export const addDeliveryPeriodMono = (payload) => {
  return {
    type: ADD_DELIVERY_PERIOD_MONO,
    payload,
  };
};
export const addDeliveryWay = (payload) => {
  return {
    type: ADD_DELIVERY_WAY,
    payload,
  };
};
export const addDeliveryPay = (payload) => {
  return {
    type: ADD_DELIVERY_PAY,
    payload,
  };
};
export const addDeliveryPayForBitrix = (payload) => {
  return {
    type: ADD_DELIVERY_PAY_BITRIX,
    payload,
  };
};
export const addDeliveryEmail = (payload) => {
  return {
    type: ADD_DELIVERY_EMAIL,
    payload,
  };
};
export const addDeliveryName = (payload) => {
  return {
    type: ADD_DELIVERY_NAME,
    payload,
  };
};
export const addDeliveryCode = (payload) => {
  return {
    type: ADD_DELIVERY_CODE,
    payload,
  };
};
export const addDeliveryBranch = (payload) => {
  return {
    type: ADD_DELIVERY_BRANCH,
    payload,
  };
};
export const addDeliveryStreet = (payload) => {
  return {
    type: ADD_DELIVERY_STREET,
    payload,
  };
};
export const addDeliveryHouse = (payload) => {
  return {
    type: ADD_DELIVERY_HOUSE,
    payload,
  };
};
export const addDeliveryAt = (payload) => {
  return {
    type: ADD_DELIVERY_AT,
    payload,
  };
};
export const addDeliveryFloor = (payload) => {
  return {
    type: ADD_DELIVERY_FLOOR,
    payload,
  };
};
