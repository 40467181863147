import React from 'react'
import {connect} from 'react-redux'
import QuizModal from "./QuizModal/QuizModal";
import ProductPageModal from "./ProductPageModal/ProductPageModal";
import {
    toggleProductSliderModal,
    toggleModal,
    toggleQuiz,
    toggleSortModal,
    toggleLogModal,
    toggleFeedbackSuccessModal,
    toggleBuyOneClick,
    toggleBuyOneClickMobile,
    toggleSubscribeModal,
    toggleFeedbackModal,
    togglePhoneModal,
    toggleCallMeBack,
    toggleBasketModal,
    toggleOrderConfirm,
    toggleResetPasswordModal,
} from "../../../redux/actionCreators/modalsCreator";
import SortModal from "./SortModal/SortModal";
import LogModal from "./LogModal/LogModal";
import FeedbackSuccess from "./FeedbackSuccess/FeedbackSuccess";
import BuyOneClickThx from "./BuyOneClickThx/BuyOneClickThx";
import SubscribeModal from "./SubscribeModal/SubscribeModal";
import FeedbackModal from "./FeedbackModal/FeedbackModal";
import PhoneModal from "./PhoneModal/PhoneModal";
import BuyOneClick from "./BuyOneClick/BuyOneClick";
import CallMeBack from "./CallMeBack/CallMeBack";
import BasketModal from "./BasketModal/BasketModal";
import OrderConfirm from "./OrderConfirm";
import NewPassword from "./LogModal/ForgotModal/NewPassword";

const Modals = props => {

    return (
        <div className={`modals ${props.modal? 'active' : ''}`}>
            <div
                className='dark_bg'
                onClick={() => {
                    props.toggleProductSliderModal(false)
                    props.toggleModal(false)
                    props.toggleQuiz(false)
                    props.toggleSortModal(false)
                    props.toggleLogModal(false)
                    props.toggleFeedbackSuccessModal(false)
                    props.toggleFeedbackModal(false)
                    props.toggleBuyOneClick(false)
                    props.toggleBuyOneClickMobile(false)
                    props.toggleSubscribeModal(false)
                    props.togglePhoneModal(false)
                    props.toggleCallMeBack(false)
                    props.toggleBasketModal(false)
                    props.toggleOrderConfirm(false)
                    props.toggleResetPasswordModal(false)
                }}
            />
            {
                props.orderConfirm ? (
                    <OrderConfirm
                        toggleModal={props.toggleModal}
                        toggleOrderConfirm={props.toggleOrderConfirm}
                        orderConfirm={props.orderConfirm}
                    />
                ) : ''
            }
            {
                props.basketModal ? (
                    <BasketModal
                        toggleModal={props.toggleModal}
                        basketModal={props.basketModal}
                        toggleBasketModal={props.toggleBasketModal}
                    />
                ) : ''
            }
            {
                props.quiz ? (
                    <QuizModal
                        quiz={props.quiz}
                        toggleQuiz={props.toggleQuiz}
                        toggleModal={props.toggleModal}
                    />
                ) : ''
            }
            {
                props.productSliderModal ? (
                    <ProductPageModal
                        toggleProductSliderModal={props.toggleProductSliderModal}
                        productSliderModal={props.productSliderModal}
                        toggleModal={props.toggleModal}
                    />
                ) : ''
            }
            {
                props.sortModal ? (
                    <SortModal
                        toggleModal={props.toggleModal}
                        toggleSortModal={props.toggleSortModal}
                        sortModal={props.sortModal}
                    />
                ) : ''
            }
            {
                props.log ? (
                    <LogModal
                        toggleModal={props.toggleModal}
                        toggleLogModal={props.toggleLogModal}
                        log={props.log}
                    />
                ) : ''
            }
            {
                props.feedbackSuccess ? (
                    <FeedbackSuccess
                        feedbackSuccess={props.feedbackSuccess}
                        toggleModal={props.toggleModal}
                        toggleFeedbackSuccessModal={props.toggleFeedbackSuccessModal}
                    />
                ) : ''
            }
            {
                props.buyOneClick ? (
                    <BuyOneClickThx
                        buyOneClick={props.buyOneClick}
                        toggleModal={props.toggleModal}
                        toggleBuyOneClick={props.toggleBuyOneClick}
                    />
                ) : ''
            }
            {
                props.buyOneClickMobile ? (
                    <BuyOneClick
                        buyOneClickMobile={props.buyOneClickMobile}
                        toggleModal={props.toggleModal}
                        toggleBuyOneClickMobile={props.toggleBuyOneClickMobile}
                        toggleBuyOneClick={props.toggleBuyOneClick}
                    />
                ) : ''
            }
            {
                props.callMeBack ? (
                    <CallMeBack
                        callMeBack={props.callMeBack}
                        toggleModal={props.toggleModal}
                        toggleCallMeBack={props.toggleCallMeBack}
                        toggleBuyOneClick={props.toggleBuyOneClick}
                    />
                ) : ''
            }
            {
                props.subscribeModal ? (
                    <SubscribeModal
                        subscribeModal={props.subscribeModal}
                        toggleModal={props.toggleModal}
                        toggleSubscribeModal={props.toggleSubscribeModal}
                    />
                ) : ''
            }
            {
                props.feedbackModal ? (
                    <FeedbackModal
                        feedbackModal={props.feedbackModal}
                        toggleModal={props.toggleModal}
                        toggleFeedbackModal={props.toggleFeedbackModal}
                        toggleFeedbackSuccessModal={props.toggleFeedbackSuccessModal}
                    />
                ) : ''
            }
            {
                props.phoneModal ? (
                    <PhoneModal
                        toggleModal={props.toggleModal}
                        phoneModal={props.phoneModal}
                        togglePhoneModal={props.togglePhoneModal}
                    />
                ) : ''
            }
            {
                props.resetPassword ? (
                    <NewPassword />
                ) : ''
            }
        </div>
    )
}

const mapStateToProps = store => {
    return {
        modal: store.modalReducer.modal,
        quiz: store.modalReducer.quiz,
        productSliderModal: store.modalReducer.productSliderModal,
        sortModal: store.modalReducer.sortModal,
        log: store.modalReducer.log,
        feedbackSuccess: store.modalReducer.feedbackSuccess,
        feedbackModal: store.modalReducer.feedbackModal,
        buyOneClick: store.modalReducer.buyOneClick,
        buyOneClickMobile: store.modalReducer.buyOneClickMobile,
        subscribeModal: store.modalReducer.subscribeModal,
        phoneModal: store.modalReducer.phoneModal,
        callMeBack: store.modalReducer.callMeBack,
        basketModal: store.modalReducer.basketModal,
        orderConfirm: store.modalReducer.orderConfirm,
        resetPassword: store.modalReducer.resetPassword,
    }
}
const mapDispatchToProps = {
    toggleProductSliderModal,
    toggleModal,
    toggleQuiz,
    toggleSortModal,
    toggleLogModal,
    toggleFeedbackSuccessModal,
    toggleFeedbackModal,
    toggleBuyOneClick,
    toggleBuyOneClickMobile,
    toggleSubscribeModal,
    togglePhoneModal,
    toggleCallMeBack,
    toggleBasketModal,
    toggleOrderConfirm,
    toggleResetPasswordModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modals)