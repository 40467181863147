import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {CLEAN_COMPARE} from "../../../redux/actionConst/action";
import CompareHeaderSelectItem from "./CompareHeaderSelectItem";

const CompareHeader = (props) => {

    const dispatch = useDispatch()

    const [active, setActive] = useState(false)
    const open = () => {
        setActive(!active)
    }

    // categories

    const cleanCompare = () => {
        window.axios.delete(`/compares/destroy-all`)
        dispatch({type: CLEAN_COMPARE})
    }

    const isLogged = useSelector(store => store.userReducer.info.isLogged)

    return (
        <>
            <h1 className="page-title">
                Порівняння товарів
            </h1>
            <div className="compare-filter">
                <div className="compare-filter__compare-products">
                    <div className="compare-filter__filter-title">
                        Товари для порівняння:
                    </div>
                    <div className={`compare-filter__dropdown ${active? 'active' : ''}`} onClick={open}>
                        <div className="compare-filter__dropdown-head">
                            <div className="compare-filter__item">
                                {props.select}
                                <span className="compare-filter__item-quantity">
                                    ({props.productsCompare.length})
                                </span>
                            </div>
                            <svg className="arrow-down" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                      d="M1.74665 7.62315C1.81349 7.49007 1.93037 7.38893 2.07167 7.34191C2.21297 7.29488 2.36715 7.3058 2.5004 7.37227L8.99953 10.6201L15.4975 7.37115C15.5636 7.33738 15.6358 7.31702 15.7098 7.31127C15.7838 7.30551 15.8582 7.31445 15.9288 7.33759C15.9993 7.36073 16.0646 7.39761 16.1208 7.44609C16.177 7.49457 16.2231 7.55369 16.2563 7.62006C16.2896 7.68643 16.3094 7.75873 16.3145 7.83278C16.3197 7.90683 16.3102 7.98118 16.2865 8.05154C16.2628 8.12189 16.2255 8.18686 16.1765 8.24271C16.1276 8.29855 16.0682 8.34415 16.0015 8.3769L9.25153 11.7519C9.17331 11.7911 9.08702 11.8115 8.99953 11.8115C8.91204 11.8115 8.82575 11.7911 8.74753 11.7519L1.99753 8.3769C1.86445 8.31006 1.76331 8.19318 1.71629 8.05188C1.66926 7.91058 1.68018 7.7564 1.74665 7.62315Z"
                                      fill="#43B02A"/>
                            </svg>
                        </div>
                        <div className="compare-filter__dropdown-body">
                            {
                                props.categories.map((data, i) => {
                                    return (
                                        <CompareHeaderSelectItem
                                            open={open}
                                            choose={props.choose}
                                            products={props.products}
                                            data={data}
                                            key={i}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <button className="compare-filter__clear-btn" onClick={() => {
                        cleanCompare()
                    }}>
                        Очистити всі
                    </button>
                </div>
                {
                    isLogged ? (
                        <div className="compare-filter__compare-sort">
                            <div className="compare-filter__filter-title">
                                Показувати:
                            </div>
                            <form className="compare-filter__sort-form">
                                <label className="radio-wrap">
                                    <input
                                        type="radio"
                                        name="sort"
                                        checked={!props.reverse}
                                        onChange={() => {
                                            props.setReverse(false)
                                        }}
                                    />
                                    <span className="radio">
                                Всі параметри
                            </span>
                                </label>
                                <label className="radio-wrap">
                                    <input
                                        type="radio"
                                        name="sort"
                                        checked={props.reverse}
                                        onChange={() => {
                                            props.setReverse(true)
                                        }}
                                    />
                                    <span className="radio">
                                Відмінності
                            </span>
                                </label>
                            </form>
                        </div>
                    ) : ''
                }
            </div>
        </>
    )
}

export default CompareHeader