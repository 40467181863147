import React from 'react';

const JsonLd = ({data}) => {
    return (
        <>
            {
                data && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{__html: JSON.stringify(data)}}
                    />
                )
            }
        </>
    );
};

export default JsonLd;
