//import BLL
import React from 'react'
//import components
import SocialBox from "./SocialBox";
import TopInfo from "./TopInfo/TopInfo";
import HeaderPhone from "./HeaderPhone";
//import images


//import styles


//component
const HeaderTop = (props) => {

    return (
        <div className="header__top">
            <div className="container">
                <SocialBox />
                <TopInfo />
                <HeaderPhone phones={props.phones}/>
            </div>
        </div>
)
}

export default HeaderTop