import React, {useState} from 'react'
import Nouislider from "nouislider-react";

const QuizModalFirst = props => {

    const [value, setValue] = useState('')

    const [inputError, setInputError] = useState(false)
    const [inputValue, setInputValue] = useState(props.firstQuiz)

    const changeValue = e => {
        setValue(e.target.value)
    }

    const changeInputValue = () => {
        setInputValue(value)
    }

    return (
        <div className="quiz-modal__form quiz-modal__form--square" id='firstQuiz'>
            <h4
                className="quiz-modal__form-title"
                style={{color: inputError ? 'red ': ''}}
            >
                Введіть площу приміщення, м2</h4>
            <div className="quiz-modal__input-wrap">
                <input
                    className="quiz-modal__form-input"
                    value={value === 0 ? '' : parseInt(value)}
                    onChange={(event) => {
                        changeValue(event);
                        setInputError(false)
                        }
                    }
                    autoFocus={true}
                    id="quiz-square-input"
                    type="number"
                    placeholder="0"
                />
            </div>
            <div className="quiz-modal__range-input-wrap">
                <Nouislider
                    step={1}
                    range={{ min: 0, max: 100 }}
                    start={0}
                    onUpdate={(event) => {
                        setValue(parseInt(event))
                        setInputError(false)
                        }
                    }
                    connect={[true, false]} />
                <div className="quiz-modal__range-values-wrap">
                    <div className="quiz-modal__range-value-min">0</div>
                    <div className="quiz-modal__range-value-max">100</div>
                </div>
            </div>
            <button
                className="quiz-modal__submit-btn"
                style={{background: inputError ? "gray": ""}}
                onClick={() => {
                    if(Number(value)) {
                        changeInputValue()
                        props.changeQuiz('secondQuiz')
                        props.setFirstFilled(true)
                        props.addFirstQuiz(parseInt(value))
                    } else {
                        setInputError(true)
                    }

            }}>
                Далі
            </button>
        </div>
    )
}

export default QuizModalFirst