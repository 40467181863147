import React from 'react';

import './OrderBodyDataAdditionalServices.scss';

import {connect} from 'react-redux';
import OrderBodyDataAdditionalServicesItem from './OrderBodyDataAdditionalServicesItem';

const OrderBodyDataAdditionalServices = props => {

    return (
        <div className="order-data__section">
            <h5 className="order-data__section-title">Додаткові послуги</h5>
            {
                props.basket.filter(product => product.services.length > 0).map(product => (
                    <OrderBodyDataAdditionalServicesItem
                        key={product.id}
                        product={product}
                    />
                ))
            }
        </div>
    );
};

const mapStateToProps = store => {
    return {
        basket: store.basketReducer.basket,
    };
};

export default connect(mapStateToProps)(OrderBodyDataAdditionalServices);
