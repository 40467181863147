import {
    CHANGE_USER_EMAIL,
    CHANGE_USER_LASTNAME,
    CHANGE_USER_NAME,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PHONE,
    CHANGE_USER_LOCATION,
    USER_LOGIN,
    USER_LOGOUT
} from "../actionConst/action";


export const log_in = payload => {
    return {
        type: USER_LOGIN,
        payload
    }
}
export const log_out = () => {
    return {
        type: USER_LOGOUT
    }
}
export const change_name = payload => {
    return {
        type: CHANGE_USER_NAME,
        payload
    }
}
export const change_phone = payload => {
    return {
        type: CHANGE_USER_PHONE,
        payload
    }
}
export const change_lastname = payload => {
    return {
        type: CHANGE_USER_LASTNAME,
        payload
    }
}
export const change_email = payload => {
    return {
        type: CHANGE_USER_EMAIL,
        payload
    }
}
export const change_password = payload => {
    return {
        type: CHANGE_USER_PASSWORD,
        payload
    }
}

export const change_user_location = payload => {
    return {
        type: CHANGE_USER_LOCATION,
        payload
    }
}