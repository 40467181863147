import React, {useEffect, useState} from 'react'
import searchIco from "../../../img/header/search-ico-mobile.svg";
import {connect} from 'react-redux'
import {searchProducts, setSearchUrlFilters} from "../../../redux/actionCreators/searchReducer";
import {getSearchProducts} from "../../../redux/actionCreators/getCreators/getGlobal";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const HeaderBottomSearch = props => {

    const history = useHistory()

    const [search, setSearch] = useState('')

    const searchApi = () => {
        if (search.length > 3) {
            window.axios.get(`/products?&search=${encodeURI(search)}`, {
                params: {
                    pageSize: 10
                }
            })
                .then(res => {
                    props.getSearchProducts(res.data.data)
                })
        }
    }

    const debounce = AwesomeDebouncePromise(searchApi, 500)

    useEffect(() => {
        debounce()
    }, [search])

    return (
        <div className="search-mobile-wrap">
            <div className="search-mobile">
                <input
                    className="search-mobile__bar"
                    type="search"
                    name="search-bar"
                    placeholder="Пошук"
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                    onKeyPress={e => {
                        if(e.key === 'Enter') {
                            props.searchProducts(search)
                            props.setSearchUrlFilters(`/search?q=${search}`)
                            history.push(`/search?q=${search}`)
                        }
                    }}
                />
                <Link to={`/search?q=${search}`} className="search-mobile__btn" onClick={() => {
                    props.searchProducts(search)
                    props.setSearchUrlFilters(`/search?q=${search}`)
                }}>
                    <img className="search-mobile__ico" src={searchIco} alt='пошук'/>
                </Link>
                { (props.products.length > 0 && search.length > 3) &&
                    <div className="header-search__dropdown">
                        <ul className='header-search__drop-items'>
                            {
                                props.products.map((data, i) => {
                                    return (
                                        <li key={i} className="header-search__drop-item">
                                            <Link to={`/product_card/${data.slug}`}>
                                                {data.name}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}

const mstp = store => {
    return {
        searchUrlFilters: store.searchReducer.searchUrlFilters,
        products: store.searchReducer.products,
    }
}

const mapDispatchToProps = {
    getSearchProducts,
    setSearchUrlFilters,
    searchProducts,
}

export default connect(mstp, mapDispatchToProps)(HeaderBottomSearch)