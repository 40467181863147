import React, {useState} from 'react'
import MenuBtn from "./Mobile/MenuBtn";
import LogoHead from "./LogoHead";
import HeaderSearch from "./HeaderSearch/HeaderSearch";
import HeaderBtn from "./HeaderBtn";
import MobileMenu from "./Mobile/MobileMenu";

//component
const HeaderMain = () => {

    const [mobileMenuCategory, setMobileMenuCategory] = useState(false)

    const openMobileMenu = () => {
        return (
            setMobileMenuCategory(!mobileMenuCategory)
        )
    }


    return (
        <div className="header__main">
            <div className="container">
                <MenuBtn openMobileMenu={openMobileMenu} mobileMenuCategory={mobileMenuCategory}/>
                <LogoHead />
                <HeaderSearch />
                <HeaderBtn />
                <MobileMenu
                    mobileMenuCategory={mobileMenuCategory}
                    setMobileMenuCategory={setMobileMenuCategory}
                    openMobileMenu={openMobileMenu}
                />
            </div>
        </div>
    )
}

export default HeaderMain