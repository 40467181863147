import React from 'react'
import {connect} from 'react-redux'
import profileMobile from "../../../../img/header/profile-mobile.svg";
import MobileMenuFooter from "./MobileMenuFooter";
import MobileMenuBody from "./MobileMenuBody/MobileMenuBody";
import {toggleLogModal, toggleModal} from "../../../../redux/actionCreators/modalsCreator";
import {useHistory} from "react-router";

const MobileMenu = props => {

    const phoneNumbers = [
        {number: '+38 066 99 29 111'},
        {number: '+38 068 99 29 111'},
    ]

    const history = useHistory()

    return (
        <div className={`mobile-menu ${props.mobileMenuCategory? 'active' : ''}`}>
            <div className="mobile-menu__bg" style={{display: !props.mobileMenuCategory && 'none'}} onClick={() => {
                if(props.mobileMenuCategory) {
                    props.openMobileMenu()
                }
            }}/>
            <div className="mobile-menu__overflow-wrap">
                <div className="mobile-menu__login-btn">
                    <div style={{display: 'flex'}}>
                        <img className="mobile-menu__login-ico" src={profileMobile} alt="Вхід в особистий кабінет"/>
                        {
                            props.isLogged? (
                                <div className="mobile-menu__login-text" onClick={() => {
                                    props.setMobileMenuCategory(false)
                                    history.push('/profile')
                                }} >Вітаю, {props.name}</div>
                            ) : (
                                <span className="mobile-menu__login-text" onClick={() => {
                                    props.toggleModal(true)
                                    props.toggleLogModal(true)
                                    props.setMobileMenuCategory(false)
                                }}>Вхід в особистий кабінет</span>
                            )
                        }
                    </div>
                </div>
                <MobileMenuBody
                    openMobileMenu={props.openMobileMenu}
                    mobileMenuSubcategory={props.mobileMenuSubcategory}
                    openMobileSubMenu={props.openMobileSubMenu}
                    setMobileMenuCategory={props.setMobileMenuCategory}
                />
                <MobileMenuFooter
                    phoneNumber={phoneNumbers}
                />
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        isLogged: store.userReducer.info.isLogged,
        name: store.userReducer.info.name,
    }
}
const mapDispatchToProps = {
    toggleModal,
    toggleLogModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)