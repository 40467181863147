import {
    TOGGLE_BASKET_MODAL,
    TOGGLE_BUY_ONE_CLICK, TOGGLE_BUY_ONE_CLICK_MOBILE, TOGGLE_CALL_ME_BACK, TOGGLE_FEEDBACK_MODAL,
    TOGGLE_FEEDBACK_SUCCESS,
    TOGGLE_FORGOT, TOGGLE_FORGOT_SUCCESS,
    TOGGLE_LOG, TOGGLE_LOGIN, TOGGLE_LOGIN_SUCCESS,
    TOGGLE_MODAL, TOGGLE_ORDER_CONFIRM, TOGGLE_PHONE_MODAL,
    TOGGLE_PRODUCT_SLIDER_MODAL,
    TOGGLE_QUIZ, TOGGLE_REG, TOGGLE_REG_SUCCESS, TOGGLE_RESET_PASSWORD_MODAL,
    TOGGLE_SORT_MODAL, TOGGLE_SUBSCRIBE_MODAL
} from "../../actionConst/action";

const initialState = {
    modal: false,
    quiz: false,
    productSliderModal: false,
    sortModal: false,
    log: false,
    login: true,
    loginSuccess: false,
    reg: false,
    regSuccess: false,
    forgot: false,
    forgotSuccess: false,
    feedbackSuccess: false,
    feedbackModal: false,
    buyOneClick: false,
    buyOneClickMobile: false,
    subscribeModal: false,
    phoneModal: false,
    callMeBack: false,
    basketModal: false,
    orderConfirm: false,
    resetPassword: false,
}

export const modalReducer = (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_ORDER_CONFIRM:
            return {
                ...state,
                orderConfirm: action.payload
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                modal: action.payload
            }
        case TOGGLE_QUIZ:
            return {
                ...state,
                quiz: action.payload
            }
        case TOGGLE_PRODUCT_SLIDER_MODAL:
            return {
                ...state,
                productSliderModal: action.payload
            }
        case TOGGLE_SORT_MODAL:
            return {
                ...state,
                sortModal: action.payload
            }
        case TOGGLE_LOG:
            return {
                ...state,
                log: action.payload
            }
        case TOGGLE_LOGIN:
            return {
                ...state,
                login: action.payload,
            }
        case TOGGLE_LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccess: action.payload
            }
        case TOGGLE_REG:
            return {
                ...state,
                reg: action.payload,
            }
        case TOGGLE_REG_SUCCESS:
            return {
                ...state,
                regSuccess: action.payload
            }
        case TOGGLE_FORGOT:
            return {
                ...state,
                forgot: action.payload,
            }
        case TOGGLE_FORGOT_SUCCESS:
            return {
                ...state,
                forgotSuccess: action.payload,
            }
        case TOGGLE_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbackSuccess: action.payload,
            }
        case TOGGLE_FEEDBACK_MODAL:
            return {
                ...state,
                feedbackModal: action.payload,
            }
        case TOGGLE_BUY_ONE_CLICK:
            return {
                ...state,
                buyOneClick: action.payload,
            }
        case TOGGLE_BUY_ONE_CLICK_MOBILE:
            return {
                ...state,
                buyOneClickMobile: action.payload,
            }
        case TOGGLE_SUBSCRIBE_MODAL:
            return {
                ...state,
                subscribeModal: action.payload,
            }
        case TOGGLE_PHONE_MODAL:
            return {
                ...state,
                phoneModal: action.payload,
            }
        case TOGGLE_CALL_ME_BACK: {
            return {
                ...state,
                callMeBack: action.payload,
            }
        }
        case TOGGLE_BASKET_MODAL:
            return {
                ...state,
                basketModal: action.payload,
            }
        case TOGGLE_RESET_PASSWORD_MODAL:
            return {
                ...state,
                resetPassword: action.payload,
            }
        default:
            return state
    }
}