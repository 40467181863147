import React, {useState} from 'react'
import OrderBodyData from "./OrderBodyData/OrderBodyData";
import OrderBodyBasket from "./OrderBodyBasket/OrderBodyBasket";

const OrderBody = props => {

    const [callback, setCallback] = useState(false)
    const toggleCallback = () => {
        setCallback(!callback)
    }

    const [errorName, setErrorName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorPhone, setErrorPhone] = useState(false)
    const [empty, setEmpty] = useState(false)
    
    return (
        <div className="order-page__body">
            <OrderBodyData
                
                basket={props.basket}
                callback={callback}
                toggleCallback={toggleCallback}
                errorName={errorName}
                errorLastName={errorLastName}
                errorEmail={errorEmail}
                errorPhone={errorPhone}
                setErrorName={setErrorName}
                setErrorEmail={setErrorEmail}
                setErrorLastName={setErrorLastName}
                setErrorPhone={setErrorPhone}
                empty={empty}
                setEmpty={setEmpty}
            />
            <OrderBodyBasket
                callback={callback}
                setErrorName={setErrorName}
                setErrorLastName={setErrorLastName}
                setErrorEmail={setErrorEmail}
                setErrorPhone={setErrorPhone}
                empty={empty}
                setEmpty={setEmpty}
            />
        </div>
    )
}

export default OrderBody