import {GET_BRANCH_NP, GET_BRANCH_SAT, GET_CITY, GET_ORDER_DATA} from "../../actionConst/getActions";

const initialState = {
    city: [],
    branchNP: [],
    branchSAT: [],
    order: {
        back_call: '',
        bill_to: {
            name: "",
            email: "",
            phone: "",
            last_name: ""
        },
        email: "",
        last_name: "",
        name: "",
        phone: "",
        created_date: "",
        delivery_method: '',
        delivery_method_human: "",
        delivery_price: "",
        id: '',
        payment_method: '',
        payment_method_human: "",
        products: [
            {
                price_per_item: "",
                product_name: "",
                quantity: '',
                services: '',
            },
        ],
        ship_to: {
            city: null,
            flat: null,
            floor: null,
            house: null,
            office: null,
            street: null,
        },
        total_amount: 0,
    }
}

export const orderReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_CITY:
            return {
                ...state,
                city: action.payload
            }
        case GET_BRANCH_NP:
            return {
                ...state,
                branchNP: action.payload
            }
        case GET_BRANCH_SAT:
            return {
                ...state,
                branchSAT: action.payload
            }
        case GET_ORDER_DATA:
            return {
                ...state,
                order: action.payload
            }
        default:
            return state
    }
}