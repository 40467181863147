import {FIFTH_QUIZ, FIRST_QUIZ, FOURTH_QUIZ, SECOND_QUIZ, THIRD_QUIZ} from "../../actionConst/action";

const initialState = {
    firstQuiz: 0,
    secondQuiz: 0,
    thirdQuiz: 0,
    fourthQuiz: 0,
    fifthQuiz: {
        north: 0,
        south: 0,
        west: 0,
        east: 0,
    },
}

export const quizReducer = (state = initialState, action) => {
    switch(action.type) {
        case FIRST_QUIZ:
            return {
                ...state,
                firstQuiz: action.payload
            }
        case SECOND_QUIZ:
            return {
                ...state,
                secondQuiz: action.payload
            }
        case THIRD_QUIZ:
            return {
                ...state,
                thirdQuiz: action.payload
            }
        case FOURTH_QUIZ:
            return {
                ...state,
                fourthQuiz: action.payload
            }
        case FIFTH_QUIZ:
            return {
                ...state,
                fifthQuiz: action.payload
            }
    }
    return state
}