import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import Kondiciyuvannya from './CategoriesList/Kondiciyuvannya/Kondiciyuvannya';
import Ventilyaciya from './CategoriesList/Ventilyaciya/Ventilyaciya';
import CentralniPilososi from './CategoriesList/CentralniPilososi/CentralniPilososi';
import Osusuvaci from './CategoriesList/Osusuvaci/Osusuvaci';
import Opalennya from './CategoriesList/Opalennya/Opalennya';
import SistemiOcistkiPovitrya from './CategoriesList/SistemiOcistkiPovitrya/SistemiOcistkiPovitrya';
import TeploviNasosi from "./CategoriesList/TeploviNasosi/TeploviNasosi";
import Elektrozberezennya from './CategoriesList/Elektrozberezennya/Elektrozberezennya';
import Hvac from './CategoriesList/Hvac/Hvac';
import {connect} from 'react-redux';
import {getMenuCatalog, getShowMenuCatalog} from "../../../../redux/actionCreators/getCreators/getCategories";

const HeaderCatalog = props => {
    const history = useHistory();

    const [desktop, setDesktop] = useState(false);

    useEffect(() => {
        if (document.documentElement.clientWidth <= 868) {
            setDesktop(false)
        } else {
            setDesktop(true)
        }
    }, [document.documentElement.clientWidth]);

    return (
        <div
            className={`header-catalog ${props.showMenuCatalog ? 'active' : ''}`}
            onMouseEnter={() => props.getShowMenuCatalog(true)}
            onMouseLeave={() => props.getShowMenuCatalog(false)}
        >
            <div className="header-catalog__button" onClick={() => {
                props.getMenuCatalog({
                    brands: [],
                    children: [],
                    id: 0,
                    link: '',
                    name: 'Каталог',
                    slug: '',
                })
                props.getShowMenuCatalog(true)
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4 4H8V8H4V4ZM10 4H14V8H10V4ZM16 4H20V8H16V4ZM4 10H8V14H4V10ZM10 10H14V14H10V10ZM16 10H20V14H16V10ZM4 16H8V20H4V16ZM10 16H14V20H10V16ZM16 16H20V20H16V16Z"
                        fill="white"/>
                </svg>
                <span className="header-catalog__button-text">Каталог товарів</span>
            </div>

            <ul className="catalog-lv1">
                {
                    !desktop && (
                        <div
                            className="catalog-name-item"
                            onClick={() => {
                                if (props.menuCatalog.name === 'Каталог') {
                                    props.getShowMenuCatalog(false)
                                } else {
                                    props.getMenuCatalog({
                                        brands: [],
                                        children: [],
                                        id: 0,
                                        link: '',
                                        name: 'Каталог',
                                        slug: '',
                                    })
                                }
                            }}
                        >
                            <div className="catalog-lv1__button">
                                <div className="catalog-prev-arrow">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.77605 14.447C6.65776 14.3876 6.56786 14.2837 6.52606 14.1581C6.48425 14.0325 6.49396 13.8955 6.55305 13.777L9.44005 8L6.55205 2.224C6.52203 2.16524 6.50394 2.10111 6.49882 2.03533C6.4937 1.96954 6.50166 1.90339 6.52222 1.84069C6.54279 1.77799 6.57557 1.71998 6.61866 1.67001C6.66175 1.62003 6.71431 1.57909 6.77331 1.54952C6.8323 1.51996 6.89656 1.50237 6.96239 1.49776C7.02822 1.49315 7.0943 1.50162 7.15684 1.52267C7.21938 1.54372 7.27713 1.57695 7.32677 1.62043C7.3764 1.6639 7.41694 1.71678 7.44605 1.776L10.446 7.776C10.4809 7.84553 10.499 7.92223 10.499 8C10.499 8.07777 10.4809 8.15447 10.446 8.224L7.44605 14.224C7.38664 14.3423 7.28274 14.4322 7.15714 14.474C7.03155 14.5158 6.8945 14.5061 6.77605 14.447Z"
                                            fill="#182316"/>
                                    </svg>
                                </div>
                                {props.menuCatalog.name}
                                <div className="catalog-close" onClick={() => {
                                    props.getShowMenuCatalog(false)
                                }}>
                                    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.3332 8.54602L23.4532 6.66602L15.9998 14.1193L8.5465 6.66602L6.6665 8.54602L14.1198 15.9993L6.6665 23.4527L8.5465 25.3327L15.9998 17.8793L23.4532 25.3327L25.3332 23.4527L17.8798 15.9993L25.3332 8.54602Z"
                                            fill="#868686"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 1) {
                            return <Kondiciyuvannya
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 47) {
                            return <TeploviNasosi
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 11) {
                            return <Ventilyaciya
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 29) {
                            return <Osusuvaci
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 25) {
                            return <CentralniPilososi
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 40) {
                            return <SistemiOcistkiPovitrya
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 33) {
                            return <Opalennya
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 69) {
                            return <Elektrozberezennya
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
                {
                    props.categoryData.map((data, i) => {
                        if(data.id == 73) {
                            return <Hvac
                                key={i}
                                setActive={props.getShowMenuCatalog}
                                data={data}
                                history={history}
                                activeCatalog={props.menuCatalog}
                                setActiveCatalog={props.getMenuCatalog}
                                desktop={desktop}
                            />
                        }
                    })
                }
            </ul>
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        menuCatalog: store.headerCatalogReducer.menuCatalog,
        showMenuCatalog: store.headerCatalogReducer.showMenuCatalog,
    }
}

const mapDispatchToProps = {
    getMenuCatalog,
    getShowMenuCatalog,
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderCatalog)
