import React from 'react'

const BasketItemService = props => {

    return (
        <div className="basket-service-item">
            <div className="basket-service-item__name">{props.data.name}</div>
            <div className="basket-service-item__price">{props.data.price} грн</div>
            <div className="basket-service-item__delete-btn" onClick={() => {
                props.removeServices({id: props.productId, name: props.data.id})
            }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.0622 15.1112H3.93775C3.76673 15.1072 3.59817 15.0695 3.44172 15.0003C3.28527 14.9311 3.14398 14.8317 3.02594 14.7079C2.9079 14.5841 2.81541 14.4382 2.75376 14.2786C2.69211 14.1191 2.66251 13.9489 2.66664 13.7779V4.99121H3.55553V13.7779C3.55129 13.8322 3.55782 13.8868 3.57476 13.9386C3.5917 13.9904 3.6187 14.0383 3.65422 14.0796C3.68974 14.1209 3.73308 14.1548 3.78174 14.1793C3.8304 14.2037 3.88342 14.2184 3.93775 14.2223H12.0622C12.1165 14.2184 12.1696 14.2037 12.2182 14.1793C12.2669 14.1548 12.3102 14.1209 12.3457 14.0796C12.3812 14.0383 12.4083 13.9904 12.4252 13.9386C12.4421 13.8868 12.4487 13.8322 12.4444 13.7779V4.99121H13.3333V13.7779C13.3374 13.9489 13.3078 14.1191 13.2462 14.2786C13.1845 14.4382 13.092 14.5841 12.974 14.7079C12.856 14.8317 12.7147 14.9311 12.5582 15.0003C12.4018 15.0695 12.2332 15.1072 12.0622 15.1112Z"
                        fill="#868686"/>
                    <path
                        d="M13.6801 4.00022H2.22228C2.1044 4.00022 1.99136 3.95339 1.90801 3.87004C1.82466 3.78669 1.77783 3.67365 1.77783 3.55577C1.77783 3.4379 1.82466 3.32485 1.90801 3.2415C1.99136 3.15815 2.1044 3.11133 2.22228 3.11133H13.6801C13.7979 3.11133 13.911 3.15815 13.9943 3.2415C14.0777 3.32485 14.1245 3.4379 14.1245 3.55577C14.1245 3.67365 14.0777 3.78669 13.9943 3.87004C13.911 3.95339 13.7979 4.00022 13.6801 4.00022Z"
                        fill="#868686"/>
                    <path d="M9.33337 5.77783H10.2223V12.4445H9.33337V5.77783Z"
                          fill="#868686"/>
                    <path d="M5.77783 5.77783H6.66672V12.4445H5.77783V5.77783Z"
                          fill="#868686"/>
                    <path
                        d="M10.2223 2.60423H9.37783V1.77756H6.62228V2.60423H5.77783V1.77756C5.77755 1.54931 5.86507 1.32971 6.02228 1.16423C6.17948 0.998749 6.39432 0.900084 6.62228 0.888672H9.37783C9.60579 0.900084 9.82063 0.998749 9.97783 1.16423C10.135 1.32971 10.2226 1.54931 10.2223 1.77756V2.60423Z"
                        fill="#868686"/>
                </svg>
            </div>
        </div>
    )
}

export default BasketItemService