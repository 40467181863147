import React, {useState} from 'react'
import {useHistory} from "react-router";
import {connect} from 'react-redux'
import {
    toggleResetPasswordModal,
    toggleModal,
} from "../../../../../redux/actionCreators/modalsCreator";

const NewPassword = props => {

    const history = useHistory()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [error, setError] = useState(false)

    const resetPassword = () => {
        window.axios.post(`/auth/reset-password`, {
            token: history.location.search.split('').splice(7).join(''),
            email,
            password,
            password_confirmation: confirm,
        })
            .then(() => {
                history.push('/')
                window.location.reload()
            })
            .catch(() => setError(true))
    }

    return (
        <div className={`entrance-modal ${props.resetPassword? 'active' : ''}`}>
            <button
                className="close-btn"
                onClick={() => {
                    props.toggleResetPasswordModal(false)
                    props.toggleModal(false)
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="#868686"/>
                </svg>
            </button>
            <div className="forgot">
                <h5 className="entrance-modal__title">Забули пароль?</h5>
                <div className="entrance-modal__secondary-text">
                    Введіть новий пароль
                </div>
                <div className="entrance-modal__form">
                    <div className="input-wrap">
                        <label className="form__label">
                            <input
                                className="form__input"
                                type="email"
                                name="email"
                                placeholder=" "
                                required
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value)
                                }}
                            />
                            <span className="standart">Електронна адреса</span>
                        </label>
                        <label className="form__label">
                            <input
                                className="form__input"
                                type="email"
                                name="email"
                                placeholder=" "
                                required
                                value={password}
                                onChange={e => {
                                    setPassword(e.target.value)
                                }}
                            />
                            <span className="standart">Новий пароль</span>
                        </label>
                        <label className="form__label">
                            <input
                                className="form__input"
                                type="email"
                                name="email"
                                placeholder=" "
                                required
                                value={confirm}
                                onChange={e => {
                                    setConfirm(e.target.value)
                                }}
                            />
                            <span className="standart">Підтвердіть пароль</span>
                        </label>
                        {
                            error ? (
                                <div className="validation">
                                    <svg className="mail-validation__error-ico" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.99968 0.333496C5.68114 0.333496 4.3922 0.724489 3.29588 1.45703C2.19955 2.18957 1.34506 3.23077 0.840481 4.44894C0.335896 5.66711 0.203874 7.00756 0.461109 8.30076C0.718344 9.59397 1.35328 10.7819 2.28563 11.7142C3.21798 12.6466 4.40587 13.2815 5.69908 13.5387C6.99228 13.796 8.33273 13.6639 9.5509 13.1594C10.7691 12.6548 11.8103 11.8003 12.5428 10.704C13.2753 9.60764 13.6663 8.3187 13.6663 7.00016C13.6663 5.23205 12.964 3.53636 11.7137 2.28612C10.4635 1.03588 8.76779 0.333496 6.99968 0.333496ZM6.99968 12.5557C5.90089 12.5557 4.82678 12.2299 3.91318 11.6194C2.99957 11.009 2.2875 10.1413 1.86701 9.12618C1.44653 8.11104 1.33651 6.994 1.55087 5.91633C1.76523 4.83866 2.29435 3.84875 3.07131 3.07179C3.84826 2.29483 4.83817 1.76572 5.91584 1.55136C6.99351 1.33699 8.11055 1.44701 9.1257 1.8675C10.1408 2.28799 11.0085 3.00006 11.619 3.91366C12.2294 4.82727 12.5552 5.90138 12.5552 7.00016C12.5552 7.72973 12.4115 8.45215 12.1323 9.12618C11.8531 9.80021 11.4439 10.4127 10.928 10.9285C10.4122 11.4444 9.79973 11.8536 9.1257 12.1328C8.45166 12.412 7.72924 12.5557 6.99968 12.5557Z"
                                            fill="#DB4915"/>
                                        <path
                                            d="M6.99957 8.14985C6.80802 8.14985 6.62432 8.07376 6.48888 7.93831C6.35343 7.80287 6.27734 7.61917 6.27734 7.42763V4.09429C6.27734 3.90275 6.35343 3.71905 6.48888 3.5836C6.62432 3.44816 6.80802 3.37207 6.99957 3.37207C7.19111 3.37207 7.37481 3.44816 7.51025 3.5836C7.6457 3.71905 7.72179 3.90275 7.72179 4.09429V7.42763C7.72179 7.61917 7.6457 7.80287 7.51025 7.93831C7.37481 8.07376 7.19111 8.14985 6.99957 8.14985Z"
                                            fill="#DB4915"/>
                                        <path
                                            d="M6.97201 10.6222C7.43224 10.6222 7.80534 10.2491 7.80534 9.7889C7.80534 9.32866 7.43224 8.95557 6.97201 8.95557C6.51177 8.95557 6.13867 9.32866 6.13867 9.7889C6.13867 10.2491 6.51177 10.6222 6.97201 10.6222Z"
                                            fill="#DB4915"/>
                                    </svg>
                                    <span className="validation__error-text">Щось пішло не так</span>
                                </div>
                            ) : ''
                        }
                    </div>
                    <button className="entrance-modal__btn" onClick={resetPassword}>Надіслати</button>
                </div>
            </div>
        </div>
    )
}

const mstp = store => {
    return {
        resetPassword: store.modalReducer.resetPassword,
    }
}

const mdtp = {
    toggleResetPasswordModal,
    toggleModal,
}

export default connect(mstp, mdtp)(NewPassword)