import ReactGA from 'react-ga';

const trackingId = 'UA-188246430-1';

const initializeGoogleAnalytics = () => {
    ReactGA.initialize(trackingId, {debug: false});
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export default initializeGoogleAnalytics;
