import React from 'react'
import np from '../../img/delivery-payment/np.png'
import sat from '../../img/delivery-payment/sat.png'
import map from '../../img/delivery-payment/map.jpg'

const DeliveryPaymentDelivery = () => {

    return (
        <section className="delivery-payment__section-wrap">
            <h2 className="page-title">Доставка</h2>
            <div className="delivery-payment__section delivery-payment__section--delivery">
                <div className="delivery-payment__section-block">
                    <h3 className="delivery-payment__content-title">Доставка по Україні</h3>
                    <div className="delivery-payment__content-subtitle">Доступна доставка у відділення
                        та адресна (кур'єрською службою). Вартість доставки встановлюэться згідно з
                        тарифами компанії-перевізника.
                    </div>
                    <div className="delivery-payment__post-items">
                        <div className="delivery-payment__post-img">
                            <img src={np} alt="Нова Пошта" />
                        </div>
                        <div className="delivery-payment__post-img delivery-payment__post-img--sat">
                            <img src={sat} alt="SAT" />
                        </div>
                    </div>
                </div>
                <div className="delivery-payment__section-block">
                    <h3 className="delivery-payment__content-title">Місцева доставка</h3>
                    <div className="delivery-payment__content-subtitle">Доставка по місту Мукачево та
                        Закарпатській області — за домовленістю з менеджером магазину.
                    </div>
                    <div className="delivery-payment__img-delivery">
                        <img src={map} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DeliveryPaymentDelivery