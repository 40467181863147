import React from "react";
import logo from "../../../img/logoNew/logoLikaComfortLigth.svg";

import { Link } from "react-router-dom";

const OrderHeader = () => {
  return (
    <div className="order-page__header">
      <div className="order-page__logo">
        <Link to="/">
          <img className="header__logo" src={logo} alt="Логотип" />
        </Link>
      </div>
      <div className="order-page__work-data">
        <div className="order-page__hotline">
          <div className="order-page__hotline-title">Гаряча лінія:</div>
          <a className="order-page__hotline-number" href="tel:+380669929111">
            +38 066 99 29 111
          </a>
        </div>
        <div className="order-page__worktime">
          <div className="order-page__worktime-item">
            <span className="order-page__worktime-days">Пн-Пт:</span>
            <span className="order-page__worktime-time">9:00-18:00</span>
          </div>
          <div className="order-page__worktime-item">
            <span className="order-page__worktime-days">Сб:</span>
            <span className="order-page__worktime-time">9:00-15:00</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHeader;
