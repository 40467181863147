import {GET_PARTNERS_SLIDES} from "../../actionConst/getActions";

const initialState = {
    partners: []
}


export const partnerSliderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARTNERS_SLIDES:
            return {
                ...state,
                partners: action.payload
            }
        default:
            return state
    }
}