import React from 'react'
import ResultItems from "./ResultItems";

const HeaderSearchDropdown = props => {

    return (
        <div className="header-search__dropdown">
            <ResultItems products={props.products}/>
        </div>
    )
}

export default HeaderSearchDropdown