import {GET_NEW_INSTALYATOR_PRODUCTS} from "../../actionConst/getActions";

const initialState = {
    newProducts: []
}

export const newInstalyatorProductsSliderReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_NEW_INSTALYATOR_PRODUCTS:
            return {
                ...state,
                newInstalyatorProducts: action.payload
            }
    }
    return state
}