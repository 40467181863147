//import BLL
import React, {Fragment} from 'react'

//import image
import star from "../../../../img/header/header-top/star.svg";
import blog from "../../../../img/header/header-top/blog.svg";
import service from "../../../../img/header/header-top/service.svg";
import bus from "../../../../img/header/header-top/bus.svg";
import job from "../../../../img/header/header-top/job.svg";
import TopInfoLink from "./TopInfoLink";

const TopInfo = () => {

    const topInfoData = [
        {img: star,name: 'Хіти продаж',link: '/catalog_day'},
        {img: blog,name: 'Блог',link: '/blog'},
        {img: service,name: 'Послуги',link: '/services'},
        {img: bus,name: 'Доставка та оплата',link: '/delivery_payment'},
    ]

    return (
        <nav className="header__top-info">
            {
                topInfoData.map((data, i) => {
                    return (
                        <Fragment key={i}>
                            <TopInfoLink info={data}/>
                        </Fragment>
                    )
                })
            }
            <a href="https://www.work.ua/jobs/by-company/384954/" className="header__info-link" target="_blank" rel="nofollow">
                <img className="header__info-ico" src={job} alt="Вакансії"/>
                <span className="header__info-text">Вакансії</span>
            </a>
        </nav>
    )
}

export default TopInfo