import React, {useState} from 'react'
import {connect} from 'react-redux'
import check from '../../../../../img/check.svg'
import {Link} from "react-router-dom";
import {_api} from "../../../../../API";
import {log_in} from "../../../../../redux/actionCreators/userCreator";
import LoginSocial from "../LoginSocial/LoginSocial";

const RegModal = props => {

    const [emptyPassword, setEmptyPassword] = useState()
    const [emailError, setEmailError] = useState(false)

    const reg = (email, password, name) => {
        window.axios.post(`/auth/register`, {
            name,
            email,
            password,
        }).then(() => {
            window.axios.post(`${_api}/auth/login`, {
                email,
                password,
            })
                .then(res => {
                    localStorage.setItem('token', res.data.access_token)
                    window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
                    props.log_in(true)
                    setTimeout(() => window.location.reload(), 1000)
                })
            props.toggleRegSuccessModal(true)
        }).catch(() => setEmailError(true))
    }

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return (
        <div className="registration">
            <h5 className="entrance-modal__title">Зареєструватися</h5>
            <div className="entrance-modal__subtitle">Вже маєте обліковий запис?</div>
            <div className="entrance-modal__switch-link"
                onClick={() => {
                    props.toggleLoginModal(true)
                    props.toggleRegModal(false)
                }}
            >Увійти</div>
            <div className="entrance-modal__form">
                <div className="input-wrap">
                    <label className="form__label" style={{marginBottom: '10px',}}>
                        <input className="form__input" type="text" name="name" placeholder=" " required onChange={e => setName(e.target.value)}/>
                        <span className="standart">І’мя</span>
                    </label>
                </div>
                <div className="input-wrap">
                    <label className={`form__label ${emailError ? 'empty-feedback' : ''}`} style={{marginBottom: '10px', marginTop: '15px'}}>
                        <input className="form__input" type="email" name="email" placeholder=" " required onChange={e => setEmail(e.target.value)}/>
                        <span className="standart">Електронна адреса</span>
                    </label>
                    {
                        emailError ? (
                            <span className="empty-feedback-text" style={{marginBottom: '10px'}}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z" fill="#DB4915"/>
                                        <path d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z" fill="#DB4915"/>
                                        <path d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z" fill="#DB4915"/>
                                    </svg>
                                    Можливо такий користувач уже зареєстрований
                                </span>
                        ) : ''
                    }
                </div>
                <div className="input-wrap">
                    <label className="form__label" style={{marginBottom: '10px', marginTop: '15px'}}>
                        <input
                            className={`form__input ${emptyPassword ? 'empty-feedback' : ''}`}
                            type="password"
                            placeholder=" "
                            onChange={e => setPassword(e.target.value)}
                        />
                        <span className="standart">Пароль</span>
                    </label>
                    {
                        emptyPassword ? (
                            <span className="empty-feedback-text" style={{marginBottom: '5px'}}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99968 0.333984C5.68114 0.333984 4.3922 0.724978 3.29588 1.45752C2.19955 2.19006 1.34506 3.23125 0.840481 4.44943C0.335896 5.6676 0.203874 7.00805 0.461109 8.30125C0.718344 9.59446 1.35328 10.7823 2.28563 11.7147C3.21798 12.647 4.40587 13.282 5.69908 13.5392C6.99228 13.7965 8.33273 13.6644 9.5509 13.1598C10.7691 12.6553 11.8103 11.8008 12.5428 10.7045C13.2753 9.60812 13.6663 8.31919 13.6663 7.00065C13.6663 5.23254 12.964 3.53685 11.7137 2.28661C10.4635 1.03636 8.76779 0.333984 6.99968 0.333984ZM6.99968 12.5562C5.90089 12.5562 4.82678 12.2304 3.91318 11.6199C2.99957 11.0095 2.2875 10.1418 1.86701 9.12667C1.44653 8.11152 1.33651 6.99449 1.55087 5.91682C1.76523 4.83914 2.29435 3.84924 3.07131 3.07228C3.84826 2.29532 4.83817 1.76621 5.91584 1.55184C6.99351 1.33748 8.11055 1.4475 9.1257 1.86799C10.1408 2.28847 11.0085 3.00054 11.619 3.91415C12.2294 4.82776 12.5552 5.90187 12.5552 7.00065C12.5552 7.73022 12.4115 8.45264 12.1323 9.12667C11.8531 9.8007 11.4439 10.4131 10.928 10.929C10.4122 11.4449 9.79973 11.8541 9.1257 12.1333C8.45166 12.4125 7.72924 12.5562 6.99968 12.5562Z" fill="#DB4915"/>
                                        <path d="M6.99957 8.14887C6.80802 8.14887 6.62432 8.07278 6.48888 7.93734C6.35343 7.80189 6.27734 7.61819 6.27734 7.42665V4.09332C6.27734 3.90177 6.35343 3.71807 6.48888 3.58263C6.62432 3.44718 6.80802 3.37109 6.99957 3.37109C7.19111 3.37109 7.37481 3.44718 7.51025 3.58263C7.6457 3.71807 7.72179 3.90177 7.72179 4.09332V7.42665C7.72179 7.61819 7.6457 7.80189 7.51025 7.93734C7.37481 8.07278 7.19111 8.14887 6.99957 8.14887Z" fill="#DB4915"/>
                                        <path d="M6.97201 10.6217C7.43224 10.6217 7.80534 10.2486 7.80534 9.78841C7.80534 9.32817 7.43224 8.95508 6.97201 8.95508C6.51177 8.95508 6.13867 9.32817 6.13867 9.78841C6.13867 10.2486 6.51177 10.6217 6.97201 10.6217Z" fill="#DB4915"/>
                                    </svg>
                                    Пароль повинен містити не менше 8 символів
                                </span>
                        ) : ''
                    }
                </div>
                <button className="entrance-modal__btn" onClick={() => {
                    if(password.length >= 8) {
                        reg(email, password, name)
                    } else if(password.length <= 8) {
                        setEmptyPassword(true)
                    }
                }}>Зареєструватися</button>

                <LoginSocial />

                <label className="checkbox-label">
                    <input type="checkbox" name="cond-system" checked={true} />
                    <div className="checkbox">
                        <img src={check} alt="checkmark" />
                        <span className="input-text">
                            Даю згоду на обробку своїх персональних даних відповідно до
                            <Link to="/offer" style={{marginLeft: '5px'}}>даної оферти</Link>
                        </span>
                    </div>
                </label>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    log_in,
}

export default connect(null, mapDispatchToProps)(RegModal)