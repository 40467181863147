import React, { useState } from "react";
import privat from "../../../../../img/order-page/privat.svg";
import arrow from "../../../../../img/arrow-down-18.svg";
import mono from "../../../../../img/order-page/monobank.png";

const OrderBodyDataPayCredit = (props) => {
  const privatCredit = ["2 місяці", "3 місяці", "4 місяців", "5 місяців", "6 місяців", "7 місяців", "8 місяців"];
  const monoCredit = ["3 місяці", "4 місяці", "5 місяців", "6 місяців", "7 місяців", "8 місяців", "9 місяців", "10 місяців"];

  const [periodPrivat, setPeriodPrivat] = useState("2 місяці");
  const [selectPrivat, setSelectPrivat] = useState();
  const [periodMono, setPeriodMono] = useState("3 місяці");
  const [selectMono, setSelectMono] = useState();

  return (
    <>
      <div className="credit-item">
        <label className="radio-wrap">
          <input
            id="credit_privat"
            type="radio"
            name="credit"
            checked={props.activeRadio === "credit_privat"}
            onChange={props.openSubSelect}
            onClick={() => {
              props.addDeliveryPay(5);
              props.addDeliveryPayForBitrix(4);
            }}
          />
          <span className="radio" />
          <div className="radio-label">
            <img src={privat} alt="" width={20} height={20} />
            <div className="radio-label__text">
              Оплата частинами від ПриватБанку
            </div>
          </div>
        </label>
        <div
          className={`term-picker ${selectPrivat ? "active" : ""}`}
          onClick={() => {
            setSelectPrivat(!selectPrivat);
            setSelectMono(false);
          }}
        >
          <div className="term-picker__choosed">
            <div className="term-picker__item">{periodPrivat}</div>
            <img className="arrow-down" src={arrow} alt="" />
          </div>
          <div className="term-picker__dropdown">
            {privatCredit.map((data, i) => {
              return (
                <div
                  className="term-picker__item"
                  key={i}
                  onClick={() => {
                    setPeriodPrivat(data);
                    props.addDeliveryPeriodPrivat(parseInt(data));
                  }}
                >
                  {data}
                </div>
              );
            })}
          </div>
        </div>
        <div className="credit-item__price">{(props.basketPrice/parseInt(periodPrivat)).toFixed(2)+` грн / міс`}</div>
      </div>
      <div className="credit-item">
        <label className="radio-wrap">
          <input
            id="credit_mono"
            type="radio"
            name="credit"
            checked={props.activeRadio === "credit_mono"}
            onChange={props.openSubSelect}
            onClick={() => {
              props.addDeliveryPay(6);
              props.addDeliveryPayForBitrix(5);
            }}
          />
          <span className="radio" />
          <div className="radio-label">
            <img src={mono} alt="" width={20} height={20} />
            <div className="radio-label__text">
              Покупка частинами від Monobank
            </div>
          </div>
        </label>
        <div
          className={`term-picker ${selectMono ? "active" : ""}`}
          onClick={() => {
            setSelectMono(!selectMono);
            setSelectPrivat(false);
          }}
        >
          <div className="term-picker__choosed">
            <div className="term-picker__item">{periodMono}</div>
            <img className="arrow-down" src={arrow} alt="" />
          </div>
          <div className="term-picker__dropdown">
            {monoCredit.map((data, i) => {
              return (
                <div
                  className="term-picker__item"
                  key={i}
                  onClick={() => {
                    setPeriodMono(data);
                    props.addDeliveryPeriodMono(parseInt(data));
                  }}
                >
                  {data}
                </div>
              );
            })}
          </div>
        </div>
        <div className="credit-item__price">{(props.basketPrice/parseInt(periodMono)).toFixed(2)+` грн / міс`}</div>
      </div>
    </>
  );
};

export default OrderBodyDataPayCredit;
