import React, {useState} from 'react'
import {Element} from 'react-scroll'

import play from '../../../img/product-page/video-play-btn.svg'

const ProductPageDescription = (props) => {

    const [mirror, setMirror] = useState(true)

    const hiddenMirror = () => {
        setMirror(!mirror)
    }

    return (
        <section className={`product-page__description ${props.desc? 'highlight' : ''}`} id="product-description">
            <Element name='product-description'>
                {
                    props.video ? (
                        <section className="product-page__description-video">
                            <h3 className="product-page__container-title">Відеоогляд товару</h3>
                            <div className="product-page__description-video-wrap">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={props.video}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen />
                                <div className={`product-page__play-video-btn ${mirror? '' : 'hidden'}`} onClick={hiddenMirror}>
                                    <img src={play} alt="Відтворити відео" />
                                </div>
                            </div>
                        </section>
                    ) : ''
                }
                <section className="product-page__description-text">
                    <h3 className="product-page__container-title">Опис</h3>
                    <div className="product-page__description-list" dangerouslySetInnerHTML={{__html:[props.description]}} />
                </section>
            </Element>
        </section>
    )
}

export default ProductPageDescription