import React from 'react'
import lika from "../../../../../img/order-page/lika.svg";

const OrderBodyDeliveryLikaAddress = props => {

    return (
        <>
            <label className="radio-wrap">
                <input
                    id='likaAddress'
                    type="radio"
                    name="post"
                    checked={props.activeRadio === 'likaAddress'}
                    onChange={props.openSubSelect}
                    onClick={() => {
                        props.addDeliveryCost(0)
                        props.addDeliveryWay(5)
                    }}
                />
                <span className="radio">
                        <img src={lika} alt=""/>
                        Самовивіз з нашого магазину
                    </span>
                <span className="order-data__delivery-price order-data__delivery-price--free">
                        безкоштовно
                    </span>
            </label>
        </>
    )
}

export default OrderBodyDeliveryLikaAddress