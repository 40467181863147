import React from "react";

import privat from '../../../../../img/catalog/privat-mobile.svg'
import monobanck from '../../../../../img/catalog/monobank-mobile.png'
import {useSelector} from "react-redux";

const ProductCardPrice = (props) => {
    const showCreditForActions = useSelector(store => store.settingsReducer.showCreditForActions)

    return (
        <div className="product-card__price-section">
            <div className={`product-card__discount ${props.data.discount > 0? '' : 'hidden'}`}>
                <div className="product-card__discount-val">{props.data.price} грн</div>
                <div className="product-card__discount-percentage">-{props.data.discount_percent}%</div>
            </div>
            <div className="product-card__price-wrap">
                <div className="product-card__price">
                    {
                        props.data.final_price === 0 ? (
                            <p style={{fontSize: '13px'}}>За домовленістю</p>
                        ) : (
                            `${props.data.final_price.toLocaleString()} грн`
                        )
                    }

                </div>
                {
                    ((props.data.discount > 0 && showCreditForActions) || (props.data.discount <= 0)) && (
                        <div className="product-card__credit-conditions">
                            <div className="product-card__payment-mobile">
                                <img src={privat} alt="ПриватБанк" />
                                <img src={monobanck} alt="Monobank" />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ProductCardPrice