import React, {useState} from 'react'
import {connect} from 'react-redux'
import ProductPageFeedbackTrueItem from "./ProductPageFeedbackTrueItem";
import {
    toggleModal,
    toggleFeedbackModal,
} from "../../../redux/actionCreators/modalsCreator";


const ProductPageFeedbackTrue = props => {

    const [showAllFeedbacks, setShowAllFeedbacks] = useState(false)

    return (
        <section className={`product-page__reviews active ${props.feed? 'highlight': ''}`} onClick={() => { props.setActiveReview(prev => !prev) }}>
            <h3 className="product-page__container-title">Відгуки про товар</h3>
            <ProductPageFeedbackTrueItem showAllFeedbacks={showAllFeedbacks} rates={props.rates}/>
            <div className="product-page__see-all-section">
                {
                    props.rates.length > 3 && !showAllFeedbacks? (
                        <button className="product-page__see-all-btn" onClick={() => {
                            setShowAllFeedbacks(prev => !prev)
                        }}>
                            Всі відгуки
                            <span>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path  d="M1.55258 7.27581C1.61199 7.15752 1.71589 7.06762 1.84149 7.02581C1.96708 6.98401 2.10413 6.99372 2.22258 7.05281L7.99958 9.93981L13.7756 7.05181C13.8343 7.02179 13.8985 7.0037 13.9643 6.99858C14.03 6.99346 14.0962 7.00141 14.1589 7.02198C14.2216 7.04255 14.2796 7.07532 14.3296 7.11842C14.3795 7.16151 14.4205 7.21407 14.4501 7.27306C14.4796 7.33206 14.4972 7.39632 14.5018 7.46215C14.5064 7.52797 14.498 7.59406 14.4769 7.6566C14.4559 7.71913 14.4226 7.77689 14.3792 7.82652C14.3357 7.87616 14.2828 7.9167 14.2236 7.94581L8.22358 10.9458C8.15405 10.9806 8.07735 10.9988 7.99958 10.9988C7.92181 10.9988 7.84511 10.9806 7.77558 10.9458L1.77558 7.94581C1.65729 7.88639 1.56739 7.7825 1.52559 7.6569C1.48378 7.5313 1.49349 7.39426 1.55258 7.27581Z" fill="#257226"/>
                                </svg>
                            </span>
                        </button>
                    ) : null
                }
                <button
                    className="product-page__add-review-btn"
                    onClick={() => {
                        props.toggleModal(true)
                        props.toggleFeedbackModal(true)
                    }}
                >Додати відгук</button>
            </div>
        </section>
    )
}

const mapDispatchToProps = {
    toggleModal,
    toggleFeedbackModal,
}

export default connect(null, mapDispatchToProps)(ProductPageFeedbackTrue)