import {GET_PRODUCTS_FROM_SEARCH} from "../../actionConst/getActions";
import {SEARCH_PRODUCTS} from "../../actionConst/action";

export const getSearchProducts = payload => {
    return {
        type: GET_PRODUCTS_FROM_SEARCH,
        payload
    }
}
export const changeSearchText = payload => {
    return {
        type: SEARCH_PRODUCTS,
        payload,
    }
}
