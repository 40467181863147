import {GET_MAIN_PAGE_BANNERS} from "../../actionConst/getActions";

const initialState = {
    banners: [],
}

export const bannersReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_MAIN_PAGE_BANNERS:
            return {
                ...state,
                banners: action.payload
            }
        default:
            return state
    }
}