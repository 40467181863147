import React from 'react'
import phone from "../../../img/header/header-top/phone.svg";
import arDownWhite from "../../../img/header/header-top/ar-down--white.svg";

const HeaderPhone = (props) => {

    return (
        <div className="header__phone">
            <div className="header__phone-top">
                <img className="header__phone-ico" src={phone} alt='Телефон'/>
                <div className="header__phone-link">
                    <a href={`tel:${props.phones[0]}`}>{props.phones[0]}</a>
                </div>
                <img className="header__arrow-down" src={arDownWhite} alt='Стрілка вниз'/>
            </div>
            <div className="header__phone-drop">
                <div className="header__phone-item">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8879 0.90569C11.2321 1.1407 9.95019 2.68432 9.91815 4.56444C12.2095 4.9864 13.9401 6.52468 13.9401 9.27008C13.9401 11.9193 11.7395 14.0612 8.98877 14.0612C6.25405 14.0665 4.03743 11.663 4.03743 8.54901C4.03743 4.25465 7.85108 0.69204 12.6742 0.804206C11.5472 0.302129 10.3027 0.019043 8.98877 0.019043C4.02141 0.019043 -0.0112305 4.04634 -0.0112305 9.01904C-0.0112305 13.9917 4.02141 18.019 8.98877 18.019C13.9561 18.019 17.9888 13.9917 17.9888 9.01904C17.9888 5.44575 15.9057 2.35851 12.8879 0.90569Z" fill="#257226"/>
                    </svg>
                    <a href={`tel:+380669929111`}>+380 66 99 29 111</a>
                </div>
                <div className="header__phone-item">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.12866 1.3999V5.59422V1.3999Z" fill="black"/>
                        <path d="M9.12866 1.3999V5.59422" stroke="#257226" strokeWidth="2.3141" strokeLinecap="round"/>
                        <path d="M16.8318 6.99609L12.8428 8.29221L16.8318 6.99609Z" fill="black"/>
                        <path d="M16.8318 6.99609L12.8428 8.29221" stroke="#257226" strokeWidth="2.3141" strokeLinecap="round"/>
                        <path d="M13.8894 16.0518L11.424 12.6585L13.8894 16.0518Z" fill="black"/>
                        <path d="M13.8894 16.0518L11.424 12.6585" stroke="#257226" strokeWidth="2.3141" strokeLinecap="round"/>
                        <path d="M4.36816 16.0518L6.83352 12.6585L4.36816 16.0518Z" fill="black"/>
                        <path d="M4.36816 16.0518L6.83352 12.6585" stroke="#257226" strokeWidth="2.3141" strokeLinecap="round"/>
                        <path d="M1.42578 6.99609L5.41481 8.29221L1.42578 6.99609Z" fill="black"/>
                        <path d="M1.42578 6.99609L5.41481 8.29221" stroke="#257226" strokeWidth="2.3141" strokeLinecap="round"/>
                    </svg>
                    <a href={`tel:+380689929111`}>+380 68 99 29 111</a>
                </div>
                <div className="service-phone">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.6519 17C13.4926 16.9995 13.3339 16.9821 13.1783 16.9479C10.217 16.3353 7.48884 14.899 5.30782 12.8043C3.17335 10.7049 1.69298 8.03205 1.04583 5.10899C0.969518 4.74468 0.988247 4.36688 1.10021 4.01191C1.21218 3.65694 1.41361 3.33676 1.68513 3.08217L3.57935 1.25898C3.6761 1.16748 3.79105 1.09742 3.91672 1.05335C4.04238 1.00929 4.17592 0.992221 4.30862 1.00326C4.44622 1.01761 4.579 1.06194 4.69763 1.13312C4.81626 1.2043 4.91786 1.3006 4.99528 1.41525L7.36306 4.91483C7.45185 5.05062 7.49471 5.21132 7.48533 5.37329C7.47596 5.53527 7.41483 5.68995 7.31096 5.81458L6.12234 7.23525C6.59573 8.28297 7.26614 9.2299 8.09707 10.0245C8.92262 10.8479 9.89839 11.5054 10.9715 11.9613L12.4443 10.7917C12.568 10.6939 12.7185 10.6361 12.8759 10.6259C13.0332 10.6158 13.1899 10.6538 13.3251 10.7348L16.9005 13.0458C17.0232 13.119 17.1277 13.2191 17.2062 13.3385C17.2847 13.4579 17.3352 13.5935 17.3539 13.7352C17.3726 13.8769 17.359 14.0209 17.3141 14.1566C17.2692 14.2922 17.1941 14.416 17.0946 14.5185L15.2478 16.3465C15.038 16.5552 14.789 16.7204 14.5151 16.8326C14.2412 16.9447 13.9478 17.0016 13.6519 17Z" fill="#257226" stroke="#257226" strokeWidth="0.5625"/>
                    </svg>
                    <div className="phone-service-item">
                        <p>
                            Сервісний центр:
                        </p>
                        <a href={`tel:+380503724642`}>+38 050 372 46 42</a>
                    </div>
                </div>
                <div className="work-days">
                    <div className="work-days_item">
                        <div className="left">
                            Пн-Пт:
                            <br/>
                            Сб:
                        </div>
                        <div className="right">
                            9:00 – 18:00
                            <br/>
                            9:00 – 15:00
                        </div>
                    </div>
                </div>
                <div className="support-email">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.603271 4.6784L9.00012 8.8763L17.397 4.6784C17.3659 4.14336 17.1314 3.64046 16.7416 3.27268C16.3518 2.90491 15.8361 2.7001 15.3001 2.7002H2.70012C2.16419 2.7001 1.64849 2.90491 1.25866 3.27268C0.868825 3.64046 0.634356 4.14336 0.603271 4.6784Z" fill="#257226"/>
                        <path d="M17.3999 7.02441L8.99985 11.2244L0.599854 7.02441V13.2005C0.599854 13.7575 0.821103 14.2916 1.21493 14.6854C1.60876 15.0793 2.1429 15.3005 2.69985 15.3005H15.2999C15.8568 15.3005 16.3909 15.0793 16.7848 14.6854C17.1786 14.2916 17.3999 13.7575 17.3999 13.2005V7.02441Z" fill="#257226"/>
                    </svg>
                    <a href="mailto:sell@lika.net.ua">
                        sell@lika.net.ua
                    </a>
                </div>
            </div>
        </div>
    )
}

export default HeaderPhone