import React, {useEffect, useState} from "react";
import {connect} from 'react-redux'
import {getBlogArticles, getBlogCategories} from "../../redux/actionCreators/getCreators/getBlog";
import './Blog.scss'
import BlogHeader from "./BlogHeader";
import BlogBody from "./BlogBody";
import ScrollToTopOnMount from "../../ScrolToTop";
import Preloader from "../globalComponents/Preloader/Preloader";
import Pagination from "../globalComponents/Pagination/Pagination";
import EmptyPage from "../globalComponents/EmptyCatalog/EmptyPage";
import JsonLd from '../globalComponents/JsonLd/JsonLd';
import {_domain, _images} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Blog = (props) => {

    const [tag, setTag] = useState('Всі')
    const [page, setPage] = useState(1)
    const [pageLength, setPageLength] = useState(1)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(tag !== 'Всі') {
            setLoading(true)
            window.axios(`${encodeURI(`/posts?&tag=${tag}`)}`)
                .then(res => {
                    props.getBlogArticles(res.data.data)
                    setPageLength(res.data.meta.pagesCount)
                    setPage(1)
                    props.getBlogCategories(res.data.tags)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(true)
            window.axios.get(`/posts`)
                .then(res => {
                    props.getBlogArticles(res.data.data)
                    setPageLength(res.data.meta.pagesCount)
                    setPage(1)
                    props.getBlogCategories(res.data.tags)
                })
                .finally(() => setLoading(false))
        }
        document.title = 'Блог';
    }, [])

    useEffect(() => {
        if(tag !== 'Всі') {
            setLoading(true)
            window.axios(`${encodeURI(`/posts?&tag=${tag}`)}`)
                .then(res => {
                    props.getBlogArticles(res.data.data)
                    setPageLength(res.data.meta.pagesCount)
                    setPage(1)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(true)
            window.axios.get(`/posts`)
                .then(res => {
                    props.getBlogArticles(res.data.data)
                    setPageLength(res.data.meta.pagesCount)
                    setPage(1)
                })
                .finally(() => setLoading(false))
        }
    }, [tag]);

    useEffect(() => {
        if(tag !== 'Всі') {
            setLoading(true)
            window.axios(`${encodeURI(`/posts?page=${page}&tag=${tag}`)}`)
                .then(res => {
                    props.getBlogArticles(res.data.data)
                    setPageLength(res.data.meta.pagesCount)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(true)
            window.axios.get(`/posts?page=${page}`)
                .then(res => {
                    props.getBlogArticles(res.data.data)
                    setPageLength(res.data.meta.pagesCount)
                })
                .finally(() => setLoading(false))
        }
    }, [page]);

    const datePublished = new Date();
    datePublished.setMonth(datePublished.getMonth()-3);

    let img;
    const jsonLdImg = props.articles.map((val) => {
        img = new Image();
        img.src = _images+val.main_image;

        return {
            "@context": "http://schema.org",
            "@type": "ImageObject",
            "contentUrl": _images+val.main_image,
            "datePublished": datePublished.toISOString(),
            "name": val.title,
            "description": val.title,
            "width": img.width,
            "height": img.height,
            "author": "Ліка Комфорт"
        }
    });

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <JsonLd data={jsonLdImg} />
            <div className="content">
                <main className="blog">
                    <div className="container">
                        <BlogHeader
                            page={page}
                            tag={tag}
                            setTag={setTag}
                            categories={props.categories}
                        />
                        {
                            loading ? (
                                <Preloader />
                            ) : (
                                props.articles.length > 0 ? <BlogBody articles={props.articles}/> : <EmptyPage />
                            )
                        }
                        {
                            pageLength > 1 ? (
                                <Pagination page={page} setPage={setPage} pageLength={pageLength}/>
                            ) : (
                                <div className='pagination-wrap'/>
                            )
                        }
                    </div>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        articles: store.blogReducer.articles,
        categories: store.blogReducer.categories
    }
}

const mapDispatchToProps = {
    getBlogArticles,
    getBlogCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)