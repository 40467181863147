import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux';
import ProductPageInfoServices from "./ProductPageInfoServices";

const ProductPageInfoAdditional = props => {
    const [activeSwitch, setActiveSwitch] = useState(true);
    const [activeAdditionalInfo, setActiveAdditionalInfo] = useState(false)
    const [selectedAdditional, setSelectedAdditional] = useState(0)


    useEffect(() => {
        if (props.services.find(item => item.is_guarantee === 0)) {
            setActiveSwitch(true)
        } else {
            setActiveSwitch(false)
        }
    }, [props.services]);


    return (
        <div className="product-info__additional-services">
            <div className="grey-line" />
            <div className="services-header">
                <h6 className="product-info__section-title">
                    Додаткові послуги
                </h6>
                <div className='services-switch'>
                    {
                        props.services.find(item => item.is_guarantee === 0) && (
                            <div style={{position: 'relative'}}>
                            <div className="services-switch-message"
                                 style={{display: activeAdditionalInfo
                                            && selectedAdditional === 1
                                             ? "block"
                                             : "none"
                                 }}>
                                {props.services.find(item => item.is_guarantee === 0 && item.description !== '').description}
                                </div>
                            <div style={{display: 'flex', marginRight: '10px'}}>
                                <p
                                    className={`services-switch-item ${activeSwitch ? 'active' : ''}`}
                                    style={{color: props.service
                                            .find(service => service.is_guarantee === 0)? '#43B02A' : ''
                                            }}
                                    onClick={() => {
                                        setActiveSwitch(true)
                                    }}>
                                    Проф-інсталятор
                                </p>
                                <div className="services-switch-item-ico"
                                    style={{display: props.services.find(service => service.is_guarantee === 0 && service.description) ? 'block' : 'none'}}
                                     onMouseEnter={(e) => {
                                         if(e.target === e.currentTarget) {
                                             setSelectedAdditional(1)
                                             setActiveAdditionalInfo(true)
                                         }
                                     }}
                                     onMouseLeave={() => {
                                         setActiveAdditionalInfo(false)
                                     }}
                                >

                                </div>
                            </div>
                            </div>
                        )
                    }
                    {
                        props.services.find(item => item.is_guarantee === 1) && (
                            <div style={{position: 'relative'}}>
                            <div className="services-switch-message"
                                 style={{display: activeAdditionalInfo && selectedAdditional === 2 ? "block" : "none"}}>
                                {props.services.find(item => item.is_guarantee === 1 && item.description !== '').description}
                            </div>
                            <div style={{display: 'flex', marginRight: '10px'}}>
                                <p
                                    className={`services-switch-item ${!activeSwitch  ? 'active' : ''}`}
                                    style={{color: props.service
                                            .find(service => service.is_guarantee === 1)? '#43B02A' : ''
                                        }}
                                    onClick={() => {
                                        setActiveSwitch(false)
                                    }}>
                                    Розширений захист
                                </p>
                                <div className="services-switch-item-ico"
                                     style={{display: props.services.find(service => service.is_guarantee === 1 && service.description) ?'block' : 'none'}}
                                     onMouseEnter={() => {
                                         setSelectedAdditional(2)
                                         setActiveAdditionalInfo(true)
                                        }}
                                     onMouseLeave={() => {
                                         setActiveAdditionalInfo(false)
                                         }}>
                                         {/*<img src={ico} alt=""/>*/}
                                </div>
                            </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                activeSwitch ? (
                    props.services.filter(data => data.is_guarantee === 0).map(data => {
                        const productInBasket = props.basket.find(product => product.id === props.product.id);
                        const isServiceInBasket = productInBasket?.new_services_id.some(service => service === data.id);
                        const isServiceSaved = props.service.some(service => service.id === data.id)

                        return (
                            <ProductPageInfoServices
                                isServiceInBasket={isServiceInBasket}
                                isServiceSaved={isServiceSaved}
                                setService={props.setService}
                                productInBasket={productInBasket}
                                productId={props.product.id}
                                service={props.service}
                                key={data.id}
                                data={data}
                            />
                        )
                    })
                ) : (
                    props.services.filter(data => data.is_guarantee === 1).map(data => {
                        const productInBasket = props.basket.find(product => product.id === props.product.id);
                        const isServiceInBasket = productInBasket?.new_services_id.some(service => service === data.id);
                        const isServiceSaved = props.service.some(service => service.id === data.id)

                        return (
                            <ProductPageInfoServices
                                isServiceInBasket={isServiceInBasket}
                                isServiceSaved={isServiceSaved}
                                productInBasket={productInBasket}
                                isGarantee={true}
                                setService={props.setService}
                                productId={props.product.id}
                                service={props.service}
                                key={data.id}
                                data={data}
                            />
                        )
                    })
                )
            }
            <div className="grey-line" />
        </div>
    );
};

const mapStateToProps = store => {
    return {
        basket: store.basketReducer.basket,
        product: store.productPageReducer.product,
    };
};


export default connect(mapStateToProps)(ProductPageInfoAdditional)
