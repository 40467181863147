import React from 'react'
import HeaderSearch from "./HeaderMain/HeaderSearch/HeaderSearch";
import HeaderCatalog from "./HeaderBottom/Categories/HeaderCatalog";
import HeaderBtn from "./HeaderMain/HeaderBtn";

const ScrollHeader = props => {

    return (
        <div className='new-scroll-header'>
            <div className="container">
                <HeaderCatalog categoryData={props.categoryData}/>
                <HeaderSearch />
                <HeaderBtn />
            </div>
        </div>
    )
}

export default ScrollHeader