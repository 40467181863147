import React from 'react'

const SocialBox = () => {


    return (
        <div className="header__social-box">
            <a className="header__social-link" rel='noreferrer' href="https://www.tiktok.com/@lika_comfort" target="_blank">
                <span style={{display: 'none'}}>
                    TikTok
                </span>
                <svg fill="white" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="24px" height="24px">
                    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
                </svg>
            </a>
            <a className="header__social-link" rel='noreferrer' href="https://www.instagram.com/lika_comfort/?igshid=1qxag6rjd3m53" target="_blank">
                <span style={{display: 'none'}}>
                    instagram
                </span>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22.6924 8.99716C22.6815 8.17667 22.5279 7.36433 22.2385 6.59649C21.9875 5.94882 21.6042 5.36062 21.1131 4.86948C20.622 4.37833 20.0338 3.99503 19.3861 3.74408C18.6281 3.45955 17.8274 3.3057 17.0179 3.28908C15.9757 3.24249 15.6453 3.22949 12.9998 3.22949C10.3543 3.22949 10.0152 3.22949 8.98067 3.28908C8.17159 3.30582 7.37121 3.45967 6.61358 3.74408C5.96581 3.99486 5.37752 4.37809 4.88635 4.86926C4.39518 5.36043 4.01195 5.94872 3.76117 6.59649C3.47607 7.35389 3.32255 8.15445 3.30725 8.96358C3.26067 10.0068 3.24658 10.3372 3.24658 12.9827C3.24658 15.6282 3.24658 15.9662 3.30725 17.0019C3.3235 17.8122 3.47625 18.6117 3.76117 19.3712C4.01237 20.0187 4.39589 20.6068 4.88722 21.0977C5.37856 21.5887 5.9669 21.9718 6.61467 22.2225C7.37022 22.5185 8.17074 22.6833 8.98175 22.71C10.025 22.7566 10.3554 22.7707 13.0009 22.7707C15.6464 22.7707 15.9855 22.7707 17.0201 22.71C17.8295 22.6941 18.6303 22.5406 19.3882 22.2561C20.0357 22.0048 20.6238 21.6214 21.1149 21.1303C21.606 20.6392 21.9894 20.0512 22.2407 19.4037C22.5256 18.6453 22.6783 17.8458 22.6946 17.0344C22.7412 15.9922 22.7552 15.6618 22.7552 13.0152C22.7531 10.3697 22.7531 10.0339 22.6924 8.99716ZM12.9933 17.9856C10.2265 17.9856 7.98508 15.7442 7.98508 12.9773C7.98508 10.2105 10.2265 7.96908 12.9933 7.96908C14.3216 7.96908 15.5955 8.49673 16.5347 9.43596C17.4739 10.3752 18.0016 11.6491 18.0016 12.9773C18.0016 14.3056 17.4739 15.5795 16.5347 16.5187C15.5955 17.4579 14.3216 17.9856 12.9933 17.9856ZM18.2009 8.95166C17.5542 8.95166 17.0331 8.42949 17.0331 7.78383C17.0331 7.63053 17.0633 7.47874 17.1219 7.33712C17.1806 7.1955 17.2666 7.06682 17.375 6.95843C17.4834 6.85003 17.612 6.76405 17.7537 6.70539C17.8953 6.64673 18.0471 6.61653 18.2004 6.61653C18.3537 6.61653 18.5055 6.64673 18.6471 6.70539C18.7887 6.76405 18.9174 6.85003 19.0258 6.95843C19.1342 7.06682 19.2201 7.1955 19.2788 7.33712C19.3375 7.47874 19.3677 7.63053 19.3677 7.78383C19.3677 8.42949 18.8455 8.95166 18.2009 8.95166Z"
                        fill="white"/>
                    <path
                        d="M12.9935 16.2306C14.7902 16.2306 16.2467 14.7741 16.2467 12.9774C16.2467 11.1807 14.7902 9.72412 12.9935 9.72412C11.1968 9.72412 9.74023 11.1807 9.74023 12.9774C9.74023 14.7741 11.1968 16.2306 12.9935 16.2306Z"
                        fill="white"/>
                </svg>
            </a>
            <a className="header__social-link" rel='noreferrer' href="https://www.facebook.com/lika.net.ua/" target="_blank">
                <span style={{display: 'none'}}>
                    facebook
                </span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.2 0H0.8C0.3575 0 0 0.3575 0 0.8V19.2C0 19.6425 0.3575 20 0.8 20H19.2C19.6425 20 20 19.6425 20 19.2V0.8C20 0.3575 19.6425 0 19.2 0ZM16.89 5.8375H15.2925C14.04 5.8375 13.7975 6.4325 13.7975 7.3075V9.235H16.7875L16.3975 12.2525H13.7975V20H10.68V12.255H8.0725V9.235H10.68V7.01C10.68 4.4275 12.2575 3.02 14.5625 3.02C15.6675 3.02 16.615 3.1025 16.8925 3.14V5.8375H16.89Z"
                        fill="white"/>
                </svg>
            </a>
            <a className="header__social-link" rel='noreferrer' href="https://www.youtube.com/channel/UChJCfbehCQ2g6UNWmwZtykA" target="_blank">
                <span style={{display: 'none'}}>
                    youtube
                </span>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22.52 5.39319C22.389 4.90557 22.1323 4.46088 21.7756 4.10364C21.4188 3.7464 20.9744 3.48913 20.4869 3.35759C18.6926 2.875 11.5 2.875 11.5 2.875C11.5 2.875 4.30737 2.875 2.51306 3.35502C2.02539 3.48614 1.5808 3.74326 1.22394 4.10057C0.867087 4.45788 0.610525 4.90279 0.480022 5.39062C-9.79219e-08 7.1875 0 10.9353 0 10.9353C0 10.9353 -9.79219e-08 14.683 0.480022 16.4773C0.74442 17.4682 1.52478 18.2485 2.51306 18.5129C4.30737 18.9955 11.5 18.9955 11.5 18.9955C11.5 18.9955 18.6926 18.9955 20.4869 18.5129C21.4778 18.2485 22.2556 17.4682 22.52 16.4773C23 14.683 23 10.9353 23 10.9353C23 10.9353 23 7.1875 22.52 5.39319ZM9.2154 14.375V7.49554L15.1708 10.9096L9.2154 14.375Z"
                        fill="white"/>
                </svg>
            </a>
        </div>
    )
}

export default SocialBox