import React from 'react'
import liDot from "../../../img/li-dot.svg";

const ServicePageHeadList = props => {



    return (
        <div className="services-info__head-list">
            <h4 className="services-info__head-list-title">«МОНТАЖ без урахування матеріалів»
                включає:</h4>
            <ul className="services-info__head-ul">
                {
                    props.items.map((data, i) => {
                        return (
                            <li className="services-info__head-list-item" key={i}>
                                <div className="services-info__li-dot">
                                    <img src={liDot} alt={data} />
                                </div>
                                <span>{data}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default ServicePageHeadList