import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {useMediaQuery} from 'react-responsive/src';
import {Link} from 'react-router-dom';
import {_images} from '../../../../../API';
import {ADD_BASKET_SERVICE} from '../../../../../redux/actionConst/action';
import {removeServices} from '../../../../../redux/actionCreators/basketProducts';
import cartIcon from '../../../../../img/order-page/cart-icon.svg';
import closeIcon from '../../../../../img/order-page/close -icon.svg';

const OrderBodyDataAdditionalServicesItem = ({product, removeServices}) => {
    const dispatch = useDispatch();
    const isTabletSmall = useMediaQuery({ query: '(max-width: 770px)' });

    return (
        <div className="additional-services__product">
            <div className="additional-services__product-body">
                <div className="additional-services__product-info">
                    <Link
                        to={`/product_card/${product.slug}`}
                        target={"_blank"}
                        className="additional-services__product-img"
                    >
                        <img src={`${_images}${product.images[0]}`} alt={product.name}/>
                    </Link>

                    {
                        isTabletSmall && (
                            <div className="additional-services__product-name">
                                {product.name}
                            </div>
                        )
                    }
                </div>

                <div className="additional-services__product-data">
                    {
                        !isTabletSmall && (
                            <div className="additional-services__product-name">
                                {product.name}
                            </div>
                        )
                    }

                    <div className="additional-services__product-services">
                        {
                            product.services.map(service => {
                                const isServiceIncluded = product.new_services_id.includes(service.id);

                                return (
                                    <div key={service.id} className="additional-services__product-service">
                                        <div className="additional-services__service-name">
                                            {service.name}
                                        </div>


                                        <div className="additional-services__service-price">
                                            {service.price} грн
                                        </div>

                                        <button
                                            className={`additional-services__delete-btn ${isServiceIncluded ? 'active' : ''}`}
                                            onClick={() => {
                                                if(isServiceIncluded) {
                                                    removeServices({id: product.id, name: service.id})
                                                } else {
                                                    dispatch({type: ADD_BASKET_SERVICE, payload: {id: product.id, service}})
                                                }
                                            }}
                                        >
                                            {
                                                isTabletSmall
                                                    ? (
                                                        isServiceIncluded ? <img src={closeIcon} alt={"Видалити"} /> : <img src={cartIcon} alt={"Додати"} />
                                                    )
                                                    : (
                                                        <>
                                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.11643 2.20801V7.61668M7.11643 7.61668V13.0253M7.11643 7.61668H1.70776M7.11643 7.61668H12.5251" stroke="white" strokeWidth="1.58148"/>
                                                            </svg>

                                                            {
                                                                isServiceIncluded ? 'Видалити' : 'Додати'
                                                            }
                                                        </>

                                                    )
                                            }

                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    removeServices,
};

export default connect(null, mapDispatchToProps)(OrderBodyDataAdditionalServicesItem);
