import {
    ADD_ACTIVE_FILTERS,
    ADD_CATALOG_SLUG,
    ADD_FILTER,
    ADD_FILTER_BRANDS,
    ADD_FILTER_PRICE_MAX,
    ADD_FILTER_PRICE_MIN,
    REMOVE_ALL_FILTERS,
    SORT_DOWN_UP,
    SORT_NAME,
} from "../actionConst/action";

export const setSortName = payload => ({
    type: SORT_NAME,
    payload
})

export const setSortDownUp = payload => ({
    type: SORT_DOWN_UP,
    payload
})

export const addFilter = payload => {
    return {
        type: ADD_FILTER,
        payload
    }
}
export const addFilterBrands = payload => {
    return {
        type: ADD_FILTER_BRANDS,
        payload
    }
}
export const addFilterPriceMax = payload => {
    return {
        type: ADD_FILTER_PRICE_MAX,
        payload
    }
}
export const addFilterPriceMaxMin = payload => {
    return {
        type: ADD_FILTER_PRICE_MIN,
        payload
    }
}
export const addCatalogSlug = payload => {
    return {
        type: ADD_CATALOG_SLUG,
        payload
    }
}
export const cleanFilters = () => {
    return {
        type: REMOVE_ALL_FILTERS,
    }
}
export const addActiveFilters = payload => {
    return {
        type: ADD_ACTIVE_FILTERS,
        payload,
    }
}