import React, {useEffect} from 'react'
import './ServicePage.scss'
import ScrollToTopOnMount from "../../ScrolToTop";
import MontazhKondisijneriv from "./MontazhKondytsioneriv/MontazhKondytsioneriv";
import MontazhVentyliatsii from "./MontazhVentyliatsii/MontazhVentyliatsii";
import MontazhTeplovohoObladnannia from "./MontazhTeplovohoObladnannia/MontazhTeplovohoObladnannia";
import MontazhTsentralnykhPylososiv from "./MontazhTsentralnykhPylososiv/MontazhTsentralnykhPylososiv";
import ProektuvanniaSystem from "./ProektuvanniaSystem/ProektuvanniaSystem";
import ServisneObsluhovuvannia from "./ServisneObsluhovuvannia/ServisneObsluhovuvannia";
import Exchange from "./Exchange/Exchange";
import BreadCrumbs from '../globalComponents/BreadCrumbs/BreadCrumbs';

const ServicePage = props => {

    const chooseTitle = () => {
        switch(props.match.params.slug) {
            case 'montazh_kondytsioneriv':
                return 'Монтаж кондиціонерів'
            case 'montazh_ventyliatsii':
                return 'Монтаж вентиляційних систем'
            case 'montazh_teplovoho_obladnannia':
                return 'Монтаж теплового обладнання'
            case 'montazh_tsentralnykh_pylososiv':
                return 'Монтаж центральних пилососів'
            case 'proektuvannia_system':
                return 'Проектування систем вентиляції і кондиціонування'
            case 'servisne_obsluhovuvannia':
                return 'Обслуговування кондиціонерів та систем вентиляції повітря'
            case 'exchange':
                return 'Обмін та повернення'
        }
    }

    const choosePage = () => {
        switch(props.match.params.slug) {
            case 'montazh_kondytsioneriv':
                return <MontazhKondisijneriv />
            case 'montazh_ventyliatsii':
                return <MontazhVentyliatsii />
            case 'montazh_teplovoho_obladnannia':
                return <MontazhTeplovohoObladnannia />
            case 'montazh_tsentralnykh_pylososiv':
                return <MontazhTsentralnykhPylososiv />
            case 'proektuvannia_system':
                return <ProektuvanniaSystem />
            case 'servisne_obsluhovuvannia':
                return <ServisneObsluhovuvannia />
            case 'exchange':
                return <Exchange />
        }
    }

    const crumbs = [
        {name: 'Головна', link: '/'},
        {name: 'Наші послуги', link: '/services'},
        {name: chooseTitle()},
    ]

    return (
        <div className="content-wrap">
            <div className="container">
                <BreadCrumbs items={crumbs}/>
            </div>

            <ScrollToTopOnMount />
            {
                choosePage()
            }
        </div>
    )
}

export default ServicePage