import React from 'react'

const CompareHeaderSelectItem = props => {
    const prod =[]

    return (
        <div
            className="compare-filter__item"
            onClick={() => {
                props.open()
                props.choose(props.data)
            }}>
            {props.data}
            <span className="compare-filter__item-quantity">
                {
                    props.products.map(item => {
                        if(item.category.name === props.data) {
                            prod.push(item)
                        }
                    })
                }
                ({prod.length})
            </span>
        </div>
    )
}

export default CompareHeaderSelectItem