import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import np from "../../../../../img/order-page/np.svg";
import arrow from "../../../../../img/arrow-down-18.svg";
import { getBranchNP } from "../../../../../redux/actionCreators/getCreators/getOrder";
import axios from "axios";
import {_npAPIkey} from "../../../../../API";

const OrderBodyDataDeliveryNpBranch = (props) => {
  const [activeNp, setActiveNp] = useState(false);
  const [deliveryNp, setDeliveryNp] = useState("");
  const [loading, setLoading] = useState();
  const openNp = () => {
    setActiveNp(!activeNp);
  };
  const chooseNp = (data) => {
    setDeliveryNp(data);
  };
  const [active, setActive] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://api.novaposhta.ua/v2.0/json/AddressGeneral/getWarehouses",
        {
          modelName: "AddressGeneral",
          calledMethod: "getWarehouses",
          methodProperties: {
            Language: "ru",
            CityName: "",
            Page: "1",
            Limit: "500",
          },
          apiKey: _npAPIkey,
        }
      )
      .then((res) => props.getBranchNP(res.data.data));
  }, [props.city]);

  const getBranch = () => {
    setLoading(true);
    axios
      .post(
        "https://api.novaposhta.ua/v2.0/json/AddressGeneral/getWarehouses",
        {
          modelName: "AddressGeneral",
          calledMethod: "getWarehouses",
          methodProperties: {
            Language: "ru",
            CityName: props.city,
            Page: "1",
            Limit: "200",
          },
          apiKey: _npAPIkey,
        }
      )
      .then((res) => {
        props.getBranchNP(res.data.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <label className="radio-wrap">
        <input
          id="npAddress"
          type="radio"
          name="post"
          checked={props.activeRadio === "npAddress"}
          onChange={props.openSubSelect}
          onClick={() => {
            props.addDeliveryCost(0);
            props.addDeliveryWay(1);
          }}
        />
        <span className="radio">
          <img src={np} alt="Доставка у відділення" />
          Доставка у відділення
          <span className="order-data__post">
            Нової Пошти {loading ? "(Завантаження...)" : ""}
            {props.branchNP.length < 1 ? "(Немає відділень)" : ""}
          </span>
        </span>
        <span className="order-data__delivery-price">За тарифом</span>
      </label>
      <div
        className={`inline-input ${
          props.activeRadio === "npAddress" ? "active" : ""
        }`}
      >
        <div
          className={`order-data__dropdown-selector order-data__dropdown-selector--city ${
            active ? "active" : ""
          }`}
          style={{
            marginLeft: "30px",
            borderColor:
              !props.empty || props.delivery.length !== 0 ? "" : "red",
          }}
          onMouseLeave={() => {
            setActive(false);
          }}
        >
          <input
            className="order-data__select-input"
            type="text"
            placeholder="Ваше місто"
            value={props.delivery}
            onClick={() => {
              setActive(true);
            }}
            onFocus={() => {
              setActive(true);
            }}
            onChange={(e) => {
              props.chooseCity(e.target.value);
              props.getEnterCity(props.delivery);
              props.setEmpty(false);
              setActive(true);
            }}
          />
          <img className="arrow-down" src={arrow} alt="" />
          <ul className={`order-data__dropdown ${active ? "active" : ""}`}>
            {props.cityList.map((data, i) => {
              return (
                <li
                  className="order-data__dropdown-item"
                  key={i}
                  onClick={() => {
                    props.chooseCity(data.Present);
                    props.addDeliveryCity(data.Present);
                    props.setCity(data.MainDescription);
                    setActive(false);
                  }}
                >
                  {data.Present}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={`order-data__dropdown-selector ${
            props.activeRadio === "npAddress" ? "active" : ""
          }`}
          style={{ borderColor: !props.empty || deliveryNp ? "" : "red" }}
          onFocus={() => {
            getBranch();
          }}
          onClick={() => {
            setActiveNp(!activeNp);
          }}
          onMouseLeave={() => {
            setActiveNp(false);
          }}
        >
          <input
            className="order-data__select-input"
            type="text"
            placeholder="Вкажіть відділення"
            value={deliveryNp}
            readOnly={true}
          />
          <img className="arrow-down" src={arrow} alt="arrow-down" />
          <ul className={`order-data__dropdown ${activeNp ? "active" : ""}`}>
            {props.branchNP.length > 0
              ? props.branchNP.map((data, i) => {
                  return (
                    <li
                      className="order-data__dropdown-item"
                      key={i}
                      onClick={() => {
                        openNp();
                        chooseNp(data.Description);
                        props.addDeliveryBranch(data.Description);
                        props.setEmpty(false);
                      }}
                    >
                      {data.Description}
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    branchNP: store.orderReducer.branchNP,
  };
};

const mapDispatchToProps = {
  getBranchNP,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderBodyDataDeliveryNpBranch);
