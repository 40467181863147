import React from 'react'

const WishlistModal = props => {

    return (
        <div className="header-actions__modal-wrap" onMouseLeave={() => {
            props.setWishlistModal(false)
        }}>
            <section className={`modal modal-favorite ${props.wishlistModal? 'active' : ''}`}>
                <div className="modal__close" onClick={props.openWishlist}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 6.974L17.026 5L12 10.026L6.974 5L5 6.974L10.026 12L5 17.026L6.974 19L12 13.974L17.026 19L19 17.026L13.974 12L19 6.974Z"
                            fill="#868686"/>
                    </svg>
                </div>
                <h3 className="modal-favorite__title">Список бажань</h3>
                <div className="modal-favorite__body">
                    <p className="modal-favorite__body-title">Ваш список бажань порожній!</p>
                    <p className="modal-favorite__body-subtitle">
                        Додайте товари до списку бажань зі сторінки каталогу чи сторінки товару
                    </p>
                </div>
            </section>
        </div>
    )
}

export default WishlistModal