import React, { useState } from "react";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import {
  toggleBuyOneClick,
  toggleModal,
  toggleBuyOneClickMobile,
} from "../../../redux/actionCreators/modalsCreator";
import { analyticEvents } from "../../../utils/googleAnalytics/events";
import { cleanPhone } from "../../CleanPhone";
import { createLeadOneClick } from "../../../bitrixApi";

const ProductPageInfoOneClick = (props) => {
  const [phone, setPhone] = useState("");
  const [inputError, setInputError] = useState(false);

  const callBack = () => {
    window.axios
      .post(`/back-call`, {
        type: "buy_one_click",
        phone: cleanPhone(phone),
        product_id: props.id,
        comment: props.name,
      })
      .then(() => {
        props.toggleBuyOneClick(true);
        props.toggleModal(true);
        analyticEvents.buyOneClick();
        createLeadOneClick(phone, props.name);
      });
  };

  return (
    <>
      <InputMask
        mask="+38 (099) 999-99-99"
        style={{ borderColor: inputError && "red" }}
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
          setInputError(false);
        }}
        required
        className="product-info__phone-input"
        placeholder="+38 (0__) ___-__-__"
      />
      <input
        className="product-info__phone-submit"
        type="submit"
        value="Купити в 1 клік"
        onClick={() => {
          if (!phone.includes("_") && phone !== "") {
            callBack();
            setPhone("");
          } else {
            setInputError(true);
          }
        }}
      />
      <input
        className="product-info__phone-submit mobile-one-click"
        type="submit"
        value="Купити в 1 клік"
        onClick={() => {
          props.toggleBuyOneClickMobile(true);
          props.toggleModal(true);
        }}
      />
      <div className="product-info__phone-popup">
        Залиште ваш номер телефону і наш спеціаліст зв’яжеться з вами для
        оформлення замовлення
      </div>
    </>
  );
};

const mapDispatchToProps = {
  toggleBuyOneClick,
  toggleModal,
  toggleBuyOneClickMobile,
};

export default connect(null, mapDispatchToProps)(ProductPageInfoOneClick);
