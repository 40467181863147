import {GET_PRODUCTS} from "../../actionConst/getActions";
import {ADD_CATALOG_SLUG, REMOVE_ALL_FILTERS, SORT_DOWN_UP, SORT_NAME} from "../../actionConst/action";

export const initialState = {
    products: [],
    sortName: 'expensive',
    activeSlug: '',
}

export const catalogProductsReducer = (state = initialState, action) => {
    switch(action.type) {
        case REMOVE_ALL_FILTERS:
            return {
                ...state,
                sortName: 'expensive',
            }
        case ADD_CATALOG_SLUG:
            return {
                ...state,
                activeSlug: action.payload
            }
        case SORT_NAME:
            return {
                ...state,
                sortName: action.payload
            }
        case SORT_DOWN_UP:
            return {
                ...state,
                sortDownUp: action.payload
            }
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        default:
            return state
    }
}