import React, {useState} from 'react'
import {connect} from 'react-redux'
import {
    toggleModal,
    toggleFeedbackModal,
    toggleLogModal,
    toggleLoginModal,
} from "../../../redux/actionCreators/modalsCreator";
import ProductPageFeedbackAdd from "./ProductPageFeedbackAdd";
import ProductPageFeedbackFalse from "./ProductPageFeedbackFalse";
import ProductPageFeedbackTrue from "./ProductPageFeedbackTrue";
import {Element} from 'react-scroll'

const ProductPageFeedback = (props) => {

    const [activeReview, setActiveReview] = useState(false)

    return (
        <Element className="product-page__content-wrap" id="product-reviews">
            <div className={`product-page__reviews-wrap ${activeReview ? 'active' : ''}`}>
                {
                    props.rates.length > 0?
                        <ProductPageFeedbackTrue
                            activeReview={activeReview}
                            setActiveReview={setActiveReview}
                            feed={props.feed}
                            rates={props.rates}
                        /> :
                        <ProductPageFeedbackFalse
                            activeReview={activeReview}
                            setActiveReview={setActiveReview}
                            feed={props.feed}
                        />
                }
                <div className="set-toggle-feedback" onClick={() => {
                    setActiveReview(prev => !prev)
                }}>
                    <svg width="9" height="27" viewBox="0 0 9 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path  d="M1.0521 26.3939C0.815523 26.275 0.63572 26.0672 0.552114 25.8161C0.468508 25.5649 0.487922 25.2908 0.606099 25.0539L6.3801 13.4999L0.604099 1.94786C0.544061 1.83034 0.50788 1.70209 0.497642 1.57051C0.487405 1.43894 0.503312 1.30663 0.544449 1.18124C0.585585 1.05584 0.651136 0.939821 0.737323 0.839876C0.823509 0.739931 0.928627 0.658037 1.04662 0.598911C1.1646 0.539785 1.29313 0.504599 1.42478 0.49538C1.55643 0.486162 1.6886 0.503094 1.81368 0.5452C1.93876 0.587307 2.05426 0.653755 2.15354 0.740713C2.25281 0.827671 2.33389 0.93342 2.3921 1.05186L8.3921 13.0519C8.46178 13.1909 8.49806 13.3443 8.49806 13.4999C8.49806 13.6554 8.46178 13.8088 8.3921 13.9479L2.3921 25.9479C2.27327 26.1844 2.06548 26.3642 1.81429 26.4478C1.5631 26.5315 1.289 26.512 1.0521 26.3939Z" fill="#182316"/>
                    </svg>
                </div>
            </div>
            {
                props.isLogged ? (
                    <button className="review-add__submit-btn btn-white-second mobile-review-add" onClick={() => {
                        props.toggleModal(true)
                        props.toggleFeedbackModal(true)
                    }}>Додати відгук</button>
                ) : (
                    <button className="review-add__submit-btn btn-white-second mobile-review-add" onClick={() => {
                        props.toggleModal(true)
                        props.toggleLogModal(true)
                        props.toggleLoginModal(true)
                    }}>Авторизуйтесь, щоб залишити відгук</button>
                )
            }
            <ProductPageFeedbackAdd activeReview={activeReview} setActiveReview={setActiveReview} id={props.id} addFeed={props.addFeed}/>
        </Element>
    )
}

const mapStateToProps = store => {
    return {
        isLogged: store.userReducer.info.isLogged
    }
}

const mapDispatchToProps = {
    toggleModal,
    toggleFeedbackModal,
    toggleLogModal,
    toggleLoginModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageFeedback)